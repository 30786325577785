<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3073_7169)">
      <path d="M7.99998 0C3.58172 0 0 3.58172 0 7.99998C0 12.4183 3.58172 16 7.99998 16C12.4183 16 16 12.4183 16 7.99998C15.9953 3.58369 12.4163 0.00472098 7.99998 0ZM7.99998 14.8571C4.21289 14.8571 1.14285 11.7871 1.14285 7.99998C1.14285 4.21289 4.21289 1.14285 7.99998 1.14285C11.7871 1.14285 14.8571 4.21289 14.8571 7.99998C14.853 11.7854 11.7854 14.853 7.99998 14.8571Z" fill="#49A399"/>
      <path d="M5.14285 7.99956C5.77403 7.99956 6.2857 7.48789 6.2857 6.85671C6.2857 6.22554 5.77403 5.71387 5.14285 5.71387C4.51167 5.71387 4 6.22554 4 6.85671C4 7.48789 4.51167 7.99956 5.14285 7.99956Z" fill="#49A399"/>
      <path d="M10.8577 7.99956C11.4889 7.99956 12.0005 7.48789 12.0005 6.85671C12.0005 6.22554 11.4889 5.71387 10.8577 5.71387C10.2265 5.71387 9.71484 6.22554 9.71484 6.85671C9.71484 7.48789 10.2265 7.99956 10.8577 7.99956Z" fill="#49A399"/>
      <path d="M10.1876 9.93185C9.99891 9.67892 9.64085 9.62689 9.38793 9.81566C9.37698 9.82383 9.36636 9.83237 9.35602 9.84131C8.54793 10.4315 7.45095 10.4315 6.64285 9.84131C6.40409 9.63493 6.04322 9.66121 5.83687 9.89997C5.63049 10.1387 5.65677 10.4996 5.89553 10.706C5.90584 10.7149 5.91649 10.7234 5.92744 10.7316C7.15058 11.6665 8.84829 11.6665 10.0714 10.7316C10.3244 10.5428 10.3764 10.1848 10.1876 9.93185Z" fill="#49A399"/>
    </g>
    <defs>
      <clipPath id="clip0_3073_7169">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
