<template>
  <img src="@/assets/images/spinner/spinner.gif" alt="spinner" />
</template>

<style scoped>
img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
