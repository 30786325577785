<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_13199_64073)">
      <path
        d="M16.5018 22.3256C12.6024 22.3256 9.43213 19.1553 9.43213 15.2559V7.06977C9.43213 3.17023 12.6024 0 16.5018 0C20.4014 0 23.5717 3.17023 23.5717 7.06977V15.2559C23.5717 19.1553 20.4014 22.3256 16.5018 22.3256ZM16.5018 2.23256C13.8377 2.23256 11.6647 4.40559 11.6647 7.06977V15.2559C11.6647 17.92 13.8377 20.0931 16.5018 20.0931C19.1661 20.0931 21.3392 17.92 21.3392 15.2559V7.06977C21.3392 4.40559 19.1661 2.23256 16.5018 2.23256Z"
        fill="currentColor"
      />
      <path
        d="M16.5023 27.5346C9.61116 27.5346 4 21.9234 4 15.0322V12.502C4 11.8918 4.50604 11.3857 5.11628 11.3857C5.72651 11.3857 6.23256 11.8918 6.23256 12.502V15.0322C6.23256 20.6881 10.8465 25.3021 16.5023 25.3021C22.1581 25.3021 26.7721 20.6881 26.7721 15.0322V12.502C26.7721 11.8918 27.2781 11.3857 27.8884 11.3857C28.4987 11.3857 29.0047 11.8918 29.0047 12.502V15.0322C29.0047 21.9234 23.3935 27.5346 16.5023 27.5346Z"
        fill="currentColor"
      />
      <path
        d="M18.5712 8.82646C18.4522 8.82646 18.3182 8.81158 18.1842 8.76693C17.0978 8.36508 15.907 8.36508 14.8206 8.76693C14.24 8.9753 13.6 8.67764 13.3916 8.09717C13.1833 7.5167 13.481 6.8767 14.0615 6.66833C15.6391 6.10274 17.3806 6.10274 18.9582 6.66833C19.5387 6.8767 19.8363 7.5167 19.6279 8.09717C19.4494 8.54368 19.0178 8.82646 18.5712 8.82646Z"
        fill="currentColor"
      />
      <path
        d="M17.6932 11.9816C17.5891 11.9816 17.4997 11.9667 17.3956 11.937C16.8001 11.7733 16.19 11.7733 15.5947 11.937C14.9992 12.1007 14.3891 11.7435 14.2253 11.1481C14.0616 10.5677 14.4188 9.95744 15.0141 9.7937C15.9816 9.5258 17.0235 9.5258 17.9909 9.7937C18.5863 9.95744 18.9435 10.5677 18.7797 11.163C18.6457 11.6542 18.1844 11.9816 17.6932 11.9816Z"
        fill="currentColor"
      />
      <path
        d="M16.5025 32.0005C15.8922 32.0005 15.3862 31.4943 15.3862 30.8842V26.419C15.3862 25.8087 15.8922 25.3027 16.5025 25.3027C17.1126 25.3027 17.6188 25.8087 17.6188 26.419V30.8842C17.6188 31.4943 17.1126 32.0005 16.5025 32.0005Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13199_64073">
        <rect width="32" height="32" fill="currentColor " />
      </clipPath>
    </defs>
  </svg>
</template>
