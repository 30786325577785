<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.652 1.251c-1.218.15-2.044.846-2.331 1.965-.129.501-.128 13.097 0 13.566.408 1.484 1.15 1.928 3.273 1.958l1.294.018.031.736c.08 1.872.752 2.834 2.22 3.178.557.131 7.249.131 7.806 0 1.44-.337 2.121-1.31 2.178-3.11l.026-.804 1.27-.018c2.1-.03 2.845-.48 3.25-1.958.13-.469.13-13.065.002-13.566-.25-.974-.918-1.64-1.898-1.892-.487-.125-5.695-.123-6.152.002-1.4.384-1.941 1.214-2.002 3.069l-.028.855H11.4l-.029-.855c-.06-1.845-.59-2.664-1.978-3.066-.27-.078-5.195-.145-5.742-.078Zm5.452 1.602c.535.179.69.523.723 1.607l.024.78-.814.021c-1.716.046-2.604.673-3.004 2.122-.103.373-.103.395-.12 5.121l-.017 4.746H5.09c-1.61 0-2.016-.133-2.222-.722-.078-.224-.083-.624-.083-6.567V3.633l.132-.27c.148-.301.384-.476.742-.548.336-.067 5.232-.034 5.445.038Zm11.543.078c.24.123.295.177.418.419l.142.276v6.332c0 5.945-.006 6.346-.084 6.57-.205.587-.614.721-2.2.722h-.784l-.015-4.793c-.014-4.39-.022-4.818-.097-5.097-.39-1.445-1.392-2.11-3.181-2.11h-.706l.024-.785c.026-.86.081-1.103.306-1.34.332-.348.394-.355 3.341-.345l2.559.01.276.141Zm-4.968 3.927c.383.126.592.308.749.648l.138.299v6.164c0 6.985.028 6.466-.377 6.872-.334.333-.482.36-2.072.382l-1.371.019V19.99c0-1.542-.092-1.755-.753-1.755s-.747.201-.747 1.75v1.258l-1.348-.018c-1.964-.027-2.26-.19-2.401-1.317-.062-.493-.065-11.248-.003-11.784.1-.868.457-1.236 1.286-1.328.71-.078 6.637-.025 6.9.062Zm-6.026 3.466c-.537.275-.578.951-.08 1.32.206.153 4.638.154 4.842.002.492-.367.452-1.051-.077-1.322-.205-.104-.22-.105-2.342-.105-2.124 0-2.138 0-2.343.105Zm0 3c-.537.275-.578.951-.08 1.32.206.153 4.638.154 4.842.002.492-.367.452-1.051-.077-1.322-.205-.104-.22-.105-2.342-.105-2.124 0-2.138 0-2.343.105Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
