<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.83962 17.0863C9.52457 16.9332 9.37579 16.5357 9.50808 16.2007C9.54036 16.1189 10.0878 15.5225 11.285 14.2645L13.0152 12.4464L11.257 10.5963C9.33885 8.57795 9.41273 8.67063 9.43732 8.31391C9.46146 7.96338 9.71997 7.71401 10.0591 7.71401C10.1497 7.71401 10.2735 7.73767 10.3342 7.76663C10.4044 7.80004 11.1706 8.5791 12.4347 9.90244C13.96 11.4992 14.4421 12.0223 14.4994 12.1427C14.586 12.3246 14.596 12.5197 14.5278 12.6933C14.4635 12.8568 10.5252 17.0012 10.3539 17.0855C10.2001 17.1613 9.99458 17.1616 9.83962 17.0863Z"
      fill="#1F1F1F"
    />
  </svg>
</template>
