<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#353535" />
    <path
      d="M20.0004 30.7595C18.9104 30.7595 17.8304 30.4395 16.9804 29.8095L12.6804 26.5995C11.5404 25.7495 10.6504 23.9695 10.6504 22.5595V15.1195C10.6504 13.5795 11.7804 11.9395 13.2304 11.3995L18.2204 9.52945C19.2104 9.15945 20.7704 9.15945 21.7604 9.52945L26.7504 11.3995C28.2004 11.9395 29.3304 13.5795 29.3304 15.1195V22.5495C29.3304 23.9695 28.4404 25.7395 27.3004 26.5895L23.0004 29.7995C22.1704 30.4395 21.0904 30.7595 20.0004 30.7595ZM18.7504 10.9395L13.7604 12.8095C12.9104 13.1295 12.1604 14.2095 12.1604 15.1295V22.5595C12.1604 23.5095 12.8304 24.8395 13.5804 25.3995L17.8804 28.6095C19.0304 29.4695 20.9704 29.4695 22.1304 28.6095L26.4304 25.3995C27.1904 24.8295 27.8504 23.5095 27.8504 22.5595V15.1195C27.8504 14.2095 27.1004 13.1295 26.2504 12.7995L21.2604 10.9295C20.5804 10.6895 19.4204 10.6895 18.7504 10.9395Z"
      fill="white"
    />
    <path
      d="M22.1498 22.1909C21.9598 22.1909 21.7698 22.1209 21.6198 21.9709L17.3698 17.7209C17.0798 17.4309 17.0798 16.9509 17.3698 16.6609C17.6598 16.3709 18.1398 16.3709 18.4298 16.6609L22.6798 20.9109C22.9698 21.2009 22.9698 21.6809 22.6798 21.9709C22.5298 22.1109 22.3398 22.1909 22.1498 22.1909Z"
      fill="white"
    />
    <path
      d="M17.8491 22.2299C17.6591 22.2299 17.4691 22.1599 17.3191 22.0099C17.0291 21.7199 17.0291 21.2399 17.3191 20.9499L21.5691 16.6999C21.8591 16.4099 22.3391 16.4099 22.6291 16.6999C22.9191 16.9899 22.9191 17.4699 22.6291 17.7599L18.3791 22.0099C18.2391 22.1599 18.0391 22.2299 17.8491 22.2299Z"
      fill="white"
    />
  </svg>
</template>
