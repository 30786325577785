<template>
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/next">
      <g id="next">
        <path
          id="Vector"
          d="M4.40476 22C3.64394 22 2.89647 21.7962 2.20238 21.3885C0.814209 20.5732 0 19.1328 0 17.5022V4.49782C0 2.88077 0.827556 1.42681 2.20238 0.611489C3.59055 -0.20383 5.23232 -0.20383 6.60714 0.611489L17.6725 7.10686C19.0473 7.92218 19.8749 9.37614 19.8749 10.9932C19.8749 12.6102 19.0473 14.0642 17.6725 14.8796L6.60714 21.3749C5.91306 21.7962 5.16559 22 4.40476 22ZM4.40476 2.05188C3.99098 2.05188 3.57721 2.16058 3.20347 2.378C2.45599 2.82642 2.00217 3.61456 2.00217 4.49782V17.5022C2.00217 18.3854 2.45599 19.1736 3.20347 19.622C3.95095 20.0568 4.85858 20.0704 5.60606 19.622L16.6714 13.1266C17.4189 12.6782 17.8727 11.8901 17.8727 11.0068C17.8727 10.1235 17.4189 9.33539 16.6714 8.88697L5.60606 2.3916C5.23232 2.17418 4.81855 2.05188 4.40476 2.05188Z"
          fill="#49A399"
        />
        <path
          id="Vector_2"
          d="M22.9991 20.4237C22.4519 20.4237 21.998 19.9617 21.998 19.4045V2.60899C21.998 2.05186 22.4519 1.58984 22.9991 1.58984C23.5464 1.58984 24.0002 2.05186 24.0002 2.60899V19.4045C24.0002 19.9617 23.5597 20.4237 22.9991 20.4237Z"
          fill="#49A399"
        />
      </g>
    </g>
  </svg>
</template>
