<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9738 1.48016C9.68256 1.89554 6.91777 4.13498 5.82976 7.26657C5.44091 8.38608 5.40745 8.65257 5.36181 10.994C5.30651 13.8371 5.32617 13.7677 3.90336 16.1352C2.88786 17.825 2.7863 18.0727 2.74808 18.9521C2.64588 21.3044 4.1235 22.3852 8.61358 23.2425C9.4736 23.4068 9.46122 23.4033 9.46122 23.481C9.46122 23.52 9.5784 23.7855 9.72162 24.0711C11.4974 27.6118 16.4657 27.6303 18.2659 24.1027C18.4163 23.808 18.5394 23.5346 18.5394 23.4951C18.5394 23.4464 18.7554 23.3834 19.2093 23.2997C23.2306 22.5575 24.7274 21.696 25.1888 19.858C25.4768 18.7109 25.2676 17.9946 24.0488 15.955C22.7406 13.7659 22.6985 13.6173 22.6955 11.1855C22.6934 9.45204 22.6367 8.77157 22.4254 7.9441C21.3552 3.7525 17.2444 0.941162 12.9738 1.48016ZM15.2992 3.36484C17.6089 3.80794 19.5797 5.4591 20.4314 7.66431C20.8161 8.66055 20.8442 8.87636 20.8891 11.1855C20.9479 14.2106 21.015 14.4535 22.4575 16.8576C23.6993 18.9274 23.7714 19.349 23.0281 20.1955C22.2368 21.0968 16.8386 22.0821 13.344 21.963C9.94772 21.8473 5.79021 20.9645 4.99158 20.1895C4.24265 19.4626 4.33078 18.9739 5.59022 16.8698C7.04426 14.4406 7.10145 14.2349 7.16326 11.2128C7.20988 8.93187 7.23031 8.75617 7.55672 7.83084C8.67889 4.64913 12.0022 2.73218 15.2992 3.36484ZM13.5412 6.74297C13.1161 6.98664 13.1211 6.95248 13.1378 9.53506L13.1527 11.8222L13.3791 12.0334C13.7672 12.3954 14.2335 12.3954 14.6215 12.0334L14.8479 11.8222V9.48039C14.8479 7.22387 14.8439 7.13322 14.7386 6.99189C14.4652 6.62523 13.9377 6.51561 13.5412 6.74297ZM13.3987 23.7654C13.7747 23.7824 14.5974 23.7708 15.2269 23.7397C15.8565 23.7085 16.3815 23.6929 16.3936 23.705C16.4552 23.7666 15.731 24.3894 15.389 24.569C14.138 25.2257 12.5299 24.9157 11.727 23.8632L11.5797 23.67L12.1474 23.7022C12.4597 23.7199 13.0227 23.7484 13.3987 23.7654Z"
      fill="currentColor"
    />
  </svg>
</template>
