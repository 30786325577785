<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar">
    <g id="calendar-2">
    <path id="Vector" d="M4.33398 3.11426C4.1119 3.11426 3.92773 2.93009 3.92773 2.70801V1.08301C3.92773 0.860924 4.1119 0.676758 4.33398 0.676758C4.55607 0.676758 4.74023 0.860924 4.74023 1.08301V2.70801C4.74023 2.93009 4.55607 3.11426 4.33398 3.11426Z" fill="#B0B0B0"/>
    <path id="Vector_2" d="M8.66602 3.11426C8.44393 3.11426 8.25977 2.93009 8.25977 2.70801V1.08301C8.25977 0.860924 8.44393 0.676758 8.66602 0.676758C8.8881 0.676758 9.07227 0.860924 9.07227 1.08301V2.70801C9.07227 2.93009 8.8881 3.11426 8.66602 3.11426Z" fill="#B0B0B0"/>
    <path id="Vector_3" d="M11.1048 5.33008H1.89648C1.6744 5.33008 1.49023 5.14591 1.49023 4.92383C1.49023 4.70174 1.6744 4.51758 1.89648 4.51758H11.1048C11.3269 4.51758 11.5111 4.70174 11.5111 4.92383C11.5111 5.14591 11.3269 5.33008 11.1048 5.33008Z" fill="#B0B0B0"/>
    <path id="Vector_4" d="M8.66667 12.3226H4.33333C2.35625 12.3226 1.21875 11.1851 1.21875 9.20801V4.60384C1.21875 2.62676 2.35625 1.48926 4.33333 1.48926H8.66667C10.6438 1.48926 11.7812 2.62676 11.7812 4.60384V9.20801C11.7812 11.1851 10.6438 12.3226 8.66667 12.3226ZM4.33333 2.30176C2.78417 2.30176 2.03125 3.05467 2.03125 4.60384V9.20801C2.03125 10.7572 2.78417 11.5101 4.33333 11.5101H8.66667C10.2158 11.5101 10.9688 10.7572 10.9688 9.20801V4.60384C10.9688 3.05467 10.2158 2.30176 8.66667 2.30176H4.33333Z" fill="#B0B0B0"/>
    <path id="Vector_5" d="M4.60417 7.85385C4.53375 7.85385 4.46333 7.8376 4.39833 7.81052C4.33333 7.78344 4.27375 7.74551 4.21959 7.69676C4.17084 7.64259 4.13291 7.58302 4.10583 7.51802C4.07874 7.45302 4.0625 7.3826 4.0625 7.31218C4.0625 7.17135 4.12209 7.03052 4.21959 6.9276C4.27375 6.87885 4.33333 6.84092 4.39833 6.81384C4.49583 6.77051 4.60417 6.75967 4.71251 6.78134C4.74501 6.78676 4.7775 6.79759 4.81 6.81384C4.8425 6.82467 4.875 6.84094 4.9075 6.8626C4.93458 6.88427 4.96166 6.90594 4.98875 6.9276C5.01041 6.95469 5.03749 6.98177 5.05374 7.00885C5.07541 7.04135 5.09167 7.07384 5.10251 7.10634C5.11876 7.13884 5.12959 7.17134 5.13501 7.20384C5.14042 7.24176 5.14583 7.27426 5.14583 7.31218C5.14583 7.45301 5.08625 7.59384 4.98875 7.69676C4.88583 7.79426 4.745 7.85385 4.60417 7.85385Z" fill="#B0B0B0"/>
    <path id="Vector_6" d="M6.50065 7.85422C6.35982 7.85422 6.21899 7.79463 6.11607 7.69713C6.09441 7.67005 6.07274 7.64297 6.05107 7.61589C6.02941 7.58339 6.01314 7.55089 6.00231 7.51839C5.98606 7.48589 5.97523 7.45339 5.96981 7.42089C5.9644 7.38298 5.95898 7.35047 5.95898 7.31255C5.95898 7.24214 5.97523 7.17172 6.00231 7.10672C6.02939 7.04172 6.06732 6.98214 6.11607 6.92797C6.26774 6.77631 6.51149 6.72755 6.70649 6.81421C6.7769 6.8413 6.83106 6.87922 6.88523 6.92797C6.98273 7.03089 7.04232 7.17172 7.04232 7.31255C7.04232 7.35047 7.03691 7.38298 7.03149 7.42089C7.02607 7.45339 7.01524 7.48589 6.99899 7.51839C6.98816 7.55089 6.97189 7.58339 6.95023 7.61589C6.92856 7.64297 6.9069 7.67005 6.88523 7.69713C6.83106 7.74588 6.7769 7.78381 6.70649 7.81089C6.64149 7.83798 6.57107 7.85422 6.50065 7.85422Z" fill="#B0B0B0"/>
    <path id="Vector_7" d="M4.60417 9.74973C4.53375 9.74973 4.46333 9.73349 4.39833 9.7064C4.33333 9.67932 4.27375 9.64139 4.21959 9.59264C4.17084 9.53847 4.13291 9.48431 4.10583 9.4139C4.07874 9.3489 4.0625 9.27848 4.0625 9.20806C4.0625 9.06723 4.12209 8.9264 4.21959 8.82348C4.27375 8.77473 4.33333 8.7368 4.39833 8.70972C4.59875 8.62305 4.83708 8.67182 4.98875 8.82348C5.01041 8.85057 5.03749 8.87765 5.05374 8.90473C5.07541 8.93723 5.09167 8.96973 5.10251 9.00223C5.11876 9.03473 5.12959 9.06723 5.13501 9.10514C5.14042 9.13764 5.14583 9.17556 5.14583 9.20806C5.14583 9.34889 5.08625 9.48972 4.98875 9.59264C4.88583 9.69014 4.745 9.74973 4.60417 9.74973Z" fill="#B0B0B0"/>
    </g>
    </g>
  </svg>
</template>
