<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#a)">
      <path fill="url(#b)" d="M6 11.2c2.795 0 5.06-2.328 5.06-5.2C11.06 3.128 8.795.8 6 .8 3.208.8.942 3.128.942 6c0 2.872 2.266 5.2 5.06 5.2Z"/>
      <path fill="url(#c)" d="M6 11.2c2.795 0 5.06-2.328 5.06-5.2C11.06 3.128 8.795.8 6 .8 3.208.8.942 3.128.942 6c0 2.872 2.266 5.2 5.06 5.2Z"/>
      <path fill="#303030" d="M4.146 6.639c.27 0 .488-.413.488-.923s-.219-.922-.488-.922c-.27 0-.488.413-.488.922 0 .51.219.923.488.923Z"/>
      <path stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width=".5" d="m3.541 4.4 1.24.984"/>
      <path fill="#303030" d="M8.46 5.716c0 .504-.212.923-.488.923-.265 0-.488-.419-.488-.923s.212-.922.488-.922.488.418.488.922Z"/>
      <path stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width=".5" d="m8.45 4.4-1.241.984"/>
      <path stroke="#5B0600" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M4.4 8s1.341-.9 3.2 0"/>
    </g>
    <defs>
      <linearGradient id="b" x1="1.613" x2="10.5" y1="8.603" y2="3.61" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset=".033" stop-color="#ECB64D"/>
        <stop offset=".178" stop-color="#EEC04F"/>
        <stop offset=".551" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
      <linearGradient id="c" x1="6" x2="6" y1=".802" y2="11.207" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DD5F70"/>
        <stop offset=".162" stop-color="#DD636F" stop-opacity=".968"/>
        <stop offset=".686" stop-color="#EBAA5D" stop-opacity=".362"/>
        <stop offset="1" stop-color="#F3D652" stop-opacity="0"/>
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
