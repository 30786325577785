<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <g fill="currentColor" clip-path="url(#a)">
      <path d="M8 13.356a.546.546 0 0 0-.547.546v1.552a.546.546 0 1 0 1.092 0v-1.552A.546.546 0 0 0 8 13.356ZM8 2.645a.546.546 0 0 0 .545-.546V.546a.546.546 0 1 0-1.092 0V2.1c0 .301.245.546.546.546ZM3.442 11.785l-1.098 1.098a.546.546 0 1 0 .772.772l1.098-1.098a.546.546 0 1 0-.772-.772ZM12.173 4.373c.14 0 .28-.053.386-.16l1.098-1.097a.546.546 0 0 0-.772-.772L11.787 3.44a.546.546 0 0 0 .386.932ZM2.644 8.001a.546.546 0 0 0-.546-.546H.546a.546.546 0 0 0 0 1.092h1.552a.546.546 0 0 0 .546-.546ZM15.454 7.453h-1.553a.546.546 0 1 0 0 1.092h1.553a.546.546 0 1 0 0-1.092ZM3.441 4.213a.544.544 0 0 0 .772 0 .546.546 0 0 0 0-.772L3.116 2.344a.546.546 0 1 0-.772.772L3.44 4.213ZM12.56 11.787a.546.546 0 1 0-.773.772l1.097 1.098a.545.545 0 0 0 .772 0 .546.546 0 0 0 0-.772l-1.097-1.098ZM8 3.75A4.254 4.254 0 0 0 3.75 8 4.254 4.254 0 0 0 8 12.25 4.255 4.255 0 0 0 12.25 8 4.255 4.255 0 0 0 8 3.75Zm0 7.408A3.161 3.161 0 0 1 4.841 8a3.161 3.161 0 0 1 3.157-3.158A3.161 3.161 0 0 1 11.157 8 3.161 3.161 0 0 1 8 11.158Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
