<template>
  <SearchMainLayout>
    <div class="muvi-block">
      <ul class="muvi-list">
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_1.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было Как это было</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_2.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_3.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_4.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_5.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_6.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_7.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_8.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>

        </li>
        <li class="muvi-list__item">
          <div class="muvi-list__item-block">
            <img src="@/assets/images/search/muvi_big_1.png" alt="">

            <div class="muvi-list__item-block__author">
              <img src="@/assets/images/navbar/muvi_author_avatar.png" alt="">
              <span class="muvi-list__item-block__author-name">alexaliev</span>
              <VerifyIcon/>
            </div>

          </div>
          <h3 class="muvi-list__item-title">Наша поездка в Саудию в марте. Как это было...</h3>
          <div class="muvi-list__item-subtitle">
            <span>02.03.2023</span>
            <span class="muvi-item__watched">
                <EyeIcon/> 17,8k
              </span>
          </div>
        </li>
      </ul>
    </div>
  </SearchMainLayout>
</template>

<script>
import VerifyIcon from '@/components/icons/VerifyIcon.vue'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import SearchMainLayout from '@/components/layouts/SearchMainLayout.vue'

export default {
  components: {
    SearchMainLayout,
    EyeIcon,
    VerifyIcon
  }
}
</script>

<style scoped lang="scss">
.muvi-item__watched {
  display: flex;
  align-items: center;
  gap: 4px;
}

.muvi-block {
  display: flex;
  justify-content: center;
  padding: 24px;
  background-color: var(--color-white);
  border-radius: 20px;
  max-width: 600px;
}

.muvi-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  row-gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    &-title {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      line-height: 1.2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: var(--color-gray);
      line-height: 1;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__item-block {
    display: flex;
    position: relative;

    &__author {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px;
      color: var(--color-white);

      svg {
        width: 12px;
        height: 12px;
      }

      &-name {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 1920px) {
  .muvi-block {
    max-width: 700px;
  }

  .muvi-list {
    &__item-block {
      width: 212px;
      height: 326px;
    }
  }
}</style>
