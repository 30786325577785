<template>
  <h1 class="title">
    <slot/>
  </h1>
</template>

<script>

</script>

<style scoped lang="scss">
.title {
  margin: 0 0 40px 0;
  font-weight: 500;
  font-size: 44px;
  line-height: 1;
  color: var(--color-mine-shaft);
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    font-weight: 500;
  }
}
</style>
