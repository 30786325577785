<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9999 4.48633C13.9866 4.48633 13.9666 4.48633 13.9466 4.48633C10.4199 4.13299 6.89994 3.99966 3.41328 4.35299L2.05328 4.48633C1.77328 4.51299 1.52661 4.31299 1.49994 4.03299C1.47328 3.75299 1.67328 3.51299 1.94661 3.48633L3.30661 3.35299C6.85328 2.99299 10.4466 3.13299 14.0466 3.48633C14.3199 3.51299 14.5199 3.75966 14.4933 4.03299C14.4733 4.29299 14.2533 4.48633 13.9999 4.48633Z"
      fill="white"/>
    <path
      d="M5.66663 3.81301C5.63997 3.81301 5.6133 3.81301 5.57997 3.80634C5.3133 3.75967 5.12663 3.49967 5.1733 3.23301L5.31997 2.35967C5.42663 1.71967 5.5733 0.833008 7.12663 0.833008H8.8733C10.4333 0.833008 10.58 1.75301 10.68 2.36634L10.8266 3.23301C10.8733 3.50634 10.6866 3.76634 10.42 3.80634C10.1466 3.85301 9.88663 3.66634 9.84663 3.39967L9.69997 2.53301C9.60663 1.95301 9.58663 1.83967 8.87997 1.83967H7.1333C6.42663 1.83967 6.4133 1.93301 6.3133 2.52634L6.15997 3.39301C6.11997 3.63967 5.90663 3.81301 5.66663 3.81301Z"
      fill="white"/>
    <path
      d="M10.14 15.1667H5.85997C3.53331 15.1667 3.43997 13.88 3.36664 12.84L2.93331 6.12672C2.91331 5.85338 3.12664 5.61338 3.39997 5.59338C3.67997 5.58005 3.91331 5.78672 3.93331 6.06005L4.36664 12.7734C4.43997 13.7867 4.46664 14.1667 5.85997 14.1667H10.14C11.54 14.1667 11.5666 13.7867 11.6333 12.7734L12.0666 6.06005C12.0866 5.78672 12.3266 5.58005 12.6 5.59338C12.8733 5.61338 13.0866 5.84672 13.0666 6.12672L12.6333 12.84C12.56 13.88 12.4666 15.1667 10.14 15.1667Z"
      fill="white"/>
    <path
      d="M9.10672 11.5H6.88672C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88672 10.5H9.10672C9.38005 10.5 9.60672 10.7267 9.60672 11C9.60672 11.2733 9.38005 11.5 9.10672 11.5Z"
      fill="white"/>
    <path
      d="M9.66671 8.83301H6.33337C6.06004 8.83301 5.83337 8.60634 5.83337 8.33301C5.83337 8.05967 6.06004 7.83301 6.33337 7.83301H9.66671C9.94004 7.83301 10.1667 8.05967 10.1667 8.33301C10.1667 8.60634 9.94004 8.83301 9.66671 8.83301Z"
      fill="white"/>
  </svg>
</template>
