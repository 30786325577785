<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9993 30.3337H11.9993C4.75935 30.3337 1.66602 27.2403 1.66602 20.0003V12.0003C1.66602 4.76033 4.75935 1.66699 11.9993 1.66699H18.666C19.2127 1.66699 19.666 2.12033 19.666 2.66699C19.666 3.21366 19.2127 3.66699 18.666 3.66699H11.9993C5.85268 3.66699 3.66602 5.85366 3.66602 12.0003V20.0003C3.66602 26.147 5.85268 28.3337 11.9993 28.3337H19.9993C26.146 28.3337 28.3327 26.147 28.3327 20.0003V13.3337C28.3327 12.787 28.786 12.3337 29.3327 12.3337C29.8793 12.3337 30.3327 12.787 30.3327 13.3337V20.0003C30.3327 27.2403 27.2393 30.3337 19.9993 30.3337Z"
      fill="#9D9D9D"
    />
    <path
      d="M29.3327 14.3336H23.9993C19.4393 14.3336 17.666 12.5603 17.666 8.00031V2.66698C17.666 2.26698 17.906 1.89364 18.2793 1.74698C18.6527 1.58698 19.0793 1.68031 19.3727 1.96031L30.0394 12.627C30.3194 12.907 30.4127 13.347 30.2527 13.7203C30.0927 14.0936 29.7327 14.3336 29.3327 14.3336ZM19.666 5.08031V8.00031C19.666 11.4403 20.5593 12.3336 23.9993 12.3336H26.9193L19.666 5.08031Z"
      fill="#9D9D9D"
    />
  </svg>
</template>
