<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8.10573 7.74683C8.08573 7.74683 8.0724 7.74683 8.0524 7.74683C8.01906 7.74016 7.9724 7.74016 7.9324 7.74683C5.99906 7.68683 4.53906 6.16683 4.53906 4.2935C4.53906 2.38683 6.0924 0.833496 7.99906 0.833496C9.90573 0.833496 11.4591 2.38683 11.4591 4.2935C11.4524 6.16683 9.98573 7.68683 8.12573 7.74683C8.11906 7.74683 8.1124 7.74683 8.10573 7.74683ZM7.99906 1.8335C6.64573 1.8335 5.53906 2.94016 5.53906 4.2935C5.53906 5.62683 6.57906 6.70016 7.90573 6.74683C7.93906 6.74016 8.0324 6.74016 8.11906 6.74683C9.42573 6.68683 10.4524 5.6135 10.4591 4.2935C10.4591 2.94016 9.3524 1.8335 7.99906 1.8335Z"
      fill="currentColor"
    />
    <path
      d="M8.11307 15.0332C6.80641 15.0332 5.49307 14.6998 4.49974 14.0332C3.57307 13.4198 3.06641 12.5798 3.06641 11.6665C3.06641 10.7532 3.57307 9.9065 4.49974 9.2865C6.49974 7.95984 9.73974 7.95984 11.7264 9.2865C12.6464 9.89984 13.1597 10.7398 13.1597 11.6532C13.1597 12.5665 12.6531 13.4132 11.7264 14.0332C10.7264 14.6998 9.41974 15.0332 8.11307 15.0332ZM5.05307 10.1265C4.41307 10.5532 4.06641 11.0998 4.06641 11.6732C4.06641 12.2398 4.41974 12.7865 5.05307 13.2065C6.71307 14.3198 9.51307 14.3198 11.1731 13.2065C11.8131 12.7798 12.1597 12.2332 12.1597 11.6598C12.1597 11.0932 11.8064 10.5465 11.1731 10.1265C9.51307 9.01984 6.71307 9.01984 5.05307 10.1265Z"
      fill="currentColor"
    />
  </svg>
</template>
