<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_10598_57834)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.49912 0.831211C3.65188 1.16985 0.830078 4.20313 0.830078 8.00005C0.830078 12.6857 5.17464 16.0781 9.74912 14.9645C14.1944 13.8823 16.4795 8.85125 14.3956 4.73441C13.1149 2.20457 10.2592 0.588251 7.49912 0.831211ZM8.92776 1.92241C9.64236 2.03149 10.4737 2.32601 11.0815 2.68537C11.4072 2.87789 11.9054 3.23345 11.9054 3.27333C11.9054 3.30413 3.30328 11.9063 3.27248 11.9063C3.1716 11.9063 2.58284 10.9514 2.34912 10.4087C0.687478 6.55017 3.26336 2.31437 7.546 1.86293C7.78176 1.83809 8.60856 1.87369 8.92776 1.92241ZM12.9316 4.34249C14.6765 6.66969 14.5195 9.94013 12.5586 12.1124C10.4135 14.4887 6.83356 14.823 4.27624 12.8858L3.99692 12.6742L8.33396 8.33689C11.5611 5.10949 12.679 4.00961 12.7022 4.03893C12.7194 4.06061 12.8226 4.19721 12.9316 4.34249Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_10598_57834">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
