<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7597)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.67736 7.43289C4.53255 7.09849 4.68625 6.71 5.02055 6.56519L7.42155 5.52524C7.75621 5.38035 8.14444 5.53413 8.28925 5.86843C8.43405 6.20282 8.28036 6.59132 7.94606 6.73613L5.54506 7.77608C5.45963 7.81302 5.37069 7.83062 5.28316 7.83062C5.02812 7.83062 4.78513 7.68177 4.67736 7.43289Z" fill="#4C2908"/>
      <path d="M12.8479 17.8981C12.7382 18.4183 12.2131 18.7608 11.7015 18.6101C10.5643 18.2816 9.43196 18.2816 8.29908 18.6101C7.78748 18.7608 7.26236 18.4183 7.15269 17.8932C6.5271 14.87 7.89228 11.5 9.99759 11.5C12.1218 11.5 13.4692 14.8835 12.8479 17.8981Z" fill="#2F2F2F"/>
      <path d="M12.0709 18.0699C12.3167 17.4216 12.273 16.6631 11.8121 16.0175C11.501 15.575 10.9305 15.4097 10.428 15.6037C10.0893 15.7344 9.71331 15.7755 9.35189 15.7463C7.95808 15.6328 7.49996 16.957 7.91 18.0699C9.28112 17.6539 10.6685 17.6744 12.0709 18.0699Z" fill="#F64F4F"/>
      <path d="M10.0009 17.2021C8.75997 17.2021 7.69192 17.5436 7.19922 18.0363C7.36669 18.4771 7.83724 18.7467 8.29969 18.61C9.43203 18.2815 10.5649 18.2815 11.7021 18.61C12.1656 18.7467 12.6367 18.4766 12.8037 18.0374C12.312 17.5436 11.2429 17.2021 10.0009 17.2021Z" fill="white"/>
      <path d="M20 8C20 8.82841 19.3284 9.5 18.5001 9.5C17.6716 9.5 17 8.82841 17 8C17 7.17159 17.6716 6.5 18.5001 6.5C19.3284 6.5 20 7.17159 20 8Z" fill="#F64F4F"/>
      <path d="M18.5001 0C19.0523 0 19.5 0.479645 19.5 1.0715V3.9285C19.5 4.52036 19.0523 5 18.5001 5C17.9477 5 17.5 4.52036 17.5 3.9285V1.0715C17.5 0.479645 17.9477 0 18.5001 0Z" fill="#F64F4F"/>
      <path d="M13.9999 0C14.5523 0 15 0.479645 15 1.0715V3.9285C15 4.52036 14.5523 5 13.9999 5C13.4477 5 13 4.52036 13 3.9285V1.0715C13 0.479645 13.4477 0 13.9999 0Z" fill="#F64F4F"/>
      <path d="M15.5 8C15.5 8.82841 14.8284 9.5 13.9999 9.5C13.1716 9.5 12.5 8.82841 12.5 8C12.5 7.17159 13.1716 6.5 13.9999 6.5C14.8284 6.5 15.5 7.17159 15.5 8Z" fill="#F64F4F"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7597">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
