<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.943.366C3.282.445 2.723.67 2.62.9c-.173.391.2.766.593.597.477-.205.81-.26 1.58-.26.51 0 .579-.005.674-.053.29-.149.302-.569.02-.778C5.4.343 4.367.315 3.943.366Zm4.596.026a.455.455 0 0 0-.059.767c.09.064.109.066.817.083.811.019 1.04.058 1.455.25 1.956.903 2.19 3.555.423 4.773-.548.378-.9.47-1.878.494-.708.016-.726.018-.817.082a.452.452 0 0 0 .068.77c.17.082 1.393.066 1.802-.023 3.625-.794 3.97-5.798.485-7.043C10.283.347 8.817.249 8.54.392ZM1.163 2.447c-.138.08-.235.265-.327.629C.31 5.149 1.6 7.18 3.712 7.6c.389.077 1.668.072 1.776-.008.279-.206.271-.629-.015-.774-.087-.045-.187-.053-.797-.064-.637-.012-.72-.019-.96-.082C2.177 6.264 1.291 4.71 1.743 3.21c.095-.316.106-.44.047-.563a.454.454 0 0 0-.627-.2ZM4.47 3.59c-.244.099-.332.479-.159.685.146.173-.012.164 2.698.164H9.48l.09-.062c.287-.195.287-.556 0-.752l-.09-.061-2.478.001c-1.362.001-2.502.012-2.533.025Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
