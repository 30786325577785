<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1749_16129)">
      <path
        d="M12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 23.9999 12 23.9999C18.6274 23.9999 23.9999 18.6274 23.9999 12C23.9929 5.37554 18.6245 0.00708147 12 0ZM12 22.2857C6.31933 22.2857 1.71427 17.6806 1.71427 12C1.71427 6.31933 6.31933 1.71427 12 1.71427C17.6806 1.71427 22.2857 6.31933 22.2857 12C22.2796 17.6781 17.6781 22.2796 12 22.2857Z"
        fill="currentColor"/>
      <path
        d="M7.71427 11.9998C8.66104 11.9998 9.42854 11.2323 9.42854 10.2856C9.42854 9.33879 8.66104 8.57129 7.71427 8.57129C6.76751 8.57129 6 9.33879 6 10.2856C6 11.2323 6.76751 11.9998 7.71427 11.9998Z"
        fill="currentColor"/>
      <path
        d="M16.2865 11.9998C17.2333 11.9998 18.0008 11.2323 18.0008 10.2856C18.0008 9.33879 17.2333 8.57129 16.2865 8.57129C15.3398 8.57129 14.5723 9.33879 14.5723 10.2856C14.5723 11.2323 15.3398 11.9998 16.2865 11.9998Z"
        fill="currentColor"/>
      <path
        d="M15.2817 14.8973C14.9986 14.5179 14.4615 14.4399 14.0821 14.723C14.0657 14.7353 14.0498 14.7481 14.0343 14.7615C12.8221 15.6468 11.1767 15.6468 9.96453 14.7615C9.60638 14.4519 9.06508 14.4913 8.75555 14.8495C8.44597 15.2076 8.4854 15.7489 8.84354 16.0584C8.85901 16.0718 8.87498 16.0847 8.8914 16.0969C10.7261 17.4992 13.2727 17.4992 15.1074 16.0969C15.4868 15.8138 15.5649 15.2767 15.2817 14.8973Z"
        fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1749_16129">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
