<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M6.24541 13.6081C6.05696 13.6081 5.86852 13.5311 5.72495 13.378L1.21595 8.56826C0.928018 8.26128 0.928018 7.76493 1.21595 7.45795C1.50374 7.15097 1.96893 7.15097 2.25686 7.45795L6.24541 11.7124L14.7432 2.6482C15.031 2.34122 15.4962 2.34122 15.7842 2.6482C16.0719 2.95533 16.0719 3.45153 15.7842 3.75865L6.766 13.378C6.62244 13.5311 6.43386 13.6081 6.24541 13.6081Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>
