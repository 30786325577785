<template>
  <svg
    v-if="!isActive"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.0827 14.0001C25.0827 20.1212 20.1205 25.0834 13.9993 25.0834C7.87819 25.0834 2.91602 20.1212 2.91602 14.0001C2.91602 7.87892 7.87819 2.91675 13.9993 2.91675C20.1205 2.91675 25.0827 7.87892 25.0827 14.0001ZM26.8327 14.0001C26.8327 21.0877 21.087 26.8334 13.9993 26.8334C6.9117 26.8334 1.16602 21.0877 1.16602 14.0001C1.16602 6.91243 6.9117 1.16675 13.9993 1.16675C21.087 1.16675 26.8327 6.91243 26.8327 14.0001ZM8.01436 17.5934C8.17768 17.7217 8.36436 17.7801 8.55103 17.7801C8.81936 17.7801 9.07601 17.6634 9.22769 17.4301L12.0043 13.8251C12.0743 13.7317 12.156 13.7084 12.2027 13.7084C12.2493 13.6967 12.3427 13.6967 12.4243 13.7667L14.5593 15.4467C14.991 15.7851 15.5277 15.9367 16.076 15.8667C16.6243 15.8084 17.1027 15.5284 17.441 15.0967L20.136 11.6201C20.4393 11.2351 20.3693 10.6868 19.9843 10.3951C19.611 10.0918 19.051 10.1618 18.7593 10.5468L16.0643 14.0234C15.9943 14.1167 15.9127 14.1401 15.866 14.1401C15.8077 14.1517 15.726 14.1517 15.6443 14.0817L13.5093 12.4017C13.0777 12.0634 12.5293 11.9117 11.9927 11.9817C11.4444 12.0517 10.966 12.3317 10.6277 12.7634L7.85103 16.3684C7.55936 16.7534 7.62936 17.3017 8.01436 17.5934Z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.8327 14.0001C26.8327 21.0877 21.087 26.8334 13.9993 26.8334C6.9117 26.8334 1.16602 21.0877 1.16602 14.0001C1.16602 6.91243 6.9117 1.16675 13.9993 1.16675C21.087 1.16675 26.8327 6.91243 26.8327 14.0001ZM8.551 17.7801C8.36434 17.7801 8.17767 17.7217 8.01434 17.5934C7.62934 17.3017 7.55934 16.7534 7.851 16.3684L10.6277 12.7634C10.966 12.3317 11.4443 12.0517 11.9927 11.9817C12.5293 11.9117 13.0777 12.0634 13.5093 12.4017L15.6443 14.0817C15.726 14.1517 15.8077 14.1517 15.866 14.1401C15.9127 14.1401 15.9943 14.1167 16.0643 14.0234L18.7593 10.5468C19.051 10.1618 19.611 10.0918 19.9843 10.3951C20.3693 10.6868 20.4393 11.2351 20.136 11.6201L17.441 15.0967C17.1027 15.5284 16.6243 15.8084 16.076 15.8667C15.5277 15.9367 14.991 15.7851 14.5593 15.4467L12.4243 13.7667C12.3427 13.6967 12.2493 13.6967 12.2027 13.7084C12.156 13.7084 12.0743 13.7317 12.0043 13.8251L9.22767 17.4301C9.076 17.6634 8.81934 17.7801 8.551 17.7801Z"
      fill="#49A399"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
