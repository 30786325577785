<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/gallery-add">
      <g id="gallery-add">
        <path
          id="Vector"
          d="M22.5 26.875C18.7 26.875 15.625 23.8 15.625 20C15.625 16.2 18.7 13.125 22.5 13.125C26.3 13.125 29.375 16.2 29.375 20C29.375 23.8 26.3 26.875 22.5 26.875ZM22.5 16.875C20.775 16.875 19.375 18.275 19.375 20C19.375 21.725 20.775 23.125 22.5 23.125C24.225 23.125 25.625 21.725 25.625 20C25.625 18.275 24.225 16.875 22.5 16.875Z"
          fill="#B0B0B0"
        />
        <path
          id="Vector_2"
          d="M37.5 56.875H22.5C8.925 56.875 3.125 51.075 3.125 37.5V22.5C3.125 8.925 8.925 3.125 22.5 3.125H32.5C33.525 3.125 34.375 3.975 34.375 5C34.375 6.025 33.525 6.875 32.5 6.875H22.5C10.975 6.875 6.875 10.975 6.875 22.5V37.5C6.875 49.025 10.975 53.125 22.5 53.125H37.5C49.025 53.125 53.125 49.025 53.125 37.5V25C53.125 23.975 53.975 23.125 55 23.125C56.025 23.125 56.875 23.975 56.875 25V37.5C56.875 51.075 51.075 56.875 37.5 56.875Z"
          fill="#B0B0B0"
        />
        <path
          id="Vector_3"
          d="M53.125 14.375H39.375C38.35 14.375 37.5 13.525 37.5 12.5C37.5 11.475 38.35 10.625 39.375 10.625H53.125C54.15 10.625 55 11.475 55 12.5C55 13.525 54.15 14.375 53.125 14.375Z"
          fill="#B0B0B0"
        />
        <path
          id="Vector_4"
          d="M46.25 21.25C45.225 21.25 44.375 20.4 44.375 19.375V5.625C44.375 4.6 45.225 3.75 46.25 3.75C47.275 3.75 48.125 4.6 48.125 5.625V19.375C48.125 20.4 47.275 21.25 46.25 21.25Z"
          fill="#B0B0B0"
        />
        <path
          id="Vector_5"
          d="M6.67524 49.2504C6.07524 49.2504 5.47524 48.9504 5.12524 48.4254C4.55024 47.5754 4.77524 46.4004 5.62524 45.8254L17.9502 37.5504C20.6502 35.7504 24.3752 35.9504 26.8252 38.0254L27.6502 38.7504C28.9002 39.8254 31.0252 39.8254 32.2502 38.7504L42.6502 29.8254C45.3252 27.5504 49.4752 27.5504 52.1502 29.8254L56.2252 33.3254C57.0002 34.0004 57.1002 35.1754 56.4252 35.9754C55.7502 36.7504 54.5752 36.8504 53.7752 36.1754L49.7002 32.6754C48.4502 31.6004 46.3252 31.6004 45.1003 32.6754L34.7002 41.6004C32.0502 43.8754 27.8752 43.8754 25.2002 41.6004L24.3752 40.8754C23.2252 39.9004 21.3252 39.8004 20.0502 40.6754L7.75024 48.9504C7.40024 49.1504 7.02524 49.2504 6.67524 49.2504Z"
          fill="#B0B0B0"
        />
      </g>
    </g>
  </svg>
</template>
