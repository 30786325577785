<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon" clip-path="url(#clip0_738_24003)">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.87082 1.34292C1.99945 1.81222 0.000558174 4.17824 1.51541e-07 7.10837C-0.000781081 11.5185 3.01908 15.7451 7.84281 18.0852C9.81081 19.0401 10.5347 18.9936 12.785 17.7681C17.2903 15.3146 19.9936 11.3207 20 7.10837C20.0076 2.12369 14.2335 -0.552441 10.3263 2.62496L9.99794 2.89202L9.70444 2.64929C8.36788 1.54386 6.58687 1.06251 4.87082 1.34292ZM6.75262 2.75607C7.73494 2.95332 8.64375 3.48836 9.27837 4.24312C9.78562 4.84635 10.2139 4.84586 10.7222 4.24138C13.2646 1.21789 18.2943 2.84294 18.5689 6.77662C18.8276 10.4819 15.9274 14.6519 11.6147 16.7751C10.0848 17.5282 9.91431 17.5282 8.38437 16.7751C4.07022 14.6511 1.1714 10.4833 1.43016 6.77662C1.61547 4.12221 4.12306 2.22804 6.75262 2.75607Z"
        fill="#D94B4B"
      />
      <path
        id="Vector_2"
        d="M10 4.375L7.5 2.8125L6.875 2.1875H4.375L1.25 4.6875L0.9375 8.75L5 15.625L10.3125 18.125L16.875 13.4375L19.375 8.125L17.5 3.125L13.4375 2.1875L10 4.375Z"
        fill="#D94B4B"
        stroke="#D94B4B"
      />
    </g>
    <defs>
      <clipPath id="clip0_738_24003">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
