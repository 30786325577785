<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7756)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.5742 6.87511C11.616 7.23711 11.9433 7.49671 12.3052 7.45496L14.9046 7.15508C15.2668 7.11323 15.5261 6.78594 15.4844 6.42402C15.4426 6.06202 15.1153 5.80242 14.7533 5.84417L12.154 6.14405C12.0616 6.15476 11.9757 6.184 11.8999 6.22777C11.6791 6.35529 11.5431 6.60569 11.5742 6.87511Z" fill="#4C2908"/>
      <path d="M4.67736 7.43191C4.53255 7.09752 4.68625 6.70902 5.02055 6.56421L7.42155 5.52427C7.75621 5.37937 8.14444 5.53315 8.28925 5.86746C8.43405 6.20185 8.28036 6.59034 7.94606 6.73515L5.54506 7.7751C5.45963 7.81205 5.37069 7.82964 5.28316 7.82964C5.02812 7.82964 4.78513 7.68079 4.67736 7.43191Z" fill="#4C2908"/>
      <circle cx="7.25" cy="10.75" r="1.75" fill="#3E4347"/>
      <circle cx="6.75" cy="10.25" r="0.75" fill="white"/>
      <circle cx="12.75" cy="9.75" r="1.75" fill="#3E4347"/>
      <circle cx="13.25" cy="10.25" r="0.75" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7756">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
