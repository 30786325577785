<template>
  <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6025_35933)">
      <path
        d="M1.8125 8.75903V25.8774C1.8125 27.0344 2.7489 27.9722 3.90424 27.9722C5.05958 27.9722 5.99599 27.0344 5.99599 25.8774V12.1728L1.8125 8.75903Z"
        fill="url(#paint0_linear_6025_35933)"
      />
      <path
        d="M12.8301 14.0987V25.8776C12.8301 27.0346 13.7665 27.9723 14.9218 27.9723C16.0772 27.9723 17.0136 27.0346 17.0136 25.8776V9.83447L12.8301 14.0987Z"
        fill="url(#paint1_linear_6025_35933)"
      />
      <path
        d="M21.6786 27.9592C21.346 27.9585 21.0141 27.8747 20.7136 27.7057C20.0875 27.3545 19.6998 26.6912 19.7012 25.972L19.7305 8.12459C19.7319 7.4026 20.1251 6.73786 20.7568 6.38943C21.3892 6.0417 22.1597 6.06474 22.7698 6.45088L37.0536 15.4933C37.6316 15.8592 37.9802 16.4981 37.976 17.183C37.9719 17.868 37.6149 18.502 37.032 18.8603L22.7189 27.6652C22.4002 27.8615 22.0397 27.9599 21.6786 27.9592ZM23.6776 11.7332V22.4332L32.2502 17.1398L23.6776 11.7332Z"
        fill="url(#paint2_linear_6025_35933)"
      />
      <path
        d="M9.37798 18.2596C8.84249 18.2596 8.30701 18.055 7.89912 17.6458L0.612881 10.349C-0.204294 9.53068 -0.204294 8.2047 0.612881 7.38634C1.43006 6.56799 2.75413 6.56799 3.5713 7.38634L9.37868 13.2021L21.9494 0.613277C22.7658 -0.205077 24.0906 -0.205077 24.9078 0.613277C25.725 1.43163 25.725 2.75762 24.9078 3.57597L10.8582 17.6458C10.449 18.055 9.91347 18.2596 9.37798 18.2596Z"
        fill="url(#paint3_linear_6025_35933)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6025_35933"
        x1="0.0214169"
        y1="18.9377"
        x2="35.7447"
        y2="12.004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#06809A" />
        <stop offset="1" stop-color="#06CEE5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6025_35933"
        x1="-0.926571"
        y1="22.2116"
        x2="37.1105"
        y2="14.8289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#06809A" />
        <stop offset="1" stop-color="#06CEE5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6025_35933"
        x1="-0.0637786"
        y1="21.25"
        x2="38.8344"
        y2="13.7669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#06809A" />
        <stop offset="1" stop-color="#06CEE5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6025_35933"
        x1="4.72706"
        y1="13.4001"
        x2="22.869"
        y2="-2.46204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#06C99A" />
        <stop offset="1" stop-color="#00EA00" />
      </linearGradient>
      <clipPath id="clip0_6025_35933">
        <rect width="38" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
