<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/import">
      <g id="import">
        <path
          id="Vector"
          d="M9.86301 13.2814C9.64587 13.2814 9.42872 13.2022 9.25729 13.0325L6.33158 10.1364C6.00015 9.80831 6.00015 9.26529 6.33158 8.93722C6.66301 8.60914 7.21158 8.60914 7.54301 8.93722L9.86301 11.2337L12.183 8.93722C12.5144 8.60914 13.063 8.60914 13.3944 8.93722C13.7259 9.26529 13.7259 9.80831 13.3944 10.1364L10.4687 13.0325C10.2973 13.2022 10.0802 13.2814 9.86301 13.2814Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M9.86251 13.2022C9.39394 13.2022 9.00537 12.8175 9.00537 12.3537V0.848468C9.00537 0.384639 9.39394 0 9.86251 0C10.3311 0 10.7197 0.384639 10.7197 0.848468V12.3537C10.7197 12.8175 10.3311 13.2022 9.86251 13.2022Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M10 19.9999C4.11429 19.9999 0 15.9273 0 10.1012C0 9.63732 0.388571 9.25269 0.857143 9.25269C1.32571 9.25269 1.71429 9.63732 1.71429 10.1012C1.71429 14.9318 5.12 18.303 10 18.303C14.88 18.303 18.2857 14.9318 18.2857 10.1012C18.2857 9.63732 18.6743 9.25269 19.1429 9.25269C19.6114 9.25269 20 9.63732 20 10.1012C20 15.9273 15.8857 19.9999 10 19.9999Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
