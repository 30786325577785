<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <rect width="28" height="28" rx="8" fill="#49A399" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3106 7.02072C19.1733 7.05337 8.0107 11.6463 7.74094 11.7812C7.21114 12.0461 6.90439 12.6934 7.02683 13.2881C7.09529 13.6207 7.3674 14.0155 7.64416 14.1838C7.69152 14.2126 8.74322 14.6557 9.98122 15.1685C12.3729 16.1592 12.3624 16.1556 12.5596 16.0572C12.6804 15.9969 15.0324 13.6488 15.0978 13.5231C15.2907 13.1529 14.8938 12.7164 14.5132 12.8803C14.4711 12.8984 13.9466 13.4041 13.3477 14.0041L12.2586 15.0949L10.2092 14.2518C9.08207 13.7881 8.13232 13.3896 8.09869 13.3662C8.01672 13.3092 7.92727 13.1228 7.92727 13.009C7.92727 12.8887 8.0212 12.6971 8.1081 12.6402C8.23736 12.5555 19.5281 7.91977 19.6037 7.92037C19.7034 7.92117 19.8832 8.00588 19.9577 8.08717C20.0289 8.16488 20.0758 8.27988 20.0758 8.37696C20.0758 8.44862 15.4318 19.7708 15.3532 19.8908C15.2989 19.9736 15.1056 20.0683 14.9907 20.0683C14.8746 20.0683 14.7418 20.0098 14.6623 19.9235C14.6265 19.8847 14.34 19.2305 14.0166 18.4493C13.5838 17.4039 13.4141 17.0233 13.3528 16.9606C13.1914 16.7954 12.9152 16.7803 12.7294 16.9265C12.6379 16.9985 12.5528 17.1768 12.552 17.2982C12.5513 17.404 13.7206 20.2471 13.8299 20.4053C13.9444 20.5711 14.1814 20.7714 14.3655 20.8582C15.059 21.185 15.9018 20.9129 16.2225 20.2585C16.3808 19.9353 20.9349 8.8261 20.9721 8.67221C21.0437 8.37603 20.9733 7.94574 20.8093 7.67759C20.6463 7.41106 20.3507 7.1714 20.0599 7.07008C19.8692 7.00362 19.4881 6.97853 19.3106 7.02072Z"
      fill="white"
    />
  </svg>
</template>
