<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49998 14C10.9927 14 13.8242 11.0899 13.8242 7.5C13.8242 3.91015 10.9927 1 7.49998 1C4.00722 1 1.17578 3.91015 1.17578 7.5C1.17578 11.0899 4.00722 14 7.49998 14Z" fill="url(#paint0_linear_862_5038)"/>
    <path d="M4.42578 5.5L5.97649 6.72996" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5624 5.5L9.01172 6.72996" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5748 7.14503C10.5748 7.77539 10.3098 8.29812 9.96515 8.29812C9.6338 8.29812 9.35547 7.77539 9.35547 7.14503C9.35547 6.51468 9.62055 5.99194 9.96515 5.99194C10.3098 5.99194 10.5748 6.51468 10.5748 7.14503Z" fill="#303030"/>
    <path d="M10.168 7.94409L10.5002 8.24999" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M10.0009 7.57639L10.639 7.79999" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M4.56384 7.14503C4.56384 7.77539 4.82892 8.29812 5.17352 8.29812C5.50487 8.29812 5.7832 7.77539 5.7832 7.14503C5.7832 6.51468 5.51812 5.99194 5.17352 5.99194C4.82892 5.99194 4.56384 6.51468 4.56384 7.14503Z" fill="#303030"/>
    <path d="M4.9707 7.94409L4.63843 8.24999" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M5.13976 7.57639L4.50159 7.79999" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M6 10C6 10 7.25714 8.875 9 10" stroke="#5B0600" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_862_5038" x1="7.50003" y1="1" x2="7.19148" y2="13.9927" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EB3E7D"/>
        <stop offset="0.0001" stop-color="#FF4062"/>
        <stop offset="0.140687" stop-color="#F34F6D"/>
        <stop offset="0.299665" stop-color="#F86390"/>
        <stop offset="0.5514" stop-color="#E76493"/>
        <stop offset="1" stop-color="#FD7FB4"/>
      </linearGradient>
    </defs>
  </svg>
</template>
