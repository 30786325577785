<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <g fill="currentColor" fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd">
      <path d="M.983 7.44a1.41 1.41 0 0 0-.938 1C.009 8.575 0 9.224 0 11.674c0 3.336-.003 3.277.187 3.605.201.346.575.608.976.685.247.048 9.162.048 9.409 0a1.443 1.443 0 0 0 1.128-1.08c.018-.081.034-.424.034-.762s.006-.615.013-.615c.006 0 .55.267 1.208.592.659.326 1.287.616 1.397.644.526.136 1.119-.1 1.417-.566.239-.373.239-.375.227-2.589l-.01-1.97-.106-.226a1.411 1.411 0 0 0-.64-.674c-.207-.103-.24-.11-.587-.11h-.367l-1.276.63-1.275.628v-.613c0-.646-.033-.867-.168-1.135a1.463 1.463 0 0 0-.831-.684c-.161-.049-.668-.054-4.883-.053-4.374.001-4.715.005-4.87.06Zm9.52 1.052c.17.091.165.01.165 3.212v2.98l-.097.11-.097.11H1.26l-.097-.11-.097-.11v-2.98c0-3.185-.004-3.115.158-3.21.081-.048 9.19-.05 9.278-.002Zm4.35 1.26.083.083v3.678l-.098.098c-.053.054-.132.098-.175.097-.042 0-.715-.316-1.494-.701l-1.417-.7-.01-.621-.008-.62 1.409-.696c.921-.455 1.447-.697 1.52-.698a.25.25 0 0 1 .19.08Z"/>
      <path d="M2.305 1.018C1.4 1.144.57 1.782.223 2.618a2.708 2.708 0 0 0 .056 2.248c.334.687.902 1.17 1.655 1.408.213.068.32.08.733.08.414 0 .52-.012.733-.08.753-.237 1.326-.724 1.651-1.402.215-.447.263-.662.264-1.179 0-.354-.014-.488-.075-.704-.216-.765-.702-1.362-1.382-1.698a3.417 3.417 0 0 0-.558-.22 3.22 3.22 0 0 0-.995-.053Zm5.867 0c-.904.126-1.733.764-2.081 1.6a2.708 2.708 0 0 0 .056 2.248c.334.687.902 1.17 1.654 1.408.214.068.32.08.734.08.413 0 .52-.012.733-.08.752-.237 1.325-.724 1.651-1.402.215-.447.263-.662.263-1.179 0-.354-.013-.488-.074-.704-.217-.765-.703-1.362-1.382-1.698a3.417 3.417 0 0 0-.558-.22 3.22 3.22 0 0 0-.996-.053ZM3.19 2.164a1.617 1.617 0 0 1 .608 2.655 1.53 1.53 0 0 1-1.131.474A1.597 1.597 0 0 1 1.09 3.456a1.595 1.595 0 0 1 2.1-1.292Zm5.868 0a1.617 1.617 0 0 1 .608 2.655 1.53 1.53 0 0 1-1.131.474 1.597 1.597 0 0 1-1.578-1.837 1.595 1.595 0 0 1 2.101-1.292Zm-4.524 9.063c-.257.176-.335.466-.193.715.083.146.309.286.46.286.15 0 .377-.14.46-.286a.534.534 0 0 0-.46-.791.447.447 0 0 0-.267.076Zm2.133 0c-.256.176-.334.466-.192.715.082.146.308.286.46.286.15 0 .376-.14.46-.286a.534.534 0 0 0-.46-.791.447.447 0 0 0-.268.076Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
