<script>

</script>

<template>
  <h1>Terms Page</h1>
</template>

<style scoped>

</style>
