<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M15.165.237c-.423.164-.66.363-.845.71l-.183.342-.018 2.876-.018 2.877h-1.3C4.646 7.042.221 11.967.013 21.276c-.066 2.976.096 3.667.966 4.122.822.428 1.522.089 2.104-1.02 1.523-2.9 3.884-4.312 7.792-4.659.842-.074 2.398-.088 2.902-.026l.329.041v2.335c0 1.428.026 2.44.066 2.607.238.976 1.253 1.432 2.192.984.407-.194 11.248-11.346 11.496-11.826.178-.343.188-1.036.022-1.495C27.748 11.969 16.974.696 16.44.367c-.327-.202-.932-.263-1.275-.13ZM20.99 18.01l-4.814 4.989v-2.023c0-3.405-.086-3.475-4.14-3.385-4.512.1-7.432 1.23-9.363 3.62-.245.304-.486.597-.536.65-.13.142-.056-1.826.114-3.007.932-6.493 4.478-9.696 10.632-9.601 2.178.033 2.168.034 2.454-.118.789-.42.836-.621.837-3.588l.002-2.503 4.814 4.989 4.814 4.988-4.814 4.989Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor'
    },
    width: {
      type: String,
      default: '14'
    },
    height: {
      type: String,
      default: '14'
    }
  }
}
</script>
