export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "placeholders": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدك الإلكتروني..."])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقمك السري..."])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم هاتفك..."])}
    },
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة السر؟"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
    "with_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول برقم الهاتف"])},
    "with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول بالبريد الإلكتروني"])},
    "messages": {
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنرسل لك رسالة لتغيير كلمة المرور الخاصة بك"])},
      "verify_with_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رسالة برمز إلى رقم هاتفك"])},
      "didnt_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تتلق رمز؟"])}
    },
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون الحقل فارغًا"])},
      "empty_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون الحقل فارغًا"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريد إلكتروني خاطئ"])},
      "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز مرور خاطئ"])},
      "incorrect_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز غير صحيح"])}
    }
  },
  "links": {
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عنا"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عنا"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتخطى"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخبار"])},
    "my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحتي"])},
    "messenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسول"])},
    "my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعاتي"])},
    "my_albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألبوماتي"])},
    "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصوت الخاص بي"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنقذ"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالات"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتبة"])},
    "marriage_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وكالة زواج"])},
    "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المجموعات"])},
    "all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الناس"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
    "placeholders": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل أسمك..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدك الإلكتروني..."])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسمك الأخير..."])},
      "code_from_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الرمز من البريد الإلكتروني..."])},
      "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة مرور التسجيل الخاصة بك ..."])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الجنس"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])}
      }
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب؟"])},
    "messages": {
      "agreement_to_creating_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال إنشاء حساب ، فإنك توافق على"])},
      "verify_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رسالة تحتوي على رمز إلى عنوان بريدك الإلكتروني."])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإنشاء كلمة مرور لتسجيل الدخول إلى النظام"])},
      "fill_your_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["املأ معلوماتك لتسجيل الدخول إلى UmmaLife"])},
      "required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(الحقول المطلوبة لملئها)"])},
      "enter_your_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم هاتفك"])},
      "can_login_with_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستتمكن من الوصول إلى الشبكة باستخدام رقم هاتفك"])}
    },
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون الحقل فارغًا"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير صحيح البريد الإلكتروني"])},
      "incorrect_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شفرة خاطئة"])},
      "empty_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون الحقل فارغًا"])},
      "empty_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون الحقل فارغًا"])},
      "incorrect_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف غير صحيح"])}
    }
  },
  "forgot_password": {
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون الحقل فارغًا"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير صحيح البريد الإلكتروني"])}
    }
  },
  "buttons": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "get_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على الكود عن طريق البريد الإلكتروني"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يُقدِّم"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يخلق"])},
    "create_umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء فيديو الأمة"])},
    "toggle_theme": {
      "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مظلم"])},
      "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضوء"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينضم"])},
    "in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في مجموعة"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يشترك"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I follow"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "open_in_new_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in new tab"])},
    "complain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complain"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "open_like_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send as message"])},
    "share_on_my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share on my page"])},
    "share_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share in a group"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "available_everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to everyone"])},
    "who_am_i_following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who am I following"])},
    "who_is_following_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is following me"])},
    "anonymously": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymously"])},
    "create_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create poll"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "add_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add answer"])},
    "mood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood"])},
    "traveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling"])},
    "watching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm watching"])},
    "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm playing"])},
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm listening"])},
    "emotions": {
      "happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Happy"])},
      "in_love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In love"])},
      "outraged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outraged"])},
      "celebrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I celebrate"])},
      "giggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I giggle"])},
      "crazy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crazy"])},
      "falling_asleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falling asleep"])},
      "shocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm shocked"])},
      "excited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excited"])},
      "angry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angry"])},
      "masked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masked"])},
      "think": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think"])},
      "laughing_out_loud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laughing Out Loud"])},
      "wonder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wonder"])},
      "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm sick"])},
      "embarrassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embarrassed"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
      "frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen"])}
    },
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يقرأ"])}
  },
  "footer": {
    "links": {
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط"])},
      "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
      "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عنا"])}
    }
  },
  "languages": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اَلْعَرَبِيَّةُ"])},
    "name": {
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
      "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اَلْعَرَبِيَّةُ"])},
      "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türk"])},
      "uzbek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'zbek"])},
      "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa Indonesia"])},
      "malay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayu"])},
      "tajik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тоҷик"])},
      "tabasaran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табасаран"])},
      "chuvash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чӑвашла"])},
      "urdu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اردو"])},
      "ingush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["гӏалгӏай"])},
      "tatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Татар"])},
      "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лакский"])},
      "qumuq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Къумукъ"])},
      "balkarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tаулу"])},
      "amharic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["አማርኛ"])},
      "lezgin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лезги"])}
    }
  },
  "profile": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف الشخصي"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرضية"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عيد ميلاد"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد الإقامة"])},
    "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤسسة تعليمية"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع العمل"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ْعَنِّي"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراكات"])},
    "subscribes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشتركين"])},
    "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو الأمة"])},
    "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شورت الأمة"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشتركين"])},
    "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعامات"])}
  },
  "tabs": {
    "profile_tabs": {
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخبار"])},
      "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالات"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صوتي"])}
    },
    "my_page": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات"])},
      "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شورت الأمة"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو الأمة"])},
      "alboms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألبومات"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنقذ"])}
    }
  },
  "chat": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرسل"])},
    "empty_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من تريد الكتابة إليه"])}
  }
}