<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_6025_36599)">
      <circle cx="20" cy="20" r="20" fill="#1F1F1F" fill-opacity="0.5" />
    </g>
    <path
      d="M20.9109 20.0088L26.0603 14.8875C26.3072 14.6432 26.3072 14.2475 26.0603 14.0038C25.8141 13.7594 25.4141 13.7594 25.1678 14.0038L20.0228 19.1207L14.8303 13.9275C14.5841 13.6807 14.1841 13.6807 13.9378 13.9275C13.6916 14.175 13.6916 14.5757 13.9378 14.8225L19.1266 20.0119L13.9191 25.1907C13.6728 25.435 13.6728 25.8307 13.9191 26.0744C14.1653 26.3188 14.5653 26.3188 14.8116 26.0744L20.0147 20.9L25.1866 26.0725C25.4328 26.3194 25.8328 26.3194 26.0791 26.0725C26.3253 25.825 26.3253 25.4244 26.0791 25.1775L20.9109 20.0088Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_b_6025_36599"
        x="-10"
        y="-10"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6025_36599" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_6025_36599"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
