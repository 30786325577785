<template>
  <div class="premium__icon" :class="{ investor: is_investor }">
    <div class="premium__icon--inner">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <g clip-path="url(#clip0_384_19941)">
          <path
            d="M4.32194 1.04154L5.06528 2.53188C5.11888 2.6403 5.22346 2.71575 5.34477 2.73352L7.00984 2.97606C7.10773 2.98979 7.19602 3.04129 7.25517 3.11917C7.31432 3.19705 7.33945 3.29488 7.32501 3.39102C7.31349 3.47071 7.27592 3.54455 7.21797 3.60142L6.0108 4.77032C5.92238 4.85296 5.88216 4.97374 5.90376 5.09177L6.20109 6.73699C6.23393 6.93381 6.10262 7.12094 5.90376 7.16071C5.82238 7.1735 5.73897 7.16017 5.66589 7.12272L4.17922 6.34833C4.06913 6.29268 3.93847 6.29268 3.82837 6.34833L2.3417 7.12272C2.15858 7.22012 1.9298 7.15345 1.83029 6.97369C1.79258 6.90174 1.77905 6.81989 1.79163 6.73991L2.08897 5.09469C2.11056 4.97667 2.07034 4.85588 1.98193 4.77324L0.774753 3.60434C0.703932 3.5357 0.664062 3.44202 0.664062 3.34426C0.664062 3.24651 0.703932 3.15283 0.774753 3.08419C0.831805 3.02613 0.907348 2.98901 0.988833 2.97898L2.6539 2.73644C2.77521 2.71867 2.87979 2.64322 2.9334 2.5348L3.64997 1.04154C3.71263 0.914162 3.845 0.834282 3.98893 0.83698H4.03353C4.15878 0.852596 4.2674 0.929633 4.32194 1.04154Z"
            fill="white"
          />
          <path
            d="M3.99958 6.30451C3.93446 6.30409 3.87322 6.32553 3.81528 6.35475L2.33331 7.12725C2.15556 7.21681 1.92986 7.14485 1.83202 6.9737C1.79414 6.90286 1.78059 6.82188 1.79336 6.74285L2.0907 5.10055C2.11066 4.98176 2.07076 4.86084 1.98366 4.77618L0.773509 3.60728C0.62758 3.46355 0.62758 3.23085 0.773509 3.08712C0.830588 3.03073 0.904594 2.99385 0.984616 2.98192L2.65563 2.73645C2.7844 2.71776 2.87644 2.64656 2.93512 2.53482L3.66105 1.02444C3.71637 0.901569 3.90236 0.81666 4.00255 0.836995C3.99958 0.936351 3.99958 6.2373 3.99958 6.30451Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_384_19941">
            <rect width="8" height="8" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    is_investor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.premium__icon {
  max-width: 16px;
  width: 100%;
  height: 16px;
  background: var(--color-white);
  border-radius: 50%;
  display: grid;
  place-items: center;
  &.investor {
    .premium__icon--inner {
      border-radius: 4px 0 4px 0;
      max-width: 100%;
    }
  }
  &--inner {
    max-width: 14px;
    width: 100%;
    height: 14px;
    background: var(--color-green);
    border-radius: 50%;
    svg {
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
