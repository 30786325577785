<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
    <g clip-path="url(#a)">
      <path fill="url(#b)" d="M6 11.2c2.795 0 5.06-2.328 5.06-5.2C11.06 3.128 8.795.8 6 .8 3.208.8.942 3.128.942 6c0 2.872 2.266 5.2 5.06 5.2Z"/>
      <path fill="#fff" d="M6.001 8.623c1.54 0 2.816-1.176 3.036-2.713a.387.387 0 0 0-.396-.453H3.318c-.22 0-.396.226-.396.453.264 1.537 1.54 2.713 3.08 2.713Z"/>
      <path fill="#303030" d="M5.188 3.693c-.195-.541-.665-.893-1.193-.893-.52 0-.982.343-1.177.866-.04.1-.008.217.073.28.081.063.187.054.26-.027.381-.415.99-.749 1.704.018.041.045.09.063.147.063.04 0 .08-.009.113-.036.073-.054.106-.171.073-.27ZM9.183 3.693C8.99 3.152 8.52 2.8 7.993 2.8c-.519 0-.98.343-1.175.866-.04.1-.008.217.073.28.081.063.186.054.26-.027.38-.415.987-.749 1.7.018.04.045.09.063.146.063.04 0 .081-.009.113-.036.081-.054.114-.171.073-.27Z"/>
    </g>
    <defs>
      <linearGradient id="b" x1="1.613" x2="10.5" y1="8.603" y2="3.61" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset=".033" stop-color="#ECB64D"/>
        <stop offset=".178" stop-color="#EEC04F"/>
        <stop offset=".551" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
