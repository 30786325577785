<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="svgg">
      <path
        id="path0"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.832 0.883847C10.5298 1.00104 10.3613 1.14254 10.2289 1.39017L10.0979 1.63507L10.085 3.68955L10.072 5.74397H9.14384C3.3184 5.74397 0.157969 9.26192 0.00949277 15.9117C-0.037992 18.0372 0.0777379 18.5308 0.699571 18.8555C1.28619 19.1617 1.78656 18.9193 2.20234 18.1274C3.28984 16.0558 4.97647 15.0475 7.76815 14.7997C8.36923 14.7463 9.48099 14.7364 9.84034 14.7811L10.0755 14.8103V16.4779C10.0755 17.4977 10.094 18.2212 10.123 18.3402C10.2928 19.0374 11.0182 19.3631 11.6885 19.0431C11.9795 18.9041 19.7226 10.9386 19.9 10.5957C20.0268 10.3506 20.0341 9.85549 19.9157 9.52786C19.8203 9.26406 12.1241 1.21147 11.7427 0.976495C11.509 0.832502 11.0775 0.788584 10.832 0.883847ZM14.9927 13.5786L11.5541 17.142V15.6969C11.5541 13.2653 11.4928 13.2152 8.59702 13.2794C5.37413 13.3509 3.28858 14.1573 1.9094 15.8653C1.73414 16.0823 1.56181 16.2912 1.52648 16.3296C1.43352 16.4304 1.48668 15.025 1.6078 14.1818C2.27385 9.54331 4.8066 7.25599 9.20187 7.3235C10.7581 7.34739 10.7506 7.34775 10.9548 7.23918C11.5184 6.93936 11.5519 6.79579 11.5531 4.67617L11.5541 2.8887L14.9927 6.45205L18.4314 10.0153L14.9927 13.5786Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
