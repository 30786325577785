<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="profile">
  <g id="frame">
  <path id="Vector" d="M6.58737 6.29384C6.57112 6.29384 6.56029 6.29384 6.54404 6.29384C6.51695 6.28842 6.47904 6.28842 6.44654 6.29384C4.8757 6.24509 3.68945 5.01009 3.68945 3.48801C3.68945 1.93884 4.95154 0.676758 6.5007 0.676758C8.04987 0.676758 9.31195 1.93884 9.31195 3.48801C9.30654 5.01009 8.11487 6.24509 6.60362 6.29384C6.5982 6.29384 6.59279 6.29384 6.58737 6.29384ZM6.5007 1.48926C5.40112 1.48926 4.50195 2.38842 4.50195 3.48801C4.50195 4.57134 5.34695 5.44342 6.42487 5.48134C6.45195 5.47592 6.52779 5.47592 6.5982 5.48134C7.65987 5.43259 8.49404 4.56051 8.49945 3.48801C8.49945 2.38842 7.60029 1.48926 6.5007 1.48926Z" fill="#B0B0B0"/>
  <path id="Vector_2" d="M6.5926 12.2149C5.53094 12.2149 4.46385 11.9441 3.65677 11.4024C2.90385 10.9041 2.49219 10.2216 2.49219 9.47949C2.49219 8.73741 2.90385 8.04949 3.65677 7.54574C5.28177 6.46783 7.91427 6.46783 9.52844 7.54574C10.2759 8.04408 10.693 8.72658 10.693 9.46866C10.693 10.2107 10.2814 10.8987 9.52844 11.4024C8.71594 11.9441 7.65427 12.2149 6.5926 12.2149ZM4.10635 8.22824C3.58635 8.57491 3.30469 9.01908 3.30469 9.48491C3.30469 9.94533 3.59177 10.3895 4.10635 10.7307C5.4551 11.6353 7.7301 11.6353 9.07885 10.7307C9.59885 10.3841 9.88052 9.93991 9.88052 9.47408C9.88052 9.01366 9.59344 8.56949 9.07885 8.22824C7.7301 7.32908 5.4551 7.32908 4.10635 8.22824Z" fill="#B0B0B0"/>
  </g>
  </g>
  </svg>
</template>
