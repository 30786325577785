<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_11228_46555)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5954 1.47786C8.8819 1.14841 7.1181 1.14841 5.40465 1.47786C3.86116 1.77462 2.75806 3.0817 2.75806 4.59074V13.963C2.75806 14.5733 3.45327 14.978 4.02324 14.6553L6.69615 13.142C7.5018 12.6859 8.4982 12.6859 9.30385 13.142L11.9768 14.6553C12.5468 14.978 13.242 14.5733 13.242 13.963V4.59074C13.242 3.0817 12.1389 1.77462 10.5954 1.47786ZM5.162 0.270194C7.03565 -0.0900604 8.96435 -0.0900689 10.8379 0.270194C12.9617 0.678525 14.5 2.48381 14.5 4.59074V13.963C14.5 15.5458 12.7335 16.5058 11.3466 15.7206L8.67365 14.2072C8.2579 13.9718 7.7421 13.9718 7.32635 14.2072L4.65342 15.7206C3.26645 16.5058 1.5 15.5458 1.5 13.963V4.59074C1.5 2.48381 3.0383 0.678525 5.162 0.270194Z" fill="#1F1F1F"/>
    </g>
    <defs>
      <clipPath id="clip0_11228_46555">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
