<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_442_19383)">
      <path
        d="M8.41007 14.1327C7.82213 14.1327 7.1746 13.9243 6.52713 13.515L4.3539 12.153C4.20505 12.0637 4.03387 12.0116 3.86269 12.0116H2.79096C0.98986 12.0116 0 11.0217 0 9.22067V6.24362C0 4.44252 0.98986 3.45266 2.79096 3.45266H3.85525C4.02643 3.45266 4.19761 3.40056 4.34646 3.31125L6.51969 1.94926C7.60633 1.27199 8.66313 1.14547 9.49673 1.60691C10.3303 2.06835 10.7843 3.02843 10.7843 4.316V11.1408C10.7843 12.4209 10.3229 13.3885 9.49673 13.8499C9.16927 14.0434 8.79713 14.1327 8.41007 14.1327ZM2.79096 4.57649C1.61503 4.57649 1.11639 5.07514 1.11639 6.25107V9.22807C1.11639 10.404 1.61503 10.9027 2.79096 10.9027H3.85525C4.24226 10.9027 4.61439 11.0069 4.94186 11.2153L7.11507 12.5773C7.837 13.0238 8.50687 13.1429 8.96087 12.8898C9.41487 12.6368 9.67533 12.0041 9.67533 11.1631V4.32344C9.67533 3.47499 9.41487 2.84237 8.96087 2.59677C8.50687 2.34372 7.8296 2.45536 7.11507 2.90935L4.93442 4.2639C4.61439 4.47229 4.23482 4.57649 3.85525 4.57649H2.79096Z"
        fill="white"
      />
      <path
        d="M15.4438 9.75707C15.3024 9.75707 15.161 9.705 15.0494 9.59333L12.1021 6.6461C11.8862 6.43027 11.8862 6.07302 12.1021 5.85719C12.3179 5.64135 12.6752 5.64135 12.891 5.85719L15.8382 8.80447C16.0541 9.02027 16.0541 9.37753 15.8382 9.59333C15.7266 9.69753 15.5852 9.75707 15.4438 9.75707Z"
        fill="white"
      />
      <path
        d="M12.4653 9.78739C12.3239 9.78739 12.1825 9.73526 12.0708 9.62366C11.855 9.40779 11.855 9.05053 12.0708 8.83473L15.0181 5.88746C15.2339 5.67163 15.5912 5.67163 15.807 5.88746C16.0228 6.1033 16.0228 6.46054 15.807 6.67639L12.8598 9.62366C12.7481 9.73526 12.6067 9.78739 12.4653 9.78739Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_442_19383">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
