<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8.00065 15.2068C7.54065 15.2068 7.10732 14.9735 6.80065 14.5668L5.80065 13.2335C5.78065 13.2068 5.70065 13.1735 5.66732 13.1668H5.33398C2.55398 13.1668 0.833984 12.4135 0.833984 8.66683V5.3335C0.833984 2.38683 2.38732 0.833496 5.33398 0.833496H10.6673C13.614 0.833496 15.1673 2.38683 15.1673 5.3335V8.66683C15.1673 11.6135 13.614 13.1668 10.6673 13.1668H10.334C10.2807 13.1668 10.234 13.1935 10.2007 13.2335L9.20065 14.5668C8.89398 14.9735 8.46065 15.2068 8.00065 15.2068ZM5.33398 1.8335C2.94732 1.8335 1.83398 2.94683 1.83398 5.3335V8.66683C1.83398 11.6802 2.86732 12.1668 5.33398 12.1668H5.66732C6.00732 12.1668 6.39398 12.3602 6.60065 12.6335L7.60065 13.9668C7.83398 14.2735 8.16732 14.2735 8.40065 13.9668L9.40065 12.6335C9.62065 12.3402 9.96732 12.1668 10.334 12.1668H10.6673C13.054 12.1668 14.1673 11.0535 14.1673 8.66683V5.3335C14.1673 2.94683 13.054 1.8335 10.6673 1.8335H5.33398Z"
      fill="currentColor"
    />
    <path
      d="M8.00065 7.99984C7.62732 7.99984 7.33398 7.69984 7.33398 7.33317C7.33398 6.9665 7.63398 6.6665 8.00065 6.6665C8.36732 6.6665 8.66732 6.9665 8.66732 7.33317C8.66732 7.69984 8.37398 7.99984 8.00065 7.99984Z"
      fill="currentColor"
    />
    <path
      d="M10.6667 7.99984C10.2933 7.99984 10 7.69984 10 7.33317C10 6.9665 10.3 6.6665 10.6667 6.6665C11.0333 6.6665 11.3333 6.9665 11.3333 7.33317C11.3333 7.69984 11.04 7.99984 10.6667 7.99984Z"
      fill="currentColor"
    />
    <path
      d="M5.33268 7.99984C4.95935 7.99984 4.66602 7.69984 4.66602 7.33317C4.66602 6.9665 4.96602 6.6665 5.33268 6.6665C5.69935 6.6665 5.99935 6.9665 5.99935 7.33317C5.99935 7.69984 5.70602 7.99984 5.33268 7.99984Z"
      fill="currentColor"
    />
  </svg>
</template>
