<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
        fill="currentColor"/>
    <path
        d="M7.99955 9.30205C7.69443 9.30205 7.44141 9.04903 7.44141 8.74391V5.02298C7.44141 4.71787 7.69443 4.46484 7.99955 4.46484C8.30466 4.46484 8.55769 4.71787 8.55769 5.02298V8.74391C8.55769 9.04903 8.30466 9.30205 7.99955 9.30205Z"
        fill="currentColor"/>
    <path
        d="M8.00005 11.721C7.9033 11.721 7.80656 11.6987 7.71725 11.6614C7.62795 11.6242 7.54609 11.5721 7.47167 11.5052C7.4047 11.4307 7.3526 11.3563 7.31539 11.2596C7.27818 11.1703 7.25586 11.0735 7.25586 10.9768C7.25586 10.88 7.27818 10.7833 7.31539 10.694C7.3526 10.6047 7.4047 10.5228 7.47167 10.4484C7.54609 10.3814 7.62795 10.3294 7.71725 10.2921C7.89586 10.2177 8.10423 10.2177 8.28284 10.2921C8.37214 10.3294 8.454 10.3814 8.52842 10.4484C8.59539 10.5228 8.64749 10.6047 8.6847 10.694C8.72191 10.7833 8.74423 10.88 8.74423 10.9768C8.74423 11.0735 8.72191 11.1703 8.6847 11.2596C8.64749 11.3563 8.59539 11.4307 8.52842 11.5052C8.454 11.5721 8.37214 11.6242 8.28284 11.6614C8.19353 11.6987 8.09679 11.721 8.00005 11.721Z"
        fill="currentColor"/>
  </svg>
</template>
