<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_126914)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.02505 21.0089C8.75253 22.208 11.0972 22.1996 12.8194 20.988C13.2419 20.6908 19.3697 14.5649 19.7219 14.0877C22.3165 10.5721 21.1189 5.58682 17.2098 3.63047C14.9673 2.50818 12.4073 2.6654 10.1943 4.06128L9.85719 4.27392L6.49835 7.61509L3.13947 10.9563L3.08986 11.1465C2.94492 11.7018 3.49364 12.255 4.04602 12.1104L4.23009 12.0623L7.42879 8.88313C11.2007 5.1343 11.1454 5.18022 12.3834 4.77208C17.3427 3.13724 21.4935 8.80037 18.478 13.0872C18.1221 13.5932 12.2589 19.4614 11.8492 19.7217C8.65893 21.7486 5.03051 18.1629 6.99746 14.927C7.17171 14.6403 12.6654 9.07811 13.1085 8.73974C14.5644 7.6279 16.3764 9.39139 15.2822 10.8553C15.1804 10.9915 13.9955 12.1978 12.1523 14.0417L9.18626 17.0088L9.13721 17.1968C8.9923 17.752 9.54272 18.3024 10.0979 18.1575L10.2859 18.1084L13.4474 14.9469C17.0448 11.3495 16.8089 11.6165 17.0661 10.8506C17.9653 8.17265 14.9197 5.76308 12.4823 7.22395C12.1081 7.4482 6.10635 13.4286 5.75683 13.9255C4.15145 16.2074 4.72529 19.4125 7.02505 21.0089Z" fill="#9D9D9D"/>
    </g>
    <defs>
      <clipPath id="clip0_4_126914">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
