<template>
  <svg
    v-if="!isActive"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.334 6.70825H16.334C15.8557 6.70825 15.459 6.31159 15.459 5.83325C15.459 5.35492 15.8557 4.95825 16.334 4.95825H23.334C23.8123 4.95825 24.209 5.35492 24.209 5.83325C24.209 6.31159 23.8123 6.70825 23.334 6.70825Z"
      fill="currentColor"
    />
    <path
      d="M19.834 10.2083H16.334C15.8557 10.2083 15.459 9.81159 15.459 9.33325C15.459 8.85492 15.8557 8.45825 16.334 8.45825H19.834C20.3123 8.45825 20.709 8.85492 20.709 9.33325C20.709 9.81159 20.3123 10.2083 19.834 10.2083Z"
      fill="currentColor"
    />
    <path
      d="M13.4173 25.3749C6.82565 25.3749 1.45898 20.0083 1.45898 13.4166C1.45898 6.82492 6.82565 1.45825 13.4173 1.45825C13.8957 1.45825 14.2923 1.85492 14.2923 2.33325C14.2923 2.81159 13.8957 3.20825 13.4173 3.20825C7.78232 3.20825 3.20898 7.79325 3.20898 13.4166C3.20898 19.0399 7.78232 23.6249 13.4173 23.6249C19.0523 23.6249 23.6257 19.0399 23.6257 13.4166C23.6257 12.9383 24.0223 12.5416 24.5007 12.5416C24.979 12.5416 25.3757 12.9383 25.3757 13.4166C25.3757 20.0083 20.009 25.3749 13.4173 25.3749Z"
      fill="currentColor"
    />
    <path
      d="M25.6683 26.5416C25.4466 26.5416 25.225 26.4599 25.05 26.2849L22.7166 23.9516C22.3783 23.6133 22.3783 23.0533 22.7166 22.7149C23.055 22.3766 23.615 22.3766 23.9533 22.7149L26.2866 25.0483C26.625 25.3866 26.625 25.9466 26.2866 26.2849C26.1116 26.4599 25.89 26.5416 25.6683 26.5416Z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.45898 13.4166C1.45898 20.021 6.81291 25.3749 13.4173 25.3749C20.0217 25.3749 25.3757 20.021 25.3757 13.4166C25.3757 10.9303 24.6168 8.62119 23.3182 6.70825H16.334C15.8557 6.70825 15.459 6.31158 15.459 5.83325C15.459 5.35492 15.8557 4.95825 16.334 4.95825H21.8706C19.7068 2.7957 16.7183 1.45825 13.4173 1.45825C6.81291 1.45825 1.45898 6.81218 1.45898 13.4166ZM16.334 10.2083H19.834C20.3123 10.2083 20.709 9.81159 20.709 9.33325C20.709 8.85492 20.3123 8.45825 19.834 8.45825H16.334C15.8557 8.45825 15.459 8.85492 15.459 9.33325C15.459 9.81159 15.8557 10.2083 16.334 10.2083ZM25.049 26.2848C25.224 26.4598 25.4457 26.5415 25.6673 26.5415C25.889 26.5415 26.1107 26.4598 26.2857 26.2848C26.624 25.9465 26.624 25.3865 26.2857 25.0481L23.9523 22.7148C23.614 22.3765 23.054 22.3765 22.7156 22.7148C22.3773 23.0531 22.3773 23.6131 22.7156 23.9515L25.049 26.2848Z"
      fill="#49A399"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
