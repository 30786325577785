<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8.321 1.27c-3.44.405-5.532 4.224-4.04 7.374 1.87 3.946 7.452 4.005 9.386.099C15.51 5.02 12.477.78 8.321 1.27Zm1.29 1.563c2.86.52 4.033 3.962 2.072 6.08-2.309 2.494-6.489.74-6.325-2.655.105-2.185 2.124-3.811 4.252-3.425Zm6.403.505c-.724.43-.391 1.356.52 1.444 3.456.335 3.426 5.195-.034 5.435-.689.048-.938.237-.973.74-.053.762.786 1.025 2.066.649 3.736-1.1 4.186-6.186.703-7.942-.75-.378-1.914-.545-2.282-.326ZM8.228 12.45l-.563.07c-4.375.526-7.08 3.593-5.751 6.523 1.338 2.951 6.497 4.397 10.688 2.995 5.81-1.942 5.428-7.714-.612-9.261-1.07-.275-2.946-.438-3.762-.327Zm9.843.836c-.787.351-.585 1.283.33 1.525 3.113.822 3.008 3.556-.17 4.428-.746.205-.903 1.001-.28 1.42.268.182.741.116 1.585-.222 3.57-1.427 3.603-5.396.059-6.858-.742-.306-1.268-.407-1.524-.293Zm-7.861.733c4.094.448 6.245 3.12 4.187 5.202-2.69 2.722-9.53 2.244-11.024-.77-1.216-2.454 2.557-4.9 6.837-4.432Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
