<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="link">
    <g id="link_2">
    <path id="Vector" d="M8.93706 9.88509H8.11914C7.89706 9.88509 7.71289 9.70092 7.71289 9.47884C7.71289 9.25676 7.89706 9.07259 8.11914 9.07259H8.93706C10.3562 9.07259 11.51 7.91884 11.51 6.49967C11.51 5.08051 10.3562 3.92676 8.93706 3.92676H8.12456C7.90247 3.92676 7.71831 3.74259 7.71831 3.52051C7.71831 3.29842 7.89706 3.11426 8.12456 3.11426H8.93706C10.8058 3.11426 12.3225 4.63092 12.3225 6.49967C12.3225 8.36842 10.8058 9.88509 8.93706 9.88509Z" fill="#B0B0B0"/>
    <path id="Vector_2" d="M4.87565 9.88509H4.06315C2.1944 9.88509 0.677734 8.36842 0.677734 6.49967C0.677734 4.63092 2.1944 3.11426 4.06315 3.11426H4.87565C5.09773 3.11426 5.2819 3.29842 5.2819 3.52051C5.2819 3.74259 5.09773 3.92676 4.87565 3.92676H4.06315C2.64398 3.92676 1.49023 5.08051 1.49023 6.49967C1.49023 7.91884 2.64398 9.07259 4.06315 9.07259H4.87565C5.09773 9.07259 5.2819 9.25676 5.2819 9.47884C5.2819 9.70092 5.09773 9.88509 4.87565 9.88509Z" fill="#B0B0B0"/>
    <path id="Vector_3" d="M8.66732 6.90625H4.33398C4.1119 6.90625 3.92773 6.72208 3.92773 6.5C3.92773 6.27792 4.1119 6.09375 4.33398 6.09375H8.66732C8.8894 6.09375 9.07357 6.27792 9.07357 6.5C9.07357 6.72208 8.8894 6.90625 8.66732 6.90625Z" fill="#B0B0B0"/>
    </g>
    </g>
  </svg>
</template>
