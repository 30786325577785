<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_820_5604)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1299 0.133048C10.6899 0.273835 10.2474 0.535517 9.71398 0.970622C9.07127 1.4948 0.162335 10.4375 0.0891777 10.6319C-0.340923 11.7752 0.875043 12.4348 1.80663 11.5634L2.09094 11.2974L2.11569 13.8478C2.13845 16.1944 2.15032 16.4425 2.26436 16.954C3.14865 20.9209 6.09504 23.4385 10.4006 23.906C16.2973 24.5464 20.6302 21.8806 21.7407 16.929C21.8487 16.4473 21.862 16.162 21.8845 13.8478L21.9092 11.2974L22.1832 11.5538C23.1208 12.4307 24.3411 11.7797 23.9111 10.6319C23.8324 10.4216 14.8224 1.3946 14.216 0.918315C13.1338 0.0684378 12.1302 -0.186908 11.1299 0.133048ZM12.4771 2.04498C12.9286 2.2727 13.4619 2.77433 16.7374 6.05301L20.0071 9.32573L19.9862 12.7756L19.9652 16.2255L19.8141 16.801C19.2754 18.8536 17.8855 20.5428 16.0783 21.3412L15.77 21.4774L15.7663 20.4918C15.763 19.5837 15.7506 19.4699 15.6096 19.0457C14.3638 15.3004 9.12099 15.6092 8.28216 19.4774C8.19972 19.8578 8.16649 21.2191 8.23538 21.3985C8.32224 21.6249 7.20934 21.0157 6.51151 20.4549C5.34166 19.5148 4.58954 18.3328 4.18641 16.801L4.03493 16.2255L4.01394 12.7756L3.99301 9.32573L7.26268 6.05301C11.6102 1.7013 11.6838 1.64479 12.4771 2.04498ZM12.6439 18.42C13.4953 18.6907 13.9368 19.5083 13.8869 20.7221C13.8321 22.0568 13.8263 22.0609 12 22.0609C10.1282 22.0609 10.131 22.0631 10.1311 20.5709C10.1313 18.8416 11.1711 17.9516 12.6439 18.42Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_820_5604">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
