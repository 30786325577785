<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.99972 14.9336C11.7253 14.9336 14.7455 11.8294 14.7455 8.00023C14.7455 4.17105 11.7253 1.06689 7.99972 1.06689C4.27411 1.06689 1.25391 4.17105 1.25391 8.00023C1.25391 11.8294 4.27411 14.9336 7.99972 14.9336Z" fill="url(#paint0_linear_862_4862)"/>
    <path d="M8.00097 12.9059C8.88495 12.9059 9.60155 11.6883 9.60155 10.1864C9.60155 8.68438 8.88495 7.4668 8.00097 7.4668C7.117 7.4668 6.40039 8.68438 6.40039 10.1864C6.40039 11.6883 7.117 12.9059 8.00097 12.9059Z" fill="#303030"/>
    <path d="M9.35358 11.6455C8.18502 11.001 7.23601 11.3693 6.68359 11.7305C6.97396 12.4387 7.45556 12.9061 8.00089 12.9061C8.56746 12.9061 9.0703 12.4033 9.35358 11.6455Z" fill="#F06669"/>
    <path d="M6.4036 7.42094C6.93346 6.39215 6.74458 5.26901 5.98171 4.91236C5.21885 4.5557 4.17089 5.10059 3.64103 6.12938C3.11117 7.15818 3.30006 8.28131 4.06293 8.63797C4.82579 8.99462 5.87375 8.44974 6.4036 7.42094Z" fill="white"/>
    <path d="M11.9524 8.6394C12.7153 8.28275 12.9042 7.15962 12.3743 6.13082C11.8445 5.10202 10.7965 4.55714 10.0336 4.91379C9.27077 5.27044 9.08188 6.39358 9.61174 7.42238C10.1416 8.45117 11.1896 8.99605 11.9524 8.6394Z" fill="white"/>
    <path d="M11.2521 4.24395C11.0879 4.24395 10.959 4.11499 10.959 3.95086V0.29309C10.959 0.12896 11.0879 0 11.2521 0C11.4162 0 11.5452 0.12896 11.5452 0.29309V3.95086C11.5452 4.10326 11.4045 4.24395 11.2521 4.24395Z" fill="#414042"/>
    <path d="M12.5997 3.64587C12.4356 3.64587 12.3066 3.51691 12.3066 3.35278V0.714965C12.3066 0.550835 12.4356 0.421875 12.5997 0.421875C12.7639 0.421875 12.8928 0.550835 12.8928 0.714965V3.35278C12.8928 3.51691 12.7639 3.64587 12.5997 3.64587Z" fill="#414042"/>
    <path d="M13.9611 5.59221C13.7969 5.59221 13.668 5.46325 13.668 5.29912V1.35998C13.668 1.19585 13.7969 1.06689 13.9611 1.06689C14.1252 1.06689 14.2541 1.19585 14.2541 1.35998V5.29912C14.2541 5.46325 14.1252 5.59221 13.9611 5.59221Z" fill="#414042"/>
    <defs>
      <linearGradient id="paint0_linear_862_4862" x1="2.1492" y1="11.4703" x2="13.999" y2="4.81379" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
    </defs>
  </svg>
</template>
