<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <g clip-path="url(#a)">
      <path fill="currentColor" d="M15.622 9.101a.562.562 0 0 0-.47.103 5.998 5.998 0 0 1-1.82 1.063 5.698 5.698 0 0 1-2.066.368 6.013 6.013 0 0 1-4.253-1.758 6.013 6.013 0 0 1-1.758-4.254c0-.695.123-1.37.327-1.983A5.49 5.49 0 0 1 6.564.882a.522.522 0 0 0-.082-.737c-.143-.102-.307-.143-.47-.102a8.251 8.251 0 0 0-4.335 2.904A8.098 8.098 0 0 0 0 7.875c0 2.228.9 4.253 2.372 5.725a8.067 8.067 0 0 0 5.725 2.372c1.881 0 3.62-.655 5.01-1.738a8.062 8.062 0 0 0 2.862-4.478c.103-.307-.061-.593-.347-.655Zm-3.129 4.274a7.017 7.017 0 0 1-9.324-.552 7.017 7.017 0 0 1-2.065-4.969c0-1.615.532-3.087 1.452-4.273a7.03 7.03 0 0 1 2.351-1.963c-.102.225-.204.45-.286.695a6.804 6.804 0 0 0-.388 2.33c0 1.943.797 3.722 2.065 4.99a7.065 7.065 0 0 0 4.989 2.065c.859 0 1.677-.143 2.433-.43.266-.101.532-.204.777-.326a7.44 7.44 0 0 1-2.004 2.433Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
