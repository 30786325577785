<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_253_18808)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.832 0.884579C10.5298 1.00177 10.3613 1.14327 10.2289 1.3909L10.0979 1.63581L10.085 3.69029L10.072 5.7447H9.14384C3.3184 5.7447 0.157969 9.26265 0.00949277 15.9124C-0.037992 18.0379 0.0777379 18.5315 0.699571 18.8562C1.28619 19.1624 1.78656 18.92 2.20234 18.1281C3.28984 16.0566 4.97647 15.0482 7.76815 14.8004C8.36923 14.7471 9.48099 14.7371 9.84034 14.7818L10.0755 14.8111V16.4787C10.0755 17.4984 10.094 18.2219 10.123 18.341C10.2928 19.0382 11.0182 19.3638 11.6885 19.0438C11.9795 18.9048 19.7226 10.9394 19.9 10.5965C20.0268 10.3513 20.0341 9.85622 19.9157 9.52859C19.8203 9.26479 12.1241 1.21221 11.7427 0.977228C11.509 0.833234 11.0775 0.789317 10.832 0.884579ZM14.9927 13.5794L11.5541 17.1427V15.6977C11.5541 13.2661 11.4928 13.216 8.59702 13.2802C5.37413 13.3516 3.28858 14.158 1.9094 15.866C1.73414 16.0831 1.56181 16.292 1.52648 16.3303C1.43352 16.4311 1.48668 15.0257 1.6078 14.1825C2.27385 9.54404 4.8066 7.25673 9.20187 7.32424C10.7581 7.34813 10.7506 7.34848 10.9548 7.23991C11.5184 6.9401 11.5519 6.79652 11.5531 4.67691L11.5541 2.88944L14.9927 6.45279L18.4314 10.0161L14.9927 13.5794Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_253_18808">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
