<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M12.9921 18.051C12.8337 18.051 12.6754 17.9927 12.5504 17.8677C12.3087 17.626 12.3087 17.226 12.5504 16.9844L14.9004 14.6344C15.1421 14.3927 15.5421 14.3927 15.7837 14.6344C16.0254 14.876 16.0254 15.276 15.7837 15.5177L13.4337 17.8677C13.3087 17.9927 13.1504 18.051 12.9921 18.051Z"
      fill="#D94B4B"
    />
    <path
      d="M15.3421 18.051C15.1837 18.051 15.0254 17.9927 14.9004 17.8677L12.5504 15.5177C12.3087 15.276 12.3087 14.876 12.5504 14.6344C12.7921 14.3927 13.1921 14.3927 13.4337 14.6344L15.7837 16.9844C16.0254 17.226 16.0254 17.626 15.7837 17.8677C15.6587 17.9927 15.5004 18.051 15.3421 18.051Z"
      fill="#D94B4B"
    />
    <path
      d="M10.1341 9.68464C10.1091 9.68464 10.0924 9.68464 10.0674 9.68464C10.0258 9.6763 9.96745 9.6763 9.91745 9.68464C7.50078 9.60964 5.67578 7.70964 5.67578 5.36797C5.67578 2.98464 7.61745 1.04297 10.0008 1.04297C12.3841 1.04297 14.3258 2.98464 14.3258 5.36797C14.3174 7.70964 12.4841 9.60964 10.1591 9.68464C10.1508 9.68464 10.1424 9.68464 10.1341 9.68464ZM10.0008 2.29297C8.30911 2.29297 6.92578 3.6763 6.92578 5.36797C6.92578 7.03464 8.22578 8.3763 9.88411 8.43464C9.93411 8.4263 10.0424 8.4263 10.1508 8.43464C11.7841 8.35964 13.0674 7.01797 13.0758 5.36797C13.0758 3.6763 11.6924 2.29297 10.0008 2.29297Z"
      fill="#D94B4B"
    />
    <path
      d="M10.0003 18.801C8.30026 18.801 6.69193 18.3594 5.47526 17.5427C4.31693 16.7677 3.68359 15.7094 3.68359 14.5677C3.68359 13.426 4.32526 12.376 5.47526 11.6094C7.96693 9.94271 12.0169 9.94271 14.5086 11.6094C14.7919 11.801 14.8753 12.1927 14.6836 12.476C14.4919 12.7677 14.1003 12.8427 13.8169 12.651C11.7419 11.2677 8.24193 11.2677 6.16693 12.651C5.36693 13.1844 4.93359 13.8594 4.93359 14.5677C4.93359 15.276 5.37526 15.9677 6.16693 16.501C7.17526 17.176 8.53359 17.5427 9.99193 17.5427C10.3336 17.5427 10.6169 17.826 10.6169 18.1677C10.6169 18.5094 10.3419 18.801 10.0003 18.801Z"
      fill="#D94B4B"
    />
  </svg>
</template>
