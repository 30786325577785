export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cv"])},
  "meta_title": {
    "preview_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Article"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "login": {
      "by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Email"])},
      "by_phone_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Phone - Step 1"])},
      "confirm_phone_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Phone - Step 2"])}
    },
    "register": {
      "add_email_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register - Add Email"])},
      "confirm_email_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register - Confirm Email"])},
      "create_password_step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register - Create Password"])},
      "add_info_step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register - Add Info"])},
      "add_phone_step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register - Add Phone"])},
      "category_interests_step6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register - Category Interests"])}
    },
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "marriage_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marriage Agency"])},
    "my_albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Albums"])},
    "messenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
    "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Audio"])},
    "my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Groups"])},
    "my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Page"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
    "search": {
      "all-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all recommendations"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])}
    },
    "show_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "placeholders": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email..."])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password..."])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your phone number..."])}
    },
    "code_from_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS code"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "with_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with phone number"])},
    "with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with email"])},
    "messages": {
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send you a message to change your password"])},
      "verify_with_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A message with a code was sent to your phone number"])},
      "didnt_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive a code?"])}
    },
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field must not be empty"])},
      "empty_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field must not be empty"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
      "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
      "incorrect_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid code"])}
    },
    "interests_category": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Step!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select interest categories so we can show you content that will interest you"])}
    }
  },
  "links": {
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link successfully copied"])},
    "link_copied_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while copying the link"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Page"])},
    "messenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
    "my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My groups"])},
    "my_albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My albums"])},
    "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My audio"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "marriage_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marriage agency"])},
    "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])},
    "all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All people"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "search": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])}
    },
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "placeholders": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email..."])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter you last name..."])},
      "code_from_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter code from email..."])},
      "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration password..."])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose gender"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
      }
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have an account?"])},
    "messages": {
      "agreement_to_creating_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By creating an account, you agree to our"])},
      "verify_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A message with a code has been sent to your email address."])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password to log in to the system"])},
      "fill_your_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out your information to log in to UmmaLife"])},
      "required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Required fields to fill in)"])},
      "enter_your_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your phone number"])},
      "can_login_with_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be able to access the network using your phone number"])}
    },
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field must not be empty"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email"])},
      "incorrect_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect code"])},
      "empty_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field must not be empty"])},
      "empty_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field must not be empty"])},
      "incorrect_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect phone number"])}
    },
    "category_interests": {
      "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
      "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health"])},
      "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness"])},
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
      "traveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling"])},
      "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
      "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Science"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
      "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion"])},
      "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture"])},
      "beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
      "studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studies"])},
      "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
      "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "psychology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychology"])},
      "upbringing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upbringing"])}
    }
  },
  "forgot_password": {
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field must not be empty"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email"])}
    }
  },
  "reset_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "succeed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The E-mail has been sent successfully."])}
  },
  "buttons": {
    "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
    "get_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get code by email"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "create_umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Umma Video"])},
    "toggle_theme": {
      "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
      "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
    "in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In group"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I follow"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "open_in_new_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in new tab"])},
    "complain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complain"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "open_like_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send as message"])},
    "share_on_my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share on my page"])},
    "share_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share in a group"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "available_everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to everyone"])},
    "who_am_i_following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who am I following"])},
    "who_is_following_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is following me"])},
    "anonymously": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymously"])},
    "create_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create poll"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "add_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add answer"])},
    "mood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood"])},
    "traveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling"])},
    "watching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm watching"])},
    "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm playing"])},
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm listening"])},
    "emotions": {
      "happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Happy"])},
      "in_love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In love"])},
      "outraged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outraged"])},
      "celebrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I celebrate"])},
      "giggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I giggle"])},
      "crazy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crazy"])},
      "falling_asleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falling asleep"])},
      "shocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm shocked"])},
      "excited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excited"])},
      "angry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angry"])},
      "masked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masked"])},
      "think": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think"])},
      "laughing_out_loud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laughing Out Loud"])},
      "wonder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wonder"])},
      "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm sick"])},
      "embarrassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embarrassed"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
      "frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen"])}
    },
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "pinned_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinned message"])},
    "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filter"])},
    "image_upload": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click or drag the image"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptable extensions jpeg, jpg, png"])}
    },
    "back_to_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to list of articles"])},
    "article_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article preview"])},
    "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])}
  },
  "languages": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "name": {
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
      "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اَلْعَرَبِيَّةُ"])},
      "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türk"])},
      "uzbek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'zbek"])},
      "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa Indonesia"])},
      "malay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayu"])},
      "tajik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тоҷик"])},
      "tabasaran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табасаран"])},
      "chuvash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чӑвашла"])},
      "urdu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اردو"])},
      "ingush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["гӏалгӏай"])},
      "tatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Татар"])},
      "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лакский"])},
      "qumuq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Къумукъ"])},
      "balkarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tаулу"])},
      "amharic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["አማርኛ"])},
      "lezgin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лезги"])}
    }
  },
  "placeholders": {
    "search_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "search_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search articles"])},
    "publications_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write good or be silent..."])},
    "comment_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comment..."])},
    "question_for_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll question or topic... "])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message..."])},
    "article_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article title"])}
  },
  "tabs": {
    "profile_tabs": {
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaVideo"])}
    },
    "my_page": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaShorts"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaVideo"])},
      "alboms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albums"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])}
    },
    "news_page": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaShorts"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaVideo"])}
    },
    "publications_inside": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])}
    },
    "stickers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stickers"])}
    },
    "search": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
      "communities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communities"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "age": {
        "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
        "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])}
      }
    },
    "articles_page": {
      "all_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All articles"])},
      "my_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My articles"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drafts"])},
      "create_new_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new article"])}
    }
  },
  "video": {
    "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reactions"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["views"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read more"])},
    "search_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search video"])},
    "related_movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related movies"])},
    "video_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video page"])},
    "tabs": {
      "all_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All movies"])},
      "new_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "recommended_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
      "saved_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
      "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watched"])}
    },
    "parameters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search options"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
      "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
      "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of views"])}
    }
  },
  "audio": {
    "tabs": {
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My audios"])},
      "playlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlists"])},
      "liked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked"])},
      "download_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download new audio"])}
    },
    "search_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for audio"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "all_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All audio"])},
    "my_playlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My playlists"])},
    "add_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add audio"])},
    "all_audios_for_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All audio for download"])},
    "create_album": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an album"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])},
    "drop_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add or drag and drop the audio"])},
    "drop_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid extension is mp3"])},
    "status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload completed"])},
    "status_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "status_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading error"])},
    "album_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create an album you need to fill in the fields below"])},
    "album_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add album title"])},
    "album_textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add album description"])},
    "download_to_playlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload to playlist"])},
    "create_as_album": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create as album"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "upload_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload audio"])}
  },
  "sections_title": {
    "last_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last actions"])},
    "recommended_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended groups"])},
    "recommended_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended people"])},
    "create_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a poll"])}
  },
  "labels": {
    "subscribers": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribers"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribe"])}
    },
    "members": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["members"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member"])}
    },
    "comments": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])}
    },
    "reposts": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reposts"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repost"])}
    },
    "time": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])},
      "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["назад"])}
    },
    "poll": {
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
      "answer_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer options"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer №"])},
      "only_eight_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add up to 8 answer options"])},
      "quiz_options_valid_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The options in the Quiz can only have one correct answer."])},
      "anonymous_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous Poll"])},
      "multiple_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple choice"])},
      "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])}
    },
    "feeling": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you feeling or what are you doing?"])},
      "mood": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you feeling?"])}
      },
      "traveling": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you going?"])}
      },
      "watching": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watching"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you watching?"])}
      },
      "playing": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you playing?"])}
      },
      "listening": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listening"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you listening"])}
      }
    },
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My audios"])},
    "stickers": {
      "popular_stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular stickers"])},
      "featured_stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured stickers"])}
    },
    "search": {
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
      "communities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communities"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "show_all_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all recommendations"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced search"])}
    },
    "choose_article_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose article category"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "article_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article cover"])},
    "articles": {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "popular_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular articles"])},
      "create_article": {
        "press_to_select_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press “+” to select a tool"])}
      },
      "editor": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header"])},
        "photo_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo and Video"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "inset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inset"])},
        "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
        "divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divider"])},
        "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
        "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your post..."])},
        "poll": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll title"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer option"])}
        }
      }
    }
  },
  "dropdown": {
    "reply_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View answers"])},
    "download_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
    "delete_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete dialog"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "copy_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Text"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "first_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
    "first_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldest"])}
  },
  "profile": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of residence"])},
    "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web site"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "subscribes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers"])},
    "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umma Video"])},
    "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umma Shorts"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])}
  },
  "chat": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "empty_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose who you'd like to write to"])}
  },
  "modal": {
    "delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete message"])},
    "sure_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the message?"])},
    "also_delete_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also remove from"])},
    "cancel_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "reaction_tooltip": {
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma sha Allah"])},
    "dislike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outraged"])},
    "love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Love"])},
    "fire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire"])},
    "angry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angry"])},
    "scared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surprised"])},
    "laugh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HaHa"])},
    "think": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thought"])},
    "sad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sad"])}
  },
  "settings": {
    "nav": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main settings"])},
      "privet_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
      "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "control_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session management"])},
      "restore_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Contacts"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings"])},
      "black_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist"])},
      "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])}
    },
    "main": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main settings"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the \"Main Settings\" section, you can customize the basic settings of your profile. Update your username, phone number, email address, date of birth and country of residence to keep your Umma Life profile up to date and complete."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "show_in_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display in profile"])},
      "show_in_profile_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display login in profile instead of first and last name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the address"])},
      "categories_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories of interest"])},
      "categories": {
        "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
        "psychology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychology"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
        "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health"])},
        "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
        "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study"])},
        "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel"])},
        "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
        "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Science"])},
        "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
        "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
        "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
        "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion"])},
        "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture"])},
        "beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty"])},
        "parenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parenting"])},
        "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])}
      }
    },
    "privet_data": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the \"Personal Information\" section, you can provide additional information about yourself so that other users can get to know you better. Fill in your full name, tell us a little about yourself, and indicate where you live. This will help create a more personal and individualized picture of you in the Umma Life community."])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "about_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
      "about_me_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about yourself..."])},
      "marital_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital status"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Married"])},
      "not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])}
    },
    "education": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the Education and Work section, you can provide information about your education and job title. This will help other users better understand your academic and professional interests."])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
      "school_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of your school"])},
      "school_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a school"])},
      "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College"])},
      "college_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of your college"])},
      "college_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a college"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University"])},
      "university_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of your university"])},
      "university_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a university"])}
    },
    "work": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the \"Work\" section, you can provide information about the organization where you work and the position you hold. This will help other users better understand your area of interest."])},
      "work_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of work"])},
      "work_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate your place of work"])},
      "work_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company website"])},
      "self_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal page"])}
    },
    "privacy": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the \"Privacy\" section, you can set privacy settings for your profile. Here you can restrict what other users can do with your profile, set up notifications, restrict access to certain information, and so on. Customize these settings according to your preferences and desired level of privacy."])},
      "top_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose the information you want to show on your page, as well as prohibit or restrict access to your page"])},
      "available_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to all"])},
      "only_friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only friends"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can subscribe to me?"])},
      "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can send me messages?"])},
      "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can see my friends?"])},
      "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can leave notes on my personal page?"])},
      "question5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can see my birthday?"])},
      "question6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepting a subscription request?"])},
      "question7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can add me to the group?"])},
      "question8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send users notifications when I visit their profiles?"])},
      "question8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you disable this notification, you will not receive notifications about who has visited your profile."])},
      "question9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show your actions?"])},
      "question10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show your location to others?"])},
      "question11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving and copying materials"])},
      "question11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable copying"])},
      "question11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose the information you want to show on your page, as well as prohibit or restrict access to your page"])}
    },
    "password": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password is an important element of your account security. It protects against unauthorized access and keeps your personal information private. The right password helps prevent fraud and protects your reputation in the online environment. So remember to create and use strong passwords for your accounts and keep them secure."])},
      "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
      "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "repeat_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat the new password"])},
      "auth2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
      "auth2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For two-factor authentication, you must enter your phone number in your settings to receive a verification code for your UmmaLife account"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])}
    },
    "session": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session management"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Management is an important feature that allows you to control the active sessions of your account. This gives you an additional opportunity to ensure security and protect your account from unauthorized access. You can view active sessions and terminate them if necessary. This way, you have more control over your account and can be sure that it is secure."])},
      "current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current session"])},
      "recent_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent sessions"])},
      "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["online"])},
      "hour_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hour ago"])},
      "ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])},
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
      "auto_quit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic session logout"])},
      "if_not_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If inactive"])},
      "six_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 months"])},
      "complete_all_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End other sessions"])}
    },
    "restore": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery Contacts"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you forget your password, we can help you regain access to your mail within a few minutes: a recovery code will be sent to the cell phone or backup email specified here. Check your contacts and replace them with new ones if necessary."])},
      "mobile_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
      "mobile_phone_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another mobile phone"])},
      "reserve_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup e-mail"])},
      "reserve_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter e-mail address"])},
      "reserve_email_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another backup e-mail"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
    },
    "notifications": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings allow you to customize the information and alerts you receive from our site."])},
      "system_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Notifications"])},
      "email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail notifications"])},
      "like_my_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like my notes"])},
      "comment_my_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commented on my notes"])},
      "share_my_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share my notes"])},
      "subscribed_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed to me"])},
      "like_my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like my page"])},
      "mentioned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentioned me"])},
      "join_my_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join my group"])},
      "accept_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription request accepted"])},
      "post_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing on the profile page"])}
    },
    "black_list": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisting is a feature that allows you to control and limit your interactions with certain users on our site. You can add users to the blacklist to prevent you from receiving messages, comments or mentions from them."])}
    },
    "delete_account": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You always have the option to delete your account on our website. We value your right to control your personal data and respect your choice."])},
      "delete_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Deletion"])},
      "delete_account_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not log into your account during this time, your account will be automatically deleted from the system permanently"])},
      "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the password to delete the account"])},
      "enter_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your current password"])}
    },
    "account": {
      "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety"])},
      "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
      "notif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "popup_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up notification"])},
      "show_popup_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show notifications from the application"])},
      "help_and_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support and assistance"])},
      "to_system_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To system settings"])},
      "report_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue"])},
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
      "profile_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile settings"])},
      "profile_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
      "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Address"])},
      "finish_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End session"])}
    }
  },
  "themes": {
    "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
    "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
    "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])}
  },
  "library": {
    "all_books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All books"])},
    "read_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading now"])},
    "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarks"])},
    "liked_books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked books"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by book title"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "back_to_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to book list"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
    "share_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share in"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read more"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "popular_books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular books"])},
    "book_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book page"])}
  },
  "notification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark it all as read"])},
    "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])}
  },
  "muvi": {
    "tabs": {
      "feed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed"])},
      "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "add_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add MUVI"])}
    },
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["followers"])},
    "my_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Muvi"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "liked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed"])},
    "other_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other MUVI"])},
    "view_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View replys"])},
    "hide_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide replys"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "no_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a video please"])},
    "hide_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide MUVI"])},
    "block_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block a user"])},
    "comments_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments disabled"])}
  },
  "time": {
    "day_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day ago"])},
    "days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days ago"])},
    "hour_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour ago"])},
    "hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours ago"])},
    "minute_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute ago"])},
    "minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes ago"])},
    "just_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just now"])}
  },
  "report_comment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for letting us know!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All posts are analyzed and reviewed by our moderators and deleted if they violate the rules of Umma Life!"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
  },
  "add_muvi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add MUVI"])},
    "file_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a video to download or drag and drop a file"])},
    "file_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowable extensions: mp4"])},
    "file_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution 1280*720"])},
    "file_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more than 5 minutes"])},
    "file_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 50 mb"])},
    "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "errors": {
      "size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size exceeds the maximum limit of 50 mb"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video duration exceeds the maximum limit of 5 minutes"])},
      "invalid_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid file type. Please select a valid video file"])},
      "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading video metadata"])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video successfully added"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5000"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover"])},
    "change_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change cover"])},
    "add_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add cover"])},
    "allow_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow comments"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "tag_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag people"])},
    "add_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add location"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "current_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Location"])},
    "can_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can view this MUVI"])},
    "change_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the visibility of MUVI"])},
    "delete_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete MUVI"])},
    "edit_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit description"])},
    "disable_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable comments"])},
    "playback_speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playback speed"])},
    "views_options": {
      "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to all"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Umma Life and beyond"])},
      "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers only"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscribers to Umma Life"])},
      "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions only"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscriptions to Umma Life"])}
    },
    "choose_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a category"])}
  },
  "muvi_mobile": {
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
    "no_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Comments"])},
    "no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found"])}
  },
  "date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])}
  }
}