<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7703)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.65281 7.37498C4.78687 7.71382 5.17025 7.87986 5.50904 7.74586L7.94216 6.78345C8.28125 6.64927 8.44703 6.26601 8.31304 5.92722C8.17898 5.58838 7.79559 5.42235 7.45681 5.55634L5.02369 6.51875C4.93716 6.55303 4.86183 6.60348 4.79993 6.66538C4.61959 6.84572 4.55303 7.12279 4.65281 7.37498Z" fill="#4C2908"/>
      <path d="M12.2222 15H7.77778C7.70411 15 7.63345 14.9473 7.58136 14.8536C7.52927 14.7598 7.5 14.6326 7.5 14.5C7.5 14.3674 7.52927 14.2402 7.58136 14.1464C7.63345 14.0527 7.70411 14 7.77778 14H12.2222C12.2959 14 12.3665 14.0527 12.4186 14.1464C12.4707 14.2402 12.5 14.3674 12.5 14.5C12.5 14.6326 12.4707 14.7598 12.4186 14.8536C12.3665 14.9473 12.2959 15 12.2222 15Z" fill="#2F2F2F"/>
      <path d="M11.4483 14.2567L11.7343 13.9669L11.8513 13.8483L11.7869 13.6946C11.6236 13.3052 11.7039 12.8353 12.0223 12.5127L17.8765 6.58007C17.8765 6.58006 17.8765 6.58005 17.8765 6.58004C18.3005 6.15061 18.994 6.14569 19.4241 6.5703C19.8543 6.99495 19.8594 7.68718 19.4344 8.11894L13.5803 14.0514L13.5803 14.0514C13.262 14.374 12.7926 14.4605 12.4023 14.3028L12.2479 14.2404L12.1308 14.3589L11.9344 14.5577C11.785 14.4403 11.6227 14.3389 11.4483 14.2567Z" fill="#E9EFF4" stroke="#F64F4F" stroke-width="0.5"/>
      <path d="M18.4101 7.10716L15.1098 10.4512C14.7381 10.8281 15.2236 11.3075 15.5952 10.9306L18.8956 7.5866C19.0275 7.45242 19.026 7.23682 18.8925 7.10415C18.7591 6.97222 18.5428 6.97298 18.4101 7.10716Z" fill="#F64F4F"/>
      <path d="M12.6846 12.433C12.6589 12.4594 12.6167 12.4602 12.5903 12.4338L12.3793 12.2257C12.3529 12.1993 12.3529 12.1579 12.3785 12.1315C12.4041 12.1051 12.4463 12.1044 12.4727 12.1307L12.6838 12.3388C12.7102 12.3644 12.7102 12.4066 12.6846 12.433Z" fill="#3E4347"/>
      <path d="M13.2068 12.1422C13.1811 12.1686 13.1389 12.1693 13.1125 12.143L12.7816 11.8165C12.7552 11.7902 12.7552 11.7487 12.7808 11.7223C12.8065 11.6959 12.8487 11.6952 12.8751 11.7216L13.206 12.048C13.2332 12.0744 13.2332 12.1166 13.2068 12.1422Z" fill="#3E4347"/>
      <path d="M13.4892 11.6157C13.4635 11.642 13.4213 11.6428 13.3949 11.6164L13.1839 11.4083C13.1575 11.382 13.1575 11.3405 13.1839 11.3141C13.2095 11.2877 13.2517 11.287 13.2781 11.3134L13.4892 11.5214C13.5155 11.5478 13.5155 11.5893 13.4892 11.6157Z" fill="#3E4347"/>
      <path d="M14.0153 11.3248C13.9896 11.3512 13.9474 11.352 13.921 11.3256L13.5901 10.9992C13.5637 10.9728 13.5637 10.9313 13.5901 10.9049C13.6157 10.8786 13.6579 10.8778 13.6843 10.9042L14.0153 11.2306C14.0409 11.257 14.0409 11.2984 14.0153 11.3248Z" fill="#3E4347"/>
      <path d="M14.2939 10.7983C14.2683 10.8246 14.2261 10.8254 14.1997 10.799L13.9886 10.591C13.9622 10.5646 13.9622 10.5231 13.9879 10.4967C14.0135 10.4703 14.0557 10.4696 14.0821 10.496L14.2932 10.704C14.3203 10.7304 14.3196 10.7719 14.2939 10.7983Z" fill="#3E4347"/>
      <path d="M14.8199 10.5074C14.7943 10.5338 14.7521 10.5346 14.7257 10.5082L14.3948 10.1818C14.3684 10.1554 14.3684 10.1139 14.3948 10.0876C14.4204 10.0612 14.4626 10.0604 14.489 10.0868L14.8199 10.4132C14.8456 10.4396 14.8456 10.4818 14.8199 10.5074Z" fill="#3E4347"/>
      <path d="M15.1063 9.98088C15.0807 10.0073 15.0385 10.008 15.0121 9.98163L14.801 9.77358C14.7747 9.74719 14.7747 9.70573 14.801 9.67935C14.8267 9.65296 14.8689 9.65221 14.8953 9.67859L15.1063 9.88665C15.1327 9.91228 15.132 9.95449 15.1063 9.98088Z" fill="#3E4347"/>
      <path d="M15.6247 9.69005C15.5991 9.71643 15.5569 9.71719 15.5305 9.6908L15.1996 9.3644C15.1732 9.33801 15.1732 9.29655 15.1988 9.27017C15.2244 9.24378 15.2667 9.24303 15.293 9.26942L15.624 9.59582C15.6511 9.62221 15.6511 9.66442 15.6247 9.69005Z" fill="#3E4347"/>
      <path d="M15.9111 9.16448C15.8855 9.19087 15.8433 9.19162 15.8169 9.16524L15.6058 8.95717C15.5794 8.93079 15.5794 8.88933 15.6051 8.86294C15.6307 8.83656 15.6729 8.8358 15.6993 8.86219L15.9104 9.07025C15.9367 9.09588 15.9367 9.1381 15.9111 9.16448Z" fill="#3E4347"/>
      <path d="M16.4332 8.87364C16.4076 8.90003 16.3654 8.90078 16.339 8.8744L16.0081 8.54799C15.9817 8.52161 15.9817 8.48015 16.0081 8.45376C16.0337 8.42738 16.0759 8.42663 16.1023 8.45301L16.4332 8.77942C16.4589 8.8058 16.4589 8.84726 16.4332 8.87364Z" fill="#3E4347"/>
      <path d="M16.7157 8.34711C16.6901 8.37349 16.6479 8.37424 16.6215 8.34786L16.4104 8.13979C16.384 8.11341 16.384 8.07195 16.4104 8.04556C16.436 8.01918 16.4783 8.01842 16.5046 8.04481L16.7157 8.25287C16.7421 8.2785 16.7421 8.32072 16.7157 8.34711Z" fill="#3E4347"/>
      <path d="M17.2412 8.05627C17.2155 8.08265 17.1733 8.0834 17.1469 8.05702L16.8168 7.73061C16.7904 7.70423 16.7904 7.66276 16.816 7.63638C16.8416 7.61 16.8838 7.60924 16.9102 7.63563L17.2412 7.96204C17.2668 7.98767 17.2668 8.02988 17.2412 8.05627Z" fill="#3E4347"/>
      <path d="M17.5204 7.52972C17.4948 7.5561 17.4526 7.55685 17.4262 7.53047L17.2151 7.32241C17.1887 7.29602 17.1887 7.25456 17.2151 7.22818C17.2407 7.20179 17.2829 7.20104 17.3093 7.22742L17.5204 7.43549C17.5468 7.46112 17.546 7.50333 17.5204 7.52972Z" fill="#3E4347"/>
      <path d="M18.0465 7.23888C18.0209 7.26527 17.9787 7.26602 17.9523 7.23964L17.6214 6.91323C17.595 6.88684 17.595 6.84538 17.6214 6.819C17.647 6.79261 17.6892 6.79186 17.7156 6.81824L18.0465 7.14465C18.0729 7.17029 18.0729 7.2125 18.0465 7.23888Z" fill="#3E4347"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7703">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
