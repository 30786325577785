<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5954 1.47786C8.8819 1.14841 7.1181 1.14841 5.40463 1.47786C3.86115 1.77462 2.75806 3.08169 2.75806 4.59074V13.963C2.75806 14.5733 3.45327 14.9781 4.02324 14.6554L6.69614 13.142C7.50181 12.6859 8.49819 12.6859 9.30386 13.142L11.9767 14.6554C12.5467 14.9781 13.2419 14.5733 13.2419 13.963V4.59074C13.2419 3.08169 12.1389 1.77462 10.5954 1.47786ZM5.16202 0.270194C7.03565 -0.0900606 8.96435 -0.0900688 10.8379 0.270194C12.9617 0.678523 14.5 2.48381 14.5 4.59074V13.963C14.5 15.5458 12.7335 16.5059 11.3466 15.7206L8.67365 14.2072C8.2579 13.9718 7.7421 13.9718 7.32635 14.2072L4.65341 15.7206C3.26645 16.5059 1.5 15.5458 1.5 13.963V4.59074C1.5 2.48381 3.03829 0.678523 5.16202 0.270194Z"
      fill="currentColor"
    />
  </svg>
</template>
