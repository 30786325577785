<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
  >
    <path fill="currentColor"
          d="M21 22.75H3c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75ZM5.6 19.75H4c-.96 0-1.75-.79-1.75-1.75V9.38c0-.96.79-1.75 1.75-1.75h1.6c.96 0 1.75.79 1.75 1.75V18c0 .96-.79 1.75-1.75 1.75ZM4 9.12c-.14 0-.25.11-.25.25V18c0 .14.11.25.25.25h1.6c.14 0 .25-.11.25-.25V9.38c0-.14-.11-.25-.25-.25H4v-.01ZM12.797 19.75h-1.6c-.96 0-1.75-.79-1.75-1.75V6.19c0-.96.79-1.75 1.75-1.75h1.6c.96 0 1.75.79 1.75 1.75V18c0 .96-.79 1.75-1.75 1.75Zm-1.6-13.81c-.14 0-.25.11-.25.25V18c0 .14.11.25.25.25h1.6c.14 0 .25-.11.25-.25V6.19c0-.14-.11-.25-.25-.25h-1.6ZM20 19.75h-1.6c-.96 0-1.75-.79-1.75-1.75V3c0-.96.79-1.75 1.75-1.75H20c.96 0 1.75.79 1.75 1.75v15c0 .96-.79 1.75-1.75 1.75Zm-1.6-17c-.14 0-.25.11-.25.25v15c0 .14.11.25.25.25H20c.14 0 .25-.11.25-.25V3c0-.14-.11-.25-.25-.25h-1.6ZM6.75 4.5h-4c-.41 0-.75-.34-.75-.75S2.34 3 2.75 3h4c.41 0 .75.34.75.75s-.34.75-.75.75Z"/>
    <path fill="currentColor"
          d="M4.75 6.5c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75Z"/>
  </svg>
</template>
