<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5865 30.3335C18.9998 30.3335 18.3865 30.1602 17.7598 29.8268L12.5199 26.9068C12.2665 26.7735 11.7198 26.7735 11.4665 26.9068L6.23983 29.8268C5.10649 30.4535 3.94649 30.5068 3.06649 29.9868C2.17315 29.4668 1.6665 28.4268 1.6665 27.1468V12.0002C1.6665 9.16016 3.97315 6.85352 6.81315 6.85352H17.1865C20.0265 6.85352 22.3332 9.16016 22.3332 12.0002V27.1468C22.3332 28.4268 21.8265 29.4668 20.9332 29.9868C20.5332 30.2135 20.0798 30.3335 19.5865 30.3335ZM11.9998 24.8002C12.5332 24.8002 13.0532 24.9202 13.4932 25.1602L18.7332 28.0802C19.2132 28.3468 19.6532 28.4135 19.9198 28.2535C20.1732 28.1068 20.3332 27.6802 20.3332 27.1335V11.9868C20.3332 10.2535 18.9199 8.84017 17.1865 8.84017H6.81315C5.07982 8.84017 3.6665 10.2535 3.6665 11.9868V27.1335C3.6665 27.6802 3.8265 28.1068 4.07983 28.2535C4.33317 28.4002 4.78652 28.3335 5.26652 28.0668L10.5065 25.1468C10.9332 24.9201 11.4665 24.8002 11.9998 24.8002Z"
      fill="currentColor"
    />
    <path
      d="M27.5865 25.1597C26.9998 25.1597 26.3865 24.9863 25.7598 24.653L20.8398 21.9063C20.5198 21.733 20.3332 21.3997 20.3332 21.0397V11.9997C20.3332 10.2663 18.9199 8.85303 17.1865 8.85303H10.6665C10.1198 8.85303 9.6665 8.39969 9.6665 7.85303V6.82633C9.6665 3.98633 11.9732 1.67969 14.8132 1.67969H25.1865C28.0265 1.67969 30.3332 3.98633 30.3332 6.82633V21.973C30.3332 23.253 29.8265 24.293 28.9332 24.813C28.5332 25.0397 28.0798 25.1597 27.5865 25.1597ZM22.3332 20.4397L26.7332 22.9063C27.2132 23.173 27.6532 23.2397 27.9198 23.0797C28.1732 22.933 28.3332 22.5063 28.3332 21.9597V6.81299C28.3332 5.07966 26.9199 3.66634 25.1865 3.66634H14.8132C13.0798 3.66634 11.6665 5.07966 11.6665 6.81299V6.83968H17.1865C20.0265 6.83968 22.3332 9.14633 22.3332 11.9863V20.4397Z"
      fill="currentColor"
    />
    <path
      d="M14.6668 17H9.3335C8.78683 17 8.3335 16.5467 8.3335 16C8.3335 15.4533 8.78683 15 9.3335 15H14.6668C15.2135 15 15.6668 15.4533 15.6668 16C15.6668 16.5467 15.2135 17 14.6668 17Z"
      fill="currentColor"
    />
    <path
      d="M12 19.6673C11.4533 19.6673 11 19.214 11 18.6673V13.334C11 12.7873 11.4533 12.334 12 12.334C12.5467 12.334 13 12.7873 13 13.334V18.6673C13 19.214 12.5467 19.6673 12 19.6673Z"
      fill="currentColor"
    />
  </svg>
</template>
