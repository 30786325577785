<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7850)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.65281 7.37498C4.78687 7.71382 5.17025 7.87986 5.50904 7.74586L7.94216 6.78345C8.28125 6.64927 8.44703 6.26601 8.31304 5.92722C8.17898 5.58838 7.79559 5.42235 7.45681 5.55634L5.02369 6.51875C4.93716 6.55303 4.86183 6.60348 4.79993 6.66538C4.61959 6.84572 4.55303 7.12279 4.65281 7.37498Z" fill="#4C2908"/>
      <path d="M9.38058 9.60843L7.99721 9.3968L7.37715 8.07408C7.33083 7.97531 7.16902 7.97531 7.12271 8.07408L6.50284 9.3968L5.11947 9.60843C5.00584 9.62589 4.96047 9.76428 5.04015 9.84597L6.04515 10.8776L5.80759 12.3361C5.78865 12.452 5.91221 12.5389 6.0144 12.482L7.25002 11.7981L8.48564 12.4822C8.58689 12.5385 8.71158 12.4533 8.69246 12.3363L8.45489 10.8778L9.45989 9.84616C9.53958 9.76428 9.49402 9.62589 9.38058 9.60843Z" fill="#E24B4B"/>
      <path d="M14.8806 9.60843L13.4972 9.3968L12.8771 8.07408C12.8308 7.97531 12.669 7.97531 12.6227 8.07408L12.0028 9.3968L10.6195 9.60843C10.5058 9.62589 10.4605 9.76428 10.5402 9.84597L11.5452 10.8776L11.3076 12.3361C11.2887 12.452 11.4122 12.5389 11.5144 12.482L12.75 11.7981L13.9856 12.4822C14.0869 12.5385 14.2116 12.4533 14.1925 12.3363L13.9549 10.8778L14.9599 9.84616C15.0396 9.76428 14.994 9.62589 14.8806 9.60843Z" fill="#E24B4B"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7850">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
