<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path
      d="M47.8337 72.5003H28.8336C11.5336 72.5003 4.16699 65.1336 4.16699 47.8336V28.8337C4.16699 11.5337 11.5336 4.16699 28.8336 4.16699H47.8337C65.1337 4.16699 72.5003 11.5337 72.5003 28.8337V47.8336C72.5003 49.0336 72.467 50.167 72.4003 51.267C72.3337 52.267 71.667 53.1336 70.7003 53.4669C69.7337 53.8003 68.7003 53.5004 68.0003 52.7671C66.0003 50.5004 63.067 49.167 59.967 49.167C54.0003 49.167 49.1337 54.0337 49.1337 60.0003C49.1337 61.967 49.667 63.9003 50.7004 65.5669C51.2337 66.5003 51.9337 67.3336 52.7337 68.0336C53.5004 68.7002 53.8003 69.7671 53.467 70.7004C53.1336 71.6671 52.267 72.3336 51.267 72.4002C50.2003 72.4669 49.067 72.5003 47.8337 72.5003ZM28.8336 9.16699C14.3003 9.16699 9.16699 14.3004 9.16699 28.8337V47.8336C9.16699 62.3669 14.3003 67.5003 28.8336 67.5003H46.067C44.8337 65.2337 44.167 62.667 44.167 60.0003C44.167 51.267 51.267 44.167 60.0003 44.167C62.667 44.167 65.2337 44.8337 67.5003 46.0337V28.8337C67.5003 14.3004 62.367 9.16699 47.8337 9.16699H28.8336Z"
      fill="#B0B0B0"
    />
    <path
      d="M22.3997 50.5669C18.3664 50.5669 15.0664 47.2669 15.0664 43.2336C15.0664 39.2002 18.3664 35.9004 22.3997 35.9004C26.4331 35.9004 29.7331 39.2002 29.7331 43.2336C29.7331 47.2669 26.4331 50.5669 22.3997 50.5669ZM22.3997 40.9004C21.1331 40.9004 20.0664 41.9336 20.0664 43.2336C20.0664 44.5002 21.0997 45.5669 22.3997 45.5669C23.6997 45.5669 24.7331 44.5336 24.7331 43.2336C24.7331 41.9336 23.6997 40.9004 22.3997 40.9004Z"
      fill="#B0B0B0"
    />
    <path
      d="M27.2334 45.7333C25.8667 45.7333 24.7334 44.5999 24.7334 43.2333V23.8001C24.7334 20.7668 26.6334 18.3 29.5334 17.5L39.2001 14.8668C42.2001 14.0335 44.1 14.8334 45.1334 15.6334C46.1667 16.4334 47.4334 18.0335 47.4334 21.1335V40C47.4334 41.3667 46.3001 42.5 44.9334 42.5C43.5667 42.5 42.4334 41.3667 42.4334 40V21.1335C42.4334 20.0668 42.2001 19.6667 42.1001 19.6001C42.0001 19.5334 41.5334 19.4001 40.5001 19.6668L30.8667 22.3001C29.8667 22.5667 29.7334 23.2667 29.7334 23.7667V43.2001C29.7334 44.6001 28.6001 45.7333 27.2334 45.7333Z"
      fill="#B0B0B0"
    />
    <path
      d="M40.0999 47.3328C36.0666 47.3328 32.7666 44.0327 32.7666 39.9994C32.7666 35.9661 36.0666 32.666 40.0999 32.666C44.1333 32.666 47.4332 35.9661 47.4332 39.9994C47.4332 44.0661 44.1333 47.3328 40.0999 47.3328ZM40.0999 37.6994C38.8333 37.6994 37.7666 38.7328 37.7666 40.0328C37.7666 41.2994 38.7999 42.3661 40.0999 42.3661C41.3999 42.3661 42.4332 41.3328 42.4332 40.0328C42.4332 38.7328 41.3666 37.6994 40.0999 37.6994Z"
      fill="#B0B0B0"
    />
    <path
      d="M27.2332 31.9333C26.1332 31.9333 25.1332 31.2 24.8332 30.1C24.4665 28.7666 25.2665 27.3998 26.5999 27.0332L44.2999 22.1998C45.6332 21.8665 46.9999 22.6332 47.3666 23.9666C47.7332 25.2999 46.9332 26.6665 45.5999 27.0332L27.8999 31.8665C27.6665 31.8999 27.4332 31.9333 27.2332 31.9333Z"
      fill="#B0B0B0"
    />
    <path
      d="M60.0003 75.8337C54.467 75.8337 49.267 72.9003 46.467 68.1337C44.967 65.7337 44.167 62.9003 44.167 60.0003C44.167 51.267 51.267 44.167 60.0003 44.167C68.7337 44.167 75.8337 51.267 75.8337 60.0003C75.8337 62.9003 75.0336 65.7337 73.5336 68.167C70.7337 72.9004 65.5337 75.8337 60.0003 75.8337ZM60.0003 49.167C54.0337 49.167 49.167 54.0337 49.167 60.0003C49.167 61.967 49.7003 63.9003 50.7337 65.5669C52.7003 68.9003 56.167 70.8337 60.0003 70.8337C63.8337 70.8337 67.3003 68.867 69.267 65.6003C70.3003 63.9003 70.8337 62.0003 70.8337 60.0003C70.8337 54.0337 65.967 49.167 60.0003 49.167Z"
      fill="#B0B0B0"
    />
    <path
      d="M64.9667 62.4336H55C53.6333 62.4336 52.5 61.3003 52.5 59.9336C52.5 58.5669 53.6333 57.4336 55 57.4336H64.9667C66.3334 57.4336 67.4667 58.5669 67.4667 59.9336C67.4667 61.3003 66.3667 62.4336 64.9667 62.4336Z"
      fill="#B0B0B0"
    />
    <path
      d="M60 67.533C58.6333 67.533 57.5 66.3997 57.5 65.033V55.0664C57.5 53.6997 58.6333 52.5664 60 52.5664C61.3667 52.5664 62.5 53.6997 62.5 55.0664V65.033C62.5 66.433 61.3667 67.533 60 67.533Z"
      fill="#B0B0B0"
    />
  </svg>
</template>
