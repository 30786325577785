<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
    <g clip-path="url(#clip0_1122_5291)">
      <path
        d="M8.58833 13.378C8.7338 13.5311 8.92476 13.6081 9.11572 13.6081C9.30668 13.6081 9.49778 13.5311 9.64325 13.378L18.7816 3.75865C19.0733 3.45153 19.0733 2.95533 18.7816 2.6482C18.4899 2.34122 18.0185 2.34122 17.7268 2.6482L9.11572 11.7124L7.70518 10.2276L6.65039 11.3379L8.58833 13.378Z"
        :fill="color"
      />
      <path
        d="M5.31534 13.6081C5.12438 13.6081 4.93342 13.5311 4.78795 13.378L0.218826 8.56826C-0.072942 8.26128 -0.072942 7.76493 0.218826 7.45795C0.510458 7.15097 0.981849 7.15097 1.27362 7.45795L5.31534 11.7124L13.9265 2.6482C14.2181 2.34122 14.6895 2.34122 14.9813 2.6482C15.2729 2.95533 15.2729 3.45153 14.9813 3.75865L5.84288 13.378C5.6974 13.5311 5.50631 13.6081 5.31534 13.6081Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_1122_5291">
        <rect width="19" height="16" :fill="color" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>
