<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="8" fill="#F1F1F1" />
    <path
      d="M45 29.6259H30C29.385 29.6259 28.875 29.1159 28.875 28.5009V18.0009C28.875 17.4609 29.25 16.9959 29.775 16.8909L44.775 13.8909C45.105 13.8309 45.45 13.9059 45.705 14.1309C45.96 14.3409 46.11 14.6709 46.11 15.0009V28.5009C46.125 29.1159 45.615 29.6259 45 29.6259ZM31.125 27.3759H43.875V16.3809L31.125 18.9309V27.3759Z"
      fill="#9D9D9D"
    />
    <path
      d="M45 46.125C44.925 46.125 44.85 46.125 44.775 46.11L29.775 43.11C29.25 43.005 28.875 42.54 28.875 42V31.5C28.875 30.885 29.385 30.375 30 30.375H45C45.615 30.375 46.125 30.885 46.125 31.5V45C46.125 45.33 45.975 45.66 45.72 45.87C45.51 46.035 45.255 46.125 45 46.125ZM31.125 41.07L43.875 43.62V32.625H31.125V41.07Z"
      fill="#9D9D9D"
    />
    <path
      d="M27 29.6242H15C14.385 29.6242 13.875 29.1142 13.875 28.4992V20.9992C13.875 20.4742 14.25 20.0092 14.76 19.9042L26.76 17.3542C27.09 17.2792 27.435 17.3692 27.705 17.5792C27.975 17.7892 28.125 18.1192 28.125 18.4492V28.4992C28.125 29.1142 27.615 29.6242 27 29.6242ZM16.125 27.3742H25.875V19.8443L16.125 21.9142V27.3742Z"
      fill="#9D9D9D"
    />
    <path
      d="M27 42.675C26.925 42.675 26.85 42.66 26.76 42.645L14.76 40.095C14.235 39.99 13.875 39.525 13.875 39V31.5C13.875 30.885 14.385 30.375 15 30.375H27C27.615 30.375 28.125 30.885 28.125 31.5V41.55C28.125 41.895 27.975 42.21 27.705 42.42C27.51 42.585 27.255 42.675 27 42.675ZM16.125 38.085L25.875 40.155V32.625H16.125V38.085Z"
      fill="#9D9D9D"
    />
  </svg>
</template>
