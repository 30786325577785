<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4545_21215)">
      <mask id="mask0_4545_21215" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="12"
            height="12">
        <path d="M12 0H0V12H12V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_4545_21215)">
        <path
          d="M3.70457 9.07643L1.21934 6.48945C0.940016 6.19868 0.488809 6.19868 0.20949 6.48945C-0.0698299 6.78019 -0.0698299 7.24988 0.20949 7.54061L3.20322 10.6569C3.48254 10.9477 3.93375 10.9477 4.21309 10.6569L11.7905 2.76926C12.0698 2.4785 12.0698 2.00882 11.7905 1.71807C11.5112 1.42731 11.06 1.42731 10.7807 1.71807L3.70457 9.07643Z"
          fill="currentColor"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4545_21215">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
