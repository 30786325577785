<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="global">
    <g id="global_2">
    <path id="Vector" d="M6.50065 12.3226C3.28857 12.3226 0.677734 9.71176 0.677734 6.49967C0.677734 3.28759 3.28857 0.676758 6.50065 0.676758C9.71273 0.676758 12.3236 3.28759 12.3236 6.49967C12.3236 9.71176 9.71273 12.3226 6.50065 12.3226ZM6.50065 1.48926C3.73815 1.48926 1.49023 3.73717 1.49023 6.49967C1.49023 9.26217 3.73815 11.5101 6.50065 11.5101C9.26315 11.5101 11.5111 9.26217 11.5111 6.49967C11.5111 3.73717 9.26315 1.48926 6.50065 1.48926Z" fill="#B0B0B0"/>
    <path id="Vector_2" d="M4.87586 11.7812H4.33419C4.11211 11.7812 3.92794 11.5971 3.92794 11.375C3.92794 11.1529 4.10128 10.9742 4.32336 10.9688C3.47294 8.06542 3.47294 4.93458 4.32336 2.03125C4.10128 2.02583 3.92794 1.84708 3.92794 1.625C3.92794 1.40292 4.11211 1.21875 4.33419 1.21875H4.87586C5.00586 1.21875 5.13044 1.28375 5.20628 1.38667C5.28211 1.495 5.30378 1.63042 5.26044 1.755C4.24211 4.81542 4.24211 8.18458 5.26044 11.2504C5.30378 11.375 5.28211 11.5104 5.20628 11.6188C5.13044 11.7163 5.00586 11.7812 4.87586 11.7812Z" fill="#B0B0B0"/>
    <path id="Vector_3" d="M8.12432 11.781C8.08099 11.781 8.03765 11.7755 7.99432 11.7593C7.78307 11.6889 7.6639 11.456 7.73974 11.2447C8.75807 8.18429 8.75807 4.81513 7.73974 1.74929C7.66932 1.53804 7.78307 1.30513 7.99432 1.23471C8.21099 1.16429 8.43849 1.27804 8.5089 1.48929C9.58682 4.71763 9.58682 8.27096 8.5089 11.4939C8.45474 11.6726 8.29224 11.781 8.12432 11.781Z" fill="#B0B0B0"/>
    <path id="Vector_4" d="M6.5 9.31709C4.98875 9.31709 3.48292 9.10584 2.03125 8.67792C2.02583 8.89459 1.84708 9.07334 1.625 9.07334C1.40292 9.07334 1.21875 8.88917 1.21875 8.66709V8.12542C1.21875 7.99542 1.28375 7.87084 1.38667 7.795C1.495 7.71917 1.63042 7.6975 1.755 7.74084C4.81542 8.75917 8.19 8.75917 11.2504 7.74084C11.375 7.6975 11.5104 7.71917 11.6188 7.795C11.7271 7.87084 11.7867 7.99542 11.7867 8.12542V8.66709C11.7867 8.88917 11.6025 9.07334 11.3804 9.07334C11.1583 9.07334 10.9796 8.9 10.9742 8.67792C9.51708 9.10584 8.01125 9.31709 6.5 9.31709Z" fill="#B0B0B0"/>
    <path id="Vector_5" d="M11.375 5.28091C11.3317 5.28091 11.2884 5.27549 11.245 5.25924C8.18463 4.24091 4.81005 4.24091 1.74963 5.25924C1.53296 5.32966 1.30546 5.21591 1.23505 5.00466C1.17005 4.78799 1.2838 4.56049 1.49505 4.49008C4.72338 3.41216 8.27671 3.41216 11.4996 4.49008C11.7109 4.56049 11.83 4.79341 11.7542 5.00466C11.7055 5.17258 11.543 5.28091 11.375 5.28091Z" fill="#B0B0B0"/>
    </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: String
  }
}
</script>
