<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.276.063C.22.401-.292 1.593.201 2.575c.072.143 1.288 1.422 2.703 2.842L5.476 8l-2.572 2.583C1.489 12.003.273 13.283.2 13.425c-.36.715-.234 1.442.349 2.025s1.31.708 2.025.349c.143-.072 1.422-1.288 2.842-2.703L8 10.524l2.583 2.572c1.42 1.415 2.713 2.64 2.872 2.723.342.178 1.175.199 1.487.037.299-.155.738-.585.89-.872.183-.348.176-1.165-.013-1.53-.083-.158-1.308-1.45-2.723-2.871L10.524 8l2.572-2.583c1.415-1.42 2.631-2.7 2.703-2.842.36-.715.234-1.442-.349-2.025S14.14-.158 13.425.2c-.143.072-1.422 1.288-2.842 2.703L8 5.476 5.417 2.904C3.997 1.489 2.712.27 2.561.194c-.333-.168-.966-.232-1.285-.13Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
