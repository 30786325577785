<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6025_35664)">
      <path
        d="M4.44196 3.32771C4.52971 3.32771 4.61845 3.30171 4.69595 3.24771C5.3847 2.76796 6.15195 2.45021 6.97645 2.30321C7.21846 2.26021 7.37996 2.02871 7.3367 1.78646C7.2937 1.54421 7.06245 1.38321 6.8202 1.42646C5.86795 1.59621 4.98221 1.96321 4.18696 2.51721C3.98521 2.65771 3.93546 2.93521 4.07596 3.13721C4.16271 3.26121 4.30121 3.32771 4.44196 3.32771Z"
        fill="currentColor"
      />
      <path
        d="M1.78719 7.33652C1.81369 7.34127 1.83994 7.34352 1.86594 7.34352C2.07769 7.34352 2.26544 7.19202 2.30369 6.97652C2.45094 6.15152 2.76869 5.38452 3.24844 4.69602C3.38919 4.49377 3.33969 4.21627 3.13769 4.07577C2.93569 3.93477 2.65844 3.98477 2.51769 4.18652C1.96394 4.98127 1.59694 5.86727 1.42694 6.81977C1.38369 7.06202 1.54494 7.29327 1.78719 7.33652Z"
        fill="currentColor"
      />
      <path
        d="M6.97652 13.6965C6.15202 13.5495 5.38477 13.2315 4.69602 12.752C4.49427 12.611 4.21677 12.661 4.07602 12.8628C3.93552 13.0648 3.98502 13.3423 4.18702 13.4828C4.98202 14.0368 5.86802 14.4035 6.82027 14.5735C6.84677 14.5783 6.87302 14.5805 6.89902 14.5805C7.11077 14.5805 7.29852 14.429 7.33677 14.2135C7.38002 13.971 7.21877 13.7398 6.97652 13.6965Z"
        fill="currentColor"
      />
      <path
        d="M2.30368 9.02431C2.26043 8.78206 2.02893 8.62081 1.78718 8.66431C1.54518 8.70731 1.38368 8.93881 1.42693 9.18106C1.59668 10.1336 1.96368 11.0196 2.51768 11.8143C2.60443 11.9386 2.74268 12.0051 2.88343 12.0051C2.97118 12.0051 3.05993 11.9791 3.13768 11.9251C3.33943 11.7846 3.38893 11.5068 3.24843 11.3051C2.76868 10.6163 2.45068 9.84931 2.30368 9.02431Z"
        fill="currentColor"
      />
      <path
        d="M9.18001 1.42653C8.93726 1.38253 8.70651 1.54428 8.66326 1.78653C8.62001 2.02878 8.78126 2.25978 9.02326 2.30328C11.7848 2.79578 13.7888 5.19178 13.7888 8.00003C13.7888 10.8083 11.7848 13.2043 9.02351 13.6968C8.78151 13.74 8.62026 13.9713 8.66351 14.2135C8.70201 14.4293 8.88976 14.5805 9.10126 14.5805C9.12726 14.5805 9.15351 14.5783 9.18001 14.5735C12.3668 14.005 14.6795 11.2405 14.6795 8.00003C14.6795 4.75953 12.3668 1.99503 9.18001 1.42653Z"
        fill="currentColor"
      />
      <path
        d="M10.1773 7.99992C10.1773 7.71817 10.0315 7.46592 9.78753 7.32492L7.43828 5.96842C7.19453 5.82742 6.90303 5.82767 6.65903 5.96867C6.41528 6.10967 6.26953 6.36192 6.26953 6.64342V9.35617C6.26953 9.63767 6.41528 9.88992 6.65903 10.0309C6.78103 10.1014 6.91503 10.1367 7.04878 10.1367C7.18253 10.1367 7.31653 10.1014 7.43853 10.0312L9.78778 8.67492C10.0315 8.53392 10.1773 8.28167 10.1773 7.99992ZM7.16028 9.16342V6.83642L9.17528 7.99992L7.16028 9.16342Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6025_35664">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
