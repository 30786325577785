<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#B161CD" />
    <path
      d="M20.0088 24.1671C19.3004 24.1671 18.5838 23.9837 18.0254 23.6254L13.0088 20.3504C12.0754 19.7421 11.5171 18.7171 11.5171 17.6004C11.5171 16.4837 12.0754 15.4587 13.0088 14.8504L18.0338 11.5837C19.1504 10.8587 20.8921 10.8587 22.0004 11.5921L26.9921 14.8671C27.9171 15.4754 28.4754 16.5004 28.4754 17.6087C28.4754 18.7171 27.9171 19.7421 26.9921 20.3504L22.0004 23.6254C21.4421 23.9921 20.7254 24.1671 20.0088 24.1671ZM20.0088 12.2921C19.5338 12.2921 19.0588 12.4004 18.7171 12.6337L13.7004 15.9004C13.1171 16.2837 12.7754 16.9004 12.7754 17.6004C12.7754 18.3004 13.1088 18.9171 13.7004 19.3004L18.7171 22.5754C19.4088 23.0254 20.6254 23.0254 21.3171 22.5754L26.3088 19.3004C26.8921 18.9171 27.2254 18.3004 27.2254 17.6004C27.2254 16.9004 26.8921 16.2837 26.3088 15.9004L21.3171 12.6254C20.9671 12.4087 20.4921 12.2921 20.0088 12.2921Z"
      fill="white"
    />
    <path
      d="M19.9993 28.9587C19.6327 28.9587 19.2577 28.9087 18.9577 28.8087L16.2993 27.9254C15.041 27.5087 14.0493 26.1337 14.0577 24.8087L14.066 20.9004C14.066 20.5587 14.3493 20.2754 14.691 20.2754C15.0327 20.2754 15.316 20.5587 15.316 20.9004L15.3077 24.8087C15.3077 25.5921 15.9577 26.4921 16.6993 26.7421L19.3577 27.6254C19.691 27.7337 20.3077 27.7337 20.641 27.6254L23.2993 26.7421C24.041 26.4921 24.691 25.5921 24.691 24.8171V20.9504C24.691 20.6087 24.9743 20.3254 25.316 20.3254C25.6577 20.3254 25.941 20.6087 25.941 20.9504V24.8171C25.941 26.1421 24.9577 27.5087 23.6993 27.9337L21.041 28.8171C20.741 28.9087 20.366 28.9587 19.9993 28.9587Z"
      fill="white"
    />
    <path
      d="M27.8335 23.125C27.4918 23.125 27.2085 22.8417 27.2085 22.5V17.5C27.2085 17.1583 27.4918 16.875 27.8335 16.875C28.1752 16.875 28.4585 17.1583 28.4585 17.5V22.5C28.4585 22.8417 28.1752 23.125 27.8335 23.125Z"
      fill="white"
    />
  </svg>
</template>
