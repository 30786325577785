<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.54493 0.84785C4.75581 1.19625 3.53409 4.64969 5.48017 6.68433C7.64885 8.95173 11.4476 7.43189 11.4471 4.29701C11.4467 2.16005 9.66289 0.58329 7.54493 0.84785ZM8.56101 1.91861C10.4207 2.41109 11.0595 4.62501 9.73725 5.99517C8.19605 7.59229 5.46193 6.39633 5.56921 4.17201C5.64233 2.65673 7.12665 1.53877 8.56101 1.91861ZM7.48241 8.29945C7.43085 8.30641 7.26213 8.32737 7.10741 8.34597C4.19061 8.69713 2.38773 10.742 3.27321 12.6949C4.16533 14.6624 7.60453 15.6263 10.3991 14.6919C14.2723 13.3969 14.0177 9.54877 9.99057 8.51737C9.27693 8.33457 8.02681 8.22597 7.48241 8.29945ZM8.80397 9.34581C11.5331 9.64417 12.9673 11.4258 11.5956 12.8138C9.80209 14.6286 5.24189 14.3099 4.24601 12.3001C3.43545 10.6643 5.95093 9.03389 8.80397 9.34581Z"
      fill="currentColor"
    />
  </svg>
</template>
