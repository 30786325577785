<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.0177 10.5674C16.6761 10.5674 16.3927 10.2841 16.3927 9.9424V7.17578C16.3927 6.14245 15.5511 5.30078 14.5177 5.30078H2.98438C2.64271 5.30078 2.35938 5.01745 2.35938 4.67578C2.35938 4.33411 2.64271 4.05078 2.98438 4.05078H14.5177C16.2427 4.05078 17.6427 5.45078 17.6427 7.17578V9.9424C17.6427 10.2924 17.3594 10.5674 17.0177 10.5674Z"
      fill="currentColor"
    />
    <path
      d="M5.61771 7.94235C5.45938 7.94235 5.30105 7.88399 5.17605 7.75899L2.54271 5.12568C2.42604 5.00901 2.35938 4.85065 2.35938 4.68399C2.35938 4.51732 2.42604 4.35901 2.54271 4.24235L5.17605 1.60898C5.41771 1.36732 5.81771 1.36732 6.05938 1.60898C6.30104 1.85065 6.30104 2.2507 6.05938 2.49237L3.86773 4.68399L6.05938 6.87566C6.30104 7.11732 6.30104 7.51732 6.05938 7.75899C5.93438 7.87566 5.77604 7.94235 5.61771 7.94235Z"
      fill="currentColor"
    />
    <path
      d="M17.0177 16.71H5.48438C3.75937 16.71 2.35938 15.31 2.35938 13.585V10.8184C2.35938 10.4767 2.64271 10.1934 2.98438 10.1934C3.32604 10.1934 3.60938 10.4767 3.60938 10.8184V13.585C3.60938 14.6184 4.45104 15.46 5.48438 15.46H17.0177C17.3594 15.46 17.6427 15.7434 17.6427 16.085C17.6427 16.4267 17.3594 16.71 17.0177 16.71Z"
      fill="currentColor"
    />
    <path
      d="M14.3846 19.3427C14.2263 19.3427 14.068 19.2844 13.943 19.1594C13.7013 18.9177 13.7013 18.5177 13.943 18.276L16.1346 16.0844L13.943 13.8927C13.7013 13.651 13.7013 13.251 13.943 13.0094C14.1846 12.7677 14.5846 12.7677 14.8263 13.0094L17.4596 15.6427C17.5763 15.7594 17.643 15.9177 17.643 16.0844C17.643 16.251 17.5763 16.4094 17.4596 16.526L14.8263 19.1594C14.7096 19.2844 14.5513 19.3427 14.3846 19.3427Z"
      fill="currentColor"
    />
  </svg>
</template>
