<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.75 8.0625C5.61 8.0625 4.6875 7.14 4.6875 6C4.6875 4.86 5.61 3.9375 6.75 3.9375C7.89 3.9375 8.8125 4.86 8.8125 6C8.8125 7.14 7.89 8.0625 6.75 8.0625ZM6.75 5.0625C6.2325 5.0625 5.8125 5.4825 5.8125 6C5.8125 6.5175 6.2325 6.9375 6.75 6.9375C7.2675 6.9375 7.6875 6.5175 7.6875 6C7.6875 5.4825 7.2675 5.0625 6.75 5.0625Z"
        fill="#49A399"/>
    <path
        d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H9.75C10.0575 0.9375 10.3125 1.1925 10.3125 1.5C10.3125 1.8075 10.0575 2.0625 9.75 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V7.5C15.9375 7.1925 16.1925 6.9375 16.5 6.9375C16.8075 6.9375 17.0625 7.1925 17.0625 7.5V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z"
        fill="#49A399"/>
    <path
        d="M15.9375 4.3125H11.8125C11.505 4.3125 11.25 4.0575 11.25 3.75C11.25 3.4425 11.505 3.1875 11.8125 3.1875H15.9375C16.245 3.1875 16.5 3.4425 16.5 3.75C16.5 4.0575 16.245 4.3125 15.9375 4.3125Z"
        fill="#49A399"/>
    <path
        d="M13.875 6.375C13.5675 6.375 13.3125 6.12 13.3125 5.8125V1.6875C13.3125 1.38 13.5675 1.125 13.875 1.125C14.1825 1.125 14.4375 1.38 14.4375 1.6875V5.8125C14.4375 6.12 14.1825 6.375 13.875 6.375Z"
        fill="#49A399"/>
    <path
        d="M2.00248 14.7749C1.82248 14.7749 1.64248 14.6849 1.53748 14.5274C1.36498 14.2724 1.43248 13.9199 1.68748 13.7474L5.38498 11.2649C6.19498 10.7249 7.31248 10.7849 8.04747 11.4074L8.29498 11.6249C8.66998 11.9474 9.30748 11.9474 9.67498 11.6249L12.795 8.94742C13.5975 8.26492 14.8425 8.26492 15.645 8.94742L16.8675 9.99742C17.1 10.1999 17.13 10.5524 16.9275 10.7924C16.725 11.0249 16.3725 11.0549 16.1325 10.8524L14.91 9.80242C14.535 9.47992 13.8975 9.47992 13.53 9.80242L10.41 12.4799C9.61498 13.1624 8.36248 13.1624 7.55998 12.4799L7.31248 12.2624C6.96748 11.9699 6.39748 11.9399 6.01498 12.2024L2.32498 14.6849C2.21998 14.7449 2.10748 14.7749 2.00248 14.7749Z"
        fill="#49A399"/>
  </svg>
</template>
