<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_124150)">
      <path d="M22.7502 12.0287C22.7502 12.6387 22.7002 13.2687 22.5902 13.9087C21.8402 18.3087 18.2402 21.8987 13.8202 22.6287C10.3102 23.2087 6.88015 22.1187 4.39015 19.6287C1.90015 17.1387 0.820153 13.7087 1.39015 10.2087C2.12015 5.79873 5.70015 2.18873 10.1002 1.43873C11.0702 1.27873 12.0202 1.23873 12.9202 1.31873L12.9302 1.31873C13.1602 1.33873 13.3802 1.37873 13.6002 1.43873C13.8502 1.50873 14.0402 1.69873 14.1202 1.93873C14.2002 2.17873 14.1402 2.44873 13.9802 2.64873C13.1902 3.58873 12.7502 4.78873 12.7502 6.02873C12.7502 8.92873 15.1102 11.2787 18.0002 11.2787C19.2402 11.2787 20.4402 10.8387 21.3802 10.0487C21.5802 9.87873 21.8402 9.82873 22.0902 9.90873C22.3402 9.98873 22.5202 10.1787 22.5902 10.4287C22.6502 10.6487 22.6902 10.8687 22.7102 11.0987C22.7402 11.3987 22.7502 11.7087 22.7502 12.0287ZM2.75015 12.0287C2.75015 14.4787 3.70015 16.7987 5.46015 18.5587C7.60015 20.6987 10.5602 21.6387 13.5802 21.1387C17.3702 20.5087 20.4702 17.4387 21.1102 13.6487C21.2102 13.0487 21.2702 12.4787 21.2502 11.9287C20.2602 12.4687 19.1402 12.7587 18.0002 12.7587C14.2802 12.7587 11.2502 9.72873 11.2502 6.00873C11.2502 4.86873 11.5402 3.74873 12.0802 2.75873C11.5302 2.74873 10.9602 2.79873 10.3602 2.89873C6.58015 3.53873 3.50015 6.63873 2.88015 10.4287C2.79015 10.9787 2.75015 11.5087 2.75015 12.0287Z" fill="#9D9D9D"/>
      <path d="M22.61 10.6212C22.61 10.8412 22.52 11.0512 22.34 11.1912C21.13 12.2112 19.59 12.7812 18 12.7812C14.28 12.7812 11.25 9.75125 11.25 6.03125C11.25 4.44125 11.81 2.90125 12.84 1.69125C13.03 1.46125 13.34 1.37125 13.62 1.45125C14.24 1.63125 14.78 1.95125 15.24 2.41125L21.62 8.79125C22.08 9.25125 22.4 9.80125 22.58 10.4112C22.6 10.4812 22.61 10.5512 22.61 10.6212ZM12.75 6.03125C12.75 8.93125 15.11 11.2812 18 11.2812C19.06 11.2812 20.09 10.9612 20.95 10.3712C20.85 10.1812 20.72 10.0113 20.56 9.85125L14.18 3.47125C14.02 3.31125 13.85 3.18125 13.66 3.08125C13.07 3.94125 12.75 4.97125 12.75 6.03125Z" fill="#9D9D9D"/>
    </g>
    <defs>
      <clipPath id="clip0_4_124150">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
