<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_860_5019)">
      <path d="M9.88059 12.2449C10.0811 11.5756 10.4821 10.9733 11.0168 10.5047C12.0193 9.66815 12.6543 8.39655 12.6543 6.99109C12.6543 4.38094 10.4821 2.23928 7.8087 2.37314C5.43601 2.47353 3.49777 4.48133 3.43095 6.85723C3.39752 8.32964 4.03247 9.63471 5.06843 10.5047C5.63652 10.9732 6.03753 11.5756 6.23804 12.2449H9.88059Z" fill="#FFD15C"/>
      <path d="M7.62365 12.327H7.92189L6.59637 7.34436C6.62952 7.34436 6.66264 7.34436 6.69578 7.34436C6.86146 7.34436 7.02717 7.27748 7.1597 7.14371C7.22597 7.07684 7.29226 7.04339 7.39168 7.04339C7.49109 7.04339 7.55736 7.07684 7.62365 7.14371C7.85563 7.41123 8.25328 7.41123 8.48522 7.14371C8.55149 7.07684 8.61779 7.04339 8.7172 7.04339C8.78346 7.04339 8.88288 7.07684 8.94917 7.14371C9.08174 7.27748 9.21427 7.34436 9.41309 7.34436C9.44624 7.34436 9.47936 7.34436 9.51251 7.34436L8.22013 12.327H8.51837L9.87701 7.11026C9.87701 7.04339 9.87701 6.97649 9.81075 6.94307C9.74448 6.90962 9.67819 6.94307 9.64507 6.97652C9.5788 7.04339 9.51251 7.07684 9.44624 7.07684C9.34683 7.07684 9.28056 7.04339 9.18115 6.97652C9.04859 6.84275 8.91606 6.77588 8.75035 6.77588C8.58464 6.77588 8.45211 6.84275 8.31955 6.97652C8.18698 7.11029 7.98816 7.11029 7.85563 6.97652C7.75621 6.84275 7.59053 6.77588 7.42483 6.77588C7.25915 6.77588 7.09344 6.84275 6.99402 6.97652C6.92776 7.04339 6.82834 7.07684 6.72893 7.07684C6.66267 7.07684 6.56325 7.04339 6.5301 6.97652C6.49696 6.94307 6.43069 6.90965 6.36443 6.94307C6.29816 6.97652 6.26501 7.04339 6.29816 7.11026L7.62365 12.327Z" fill="white"/>
      <path d="M7 15.2998C7.16669 15.6998 7.56669 15.9998 8.03336 15.9998C8.50002 15.9998 8.90002 15.6998 9.06671 15.2998H7Z" fill="#344A5E"/>
      <path d="M9.26579 15.3336H6.83243C6.49908 15.3336 6.23242 15.0669 6.23242 14.7336V12.2002H9.86579V14.7336C9.86579 15.0669 9.5991 15.3336 9.26579 15.3336Z" fill="#344A5E"/>
      <path d="M8.03257 0C7.86591 0 7.69922 0.133345 7.69922 0.333348V1.4667C7.69922 1.63336 7.83257 1.80005 8.03257 1.80005C8.23257 1.80005 8.36592 1.66671 8.36592 1.4667V0.333348C8.36592 0.133345 8.19923 0 8.03257 0Z" fill="#FFD15C"/>
      <path d="M3.56666 2.03311C3.43332 1.89977 3.23331 1.89977 3.1 2.03311C2.96668 2.16646 2.96665 2.36646 3.1 2.49978L3.90001 3.29978C4.03336 3.43313 4.23336 3.43313 4.36668 3.29978C4.49999 3.16644 4.50003 2.96644 4.36668 2.83312L3.56666 2.03311Z" fill="#FFD15C"/>
      <path d="M2.5331 6.63281H1.39975C1.23309 6.63281 1.06641 6.76616 1.06641 6.96616C1.06641 7.13282 1.19975 7.2995 1.39975 7.2995H2.5331C2.69976 7.2995 2.86644 7.16616 2.86644 6.96616C2.86644 6.79947 2.69976 6.63281 2.5331 6.63281Z" fill="#FFD15C"/>
      <path d="M3.90002 10.6332L3.10001 11.4332C2.96666 11.5666 2.96666 11.7666 3.10001 11.8999C3.23335 12.0332 3.43336 12.0332 3.56667 11.8999L4.36668 11.0999C4.50003 10.9665 4.50003 10.7665 4.36668 10.6332C4.23334 10.4999 4.03337 10.4999 3.90002 10.6332Z" fill="#FFD15C"/>
      <path d="M12.1663 10.6332C12.033 10.4999 11.833 10.4999 11.6996 10.6332C11.5663 10.7666 11.5663 10.9666 11.6996 11.0999L12.4996 11.8999C12.633 12.0332 12.8329 12.0332 12.9663 11.8999C13.0996 11.7665 13.0996 11.5665 12.9663 11.4332L12.1663 10.6332Z" fill="#FFD15C"/>
      <path d="M14.6679 6.63281H13.5345C13.3679 6.63281 13.2012 6.76616 13.2012 6.96616C13.2012 7.13282 13.3345 7.2995 13.5345 7.2995H14.6679C14.8346 7.2995 15.0013 7.16616 15.0013 6.96616C15.0012 6.79947 14.8679 6.63281 14.6679 6.63281Z" fill="#FFD15C"/>
      <path d="M12.4996 2.0331L11.6996 2.83311C11.5663 2.96645 11.5663 3.16646 11.6996 3.29977C11.833 3.43312 12.033 3.43312 12.1663 3.29977L12.9663 2.49976C13.0996 2.36642 13.0996 2.16642 12.9663 2.0331C12.8329 1.89979 12.633 1.89976 12.4996 2.0331Z" fill="#FFD15C"/>
    </g>
    <defs>
      <clipPath id="clip0_860_5019">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
