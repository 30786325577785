<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_3895_27513)">
      <path
        d="M8.96568 3.04968C8.96078 3.04478 8.95649 3.03926 8.95159 3.03435L8.73468 2.81744L8.73406 2.81806L6 0.0839844L0 6.08398L2.87685 8.96083C2.89951 8.98534 2.92219 9.00984 2.94609 9.03374L3.16299 9.25065L3.16483 9.24881L6 12.084L12 6.08398L8.96568 3.04968ZM6.30638 1.25678L8.29596 3.24636L7.30699 4.23533C7.00735 3.98779 6.6685 3.80028 6.30638 3.67712V1.25678ZM8.51777 5.78986L8.46936 5.83827C7.90318 6.40445 7.02329 6.47002 6.38419 6.03435L8.67218 3.74636C8.76531 3.86523 8.84619 3.99024 8.91605 4.12013C9.10111 4.68876 8.96875 5.33889 8.51777 5.78986ZM3.38481 6.27578L3.4326 6.22798C3.75245 5.90813 4.17341 5.74821 4.59375 5.74821C4.91544 5.74821 5.23653 5.84196 5.51349 6.02945L4.06802 7.47492L4.07047 7.47737L3.18811 8.35971C2.75306 7.72063 2.81925 6.84134 3.38481 6.27578ZM6.87132 4.671L5.95221 5.59011C5.06925 4.92223 3.80391 4.99023 2.99938 5.79476L2.95159 5.84256C2.85907 5.9351 2.77695 6.03374 2.70405 6.1373C2.81434 5.70163 3.03922 5.28864 3.37929 4.94796L3.45098 4.87627C3.93873 4.38852 4.58702 4.12013 5.27697 4.12013C5.86214 4.12075 6.41851 4.31438 6.87132 4.671ZM5.94485 6.47124C6.3462 6.777 6.82781 6.93019 7.30944 6.93019C7.88664 6.93019 8.46384 6.71021 8.90379 6.27087L8.95221 6.22246C9.02023 6.15446 9.08272 6.08277 9.14032 6.00801C9.02757 6.43937 8.8033 6.84807 8.46569 7.18509L8.394 7.25678C7.90318 7.74759 7.25061 8.01782 6.55637 8.01782C5.96691 8.01782 5.40809 7.82295 4.95221 7.46388L5.94485 6.47124ZM6.30638 8.62137C6.38909 8.62749 6.47181 8.63179 6.55515 8.63179C7.41298 8.63179 8.21937 8.29783 8.82598 7.69121L8.89767 7.61952C9.68199 6.83522 9.97488 5.74207 9.77635 4.72737L11.1336 6.0846L6.30638 10.9118V8.62137ZM0.866422 6.08398L5.69362 1.25678V3.53496C5.55638 3.5172 5.41728 3.50739 5.27697 3.50739C4.42341 3.50739 3.62133 3.8395 3.01778 4.44305L2.94609 4.51474C2.1973 5.26353 1.89951 6.29415 2.05025 7.26782L0.866422 6.08398ZM3.59926 8.81683L4.51533 7.90078C4.86398 8.18877 5.26409 8.39587 5.69362 8.51475V10.9112L3.59926 8.81683Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3895_27513">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
