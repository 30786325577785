<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_6025_34398)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.84498 1.61151C2.39934 2.17466 0.000669809 5.01388 1.8185e-07 8.53005C-0.000937298 13.8222 3.62289 18.8941 9.41138 21.7023C11.773 22.8481 12.6416 22.7924 15.342 21.3218C20.7484 18.3775 23.9923 13.5848 24 8.53005C24.0091 2.54842 17.0802 -0.66293 12.3916 3.14996L11.9975 3.47043L11.6453 3.17914C10.0414 1.85263 7.90425 1.27501 5.84498 1.61151ZM8.10315 3.30729C9.28193 3.54398 10.3725 4.18603 11.1341 5.09174C11.7428 5.81562 12.2567 5.81503 12.8667 5.08966C15.9176 1.46147 21.9532 3.41153 22.2827 8.13195C22.5932 12.5783 19.1129 17.5822 13.9377 20.1301C12.1018 21.0339 11.8972 21.0339 10.0612 20.1301C4.88426 17.5814 1.40568 12.58 1.71619 8.13195C1.93857 4.94666 4.94767 2.67364 8.10315 3.30729Z"
        fill="#D94B4B"
      />
      <path
        d="M12 5.25L9 3.375L8.25 2.625H5.25L1.5 5.625L1.125 10.5L6 18.75L12.375 21.75L20.25 16.125L23.25 9.75L21 3.75L16.125 2.625L12 5.25Z"
        fill="#D94B4B"
        stroke="#D94B4B"
      />
    </g>
    <defs>
      <clipPath id="clip0_6025_34398">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
