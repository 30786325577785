<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#a)">
      <path fill="#FFD15C" d="M7.41 9.184c.15-.502.452-.954.853-1.305A3.43 3.43 0 0 0 9.49 5.243c0-1.957-1.63-3.563-3.634-3.463-1.78.075-3.234 1.581-3.284 3.363a3.44 3.44 0 0 0 1.228 2.736c.426.351.727.803.878 1.305H7.41Z"/>
      <path fill="#fff" d="M5.719 9.245h.223l-.994-3.737h.075c.124 0 .248-.05.348-.15.05-.05.1-.076.174-.076.074 0 .124.026.174.076.174.2.472.2.646 0 .05-.05.1-.076.174-.076.05 0 .124.026.174.076.1.1.199.15.348.15h.074l-.969 3.737h.224l1.019-3.912c0-.05 0-.1-.05-.126-.05-.025-.1 0-.124.025-.05.05-.1.076-.15.076-.074 0-.124-.026-.198-.076a.436.436 0 0 0-.323-.15.436.436 0 0 0-.323.15c-.1.1-.249.1-.348 0a.4.4 0 0 0-.323-.15.4.4 0 0 0-.324.15.28.28 0 0 1-.198.076c-.05 0-.125-.026-.15-.076-.024-.025-.074-.05-.124-.025s-.074.075-.05.126l.995 3.912Z"/>
      <path fill="#344A5E" d="M5.25 11.475a.85.85 0 0 0 .775.525.85.85 0 0 0 .775-.525H5.25Z"/>
      <path fill="#344A5E" d="M6.95 11.5H5.127c-.25 0-.45-.2-.45-.45v-1.9H7.4v1.9c0 .25-.2.45-.45.45Z"/>
      <path fill="#FFD15C" d="M6.025 0a.25.25 0 0 0-.25.25v.85c0 .125.1.25.25.25s.25-.1.25-.25V.25a.25.25 0 0 0-.25-.25ZM2.677 1.525c-.1-.1-.25-.1-.35 0-.1.1-.1.25 0 .35l.6.6c.1.1.25.1.35 0 .1-.1.1-.25 0-.35l-.6-.6ZM1.9 4.975h-.85a.25.25 0 0 0 0 .5h.85c.126 0 .25-.1.25-.25a.269.269 0 0 0-.25-.25ZM2.927 7.975l-.6.6c-.1.1-.1.25 0 .35.1.1.25.1.35 0l.6-.6c.1-.1.1-.25 0-.35-.1-.1-.25-.1-.35 0ZM9.126 7.975c-.1-.1-.25-.1-.35 0-.1.1-.1.25 0 .35l.6.6c.1.1.25.1.35 0 .1-.1.1-.25 0-.35l-.6-.6ZM11.002 4.975h-.85a.25.25 0 0 0 0 .5h.85a.25.25 0 0 0 0-.5ZM9.376 1.525l-.6.6c-.1.1-.1.25 0 .35.1.1.25.1.35 0l.6-.6c.1-.1.1-.25 0-.35-.1-.1-.25-.1-.35 0Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
