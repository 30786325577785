<template>
  <svg
    v-if="!isActive"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1405 15.785C14.1172 15.785 14.0822 15.785 14.0589 15.785C14.0239 15.785 13.9772 15.785 13.9422 15.785C11.2939 15.7033 9.31055 13.6383 9.31055 11.095C9.31055 8.50498 11.4222 6.39331 14.0122 6.39331C16.6022 6.39331 18.7139 8.50498 18.7139 11.095C18.7022 13.65 16.7072 15.7033 14.1755 15.785C14.1522 15.785 14.1522 15.785 14.1405 15.785ZM14.0005 8.13164C12.3672 8.13164 11.0489 9.46164 11.0489 11.0833C11.0489 12.6816 12.2972 13.9766 13.8839 14.035C13.9189 14.0233 14.0355 14.0233 14.1522 14.035C15.7155 13.9533 16.9405 12.67 16.9522 11.0833C16.9522 9.46164 15.6339 8.13164 14.0005 8.13164Z"
      fill="currentColor"
    />
    <path
      d="M13.9996 26.5418C10.8613 26.5418 7.86298 25.3751 5.54131 23.2518C5.33131 23.0651 5.23798 22.7851 5.26131 22.5168C5.41298 21.1285 6.27631 19.8335 7.71131 18.8768C11.188 16.5668 16.823 16.5668 20.288 18.8768C21.723 19.8451 22.5863 21.1285 22.738 22.5168C22.773 22.7968 22.668 23.0651 22.458 23.2518C20.1363 25.3751 17.138 26.5418 13.9996 26.5418ZM7.09298 22.2835C9.02965 23.9051 11.468 24.7918 13.9996 24.7918C16.5313 24.7918 18.9696 23.9051 20.9063 22.2835C20.6963 21.5718 20.1363 20.8835 19.308 20.3235C16.438 18.4101 11.573 18.4101 8.67965 20.3235C7.85131 20.8835 7.30298 21.5718 7.09298 22.2835Z"
      fill="currentColor"
    />
    <path
      d="M14.0007 26.5416C7.08232 26.5416 1.45898 20.9183 1.45898 13.9999C1.45898 7.08159 7.08232 1.45825 14.0007 1.45825C20.919 1.45825 26.5423 7.08159 26.5423 13.9999C26.5423 20.9183 20.919 26.5416 14.0007 26.5416ZM14.0007 3.20825C8.05065 3.20825 3.20898 8.04992 3.20898 13.9999C3.20898 19.9499 8.05065 24.7916 14.0007 24.7916C19.9507 24.7916 24.7923 19.9499 24.7923 13.9999C24.7923 8.04992 19.9507 3.20825 14.0007 3.20825Z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8339 26.5405C13.8444 26.5407 13.8549 26.5408 13.8654 26.5409C7.00113 26.4683 1.45898 20.8814 1.45898 13.9999C1.45898 7.07335 7.07408 1.45825 14.0007 1.45825C20.9273 1.45825 26.5423 7.07335 26.5423 13.9999C26.5423 20.8814 21.0002 26.4683 14.1359 26.5409C14.1464 26.5408 14.1569 26.5407 14.1674 26.5405C14.1118 26.5412 14.0563 26.5416 14.0007 26.5416C13.945 26.5416 13.8895 26.5412 13.8339 26.5405ZM5.75415 20.9531C7.70983 23.2689 10.621 24.753 13.873 24.7909C11.3871 24.7616 8.99808 23.8776 7.09398 22.2833C7.30398 21.5716 7.85232 20.8833 8.68065 20.3233C11.574 18.4099 16.439 18.4099 19.309 20.3233C20.1373 20.8833 20.6973 21.5716 20.9073 22.2833C19.0032 23.8776 16.6142 24.7616 14.1283 24.7909C17.3798 24.753 20.2904 23.2694 22.2461 20.9544C21.8281 20.1763 21.165 19.4677 20.289 18.8766C16.824 16.5666 11.189 16.5666 7.71232 18.8766C6.83553 19.4611 6.17217 20.1719 5.75415 20.9531ZM18.714 11.0949C18.7023 13.6499 16.7073 15.7033 14.1757 15.7849H14.1407H14.059H13.9423C11.294 15.7033 9.31065 13.6383 9.31065 11.0949C9.31065 8.5049 11.4223 6.39323 14.0123 6.39323C16.6023 6.39323 18.714 8.5049 18.714 11.0949ZM14.0007 8.13156C12.3673 8.13156 11.049 9.46156 11.049 11.0832C11.049 12.6816 12.2973 13.9766 13.884 14.0349C13.919 14.0233 14.0357 14.0233 14.1523 14.0349C15.7157 13.9533 16.9407 12.6699 16.9523 11.0832C16.9523 9.46156 15.634 8.13156 14.0007 8.13156Z"
      fill="#49A399"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
