<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_860_5004)">
      <path d="M14.8189 18.3675C15.1197 17.3636 15.7212 16.4601 16.5233 15.7574C18.0271 14.5025 18.9795 12.5951 18.9795 10.4869C18.9795 6.57166 15.7213 3.35916 11.7111 3.55995C8.15207 3.71054 5.2447 6.72225 5.14447 10.2861C5.09432 12.4947 6.04675 14.4523 7.60069 15.7574C8.45283 16.4601 9.05435 17.3636 9.35511 18.3675H14.8189Z" fill="#FFD15C"/>
      <path d="M11.4374 18.4901H11.8848L9.89651 11.0162C9.94623 11.0162 9.99591 11.0162 10.0456 11.0162C10.2941 11.0162 10.5427 10.9159 10.7415 10.7152C10.8409 10.6149 10.9403 10.5647 11.0895 10.5647C11.2386 10.5647 11.338 10.6149 11.4374 10.7152C11.7854 11.1165 12.3819 11.1165 12.7298 10.7152C12.8292 10.6149 12.9286 10.5647 13.0778 10.5647C13.1772 10.5647 13.3263 10.6149 13.4257 10.7152C13.6246 10.9159 13.8234 11.0162 14.1216 11.0162C14.1713 11.0162 14.221 11.0162 14.2707 11.0162L12.3322 18.4901H12.7795L14.8175 10.665C14.8175 10.5647 14.8175 10.4644 14.7181 10.4142C14.6187 10.3641 14.5192 10.4142 14.4696 10.4644C14.3702 10.5647 14.2707 10.6149 14.1713 10.6149C14.0222 10.6149 13.9228 10.5647 13.7737 10.4644C13.5748 10.2638 13.376 10.1635 13.1275 10.1635C12.8789 10.1635 12.6801 10.2638 12.4813 10.4644C12.2824 10.6651 11.9842 10.6651 11.7854 10.4644C11.6363 10.2638 11.3878 10.1635 11.1392 10.1635C10.8907 10.1635 10.6421 10.2638 10.493 10.4644C10.3936 10.5647 10.2445 10.6149 10.0954 10.6149C9.99595 10.6149 9.84683 10.5647 9.79711 10.4644C9.74739 10.4142 9.64799 10.3641 9.54859 10.4142C9.4492 10.4644 9.39947 10.5647 9.44919 10.665L11.4374 18.4901Z" fill="white"/>
      <path d="M10.498 22.9503C10.7481 23.5503 11.3481 24.0003 12.0481 24.0003C12.7481 24.0003 13.3481 23.5503 13.5981 22.9503H10.498Z" fill="#344A5E"/>
      <path d="M13.8997 23.0007H10.2496C9.7496 23.0007 9.34961 22.6007 9.34961 22.1006V18.3005H14.7997V22.1006C14.7997 22.6006 14.3996 23.0007 13.8997 23.0007Z" fill="#344A5E"/>
      <path d="M12.0489 0C11.7989 0 11.5488 0.200018 11.5488 0.500022V2.20006C11.5488 2.45004 11.7488 2.70008 12.0489 2.70008C12.3489 2.70008 12.5489 2.50006 12.5489 2.20006V0.500022C12.5489 0.200018 12.2988 0 12.0489 0Z" fill="#FFD15C"/>
      <path d="M5.35195 3.04967C5.15193 2.84965 4.85192 2.84965 4.65195 3.04967C4.45198 3.24969 4.45193 3.54969 4.65195 3.74966L5.85197 4.94968C6.05199 5.14969 6.352 5.14969 6.55197 4.94968C6.75195 4.74966 6.75199 4.44966 6.55197 4.24968L5.35195 3.04967Z" fill="#FFD15C"/>
      <path d="M3.79965 9.94946H2.09963C1.84964 9.94946 1.59961 10.1495 1.59961 10.4495C1.59961 10.6995 1.79963 10.9495 2.09963 10.9495H3.79965C4.04963 10.9495 4.29967 10.7495 4.29967 10.4495C4.29967 10.1994 4.04963 9.94946 3.79965 9.94946Z" fill="#FFD15C"/>
      <path d="M5.85198 15.9499L4.65197 17.1499C4.45195 17.35 4.45195 17.65 4.65197 17.8499C4.85199 18.0499 5.15199 18.05 5.35196 17.8499L6.55198 16.6499C6.752 16.4499 6.752 16.1499 6.55198 15.9499C6.35196 15.75 6.052 15.7499 5.85198 15.9499Z" fill="#FFD15C"/>
      <path d="M18.2504 15.9499C18.0504 15.7499 17.7504 15.7499 17.5504 15.9499C17.3504 16.15 17.3504 16.45 17.5504 16.6499L18.7504 17.85C18.9504 18.05 19.2504 18.05 19.4504 17.85C19.6503 17.6499 19.6504 17.3499 19.4504 17.15L18.2504 15.9499Z" fill="#FFD15C"/>
      <path d="M22.0009 9.94946H20.3008C20.0508 9.94946 19.8008 10.1495 19.8008 10.4495C19.8008 10.6995 20.0008 10.9495 20.3008 10.9495H22.0009C22.2509 10.9495 22.5009 10.7495 22.5009 10.4495C22.5009 10.1994 22.3009 9.94946 22.0009 9.94946Z" fill="#FFD15C"/>
      <path d="M18.7504 3.04965L17.5504 4.24966C17.3504 4.44968 17.3504 4.74969 17.5504 4.94966C17.7504 5.14967 18.0504 5.14967 18.2504 4.94966L19.4504 3.74964C19.6504 3.54963 19.6504 3.24962 19.4504 3.04965C19.2504 2.84968 18.9504 2.84964 18.7504 3.04965Z" fill="#FFD15C"/>
    </g>
    <defs>
      <clipPath id="clip0_860_5004">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
