<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9996 22.4C17.588 22.4 22.1183 17.7437 22.1183 12C22.1183 6.25621 17.588 1.59998 11.9996 1.59998C6.41117 1.59998 1.88086 6.25621 1.88086 12C1.88086 17.7437 6.41117 22.4 11.9996 22.4Z" fill="url(#paint0_linear_860_5076)"/>
    <path d="M11.9996 22.4C17.588 22.4 22.1183 17.7437 22.1183 12C22.1183 6.25621 17.588 1.59998 11.9996 1.59998C6.41117 1.59998 1.88086 6.25621 1.88086 12C1.88086 17.7437 6.41117 22.4 11.9996 22.4Z" fill="url(#paint1_linear_860_5076)"/>
    <path d="M8.28799 13.277C8.82674 13.277 9.26348 12.451 9.26348 11.4321C9.26348 10.4132 8.82674 9.58716 8.28799 9.58716C7.74924 9.58716 7.3125 10.4132 7.3125 11.4321C7.3125 12.451 7.74924 13.277 8.28799 13.277Z" fill="#303030"/>
    <path d="M7.08008 8.80005L9.56121 10.768" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.9197 11.4321C16.9197 12.4407 16.4956 13.277 15.9442 13.277C15.4141 13.277 14.9688 12.4407 14.9688 11.4321C14.9688 10.4235 15.3929 9.58716 15.9442 9.58716C16.4956 9.58716 16.9197 10.4235 16.9197 11.4321Z" fill="#303030"/>
    <path d="M16.8991 8.80005L14.418 10.768" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.80078 16C8.80078 16 11.4827 14.2 15.2008 16" stroke="#5B0600" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_860_5076" x1="3.22379" y1="17.205" x2="20.9986" y2="7.22032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
      <linearGradient id="paint1_linear_860_5076" x1="11.9981" y1="1.6031" x2="11.9981" y2="22.4133" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DD5F70"/>
        <stop offset="0.1619" stop-color="#DD636F" stop-opacity="0.9684"/>
        <stop offset="0.6863" stop-color="#EBAA5D" stop-opacity="0.3625"/>
        <stop offset="1" stop-color="#F3D652" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>
