<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#DE5AB9" />
    <path
      d="M18.3334 24.7491H15.1751C12.0084 24.7491 11.0417 23.7824 11.0417 20.6158V15.6158C11.0417 12.4491 12.0084 11.4824 15.1751 11.4824H23.9501C27.1167 11.4824 28.0834 12.4491 28.0834 15.6158C28.0834 15.9574 27.8001 16.2408 27.4584 16.2408C27.1167 16.2408 26.8334 15.9574 26.8334 15.6158C26.8334 13.1658 26.4001 12.7324 23.9501 12.7324H15.1751C12.7251 12.7324 12.2917 13.1658 12.2917 15.6158V20.6158C12.2917 23.0658 12.7251 23.4991 15.1751 23.4991H18.3334C18.6751 23.4991 18.9584 23.7824 18.9584 24.1241C18.9584 24.4658 18.6751 24.7491 18.3334 24.7491Z"
      fill="white"
    />
    <path
      d="M18.3333 28.5172C17.9916 28.5172 17.7083 28.2339 17.7083 27.8922V24.1172C17.7083 23.7755 17.9916 23.4922 18.3333 23.4922C18.6749 23.4922 18.9583 23.7755 18.9583 24.1172V27.8922C18.9583 28.2422 18.6749 28.5172 18.3333 28.5172Z"
      fill="white"
    />
    <path
      d="M18.3334 21.416H11.6667C11.3251 21.416 11.0417 21.1327 11.0417 20.791C11.0417 20.4493 11.3251 20.166 11.6667 20.166H18.3334C18.6751 20.166 18.9584 20.4493 18.9584 20.791C18.9584 21.1327 18.6751 21.416 18.3334 21.416Z"
      fill="white"
    />
    <path
      d="M18.3336 28.5176H15.6169C15.2753 28.5176 14.9919 28.2342 14.9919 27.8926C14.9919 27.5509 15.2753 27.2676 15.6169 27.2676H18.3336C18.6753 27.2676 18.9586 27.5509 18.9586 27.8926C18.9586 28.2342 18.6753 28.5176 18.3336 28.5176Z"
      fill="white"
    />
    <path
      d="M25.8676 28.5159H22.9093C20.601 28.5159 19.8176 27.7326 19.8176 25.4242V20.6659C19.8176 18.3576 20.601 17.5742 22.9093 17.5742H25.8676C28.176 17.5742 28.9593 18.3576 28.9593 20.6659V25.4242C28.9593 27.7409 28.176 28.5159 25.8676 28.5159ZM22.9093 18.8242C21.276 18.8242 21.0676 19.0326 21.0676 20.6659V25.4242C21.0676 27.0576 21.276 27.2659 22.9093 27.2659H25.8676C27.501 27.2659 27.7093 27.0576 27.7093 25.4242V20.6659C27.7093 19.0326 27.501 18.8242 25.8676 18.8242H22.9093Z"
      fill="white"
    />
    <path
      d="M24.4173 25.808C24.2007 25.808 23.984 25.7247 23.8257 25.5663C23.7507 25.4913 23.6923 25.3997 23.6507 25.2997C23.609 25.1913 23.584 25.083 23.584 24.9747C23.584 24.758 23.6757 24.5413 23.8257 24.3913C24.0173 24.1997 24.309 24.108 24.584 24.158C24.634 24.1747 24.684 24.1913 24.734 24.208C24.784 24.233 24.834 24.258 24.8757 24.283C24.9257 24.3163 24.9673 24.3497 25.009 24.3913C25.159 24.5413 25.2507 24.758 25.2507 24.9747C25.2507 25.083 25.2257 25.1913 25.184 25.2997C25.1423 25.3997 25.084 25.4913 25.009 25.5663C24.9673 25.608 24.9257 25.6413 24.8757 25.6663C24.834 25.6997 24.784 25.7247 24.734 25.7497C24.684 25.7663 24.634 25.783 24.584 25.7913C24.5257 25.808 24.4757 25.808 24.4173 25.808Z"
      fill="white"
    />
  </svg>
</template>
