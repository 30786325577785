<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="svgg">
      <rect id="Rectangle 2581" x="4" y="4" width="6" height="6" fill="white" />
      <path
        id="path0"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.87458 0.313454C4.40687 0.445194 4.15379 0.768664 3.8384 1.63775C3.58014 2.34951 3.64079 2.30933 2.7617 2.35108C1.24959 2.4229 0.860147 2.98696 1.28799 4.48556C1.34619 4.68943 1.40419 4.93188 1.41682 5.02435L1.43982 5.19245L1.30094 5.3258C1.22457 5.39913 1.0286 5.5623 0.865432 5.68833C0.207047 6.19699 0.0136719 6.49463 0.0136719 6.99912C0.0136719 7.51313 0.243692 7.85151 0.957027 8.3868C1.48707 8.78457 1.49571 8.81149 1.30269 9.46193C0.846637 10.9986 1.23517 11.5758 2.77538 11.6493C3.62483 11.6898 3.59403 11.6712 3.80494 12.2722C4.36046 13.8553 5.02802 14.1106 6.32568 13.236C6.52945 13.0987 6.76454 12.955 6.84809 12.9168L6.99999 12.8472L7.14772 12.9149C7.22899 12.9521 7.46433 13.0951 7.67076 13.2327C8.27255 13.6337 8.46113 13.7106 8.84568 13.7116C9.46266 13.7133 9.79467 13.3727 10.1616 12.3616C10.4193 11.6513 10.3609 11.6905 11.2246 11.6493C12.7517 11.5764 13.1412 11.0172 12.712 9.5138C12.6538 9.30992 12.5958 9.06748 12.5832 8.97501L12.5602 8.8069L12.699 8.67355C12.7754 8.60023 12.9714 8.43706 13.1345 8.31102C13.7931 7.80216 13.9863 7.5048 13.9863 6.99968C13.9863 6.48668 13.7956 6.19951 13.0911 5.65165C12.4991 5.19126 12.5014 5.1977 12.6973 4.53743C13.1522 3.00457 12.7645 2.42357 11.2383 2.35108C10.3592 2.30933 10.4198 2.34951 10.1616 1.63775C9.60539 0.105063 8.87368 -0.119601 7.56128 0.839258C7.47063 0.905479 7.30725 1.00393 7.19823 1.05804L6.99999 1.15643L6.80175 1.05783C6.69272 1.00362 6.51737 0.896729 6.41209 0.820253C5.7787 0.360284 5.28548 0.197709 4.87458 0.313454ZM9.42783 5.02841C9.71301 5.17509 9.83436 5.48873 9.7092 5.75553C9.63682 5.90981 6.61502 8.92314 6.4745 8.98113C6.14963 9.11525 6.09023 9.07801 5.1739 8.16605C4.1449 7.14188 4.06548 6.98722 4.38307 6.62549C4.7141 6.24847 4.96425 6.33786 5.72833 7.10629L6.27503 7.65607L7.57402 6.3582C8.39435 5.53857 8.90829 5.04521 8.96874 5.01934C9.10314 4.9618 9.30614 4.96583 9.42783 5.02841Z"
        fill="#49ADF4"
      />
    </g>
  </svg>
</template>
