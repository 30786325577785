<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/close-circle">
      <g id="close-circle">
        <path
          id="Vector"
          d="M16.0003 30.3337C8.09366 30.3337 1.66699 23.907 1.66699 16.0003C1.66699 8.09366 8.09366 1.66699 16.0003 1.66699C23.907 1.66699 30.3337 8.09366 30.3337 16.0003C30.3337 23.907 23.907 30.3337 16.0003 30.3337ZM16.0003 3.66699C9.20033 3.66699 3.66699 9.20033 3.66699 16.0003C3.66699 22.8003 9.20033 28.3337 16.0003 28.3337C22.8003 28.3337 28.3337 22.8003 28.3337 16.0003C28.3337 9.20033 22.8003 3.66699 16.0003 3.66699Z"
          fill="#B0B0B0"
        />
        <path
          id="Vector_2"
          d="M12.2271 20.7738C11.9738 20.7738 11.7205 20.6805 11.5205 20.4805C11.1338 20.0938 11.1338 19.4538 11.5205 19.0671L19.0671 11.5205C19.4538 11.1338 20.0938 11.1338 20.4805 11.5205C20.8671 11.9071 20.8671 12.5471 20.4805 12.9338L12.9338 20.4805C12.7471 20.6805 12.4805 20.7738 12.2271 20.7738Z"
          fill="#B0B0B0"
        />
        <path
          id="Vector_3"
          d="M19.7738 20.7738C19.5205 20.7738 19.2671 20.6805 19.0671 20.4805L11.5205 12.9338C11.1338 12.5471 11.1338 11.9071 11.5205 11.5205C11.9071 11.1338 12.5471 11.1338 12.9338 11.5205L20.4805 19.0671C20.8671 19.4538 20.8671 20.0938 20.4805 20.4805C20.2805 20.6805 20.0271 20.7738 19.7738 20.7738Z"
          fill="#B0B0B0"
        />
      </g>
    </g>
  </svg>
</template>
