<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 15C8.11278 14.9993 8.22203 14.9606 8.31 14.89C8.5 14.72 13.5 10.69 13.5 6.5C13.5 5.04131 12.9205 3.64236 11.8891 2.61091C10.8576 1.57946 9.45869 1 8 1C6.54131 1 5.14236 1.57946 4.11091 2.61091C3.07946 3.64236 2.5 5.04131 2.5 6.5C2.5 10.69 7.5 14.72 7.69 14.89C7.77797 14.9606 7.88722 14.9993 8 15ZM3.5 6.5C3.5 5.30653 3.97411 4.16193 4.81802 3.31802C5.66193 2.47411 6.80653 2 8 2C9.19347 2 10.3381 2.47411 11.182 3.31802C12.0259 4.16193 12.5 5.30653 12.5 6.5C12.5 9.65 9.065 12.905 8 13.845C6.935 12.905 3.5 9.65 3.5 6.5Z"
      fill="currentColor"
    />
    <path
      d="M10.5 6.5C10.5 6.00555 10.3534 5.5222 10.0787 5.11108C9.80397 4.69995 9.41353 4.37952 8.95671 4.1903C8.4999 4.00108 7.99723 3.95157 7.51228 4.04804C7.02732 4.1445 6.58187 4.3826 6.23223 4.73223C5.8826 5.08187 5.6445 5.52732 5.54804 6.01228C5.45157 6.49723 5.50108 6.99989 5.6903 7.45671C5.87952 7.91353 6.19995 8.30397 6.61108 8.57868C7.0222 8.85338 7.50555 9 8 9C8.66304 9 9.29893 8.73661 9.76777 8.26777C10.2366 7.79893 10.5 7.16304 10.5 6.5ZM6.5 6.5C6.5 6.20333 6.58797 5.91332 6.7528 5.66665C6.91762 5.41997 7.15189 5.22771 7.42598 5.11418C7.70007 5.00065 8.00167 4.97095 8.29264 5.02882C8.58361 5.0867 8.85088 5.22956 9.06066 5.43934C9.27044 5.64912 9.4133 5.91639 9.47118 6.20737C9.52906 6.49834 9.49935 6.79994 9.38582 7.07403C9.27229 7.34812 9.08003 7.58238 8.83336 7.74721C8.58668 7.91203 8.29667 8 8 8C7.60218 8 7.22065 7.84197 6.93934 7.56066C6.65804 7.27936 6.5 6.89783 6.5 6.5Z"
      fill="currentColor"
    />
  </svg>
</template>
