<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M16.8323 28.2847C15.6555 28.2847 14.3595 27.8677 13.0636 27.0485L8.71403 24.3225C8.41611 24.1438 8.07351 24.0395 7.73091 24.0395H5.58592C1.98113 24.0395 0 22.0583 0 18.4535V12.4952C0 8.89047 1.98113 6.90932 5.58592 6.90932H7.71601C8.05861 6.90932 8.40121 6.80506 8.69913 6.62631L13.0487 3.90038C15.2235 2.54487 17.3387 2.29164 19.0071 3.21518C20.6753 4.13871 21.584 6.06027 21.584 8.63724V22.2967C21.584 24.8587 20.6604 26.7951 19.0071 27.7187C18.3516 28.1061 17.6068 28.2847 16.8323 28.2847ZM5.58592 9.15859C3.23239 9.15859 2.23436 10.1566 2.23436 12.5101V18.4685C2.23436 20.8219 3.23239 21.8201 5.58592 21.8201H7.71601C8.49059 21.8201 9.23537 22.0286 9.89079 22.4457L14.2404 25.1715C15.6704 26.0653 17.0259 26.3037 17.9345 25.7971C18.8432 25.2907 19.3645 24.0246 19.3645 22.3414V8.65214C19.3645 6.95402 18.8432 5.68787 17.9345 5.19631C17.0259 4.68986 15.6704 4.9133 14.2404 5.82194L9.87589 8.53296C9.23537 8.95006 8.47569 9.15859 7.71601 9.15859H5.58592Z"
      fill="currentColor"
    />
    <path
      d="M24.9503 22.5645C24.7121 22.5645 24.4886 22.49 24.2801 22.3411C23.7885 21.9687 23.6842 21.2685 24.0566 20.7769C26.3953 17.6637 26.3953 13.3142 24.0566 10.201C23.6842 9.70943 23.7885 9.00932 24.2801 8.63694C24.7715 8.26454 25.4717 8.3688 25.8441 8.86036C28.7637 12.7631 28.7637 18.2149 25.8441 22.1176C25.6206 22.4155 25.293 22.5645 24.9503 22.5645Z"
      fill="currentColor"
    />
    <path
      d="M27.6764 26.2887C27.438 26.2887 27.2147 26.2143 27.006 26.0653C26.5146 25.6929 26.4103 24.9928 26.7827 24.5012C30.7599 19.1983 30.7599 11.7802 26.7827 6.47735C26.4103 5.98579 26.5146 5.28568 27.006 4.9133C27.4976 4.5409 28.1978 4.64518 28.5702 5.13674C33.1431 11.2291 33.1431 19.7495 28.5702 25.8419C28.3616 26.1397 28.019 26.2887 27.6764 26.2887Z"
      fill="currentColor"
    />
  </svg>
</template>
