<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7626)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M5.15381 7.23936C5.10049 6.87888 5.34949 6.5434 5.70989 6.49006L8.29824 6.10697C8.65899 6.05363 8.99419 6.30265 9.04754 6.66304C9.10087 7.02352 8.85186 7.359 8.49147 7.41234L5.90312 7.79543C5.81104 7.80901 5.72058 7.80299 5.63603 7.78033C5.38968 7.71432 5.1935 7.50765 5.15381 7.23936Z" fill="#4C2908"/>
      <path d="M15.8429 9.94943C16.0058 9.40566 16.3317 8.91626 16.7661 8.53559C17.5807 7.85586 18.0965 6.82269 18.0965 5.68075C18.0965 3.56001 16.3317 1.8199 14.1595 1.92866C12.2317 2.01023 10.6569 3.64157 10.6026 5.57198C10.5754 6.76832 11.0913 7.82869 11.933 8.53559C12.3946 8.91623 12.7204 9.40564 12.8833 9.94943H15.8429Z" fill="#FFD15C"/>
      <path d="M14.0094 10.0161H14.2517L13.1747 5.96775C13.2017 5.96775 13.2286 5.96775 13.2555 5.96775C13.3901 5.96775 13.5248 5.91341 13.6324 5.80472C13.6863 5.75039 13.7402 5.72321 13.8209 5.72321C13.9017 5.72321 13.9555 5.75039 14.0094 5.80472C14.1979 6.02208 14.521 6.02208 14.7094 5.80472C14.7633 5.75039 14.8171 5.72321 14.8979 5.72321C14.9518 5.72321 15.0325 5.75039 15.0864 5.80472C15.1941 5.91341 15.3018 5.96775 15.4633 5.96775C15.4903 5.96775 15.5172 5.96775 15.5441 5.96775L14.494 10.0161H14.7364L15.8403 5.77755C15.8403 5.72321 15.8403 5.66886 15.7864 5.6417C15.7326 5.61452 15.6787 5.6417 15.6518 5.66888C15.598 5.72321 15.5441 5.75039 15.4903 5.75039C15.4095 5.75039 15.3556 5.72321 15.2749 5.66888C15.1672 5.56019 15.0595 5.50586 14.9248 5.50586C14.7902 5.50586 14.6825 5.56019 14.5748 5.66888C14.4671 5.77757 14.3056 5.77757 14.1979 5.66888C14.1171 5.56019 13.9825 5.50586 13.8479 5.50586C13.7132 5.50586 13.5786 5.56019 13.4978 5.66888C13.444 5.72321 13.3632 5.75039 13.2824 5.75039C13.2286 5.75039 13.1478 5.72321 13.1209 5.66888C13.094 5.6417 13.0401 5.61455 12.9863 5.6417C12.9324 5.66888 12.9055 5.72321 12.9324 5.77755L14.0094 10.0161Z" fill="white"/>
      <path d="M13.4961 12.4316C13.6315 12.7566 13.9565 13.0004 14.3357 13.0004C14.7149 13.0004 15.0399 12.7566 15.1753 12.4316H13.4961Z" fill="#344A5E"/>
      <path d="M15.3396 12.458H13.3625C13.0917 12.458 12.875 12.2413 12.875 11.9705V9.91211H15.8271V11.9705C15.8271 12.2413 15.6104 12.458 15.3396 12.458Z" fill="#344A5E"/>
      <path d="M15.8011 10.9415H12.876C12.7135 10.9415 12.5781 10.8061 12.5781 10.6436C12.5781 10.4811 12.7135 10.3457 12.876 10.3457H15.8011C15.9636 10.3457 16.099 10.4811 16.099 10.6436C16.099 10.8061 15.9636 10.9415 15.8011 10.9415Z" fill="#415A6B"/>
      <path d="M15.8011 11.8888H12.876C12.7135 11.8888 12.5781 11.7534 12.5781 11.5909C12.5781 11.4284 12.7135 11.293 12.876 11.293H15.8011C15.9636 11.293 16.099 11.4284 16.099 11.5909C16.099 11.7534 15.9636 11.8888 15.8011 11.8888Z" fill="#415A6B"/>
      <path d="M14.3412 0C14.2057 0 14.0703 0.108343 14.0703 0.270845V1.1917C14.0703 1.32711 14.1787 1.46254 14.3412 1.46254C14.5037 1.46254 14.612 1.3542 14.612 1.1917V0.270845C14.612 0.108343 14.4766 0 14.3412 0Z" fill="#FFD15C"/>
      <path d="M10.7104 1.65157C10.6021 1.54323 10.4396 1.54323 10.3312 1.65157C10.2229 1.75991 10.2229 1.92241 10.3312 2.03073L10.9813 2.68074C11.0896 2.78908 11.2521 2.78908 11.3604 2.68074C11.4687 2.5724 11.4688 2.40989 11.3604 2.30158L10.7104 1.65157Z" fill="#FFD15C"/>
      <path d="M9.87138 5.38867H8.95053C8.81512 5.38867 8.67969 5.49701 8.67969 5.65952C8.67969 5.79492 8.78803 5.93036 8.95053 5.93036H9.87138C10.0068 5.93036 10.1422 5.82202 10.1422 5.65952C10.1422 5.52408 10.0068 5.38867 9.87138 5.38867Z" fill="#FFD15C"/>
      <path d="M10.9813 8.63984L10.3313 9.28985C10.2229 9.3982 10.2229 9.5607 10.3313 9.66902C10.4396 9.77734 10.6021 9.77736 10.7104 9.66902L11.3604 9.01901C11.4688 8.91066 11.4688 8.74816 11.3604 8.63984C11.2521 8.53152 11.0896 8.5315 10.9813 8.63984Z" fill="#FFD15C"/>
      <path d="M17.6987 8.63985C17.5904 8.53151 17.4279 8.53151 17.3195 8.63985C17.2112 8.7482 17.2112 8.9107 17.3195 9.01902L17.9695 9.66903C18.0779 9.77737 18.2404 9.77737 18.3487 9.66903C18.457 9.56068 18.457 9.39818 18.3487 9.28986L17.6987 8.63985Z" fill="#FFD15C"/>
      <path d="M19.7308 5.38867H18.8099C18.6745 5.38867 18.5391 5.49701 18.5391 5.65952C18.5391 5.79492 18.6474 5.93036 18.8099 5.93036H19.7308C19.8662 5.93036 20.0016 5.82202 20.0016 5.65952C20.0016 5.52408 19.8933 5.38867 19.7308 5.38867Z" fill="#FFD15C"/>
      <path d="M17.9695 1.65156L17.3195 2.30157C17.2112 2.40991 17.2112 2.57241 17.3195 2.68073C17.4279 2.78907 17.5904 2.78907 17.6987 2.68073L18.3487 2.03072C18.457 1.92238 18.457 1.75988 18.3487 1.65156C18.2404 1.54324 18.0779 1.54322 17.9695 1.65156Z" fill="#FFD15C"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7626">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
