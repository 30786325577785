<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.334 15.1665H4.66732C1.72732 15.1665 0.833984 14.2732 0.833984 11.3332V9.99984C0.833984 7.05984 1.72732 6.1665 4.66732 6.1665H11.334C14.274 6.1665 15.1673 7.05984 15.1673 9.99984V11.3332C15.1673 14.2732 14.274 15.1665 11.334 15.1665ZM4.66732 7.1665C2.28065 7.1665 1.83398 7.61984 1.83398 9.99984V11.3332C1.83398 13.7132 2.28065 14.1665 4.66732 14.1665H11.334C13.7207 14.1665 14.1673 13.7132 14.1673 11.3332V9.99984C14.1673 7.61984 13.7207 7.1665 11.334 7.1665H4.66732Z"
      fill="currentColor"
    />
    <path
      d="M4 7.16683C3.72667 7.16683 3.5 6.94016 3.5 6.66683V5.3335C3.5 3.40016 3.96667 0.833496 8 0.833496C10.9867 0.833496 12.5 2.12016 12.5 4.66683C12.5 4.94016 12.2733 5.16683 12 5.16683C11.7267 5.16683 11.5 4.94016 11.5 4.66683C11.5 3.34683 11.1 1.8335 8 1.8335C5.09333 1.8335 4.5 3.2335 4.5 5.3335V6.66683C4.5 6.94016 4.27333 7.16683 4 7.16683Z"
      fill="currentColor"
    />
    <path
      d="M8.00065 12.8333C6.80732 12.8333 5.83398 11.86 5.83398 10.6667C5.83398 9.47333 6.80732 8.5 8.00065 8.5C9.19398 8.5 10.1673 9.47333 10.1673 10.6667C10.1673 11.86 9.19398 12.8333 8.00065 12.8333ZM8.00065 9.5C7.36065 9.5 6.83398 10.0267 6.83398 10.6667C6.83398 11.3067 7.36065 11.8333 8.00065 11.8333C8.64065 11.8333 9.16732 11.3067 9.16732 10.6667C9.16732 10.0267 8.64065 9.5 8.00065 9.5Z"
      fill="currentColor"
    />
  </svg>
</template>
