<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="edit">
      <path
        id="Vector"
        d="M3.20278 16.9729C2.65534 16.9729 2.1438 16.7759 1.77585 16.4101C1.30919 15.9504 1.08483 15.2563 1.1656 14.5058L1.49765 11.4665C1.56047 10.8943 1.89252 10.1345 2.27842 9.72176L9.64637 1.57009C11.4861 -0.46548 13.4066 -0.521763 15.3541 1.40124C17.3015 3.32425 17.3553 5.33168 15.5156 7.36725L8.14765 15.5189C7.77073 15.941 7.07073 16.335 6.52329 16.4288L3.63355 16.9447C3.48098 16.9541 3.34637 16.9729 3.20278 16.9729ZM12.5271 1.39186C11.8361 1.39186 11.2348 1.84213 10.6246 2.51752L3.25662 10.6786C3.07714 10.8756 2.87073 11.3446 2.83483 11.6166L2.50278 14.6559C2.46688 14.9655 2.53867 15.2187 2.70021 15.3782C2.86175 15.5377 3.10406 15.594 3.40021 15.5471L6.28996 15.0311C6.55021 14.9842 6.98098 14.7403 7.16047 14.5433L14.5284 6.39167C15.6412 5.15345 16.0451 4.00902 14.4207 2.41434C13.7028 1.69204 13.0835 1.39186 12.5271 1.39186Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M13.7936 8.93058C13.7757 8.93058 13.7488 8.93058 13.7308 8.93058C10.9308 8.63979 8.67827 6.41661 8.2475 3.50865C8.19365 3.12405 8.44493 2.76759 8.81288 2.70193C9.18083 2.64564 9.52185 2.9083 9.58468 3.2929C9.9257 5.56298 11.6847 7.30776 13.8744 7.53289C14.2424 7.57041 14.5116 7.91749 14.4757 8.30209C14.4308 8.65855 14.1347 8.93058 13.7936 8.93058Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M17.0769 19.9994H0.923077C0.555128 19.9994 0.25 19.6804 0.25 19.2958C0.25 18.9112 0.555128 18.5923 0.923077 18.5923H17.0769C17.4449 18.5923 17.75 18.9112 17.75 19.2958C17.75 19.6804 17.4449 19.9994 17.0769 19.9994Z"
        fill="white"
      />
    </g>
  </svg>
</template>
