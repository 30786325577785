<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7527)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.65281 7.37498C4.78687 7.71382 5.17025 7.87986 5.50904 7.74586L7.94216 6.78345C8.28125 6.64927 8.44703 6.26601 8.31304 5.92722C8.17898 5.58838 7.79559 5.42235 7.45681 5.55634L5.02369 6.51875C4.93716 6.55303 4.86183 6.60348 4.79993 6.66538C4.61959 6.84572 4.55303 7.12279 4.65281 7.37498Z" fill="#4C2908"/>
      <path d="M8.55528 13.9999C8.71213 13.9999 8.86798 14.0661 8.9778 14.1951C9.29465 14.5672 9.75636 14.7806 10.2446 14.7806C10.7326 14.7806 11.1943 14.5673 11.5111 14.1954C11.7101 13.9618 12.0604 13.9341 12.2935 14.1334C12.5267 14.3327 12.5544 14.6837 12.3554 14.9173C11.8274 15.5371 11.058 15.8926 10.2446 15.8926C9.43089 15.8926 8.66133 15.5369 8.13324 14.9167C7.93431 14.6831 7.9621 14.3321 8.19532 14.1328C8.29982 14.0436 8.42788 13.9999 8.55528 13.9999Z" fill="#1B1C1D"/>
      <path d="M5.64311 0.571277C6.01071 0.571277 6.01132 0 5.64311 0C5.27552 0 5.27491 0.571277 5.64311 0.571277Z" fill="#FDBC00"/>
      <path d="M11.8072 1.12304C12.1748 1.12304 12.1754 0.551758 11.8072 0.551758C11.4396 0.551758 11.439 1.12304 11.8072 1.12304Z" fill="#FDBC00"/>
      <path d="M10.6041 5.46483C10.9717 5.46483 10.9723 4.89355 10.6041 4.89355C10.2365 4.89355 10.2358 5.46483 10.6041 5.46483Z" fill="#FDBC00"/>
      <path d="M14.0962 11.6504C14.4638 11.6504 14.4645 11.0791 14.0962 11.0791C13.7286 11.0791 13.728 11.6504 14.0962 11.6504Z" fill="#FDBC00"/>
      <path d="M0.775927 10.5742C1.14353 10.5742 1.14413 10.0029 0.775927 10.0029C0.408329 10.0029 0.40772 10.5742 0.775927 10.5742Z" fill="#FDBC00"/>
      <path d="M0.78374 19.5C1.15134 19.5 1.15195 18.9287 0.78374 18.9287C0.416142 18.9287 0.415532 19.5 0.78374 19.5Z" fill="#FDBC00"/>
      <path d="M2.11968 13.3105C2.48728 13.3105 2.48788 12.7393 2.11968 12.7393C1.75208 12.7393 1.75147 13.3105 2.11968 13.3105Z" fill="#FDBC00"/>
      <path d="M18.9673 16.1064C19.3349 16.1064 19.3355 15.5352 18.9673 15.5352C18.5997 15.5352 18.5991 16.1064 18.9673 16.1064Z" fill="#FDBC00"/>
      <path d="M18.2212 13.3867C18.5888 13.3867 18.5895 12.8154 18.2212 12.8154C17.8536 12.8154 17.853 13.3867 18.2212 13.3867Z" fill="#FDBC00"/>
      <path d="M6.40093 18.4404C6.76852 18.4404 6.76913 17.8691 6.40093 17.8691C6.03333 17.8691 6.03272 18.4404 6.40093 18.4404Z" fill="#FDBC00"/>
      <path d="M1.73399 0.0836636C1.62244 -0.0278879 1.44157 -0.0278879 1.33006 0.0836636L0.583664 0.830057C0.472112 0.941608 0.472112 1.12247 0.583664 1.23399C0.63942 1.28974 0.712544 1.31766 0.785629 1.31766C0.858715 1.31766 0.931838 1.28978 0.987595 1.23399L1.73399 0.487595C1.84554 0.376082 1.84554 0.195215 1.73399 0.0836636Z" fill="#50D9B2"/>
      <path d="M9.24541 1.74382C9.13386 1.63227 8.95299 1.63227 8.84148 1.74382C8.72992 1.85537 8.72992 2.03624 8.84148 2.14775L9.58787 2.89414C9.64362 2.9499 9.71675 2.97782 9.78983 2.97782C9.86292 2.97782 9.93604 2.94994 9.9918 2.89414C10.1033 2.78259 10.1033 2.60173 9.9918 2.49021L9.24541 1.74382Z" fill="#FF6381"/>
      <path d="M13.5449 14.7399L12.7985 15.4863C12.687 15.5979 12.687 15.7787 12.7985 15.8902C12.8543 15.946 12.9274 15.9739 13.0005 15.9739C13.0736 15.9739 13.1467 15.946 13.2024 15.8902L13.9488 15.1438C14.0604 15.0323 14.0604 14.8514 13.9488 14.7399C13.8373 14.6284 13.6564 14.6284 13.5449 14.7399Z" fill="#50D9B2"/>
      <path d="M1.73369 15.6198C1.62214 15.5082 1.44127 15.5082 1.32976 15.6198C1.21821 15.7313 1.21821 15.9122 1.32976 16.0237L2.07615 16.7701C2.13191 16.8259 2.20503 16.8538 2.27812 16.8538C2.3512 16.8538 2.42432 16.8259 2.48008 16.7701C2.59163 16.6586 2.59163 16.4777 2.48008 16.3662L1.73369 15.6198Z" fill="#50D9B2"/>
      <path d="M18.4212 18.2663C18.3096 18.1547 18.1288 18.1547 18.0173 18.2663C17.9057 18.3778 17.9057 18.5587 18.0173 18.6702L18.7637 19.4166C18.8194 19.4724 18.8925 19.5003 18.9656 19.5003C19.0387 19.5003 19.1118 19.4724 19.1676 19.4166C19.2791 19.305 19.2791 19.1242 19.1676 19.0127L18.4212 18.2663Z" fill="#50D9B2"/>
      <path d="M19.9176 9.43913C19.806 9.32758 19.6252 9.32758 19.5136 9.43913L18.7673 10.1855C18.6557 10.2971 18.6557 10.4779 18.7673 10.5895C18.823 10.6452 18.8961 10.6731 18.9692 10.6731C19.0423 10.6731 19.1154 10.6452 19.1712 10.5895L19.9176 9.84306C20.0292 9.73155 20.0292 9.55068 19.9176 9.43913Z" fill="#FF6381"/>
      <path d="M11.636 18.2663C11.5245 18.1547 11.3436 18.1547 11.2321 18.2663C11.1205 18.3778 11.1205 18.5587 11.2321 18.6702L11.9785 19.4166C12.0343 19.4724 12.1074 19.5003 12.1805 19.5003C12.2535 19.5003 12.3267 19.4724 12.3824 19.4166C12.494 19.305 12.494 19.1242 12.3824 19.0127L11.636 18.2663Z" fill="#FF6381"/>
      <path d="M2.40527 10.8389C2.31628 10.8389 2.24413 10.7667 2.24413 10.6777C2.24413 10.5448 2.19732 10.4248 2.14778 10.2976C2.06276 10.0796 1.96645 9.8325 2.10878 9.50985C2.1606 9.39237 2.22873 9.29715 2.28884 9.21313C2.38198 9.08295 2.45616 8.97927 2.46074 8.82061C2.46025 8.81545 2.45997 8.81025 2.45997 8.80494C2.45997 8.63734 2.384 8.53117 2.28786 8.39676C2.22774 8.31276 2.15962 8.21754 2.10779 8.10004C1.96546 7.77739 2.06178 7.53027 2.14679 7.31225C2.19635 7.18513 2.24315 7.06505 2.24315 6.93215V5.96191C2.24315 5.87293 2.31529 5.80078 2.40428 5.80078C2.49327 5.80078 2.56541 5.87293 2.56541 5.96191V6.93217C2.56541 7.12566 2.50253 7.28699 2.44703 7.42932C2.36999 7.62698 2.3143 7.76978 2.40262 7.97C2.44104 8.05707 2.49393 8.131 2.54992 8.20927C2.65614 8.35772 2.77619 8.52554 2.782 8.78529C2.78279 8.79173 2.7832 8.79828 2.7832 8.80496C2.7832 9.07599 2.65979 9.24849 2.55089 9.40066C2.4949 9.47893 2.44201 9.55286 2.40359 9.63993C2.31527 9.84015 2.37094 9.98295 2.448 10.1806C2.50349 10.3229 2.56638 10.4843 2.56638 10.6778C2.5664 10.7667 2.49426 10.8389 2.40527 10.8389ZM2.78223 8.80496H2.78245H2.78223Z" fill="#D1EDF9"/>
      <path d="M18.1128 10.8389C18.0238 10.8389 17.9517 10.7667 17.9517 10.6777V10.0482C17.9517 9.9153 17.9049 9.79522 17.8553 9.6681C17.7703 9.45008 17.674 9.20296 17.8163 8.88031C17.8681 8.76281 17.9363 8.66759 17.9964 8.58357C18.0925 8.44916 18.1685 8.34301 18.1685 8.17541C18.1685 8.00837 18.0906 7.89822 17.992 7.75876C17.9238 7.6623 17.8465 7.55296 17.7951 7.41624C17.6895 7.13543 17.7629 6.95361 17.8339 6.77781C17.8918 6.63436 17.9517 6.486 17.9517 6.23101V5.0166C17.9517 4.92761 18.0238 4.85547 18.1128 4.85547C18.2018 4.85547 18.2739 4.92761 18.2739 5.0166V6.23103C18.2739 6.54861 18.1956 6.74259 18.1327 6.89846C18.0679 7.05902 18.0354 7.1396 18.0967 7.30282C18.1336 7.40077 18.1926 7.4843 18.2552 7.57273C18.3656 7.72894 18.4908 7.90597 18.4908 8.17543C18.4908 8.44646 18.3673 8.61893 18.2584 8.77113C18.2024 8.8494 18.1495 8.92332 18.1111 9.0104C18.0228 9.21061 18.0785 9.35342 18.1555 9.55108C18.211 9.69341 18.2739 9.85474 18.2739 10.0482V10.6777C18.2739 10.7667 18.2018 10.8389 18.1128 10.8389Z" fill="#D1EDF9"/>
      <path d="M18.2203 5.35321H18.0208C17.8684 5.35321 17.7606 5.20416 17.8083 5.05942L17.9812 4.53516H18.2599L18.4328 5.05942C18.4805 5.20418 18.3727 5.35321 18.2203 5.35321Z" fill="#AD6BFF"/>
      <path d="M20.0233 1.9148C20.0233 3.21174 19.169 4.69668 18.1151 4.69668C17.0613 4.69668 16.207 3.21174 16.207 1.9148C16.207 0.617848 17.0613 0 18.1151 0C19.169 0 20.0233 0.617848 20.0233 1.9148Z" fill="#C89CFF"/>
      <path d="M18.1172 0C17.9694 0 17.8256 0.0121602 17.6875 0.0366309C18.5344 0.186635 19.1659 0.799713 19.1659 1.91482C19.1659 3.02992 18.5344 4.28397 17.6875 4.61371C17.8256 4.66748 17.9694 4.6967 18.1172 4.6967C19.171 4.6967 20.0253 3.21177 20.0253 1.91482C20.0253 0.617848 19.171 0 18.1172 0Z" fill="#C18FFF"/>
      <path d="M15.1006 4.48955C14.9483 4.34997 14.7553 4.25151 14.5268 4.19685C14.4402 4.17612 14.3533 4.22953 14.3326 4.31609C14.3119 4.40265 14.3653 4.48957 14.4518 4.51028C14.6264 4.55203 14.7714 4.62497 14.8828 4.72708C14.9928 4.82793 15.0731 4.96275 15.1215 5.12781C15.1421 5.19806 15.2064 5.24361 15.2761 5.24361C15.2911 5.24361 15.3064 5.24148 15.3215 5.23706C15.4069 5.21199 15.4558 5.12246 15.4308 5.03706C15.3656 4.81489 15.2545 4.63066 15.1006 4.48955Z" fill="#F5D400"/>
      <path d="M12.2725 1.68581C12.1202 1.54625 11.9272 1.44777 11.6986 1.39313C11.6121 1.37242 11.5252 1.42583 11.5045 1.51237C11.4838 1.59893 11.5372 1.68586 11.6237 1.70657C11.7983 1.74831 11.9433 1.82125 12.0547 1.92337C12.1646 2.02419 12.245 2.15903 12.2934 2.32409C12.314 2.39435 12.3783 2.43989 12.448 2.43989C12.463 2.43989 12.4783 2.43777 12.4934 2.43332C12.5788 2.40825 12.6277 2.31872 12.6026 2.23334C12.5374 2.01111 12.4263 1.8269 12.2725 1.68581Z" fill="#00A9CE"/>
      <path d="M19.4659 1.37884C19.4007 1.15665 19.2896 0.972417 19.1357 0.831308C18.9835 0.691745 18.7904 0.593261 18.5619 0.538626C18.4753 0.517958 18.3884 0.571304 18.3677 0.657865C18.347 0.744425 18.4004 0.831351 18.487 0.852062C18.6616 0.893806 18.8066 0.966745 18.9179 1.06884C19.0279 1.16971 19.1083 1.30452 19.1567 1.46959C19.1773 1.53984 19.2416 1.58539 19.3112 1.58539C19.3263 1.58539 19.3415 1.58326 19.3567 1.57884C19.4421 1.55376 19.491 1.46424 19.4659 1.37884Z" fill="#AD6BFF"/>
      <path d="M7.40723 7.18185C7.31824 7.18185 7.24609 7.1097 7.24609 7.02072V4.9873C7.24609 4.89832 7.31824 4.82617 7.40723 4.82617C7.49622 4.82617 7.56836 4.89832 7.56836 4.9873V7.02072C7.56836 7.1097 7.49622 7.18185 7.40723 7.18185Z" fill="#D1EDF9"/>
      <path d="M7.50933 5.39619H7.30991C7.15745 5.39619 7.04962 5.24713 7.09736 5.10239L7.27025 4.57812H7.54901L7.72189 5.10239C7.76961 5.24713 7.66178 5.39619 7.50933 5.39619Z" fill="#F5D400"/>
      <path d="M9.31623 1.9148C9.31623 3.21174 8.46195 4.69668 7.40812 4.69668C6.35428 4.69668 5.5 3.21174 5.5 1.9148C5.5 0.617848 6.35428 0 7.40812 0C8.46195 0 9.31623 0.617848 9.31623 1.9148Z" fill="#FFF200"/>
      <path d="M7.40625 0C7.2585 0 7.11466 0.0121602 6.97656 0.0366309C7.82346 0.186635 8.45499 0.799714 8.45499 1.91482C8.45499 3.02992 7.82346 4.28397 6.97656 4.61371C7.11466 4.66748 7.2585 4.6967 7.40625 4.6967C8.46008 4.6967 9.31437 3.21177 9.31437 1.91482C9.31437 0.617848 8.46006 0 7.40625 0Z" fill="#F7EB00"/>
      <path d="M2.50933 6.25068H2.30991C2.15745 6.25068 2.04962 6.10162 2.09736 5.95688L2.27025 5.43262H2.54901L2.72189 5.95688C2.76961 6.10162 2.66178 6.25068 2.50933 6.25068Z" fill="#00A9CE"/>
      <path d="M4.31623 2.76929C4.31623 4.06623 3.46195 5.55117 2.40811 5.55117C1.35428 5.55117 0.5 4.06623 0.5 2.76929C0.5 1.47234 1.35428 0.854492 2.40811 0.854492C3.46195 0.854492 4.31623 1.47234 4.31623 2.76929Z" fill="#25CDEC"/>
      <path d="M2.40625 0.854492C2.2585 0.854492 2.11466 0.866652 1.97656 0.891123C2.82346 1.04113 3.45499 1.65421 3.45499 2.76931C3.45499 3.88442 2.82346 5.13846 1.97656 5.4682C2.11466 5.52198 2.2585 5.5512 2.40625 5.5512C3.46008 5.5512 4.31437 4.06626 4.31437 2.76931C4.31437 1.47236 3.46008 0.854492 2.40625 0.854492Z" fill="#0BC5E8"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7527">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
