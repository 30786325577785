<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 15.5C6.51664 15.5 5.0666 15.0601 3.83323 14.236C2.59986 13.4119 1.63856 12.2406 1.07091 10.8701C0.50325 9.49968 0.354725 7.99168 0.644114 6.53683C0.933503 5.08197 1.64781 3.7456 2.6967 2.6967C3.7456 1.64781 5.08197 0.933503 6.53683 0.644114C7.99168 0.354725 9.49968 0.50325 10.8701 1.07091C12.2406 1.63856 13.4119 2.59986 14.236 3.83323C15.0601 5.0666 15.5 6.51664 15.5 8C15.5 9.98913 14.7098 11.8968 13.3033 13.3033C11.8968 14.7098 9.98913 15.5 8 15.5ZM8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.3741 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5133 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.5 6.2761 13.8152 4.6228 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5Z"
      fill="white"
    />
    <path
      d="M10.12 10.83L7.64501 8.355C7.59867 8.30828 7.56201 8.25287 7.53712 8.19195C7.51224 8.13103 7.49963 8.0658 7.50001 8V4H8.50001V7.795L10.83 10.12L10.12 10.83Z"
      fill="white"
    />
  </svg>
</template>
