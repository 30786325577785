<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7487)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M7.45999 8.00006C8.58437 8.00541 9.49453 8.89478 9.5 9.99353C9.5 12.0065 5.75001 14 5.75001 14C5.75001 14 2 11.9772 2 9.99353C2 8.89258 2.91333 8.00006 4.04001 8.00006C4.72968 7.9948 5.37445 8.33378 5.75001 8.89907C6.12813 8.33653 6.77124 7.99843 7.45999 8.00006Z" fill="#D7443E"/>
      <path d="M15.96 8.00006C17.0844 8.00541 17.9945 8.89478 18 9.99353C18 12.0065 14.25 14 14.25 14C14.25 14 10.5 11.9772 10.5 9.99353C10.5 8.89258 11.4133 8.00006 12.54 8.00006C13.2297 7.9948 13.8745 8.33378 14.25 8.89907C14.6281 8.33653 15.2712 7.99843 15.96 8.00006Z" fill="#D7443E"/>
      <path d="M8.31205 5.07424C8.17799 4.7354 7.7946 4.56936 7.45582 4.70336L5.02269 5.66577C4.6836 5.79995 4.51781 6.18321 4.65181 6.52199C4.78587 6.86084 5.16925 7.02687 5.50804 6.89288L7.94117 5.93046C8.0277 5.89619 8.10303 5.84574 8.16493 5.78384C8.34527 5.6035 8.41183 5.32643 8.31205 5.07424Z" fill="#4C2908"/>
      <path d="M15.3589 6.52342C15.2249 6.86226 14.8415 7.02829 14.5027 6.8943L12.0696 5.93189C11.7305 5.7977 11.5647 5.41444 11.6987 5.07566C11.8327 4.73682 12.2161 4.57078 12.5549 4.70478L14.988 5.66719C15.0746 5.70147 15.1499 5.75192 15.2118 5.81382C15.3921 5.99416 15.4587 6.27123 15.3589 6.52342Z" fill="#4C2908"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7487">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
