<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9781_44248)">
      <path
        d="M16 0C7.17714 0 0 7.17714 0 16C0 24.8229 7.17714 32 16 32C24.8229 32 32 24.8229 32 16C32 7.17714 24.8229 0 16 0ZM16 2.28571C23.5886 2.28571 29.7143 8.41143 29.7143 16C29.7143 23.5886 23.5886 29.7143 16 29.7143C8.41143 29.7143 2.28571 23.5886 2.28571 16C2.28571 8.41143 8.41143 2.28571 16 2.28571ZM24.0005 10.285C23.5532 9.83842 22.8287 9.83864 22.3817 10.2855L12.5714 20.0937L9.62049 17.1428C9.17356 16.6959 8.44904 16.6956 8.0018 17.1422C7.55405 17.5893 7.55387 18.3149 8.0014 18.7622L11.6757 22.4349C12.1704 22.9295 12.9724 22.9295 13.4672 22.4349L24.0009 11.9051C24.4484 11.4577 24.4482 10.7322 24.0005 10.285Z"
        fill="#49A399"
      />
    </g>
    <defs>
      <clipPath id="clip0_9781_44248">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
