<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#50B6B6" />
    <path
      d="M20 28.9591C19.4167 28.9591 18.825 28.7341 18.3833 28.2924L16.9583 26.8841C16.6083 26.5341 16.125 26.3424 15.6333 26.3424H15C13.275 26.3424 11.875 24.9508 11.875 23.2425V14.1508C11.875 12.4424 13.275 11.0508 15 11.0508H25C26.725 11.0508 28.125 12.4424 28.125 14.1508V23.2425C28.125 24.9508 26.725 26.3424 25 26.3424H24.3667C23.875 26.3424 23.3917 26.5424 23.0417 26.8841L21.6167 28.2924C21.175 28.7341 20.5833 28.9591 20 28.9591ZM15 12.2924C13.9667 12.2924 13.125 13.1257 13.125 14.1424V23.2341C13.125 24.2591 13.9667 25.0841 15 25.0841H15.6333C16.4583 25.0841 17.2583 25.4174 17.8416 25.9924L19.2667 27.4008C19.675 27.8008 20.3333 27.8008 20.7417 27.4008L22.1666 25.9924C22.75 25.4174 23.55 25.0841 24.375 25.0841H25C26.0333 25.0841 26.875 24.2508 26.875 23.2341V14.1424C26.875 13.1174 26.0333 12.2924 25 12.2924H15Z"
      fill="white"
    />
    <path
      d="M20.0003 18.9575C18.5836 18.9575 17.4336 17.8075 17.4336 16.3908C17.4336 14.9742 18.5836 13.8242 20.0003 13.8242C21.4169 13.8242 22.567 14.9742 22.567 16.3908C22.567 17.8075 21.4169 18.9575 20.0003 18.9575ZM20.0003 15.0742C19.2753 15.0742 18.6836 15.6658 18.6836 16.3908C18.6836 17.1158 19.2753 17.7075 20.0003 17.7075C20.7253 17.7075 21.317 17.1158 21.317 16.3908C21.317 15.6658 20.7253 15.0742 20.0003 15.0742Z"
      fill="white"
    />
    <path
      d="M23.3337 23.6756C22.992 23.6756 22.7087 23.3923 22.7087 23.0506C22.7087 21.9006 21.492 20.959 20.0003 20.959C18.5087 20.959 17.292 21.9006 17.292 23.0506C17.292 23.3923 17.0087 23.6756 16.667 23.6756C16.3253 23.6756 16.042 23.3923 16.042 23.0506C16.042 21.2089 17.817 19.709 20.0003 19.709C22.1837 19.709 23.9587 21.2089 23.9587 23.0506C23.9587 23.3923 23.6753 23.6756 23.3337 23.6756Z"
      fill="white"
    />
  </svg>
</template>
