<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M18.685 1.788c-.443.148-.79.559-.978 1.156-.1.318-.117.54-.138 1.775-.027 1.601.015 1.478-.507 1.48-.386 0-1.543.162-2.297.319-1.586.331-2.975.832-4.514 1.627-1.811.936-3.208 1.97-4.644 3.437-1.612 1.647-2.748 3.273-3.7 5.293C.71 19.42.11 21.89.014 24.67c-.027.817-.02.938.07 1.116.203.406.723.584 1.103.378.083-.045.38-.383.662-.751 2.963-3.88 6.949-6.558 11.466-7.703a20.65 20.65 0 0 1 3.702-.605l.528-.038.024 1.424c.03 1.734.089 2 .558 2.533.286.326.548.438 1.016.432.483-.005.745-.11 1.231-.497.18-.143 1.832-1.841 3.669-3.773 2.833-2.98 3.367-3.567 3.518-3.869.556-1.118.584-2.213.082-3.28-.192-.41-.369-.605-3.79-4.185-4.005-4.19-3.86-4.063-4.628-4.099-.21-.01-.454.006-.54.035Zm4.184 5.424c2.518 2.645 3.235 3.43 3.338 3.655.194.423.186 1.09-.017 1.49-.106.208-1.059 1.24-3.512 3.806-1.852 1.935-3.386 3.499-3.41 3.474-.024-.025-.055-.875-.07-1.89-.03-2.041-.03-2.036-.4-2.272-.424-.27-3.238-.05-5.264.412-4.173.953-7.91 3.064-11.058 6.249l-.665.672.097-.563c1.008-5.876 4.975-10.93 10.313-13.142 1.824-.755 3.515-1.127 5.614-1.233.938-.047 1.057-.088 1.255-.428.1-.17.107-.3.108-1.93 0-.96.016-1.805.034-1.875l.033-.129.199.167c.11.092 1.642 1.684 3.405 3.537Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
