<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.75 17.0625H2.25C1.9425 17.0625 1.6875 16.8075 1.6875 16.5C1.6875 16.1925 1.9425 15.9375 2.25 15.9375H15.75C16.0575 15.9375 16.3125 16.1925 16.3125 16.5C16.3125 16.8075 16.0575 17.0625 15.75 17.0625Z"
        fill="#49A399"/>
    <path
        d="M4.19998 14.8127H3C2.28 14.8127 1.6875 14.2202 1.6875 13.5002V7.03516C1.6875 6.31516 2.28 5.72266 3 5.72266H4.19998C4.91998 5.72266 5.51248 6.31516 5.51248 7.03516V13.5002C5.51248 14.2202 4.91998 14.8127 4.19998 14.8127ZM3 6.84015C2.895 6.84015 2.8125 6.92265 2.8125 7.02765V13.5002C2.8125 13.6052 2.895 13.6877 3 13.6877H4.19998C4.30498 13.6877 4.38748 13.6052 4.38748 13.5002V7.03516C4.38748 6.93016 4.30498 6.84766 4.19998 6.84766H3V6.84015Z"
        fill="#49A399"/>
    <path
        d="M9.60037 14.8126H8.40039C7.68039 14.8126 7.08789 14.2201 7.08789 13.5001V4.64258C7.08789 3.92258 7.68039 3.33008 8.40039 3.33008H9.60037C10.3204 3.33008 10.9129 3.92258 10.9129 4.64258V13.5001C10.9129 14.2201 10.3204 14.8126 9.60037 14.8126ZM8.40039 4.45508C8.29539 4.45508 8.21289 4.53758 8.21289 4.64258V13.5001C8.21289 13.6051 8.29539 13.6876 8.40039 13.6876H9.60037C9.70537 13.6876 9.78787 13.6051 9.78787 13.5001V4.64258C9.78787 4.53758 9.70537 4.45508 9.60037 4.45508H8.40039Z"
        fill="#49A399"/>
    <path
        d="M14.9998 14.8125H13.7998C13.0798 14.8125 12.4873 14.22 12.4873 13.5V2.25C12.4873 1.53 13.0798 0.9375 13.7998 0.9375H14.9998C15.7198 0.9375 16.3123 1.53 16.3123 2.25V13.5C16.3123 14.22 15.7198 14.8125 14.9998 14.8125ZM13.7998 2.0625C13.6948 2.0625 13.6123 2.145 13.6123 2.25V13.5C13.6123 13.605 13.6948 13.6875 13.7998 13.6875H14.9998C15.1048 13.6875 15.1873 13.605 15.1873 13.5V2.25C15.1873 2.145 15.1048 2.0625 14.9998 2.0625H13.7998Z"
        fill="#49A399"/>
  </svg>
</template>
