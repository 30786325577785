<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M11.9801 16.983H7.49501C7.21992 16.983 6.99668 16.7597 6.99668 16.4847V7.51456C6.99668 7.23948 7.21995 7.01623 7.49501 7.01623H11.9801C12.2557 7.01623 12.4784 6.79348 12.4784 6.51791C12.4784 6.24233 12.2557 6.01953 11.9801 6.01953H7.49501C6.67076 6.01953 6 6.69031 6 7.51456V16.4847C6 17.3089 6.67076 17.9797 7.49501 17.9797H11.9801C12.2557 17.9797 12.4784 17.7569 12.4784 17.4813C12.4784 17.2058 12.2557 16.983 11.9801 16.983Z"
      fill="#D94B4B"
    />
    <path
      d="M17.8491 11.6452L14.8192 8.65519C14.6239 8.46183 14.308 8.46434 14.1146 8.66018C13.9212 8.85602 13.9232 9.17147 14.1196 9.36483L16.2849 11.5017H10.4827C10.2071 11.5017 9.98438 11.7244 9.98438 12C9.98438 12.2756 10.2071 12.4984 10.4827 12.4984H16.2849L14.1196 14.6352C13.9233 14.8286 13.9218 15.144 14.1146 15.3399C14.2123 15.4386 14.3408 15.4884 14.4694 15.4884C14.596 15.4884 14.7226 15.4406 14.8192 15.3449L17.8491 12.3548C17.9438 12.2611 17.9976 12.1335 17.9976 12C17.9976 11.8665 17.9443 11.7394 17.8491 11.6452Z"
      fill="#D94B4B"
    />
  </svg>
</template>
