<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      clip-path="url(#clip0_1122_6981)"
    >
      <g id="svgg">
        <path
          id="path0"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.4107 0.707294C9.73845 0.818669 9.65499 0.889172 6.75583 3.79551C3.74885 6.80992 4.08696 6.32816 3.85812 7.92426C3.66325 9.28344 3.69653 9.56088 4.09964 9.93794C4.49767 10.3103 4.73194 10.3344 6.07566 10.1418C7.67468 9.91254 7.1914 10.2516 10.2044 7.24497C12.9983 4.45699 13.0331 4.4177 13.2299 3.82982C13.7045 2.41233 11.9368 0.454432 10.4107 0.707294ZM4.75762 0.84091C2.31328 0.988413 1.14714 2.0351 0.872038 4.32853C0.8213 4.75162 0.8213 9.24814 0.872038 9.67123C1.1283 11.8075 2.10693 12.81 4.23391 13.1151C4.67649 13.1785 9.13345 13.1885 9.65873 13.1272C11.7939 12.8779 12.8295 11.8743 13.1131 9.77941C13.1394 9.58502 13.1579 9.14419 13.1671 8.49034L13.1811 7.4967L13.1185 7.37742C12.9526 7.0612 12.5548 7.04536 12.3516 7.34689C12.2917 7.43579 12.2906 7.45174 12.2905 8.19221C12.2902 11.0589 11.6789 11.9808 9.60501 12.2415C9.20289 12.2921 4.7647 12.2926 4.39506 12.2421C2.58061 11.9944 1.88142 11.2166 1.73478 9.28258C1.69429 8.74884 1.71584 4.65454 1.7607 4.35462C2.06983 2.28758 2.9509 1.70936 5.79148 1.70936C6.54886 1.70936 6.56399 1.70829 6.65302 1.64828C6.95404 1.44543 6.93857 1.04455 6.62352 0.883535C6.50974 0.825407 5.42514 0.800622 4.75762 0.84091ZM11.1658 1.68739C11.7917 1.98663 12.3823 2.70565 12.4185 3.21241C12.4459 3.5951 12.2769 3.90118 11.7333 4.45355L11.3713 4.82136L11.2401 4.76966C10.3476 4.41787 9.51474 3.57771 9.21427 2.72603L9.179 2.62611L9.57489 2.24159C10.2677 1.56863 10.6436 1.43769 11.1658 1.68739ZM8.96677 4.10244C9.37621 4.64595 9.90596 5.09946 10.4862 5.40312L10.6826 5.50591L8.95542 7.23414C6.86755 9.32335 7.23317 9.07678 5.94964 9.26137C4.47217 9.47385 4.52717 9.52823 4.73651 8.06237C4.92021 6.77623 4.66759 7.15292 6.73988 5.07505L8.48545 3.32481L8.64911 3.61143C8.73913 3.76908 8.88206 3.99004 8.96677 4.10244Z"
          fill="#B0B0B0"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1122_6981">
        <rect
          width="13.75"
          height="13.75"
          fill="white"
          transform="translate(0.125 0.125)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
