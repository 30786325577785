<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_442_19384)">
      <path
        d="M8.41633 14.1422C7.82793 14.1422 7.17993 13.9337 6.53195 13.524L4.35711 12.161C4.20815 12.0716 4.03685 12.0195 3.86554 12.0195H2.79302C0.990593 12.0195 0 11.0289 0 9.22647V6.24725C0 4.44482 0.990593 3.45423 2.79302 3.45423H3.85809C4.0294 3.45423 4.20071 3.40209 4.34967 3.31271L6.5245 1.94972C7.61193 1.27195 8.66953 1.14533 9.50373 1.60711C10.3379 2.06889 10.7923 3.02969 10.7923 4.3182V11.1481C10.7923 12.4291 10.3305 13.3974 9.50373 13.8591C9.176 14.0528 8.8036 14.1422 8.41633 14.1422ZM2.79302 4.57888C1.61623 4.57888 1.11721 5.0779 1.11721 6.25469V9.23394C1.11721 10.4107 1.61623 10.9097 2.79302 10.9097H3.85809C4.24539 10.9097 4.61779 11.014 4.94551 11.2225L7.12033 12.5855C7.83533 13.0324 8.51313 13.1516 8.96747 12.8983C9.4218 12.6451 9.68247 12.0121 9.68247 11.1704V4.32565C9.68247 3.47657 9.4218 2.84349 8.96747 2.5977C8.51313 2.34447 7.83533 2.45619 7.12033 2.91052L4.93806 4.26607C4.61779 4.47461 4.23795 4.57888 3.85809 4.57888H2.79302Z"
        fill="white"
      />
      <path
        d="M12.4755 11.2829C12.3564 11.2829 12.2447 11.2457 12.1404 11.1712C11.8946 10.9849 11.8425 10.6349 12.0287 10.3891C13.198 8.83247 13.198 6.65765 12.0287 5.101C11.8425 4.85521 11.8946 4.50516 12.1404 4.31895C12.3861 4.13275 12.7362 4.18489 12.9224 4.43067C14.3823 6.38207 14.3823 9.10807 12.9224 11.0595C12.8107 11.2084 12.6469 11.2829 12.4755 11.2829Z"
        fill="white"
      />
      <path
        d="M13.8388 13.1446C13.7197 13.1446 13.608 13.1073 13.5037 13.0329C13.2579 12.8467 13.2058 12.4966 13.392 12.2509C15.3806 9.59934 15.3806 5.89021 13.392 3.23869C13.2058 2.99291 13.2579 2.64285 13.5037 2.45665C13.7495 2.27045 14.0996 2.32259 14.2858 2.56837C16.5723 5.61463 16.5723 9.87494 14.2858 12.9211C14.1815 13.0701 14.0102 13.1446 13.8388 13.1446Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_442_19384">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
