<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.87082 1.34293C1.99945 1.81222 0.000558175 4.17824 1.51541e-07 7.10835C-0.000781081 11.5185 3.01907 15.7451 7.84279 18.0853C9.81083 19.0401 10.5347 18.9936 12.785 17.7681C17.2903 15.3145 19.9936 11.3207 20 7.10835C20.0076 2.12369 14.2335 -0.552441 10.3263 2.62496L9.99793 2.89202L9.70447 2.64929C8.36789 1.54386 6.58685 1.06251 4.87082 1.34293ZM6.75264 2.75608C7.73492 2.95332 8.64372 3.48836 9.27836 4.24312C9.7856 4.84635 10.2139 4.84586 10.7222 4.24138C13.2647 1.21789 18.2943 2.84294 18.5689 6.77663C18.8276 10.482 15.9274 14.6519 11.6147 16.775C10.0848 17.5282 9.91428 17.5282 8.38435 16.775C4.07022 14.6511 1.1714 10.4833 1.43016 6.77663C1.61548 4.12221 4.12306 2.22804 6.75264 2.75608Z"
      fill="#818181"
    />
  </svg>
</template>
