<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <path fill="currentColor"
          d="M10 15.167H6C2.38 15.167.835 13.62.835 10V6C.834 2.38 2.381.833 6.001.833h4c3.62 0 5.166 1.547 5.166 5.167v4c0 3.62-1.546 5.167-5.166 5.167ZM6 1.833C2.928 1.833 1.835 2.927 1.835 6v4c0 3.073 1.093 4.167 4.167 4.167h4c3.073 0 4.166-1.094 4.166-4.167V6c0-3.073-1.093-4.167-4.166-4.167H6Z"/>
    <path fill="currentColor"
          d="M4.594 14.82a.504.504 0 0 1-.5-.5V1.68a.5.5 0 1 1 1 0v12.64a.5.5 0 0 1-.5.5ZM11.26 14.82a.504.504 0 0 1-.5-.5V1.68c0-.273.226-.5.5-.5.273 0 .5.227.5.5v12.64a.5.5 0 0 1-.5.5Z"/>
    <path fill="currentColor"
          d="M4.593 5.147h-2.9a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h2.9c.274 0 .5.226.5.5 0 .273-.226.5-.5.5ZM4.594 8.5h-3.24a.504.504 0 0 1-.5-.5c0-.273.226-.5.5-.5h3.24c.273 0 .5.227.5.5s-.227.5-.5.5ZM4.594 11.813h-2.94a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h2.94c.274 0 .5.227.5.5 0 .274-.226.5-.5.5ZM14.593 5.147h-2.9a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h2.9c.274 0 .5.226.5.5 0 .273-.226.5-.5.5ZM14.594 8.5h-3.24a.504.504 0 0 1-.5-.5c0-.273.226-.5.5-.5h3.24c.273 0 .5.227.5.5s-.227.5-.5.5Z"/>
    <path fill="currentColor"
          d="M11.314 8.5H3.98a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h7.334a.5.5 0 1 1 0 1ZM14.594 11.813h-2.94a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h2.94c.274 0 .5.227.5.5 0 .274-.226.5-.5.5Z"/>
  </svg>
</template>
