<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.321 1.272c-4.183.523-6.016 5.703-3.097 8.755 3.253 3.4 8.951 1.121 8.95-3.581 0-3.206-2.676-5.57-5.853-5.174Zm1.524 1.606c2.79.739 3.748 4.06 1.765 6.115-2.312 2.396-6.413.602-6.252-2.735.11-2.273 2.336-3.95 4.487-3.38Zm-1.618 9.571-.562.07c-4.375.527-7.08 3.594-5.751 6.524 1.338 2.95 6.497 4.397 10.688 2.995 5.81-1.942 5.428-7.715-.612-9.262-1.07-.274-2.946-.437-3.763-.327Zm1.983 1.57c4.094.447 6.245 3.12 4.187 5.202-2.69 2.722-9.53 2.244-11.024-.77-1.216-2.454 2.557-4.9 6.837-4.432Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
