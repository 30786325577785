<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#E6A962" />
    <path
      d="M15.1493 28.9579C15.066 28.9579 14.9743 28.9496 14.8993 28.9413L13.091 28.6913C12.2243 28.5746 11.441 27.7996 11.3077 26.9163L11.0577 25.0913C10.9743 24.5079 11.2243 23.7496 11.641 23.3246L15.2993 19.6663C14.7077 17.2996 15.391 14.7996 17.1327 13.0746C19.8327 10.3829 24.2243 10.3746 26.9327 13.0746C28.241 14.3829 28.9577 16.1246 28.9577 17.9746C28.9577 19.8246 28.241 21.5663 26.9327 22.8746C25.1827 24.6079 22.691 25.2913 20.341 24.6913L16.6743 28.3496C16.3243 28.7163 15.6993 28.9579 15.1493 28.9579ZM22.0243 12.2996C20.566 12.2996 19.116 12.8496 18.0077 13.9579C16.5077 15.4496 15.966 17.6329 16.591 19.6663C16.6577 19.8913 16.5993 20.1246 16.4327 20.2913L12.516 24.2079C12.3743 24.3496 12.2577 24.7163 12.2827 24.9079L12.5327 26.7329C12.5827 27.0496 12.9243 27.4079 13.241 27.4496L15.0577 27.6996C15.2577 27.7329 15.6243 27.6163 15.766 27.4746L19.6993 23.5496C19.866 23.3829 20.1077 23.3329 20.3243 23.3996C22.3327 24.0329 24.5243 23.4913 26.0243 21.9913C27.091 20.9246 27.6827 19.4913 27.6827 17.9746C27.6827 16.4496 27.091 15.0246 26.0243 13.9579C24.941 12.8579 23.4827 12.2996 22.0243 12.2996Z"
      fill="white"
    />
    <path
      d="M17.6584 27.1158C17.5001 27.1158 17.3418 27.0574 17.2168 26.9324L15.3001 25.0158C15.0584 24.7741 15.0584 24.3741 15.3001 24.1324C15.5418 23.8908 15.9418 23.8908 16.1834 24.1324L18.1001 26.0491C18.3418 26.2908 18.3418 26.6908 18.1001 26.9324C17.9751 27.0574 17.8168 27.1158 17.6584 27.1158Z"
      fill="white"
    />
    <path
      d="M22.0833 19.791C21.05 19.791 20.2083 18.9493 20.2083 17.916C20.2083 16.8827 21.05 16.041 22.0833 16.041C23.1166 16.041 23.9583 16.8827 23.9583 17.916C23.9583 18.9493 23.1166 19.791 22.0833 19.791ZM22.0833 17.291C21.7416 17.291 21.4583 17.5743 21.4583 17.916C21.4583 18.2577 21.7416 18.541 22.0833 18.541C22.425 18.541 22.7083 18.2577 22.7083 17.916C22.7083 17.5743 22.425 17.291 22.0833 17.291Z"
      fill="white"
    />
  </svg>
</template>
