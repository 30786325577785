<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8341_39898)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.569 0.177355C14.2126 0.391991 14.0417 0.719521 14.0414 1.1887L14.0412 1.58765L13.4033 1.70727C7.33336 2.84551 3.61579 8.27408 4.19347 15.1561L4.28081 16.1975L3.7062 16.7734C1.8846 18.5993 1.89029 21.4532 3.71917 23.2603C4.7064 24.2357 5.44728 24.5136 8.20926 24.9445C8.75055 25.029 9.26208 25.1115 9.34597 25.1281C9.46923 25.1525 9.5297 25.2746 9.66024 25.7628C11.1685 31.4018 19.2426 31.4163 20.7495 25.7827C20.8851 25.2756 20.9452 25.1528 21.0691 25.129C21.153 25.1129 21.5825 25.0459 22.0236 24.9801C23.7498 24.7225 24.837 24.4602 25.4862 24.1445C28.3169 22.7683 28.9402 19.005 26.7071 16.7734L26.1307 16.1975L26.2095 15.3581C26.8694 8.31841 23.1136 2.66479 17.1392 1.70469L16.3742 1.58175L16.3737 1.18575C16.3728 0.253755 15.3714 -0.305829 14.569 0.177355ZM17.2541 4.1595C21.7365 5.31194 24.4661 9.8569 23.8135 15.0814C23.5675 17.0513 23.6477 17.4583 24.3825 17.9661C25.7778 18.9302 26.0664 20.3964 25.0751 21.4853C24.617 21.9885 24.1879 22.1667 22.8255 22.4197C17.4733 23.4134 11.9196 23.3677 6.91496 22.2889C4.4985 21.768 3.9629 19.3774 5.9603 18.0281C6.68775 17.5367 6.86802 17.0394 6.70613 15.9707C6.30704 13.3348 6.73085 10.4027 7.7972 8.4231C9.61694 5.0445 13.6405 3.23055 17.2541 4.1595ZM15.2075 25.5091C16.0295 25.5091 17.071 25.4875 17.5221 25.4612C17.9732 25.4348 18.3423 25.4224 18.3422 25.4335C18.3419 25.5414 18.071 26.0405 17.855 26.3312C16.4524 28.2183 13.332 27.9707 12.26 25.8873C12.0062 25.3942 11.9857 25.408 12.8938 25.4612C13.3444 25.4875 14.3856 25.5091 15.2075 25.5091Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8341_39898">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
