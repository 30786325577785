<template>
  <svg
    v-if="!isActive"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7557 26.5416H7.24565C4.04898 26.5416 1.45898 23.9399 1.45898 20.7432V12.0982C1.45898 10.5116 2.43898 8.51655 3.69898 7.53656L9.98732 2.63655C11.8773 1.16656 14.899 1.09655 16.859 2.47322L24.069 7.52489C25.4573 8.49322 26.5423 10.5699 26.5423 12.2616V20.7549C26.5423 23.9399 23.9523 26.5416 20.7557 26.5416ZM11.0606 4.01322L4.77232 8.91322C3.94398 9.56656 3.20898 11.0482 3.20898 12.0982V20.7432C3.20898 22.9716 5.01732 24.7916 7.24565 24.7916H20.7557C22.984 24.7916 24.7923 22.9832 24.7923 20.7549V12.2616C24.7923 11.1416 23.9873 9.58989 23.0657 8.95989L15.8557 3.90822C14.5257 2.97489 12.3323 3.02155 11.0606 4.01322Z"
      fill="currentColor"
    />
    <path
      d="M14 21.875C13.5217 21.875 13.125 21.4783 13.125 21V17.5C13.125 17.0217 13.5217 16.625 14 16.625C14.4783 16.625 14.875 17.0217 14.875 17.5V21C14.875 21.4783 14.4783 21.875 14 21.875Z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.45898 20.7433C1.45898 23.9399 4.04898 26.5416 7.24565 26.5416H20.7557C23.9523 26.5416 26.5423 23.9399 26.5423 20.7549V12.2616C26.5423 10.5699 25.4573 8.49322 24.069 7.52488L16.859 2.47322C14.899 1.09656 11.8773 1.16656 9.98732 2.63655L3.69898 7.53655C2.43898 8.51655 1.45898 10.5116 1.45898 12.0983V20.7433ZM14.0007 21.8749C13.5223 21.8749 13.1257 21.4783 13.1257 20.9999V17.4999C13.1257 17.0216 13.5223 16.6249 14.0007 16.6249C14.479 16.6249 14.8757 17.0216 14.8757 17.4999V20.9999C14.8757 21.4783 14.479 21.8749 14.0007 21.8749Z"
      fill="#49A399"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
