<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8.94.41c-.413.065-.597.122-3.35 1.04C2.36 2.527 2.16 2.603 1.53 3.014-.191 4.132.018 5.678 2.028 6.68c.418.209.789.352 1.637.633l.767.254.255.768c.628 1.896 1.15 2.742 1.937 3.132 1.153.572 2.28-.32 3.035-2.406.294-.812 1.78-5.311 1.85-5.6.49-2.039-.617-3.355-2.568-3.051Zm.892.899c.752.16 1.045.827.816 1.86-.082.375-1.833 5.602-2.02 6.03-.888 2.049-1.849 2.002-2.722-.132-.112-.273-.616-1.766-.616-1.824 0-.015.456-.482 1.013-1.038.557-.557 1.037-1.051 1.068-1.1.154-.247-.007-.626-.29-.68-.242-.045-.213-.068-1.41 1.126L4.564 6.657l-.669-.222C.757 5.394.444 4.346 2.957 3.303c.28-.116 5.343-1.81 5.713-1.912.45-.123.84-.15 1.162-.082Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
