<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7889)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.65281 7.37303C4.78687 7.71187 5.17025 7.8779 5.50904 7.74391L7.94216 6.78149C8.28125 6.64731 8.44703 6.26405 8.31304 5.92527C8.17898 5.58643 7.79559 5.42039 7.45681 5.55439L5.02369 6.5168C4.93716 6.55108 4.86183 6.60153 4.79993 6.66343C4.61959 6.84376 4.55303 7.12084 4.65281 7.37303Z" fill="#4C2908"/>
      <path d="M14.5268 10.9454C14.3699 10.9454 14.2141 10.8792 14.1042 10.7503C13.7874 10.3782 13.3257 10.1648 12.8375 10.1648C12.3494 10.1648 11.8878 10.378 11.5709 10.75C11.3719 10.9836 11.0216 11.0113 10.7885 10.8119C10.5553 10.6126 10.5276 10.2616 10.7266 10.028C11.2547 9.40822 12.024 9.05273 12.8375 9.05273C13.6511 9.05273 14.4207 9.40841 14.9488 10.0286C15.1477 10.2622 15.1199 10.6132 14.8867 10.8125C14.7822 10.9017 14.6541 10.9454 14.5268 10.9454Z" fill="#3E4749"/>
      <path d="M8.77287 10.9454C8.61598 10.9454 8.46013 10.8792 8.35035 10.7503C8.0335 10.3782 7.57176 10.1648 7.08355 10.1648C6.59554 10.1648 6.13387 10.3781 5.81702 10.75C5.61799 10.9836 5.26766 11.0112 5.03455 10.8119C4.8014 10.6125 4.77372 10.2616 4.97272 10.028C5.50077 9.40821 6.27015 9.05273 7.08355 9.05273C7.89722 9.05273 8.66679 9.4084 9.19487 10.0286C9.3938 10.2622 9.36604 10.6131 9.13282 10.8124C9.02829 10.9017 8.90023 10.9454 8.77287 10.9454Z" fill="#3E4749"/>
      <g filter="url(#filter0_f_3220_7889)">
        <circle cx="6.25" cy="12.75" r="1.25" fill="#E24B4B"/>
      </g>
      <g filter="url(#filter1_f_3220_7889)">
        <circle cx="13.75" cy="12.75" r="1.25" fill="#E24B4B"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_f_3220_7889" x="3" y="9.5" width="6.5" height="6.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_3220_7889"/>
      </filter>
      <filter id="filter1_f_3220_7889" x="10.5" y="9.5" width="6.5" height="6.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_3220_7889"/>
      </filter>
      <clipPath id="clip0_3220_7889">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
