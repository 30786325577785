<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.81608 1.266C3.777 1.56942 1.83042 3.36342 1.3068 7.26486C1.22916 7.84338 1.19436 15.0673 1.26438 16.068C1.5573 20.2529 3.34314 22.1948 7.35942 22.6957C8.07024 22.7843 15.936 22.7838 16.6407 22.6951C20.4071 22.2208 22.194 20.489 22.6718 16.85C22.7844 15.9923 22.8032 8.286 22.6949 7.35858C22.259 3.62424 20.5432 1.85262 16.8516 1.32522C16.27 1.24212 8.78682 1.1931 7.81608 1.266ZM16.7344 2.87064C19.7553 3.32556 20.9564 4.72464 21.1912 8.06172C21.2738 9.23526 21.2234 16.1009 21.1276 16.7409C20.903 18.2404 20.4758 19.1987 19.7166 19.906C19.4416 20.1622 18.7567 20.6203 18.7294 20.5662C18.726 20.5594 18.6842 20.4379 18.6364 20.2961C18.0955 18.6895 16.4693 17.2225 14.534 16.5952C10.8034 15.386 6.47394 17.1221 5.37366 20.2686L5.2668 20.5742L5.0358 20.4524C3.83358 19.819 3.14676 18.6335 2.87082 16.7153C2.76384 15.9718 2.74632 8.21106 2.84994 7.45236C3.2748 4.34022 4.62426 3.08898 7.8516 2.81472C8.73888 2.73936 16.1733 2.7861 16.7344 2.87064ZM13.1285 17.839C15.0369 18.1594 16.6527 19.3648 17.1811 20.8624L17.2427 21.037L17.0941 21.0633C16.3127 21.2013 16.0049 21.2102 11.9766 21.2102C7.96152 21.2102 6.90978 21.1746 6.76914 21.034C6.72936 20.9942 7.03224 20.3591 7.24512 20.0359C8.35134 18.3562 10.7883 17.4462 13.1285 17.839Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2801 6.28034C7.58459 6.95096 6.35573 11.6535 9.26939 13.9748C12.1379 16.2601 16.3267 14.254 16.3289 10.5937C16.3306 7.89074 13.8911 5.80652 11.2801 6.28034ZM12.6506 7.8533C14.7565 8.34968 15.504 11.005 13.9745 12.5559C12.412 14.1403 9.71609 13.327 9.25697 11.1328C8.84417 9.1598 10.6775 7.38818 12.6506 7.8533Z"
      fill="currentColor"
    />
  </svg>
</template>
