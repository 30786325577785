<template>
  <hr class="sample__divider" />
</template>

<script>
export default {}
</script>

<style scoped>
.sample__divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-seashell);
  border: none;
}

.profile-divider.sample__divider {
  background-color: var(--color-white);
}
</style>
