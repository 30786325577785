<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6663 7.16683H11.333C9.71967 7.16683 8.83301 6.28016 8.83301 4.66683V3.3335C8.83301 1.72016 9.71967 0.833496 11.333 0.833496H12.6663C14.2797 0.833496 15.1663 1.72016 15.1663 3.3335V4.66683C15.1663 6.28016 14.2797 7.16683 12.6663 7.16683ZM11.333 1.8335C10.2797 1.8335 9.83301 2.28016 9.83301 3.3335V4.66683C9.83301 5.72016 10.2797 6.16683 11.333 6.16683H12.6663C13.7197 6.16683 14.1663 5.72016 14.1663 4.66683V3.3335C14.1663 2.28016 13.7197 1.8335 12.6663 1.8335H11.333Z"
      fill="currentColor"
    />
    <path
      d="M4.66634 15.1668H3.33301C1.71967 15.1668 0.833008 14.2802 0.833008 12.6668V11.3335C0.833008 9.72016 1.71967 8.8335 3.33301 8.8335H4.66634C6.27967 8.8335 7.16634 9.72016 7.16634 11.3335V12.6668C7.16634 14.2802 6.27967 15.1668 4.66634 15.1668ZM3.33301 9.8335C2.27967 9.8335 1.83301 10.2802 1.83301 11.3335V12.6668C1.83301 13.7202 2.27967 14.1668 3.33301 14.1668H4.66634C5.71967 14.1668 6.16634 13.7202 6.16634 12.6668V11.3335C6.16634 10.2802 5.71967 9.8335 4.66634 9.8335H3.33301Z"
      fill="currentColor"
    />
    <path
      d="M3.99967 7.16683C2.25301 7.16683 0.833008 5.74683 0.833008 4.00016C0.833008 2.2535 2.25301 0.833496 3.99967 0.833496C5.74634 0.833496 7.16634 2.2535 7.16634 4.00016C7.16634 5.74683 5.74634 7.16683 3.99967 7.16683ZM3.99967 1.8335C2.80634 1.8335 1.83301 2.80683 1.83301 4.00016C1.83301 5.1935 2.80634 6.16683 3.99967 6.16683C5.19301 6.16683 6.16634 5.1935 6.16634 4.00016C6.16634 2.80683 5.19301 1.8335 3.99967 1.8335Z"
      fill="currentColor"
    />
    <path
      d="M11.9997 15.1668C10.253 15.1668 8.83301 13.7468 8.83301 12.0002C8.83301 10.2535 10.253 8.8335 11.9997 8.8335C13.7463 8.8335 15.1663 10.2535 15.1663 12.0002C15.1663 13.7468 13.7463 15.1668 11.9997 15.1668ZM11.9997 9.8335C10.8063 9.8335 9.83301 10.8068 9.83301 12.0002C9.83301 13.1935 10.8063 14.1668 11.9997 14.1668C13.193 14.1668 14.1663 13.1935 14.1663 12.0002C14.1663 10.8068 13.193 9.8335 11.9997 9.8335Z"
      fill="currentColor"
    />
  </svg>
</template>
