<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.75 15.1875H8.25C7.9425 15.1875 7.6875 14.9325 7.6875 14.625C7.6875 14.3175 7.9425 14.0625 8.25 14.0625H15.75C16.0575 14.0625 16.3125 14.3175 16.3125 14.625C16.3125 14.9325 16.0575 15.1875 15.75 15.1875Z"
        fill="#49A399"/>
    <path
        d="M15.75 9.9375H8.25C7.9425 9.9375 7.6875 9.6825 7.6875 9.375C7.6875 9.0675 7.9425 8.8125 8.25 8.8125H15.75C16.0575 8.8125 16.3125 9.0675 16.3125 9.375C16.3125 9.6825 16.0575 9.9375 15.75 9.9375Z"
        fill="#49A399"/>
    <path
        d="M15.75 4.6875H8.25C7.9425 4.6875 7.6875 4.4325 7.6875 4.125C7.6875 3.8175 7.9425 3.5625 8.25 3.5625H15.75C16.0575 3.5625 16.3125 3.8175 16.3125 4.125C16.3125 4.4325 16.0575 4.6875 15.75 4.6875Z"
        fill="#49A399"/>
    <path
        d="M3.00008 5.43758C2.85758 5.43758 2.71508 5.38508 2.60258 5.27258L1.85258 4.52258C1.63508 4.30508 1.63508 3.94508 1.85258 3.72758C2.07008 3.51008 2.43008 3.51008 2.64758 3.72758L3.00008 4.08008L4.85258 2.22758C5.07008 2.01008 5.43008 2.01008 5.64758 2.22758C5.86508 2.44508 5.86508 2.80508 5.64758 3.02258L3.39758 5.27258C3.28508 5.38508 3.14258 5.43758 3.00008 5.43758Z"
        fill="#49A399"/>
    <path
        d="M3.00008 10.6876C2.85758 10.6876 2.71508 10.6351 2.60258 10.5226L1.85258 9.77258C1.63508 9.55508 1.63508 9.19508 1.85258 8.97758C2.07008 8.76008 2.43008 8.76008 2.64758 8.97758L3.00008 9.33008L4.85258 7.47758C5.07008 7.26008 5.43008 7.26008 5.64758 7.47758C5.86508 7.69508 5.86508 8.05508 5.64758 8.27258L3.39758 10.5226C3.28508 10.6351 3.14258 10.6876 3.00008 10.6876Z"
        fill="#49A399"/>
    <path
        d="M3.00008 15.9376C2.85758 15.9376 2.71508 15.8851 2.60258 15.7726L1.85258 15.0226C1.63508 14.8051 1.63508 14.4451 1.85258 14.2276C2.07008 14.0101 2.43008 14.0101 2.64758 14.2276L3.00008 14.5801L4.85258 12.7276C5.07008 12.5101 5.43008 12.5101 5.64758 12.7276C5.86508 12.9451 5.86508 13.3051 5.64758 13.5226L3.39758 15.7726C3.28508 15.8851 3.14258 15.9376 3.00008 15.9376Z"
        fill="#49A399"/>
  </svg>
</template>
