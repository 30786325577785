<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#7982D2" />
    <path
      d="M27 16.75C24.93 16.75 23.25 15.07 23.25 13C23.25 10.93 24.93 9.25 27 9.25C29.07 9.25 30.75 10.93 30.75 13C30.75 15.07 29.07 16.75 27 16.75ZM27 10.75C25.76 10.75 24.75 11.76 24.75 13C24.75 14.24 25.76 15.25 27 15.25C28.24 15.25 29.25 14.24 29.25 13C29.25 11.76 28.24 10.75 27 10.75Z"
      fill="white"
    />
    <path
      d="M20 21.75H15C14.59 21.75 14.25 21.41 14.25 21C14.25 20.59 14.59 20.25 15 20.25H20C20.41 20.25 20.75 20.59 20.75 21C20.75 21.41 20.41 21.75 20 21.75Z"
      fill="white"
    />
    <path
      d="M24 25.75H15C14.59 25.75 14.25 25.41 14.25 25C14.25 24.59 14.59 24.25 15 24.25H24C24.41 24.25 24.75 24.59 24.75 25C24.75 25.41 24.41 25.75 24 25.75Z"
      fill="white"
    />
    <path
      d="M23 30.75H17C11.57 30.75 9.25 28.43 9.25 23V17C9.25 11.57 11.57 9.25 17 9.25H22C22.41 9.25 22.75 9.59 22.75 10C22.75 10.41 22.41 10.75 22 10.75H17C12.39 10.75 10.75 12.39 10.75 17V23C10.75 27.61 12.39 29.25 17 29.25H23C27.61 29.25 29.25 27.61 29.25 23V18C29.25 17.59 29.59 17.25 30 17.25C30.41 17.25 30.75 17.59 30.75 18V23C30.75 28.43 28.43 30.75 23 30.75Z"
      fill="white"
    />
  </svg>
</template>
