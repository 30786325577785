<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.99972 14.9336C11.7253 14.9336 14.7455 11.8294 14.7455 8.00023C14.7455 4.17105 11.7253 1.06689 7.99972 1.06689C4.27411 1.06689 1.25391 4.17105 1.25391 8.00023C1.25391 11.8294 4.27411 14.9336 7.99972 14.9336Z" fill="url(#paint0_linear_862_4832)"/>
    <path d="M9.59961 8C9.59961 8 9.77989 8.53333 10.6663 8.53333C11.5076 8.53333 11.7329 8 11.7329 8" stroke="#303030" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.26758 8C4.26758 8 4.44786 8.53333 5.33425 8.53333C6.17556 8.53333 6.40091 8 6.40091 8" stroke="#303030" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.93359 12.2667C6.93359 12.2667 7.82756 11.0667 9.06693 12.2667" stroke="#5B0600" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9812 9.09648L12.6428 10.916C12.7782 11.307 12.5827 11.7281 12.1917 11.8634C11.8007 11.9987 11.3797 11.8032 11.2443 11.4123C11.1842 11.2469 11.1842 11.0664 11.2443 10.916L11.906 9.09648C11.921 9.08144 11.9361 9.06641 11.9661 9.06641C11.9661 9.08144 11.9812 9.08144 11.9812 9.09648Z" fill="#91B5E6"/>
    <defs>
      <linearGradient id="paint0_linear_862_4832" x1="2.1492" y1="11.4703" x2="13.999" y2="4.81379" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
    </defs>
  </svg>
</template>
