<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3.69332 13.0147C3.28666 13.0147 2.90666 12.8747 2.63332 12.6147C2.28666 12.288 2.11999 11.7947 2.17999 11.2613L2.42666 9.10133C2.47332 8.69467 2.71999 8.15467 3.00666 7.86133L8.47999 2.068C9.84666 0.621335 11.2733 0.581335 12.72 1.948C14.1667 3.31467 14.2067 4.74133 12.84 6.188L7.36666 11.9813C7.08666 12.2813 6.56666 12.5613 6.15999 12.628L4.01332 12.9947C3.89999 13.0013 3.79999 13.0147 3.69332 13.0147ZM10.62 1.94133C10.1067 1.94133 9.65999 2.26133 9.20666 2.74133L3.73332 8.54134C3.59999 8.68133 3.44666 9.01467 3.41999 9.208L3.17332 11.368C3.14666 11.588 3.19999 11.768 3.31999 11.8813C3.43999 11.9947 3.61999 12.0347 3.83999 12.0013L5.98666 11.6347C6.17999 11.6013 6.49999 11.428 6.63332 11.288L12.1067 5.49467C12.9333 4.61467 13.2333 3.80133 12.0267 2.668C11.4933 2.15467 11.0333 1.94133 10.62 1.94133Z"
      fill="currentColor"
    />
    <path
      d="M11.5609 7.29974C11.5476 7.29974 11.5276 7.29974 11.5143 7.29974C9.43427 7.09307 7.76094 5.51307 7.44094 3.4464C7.40094 3.17307 7.58761 2.91974 7.86094 2.87307C8.13427 2.83307 8.38761 3.01974 8.43427 3.29307C8.68761 4.9064 9.99427 6.1464 11.6209 6.3064C11.8943 6.33307 12.0943 6.57974 12.0676 6.85307C12.0343 7.1064 11.8143 7.29974 11.5609 7.29974Z"
      fill="currentColor"
    />
    <path
      d="M14 15.1659H2C1.72667 15.1659 1.5 14.9392 1.5 14.6659C1.5 14.3926 1.72667 14.1659 2 14.1659H14C14.2733 14.1659 14.5 14.3926 14.5 14.6659C14.5 14.9392 14.2733 15.1659 14 15.1659Z"
      fill="currentColor"
    />
  </svg>
</template>
