<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="study">
    <g id="teacher">
    <path id="Vector" d="M6.50591 9.20869C6.0455 9.20869 5.57966 9.08952 5.21674 8.8566L1.95591 6.72785C1.34924 6.33244 0.986328 5.66619 0.986328 4.94035C0.986328 4.21452 1.34924 3.54827 1.95591 3.15285L5.22216 1.02952C5.948 0.558271 7.08008 0.558271 7.8005 1.03494L11.0451 3.16369C11.6463 3.5591 12.0092 4.22535 12.0092 4.94577C12.0092 5.66619 11.6463 6.33244 11.0451 6.72785L7.8005 8.8566C7.43758 9.09494 6.97175 9.20869 6.50591 9.20869ZM6.50591 1.48994C6.19716 1.48994 5.88841 1.56035 5.66633 1.71202L2.40549 3.83535C2.02633 4.08452 1.80424 4.48535 1.80424 4.94035C1.80424 5.39535 2.02091 5.79619 2.40549 6.04535L5.66633 8.1741C6.11591 8.4666 6.90674 8.4666 7.35633 8.1741L10.6009 6.04535C10.9801 5.79619 11.1967 5.39535 11.1967 4.94035C11.1967 4.48535 10.9801 4.08452 10.6009 3.83535L7.35633 1.7066C7.12883 1.56577 6.82008 1.48994 6.50591 1.48994Z" fill="#B0B0B0"/>
    <path id="Vector_2" d="M6.50079 12.3229C6.26246 12.3229 6.01871 12.2904 5.82371 12.2254L4.09579 11.6512C3.27787 11.3804 2.63329 10.4866 2.63871 9.62538L2.64412 7.08496C2.64412 6.86288 2.82829 6.67871 3.05037 6.67871C3.27246 6.67871 3.45662 6.86288 3.45662 7.08496L3.45121 9.62538C3.45121 10.1345 3.87371 10.7195 4.35579 10.882L6.08371 11.4562C6.30037 11.5266 6.70121 11.5266 6.91787 11.4562L8.64579 10.882C9.12787 10.7195 9.55037 10.1345 9.55037 9.63079V7.11746C9.55037 6.89538 9.73454 6.71121 9.95662 6.71121C10.1787 6.71121 10.3629 6.89538 10.3629 7.11746V9.63079C10.3629 10.492 9.72371 11.3804 8.90579 11.6566L7.17787 12.2308C6.98287 12.2904 6.73912 12.3229 6.50079 12.3229Z" fill="#B0B0B0"/>
    <path id="Vector_3" d="M11.5917 8.53125C11.3696 8.53125 11.1854 8.34708 11.1854 8.125V4.875C11.1854 4.65292 11.3696 4.46875 11.5917 4.46875C11.8138 4.46875 11.9979 4.65292 11.9979 4.875V8.125C11.9979 8.34708 11.8138 8.53125 11.5917 8.53125Z" fill="#B0B0B0"/>
    </g>
    </g>
  </svg>
</template>
