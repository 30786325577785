<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#a)">
      <path fill="url(#b)" d="M6 11.2c2.795 0 5.06-2.328 5.06-5.2C11.06 3.128 8.795.8 6 .8 3.208.8.942 3.128.942 6c0 2.872 2.266 5.2 5.06 5.2Z"/>
      <path fill="#303030" d="M6.001 9.68c.663 0 1.2-.914 1.2-2.04 0-1.127-.537-2.04-1.2-2.04-.663 0-1.2.913-1.2 2.04 0 1.126.537 2.04 1.2 2.04Z"/>
      <path fill="#F06669" d="M7.016 8.734c-.876-.483-1.588-.207-2.002.064.217.53.579.881.988.881.425 0 .802-.377 1.014-.945Z"/>
      <path fill="#fff" d="M4.803 5.566c.398-.772.256-1.614-.316-1.882-.572-.267-1.358.141-1.756.913-.397.772-.255 1.614.317 1.881.572.268 1.358-.14 1.755-.912ZM8.965 6.48c.572-.268.714-1.11.317-1.882-.398-.772-1.184-1.18-1.756-.913-.572.268-.714 1.11-.316 1.882.397.771 1.183 1.18 1.755.912Z"/>
      <path fill="#414042" d="M8.439 3.183a.218.218 0 0 1-.22-.22V.22c0-.123.096-.22.22-.22.123 0 .22.097.22.22v2.743a.23.23 0 0 1-.22.22ZM9.45 2.735a.218.218 0 0 1-.22-.22V.536c0-.123.097-.22.22-.22s.22.097.22.22v1.979c0 .123-.097.22-.22.22ZM10.47 4.194a.218.218 0 0 1-.22-.22V1.02c0-.123.097-.22.22-.22s.22.097.22.22v2.954c0 .123-.097.22-.22.22Z"/>
    </g>
    <defs>
      <linearGradient id="b" x1="1.613" x2="10.5" y1="8.603" y2="3.61" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset=".033" stop-color="#ECB64D"/>
        <stop offset=".178" stop-color="#EEC04F"/>
        <stop offset=".551" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
