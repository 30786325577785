<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7658)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.65281 7.37498C4.78687 7.71382 5.17025 7.87986 5.50904 7.74586L7.94216 6.78345C8.28125 6.64927 8.44703 6.26601 8.31304 5.92722C8.17898 5.58838 7.79559 5.42235 7.45681 5.55634L5.02369 6.51875C4.93716 6.55303 4.86183 6.60348 4.79993 6.66538C4.61959 6.84572 4.55303 7.12279 4.65281 7.37498Z" fill="#4C2908"/>
      <rect x="6" y="9.5" width="3.5" height="1" rx="0.5" fill="#3E4749"/>
      <rect x="11" y="9.5" width="3.5" height="1" rx="0.5" fill="#3E4749"/>
      <path d="M19.0539 3.67836C18.9423 2.06939 17.9449 0.831661 16.3431 0.831661C16.1829 0.831661 16.0257 0.812258 15.8723 0.836736C15.7972 0.848718 15.723 0.863494 15.6499 0.881001C15.1033 0.335973 14.3565 0 13.5328 0C11.8578 0 10.5 1.38854 10.5 3.1014C10.5 4.81425 11.8578 6.20279 13.5328 6.20279C13.7713 6.20279 14.0033 6.17456 14.226 6.12133C14.2361 6.13138 14.2462 6.14147 14.2564 6.1514C14.354 7.55921 15.3704 8.70747 16.6961 8.97252C16.8854 9.01039 17.0811 8.99809 17.2813 8.99809C18.9563 8.99809 20 7.64167 20 5.92882C20 5.04274 19.6366 4.24352 19.0539 3.67836Z" fill="#00649D"/>
      <path d="M12 8.5C12 8.78674 11.7948 8.9948 11.512 8.9948C11.4529 8.9948 11.3962 9.00906 11.3433 8.99036C11.1434 8.91965 11 8.72681 11 8.5C11 8.27319 11.1434 8.08035 11.3433 8.00964C11.3961 7.99094 11.4529 8.0052 11.512 8.0052C11.7948 8.00522 12 8.21326 12 8.5Z" fill="#00649D"/>
      <path d="M14 7.24944C14 7.6783 13.6851 7.99858 13.2635 7.99858C13.1687 7.99858 13.078 8.00841 12.9943 7.97633C12.7055 7.86565 12.5 7.58193 12.5 7.24944C12.5 6.9278 12.6923 6.65183 12.9663 6.53395C13.0576 6.49464 13.158 6.50028 13.2635 6.50028C13.6851 6.50028 14 6.82059 14 7.24944Z" fill="#00649D"/>
      <path d="M12.0287 3.88737C12.0702 3.95678 12.1504 4 12.2375 4H13.7625C13.8936 4 14 3.90372 14 3.7849C14 3.66608 13.8936 3.5698 13.7625 3.5698H12.6759L13.926 1.83254C13.9736 1.76641 13.9774 1.682 13.9358 1.61263C13.8943 1.54322 13.8141 1.5 13.7269 1.5H12.2845C12.1533 1.5 12.0469 1.59628 12.0469 1.7151C12.0469 1.83392 12.1533 1.9302 12.2845 1.9302H13.2887L12.0385 3.66746C11.9909 3.73356 11.9872 3.818 12.0287 3.88737Z" fill="white"/>
      <path d="M15.5215 4.9099C15.5527 4.96542 15.6128 5 15.6782 5H16.8218C16.9202 5 17 4.92298 17 4.82792C17 4.73286 16.9202 4.65584 16.8218 4.65584H16.0069L16.9445 3.26603C16.9801 3.21313 16.983 3.1456 16.9518 3.0901C16.9207 3.03458 16.8606 3 16.7952 3H15.7133C15.6149 3 15.5352 3.07702 15.5352 3.17208C15.5352 3.26714 15.6149 3.34416 15.7133 3.34416H16.4665L15.5289 4.73397C15.4932 4.78687 15.4904 4.8544 15.5215 4.9099Z" fill="white"/>
      <path d="M17.5144 6.93242C17.5351 6.97407 17.5752 7 17.6188 7H18.3812C18.4468 7 18.5 6.94223 18.5 6.87094C18.5 6.79966 18.4468 6.74188 18.3812 6.74188H17.8379L18.463 5.69953C18.4868 5.65984 18.4887 5.6092 18.4679 5.56758C18.4471 5.52593 18.4071 5.5 18.3635 5.5H17.6422C17.5766 5.5 17.5234 5.55777 17.5234 5.62906C17.5234 5.70035 17.5766 5.75812 17.6422 5.75812H18.1443L17.5193 6.80047C17.4955 6.84016 17.4936 6.8908 17.5144 6.93242Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7658">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
