<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7868)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 7.37303C11.8337 7.71187 12.2171 7.8779 12.5559 7.74391L14.989 6.78149C15.3281 6.64731 15.4939 6.26405 15.3599 5.92527C15.2259 5.58643 14.8425 5.42039 14.5037 5.55439L12.0706 6.5168C11.984 6.55108 11.9087 6.60153 11.8468 6.66343C11.6665 6.84376 11.5999 7.12084 11.6997 7.37303Z" fill="#4C2908"/>
      <path d="M4.65281 5.9258C4.78687 5.58696 5.17025 5.42093 5.50904 5.55492L7.94216 6.51733C8.28125 6.65152 8.44703 7.03478 8.31304 7.37356C8.17898 7.7124 7.79559 7.87843 7.45681 7.74444L5.02369 6.78203C4.93716 6.74775 4.86183 6.6973 4.79993 6.6354C4.61959 6.45506 4.55303 6.17799 4.65281 5.9258Z" fill="#4C2908"/>
      <path d="M9.99922 16.2981C8.45531 16.2981 7.19922 15.0319 7.19922 13.4755C7.19922 13.3175 7.32629 13.1895 7.483 13.1895H12.5154C12.6722 13.1895 12.7992 13.3175 12.7992 13.4755C12.7992 15.0319 11.5431 16.2981 9.99922 16.2981Z" fill="#2F2F2F"/>
      <path d="M9.99922 14.2036C8.45531 14.2036 7.19922 14.0015 7.19922 13.4937C7.19922 13.2909 7.2925 13.1895 7.44922 13.1895H12.5492C12.7059 13.1895 12.7992 13.3079 12.7992 13.4937C12.7992 14.0015 11.5431 14.2036 9.99922 14.2036Z" fill="white"/>
      <path d="M5.53804 11.8016C5.45086 11.6226 5.51856 11.4034 5.68969 11.3123L7.38747 10.4059L6.0426 9.68767C5.87148 9.5965 5.80377 9.37731 5.89095 9.19836C5.97814 9.01941 6.18775 8.94861 6.35888 9.03978L8.31033 10.0819C8.42673 10.144 8.5 10.2691 8.5 10.4059C8.5 10.5426 8.42673 10.6678 8.31033 10.7298L6.00596 11.9601C5.83299 12.0523 5.6243 11.9786 5.53804 11.8016Z" fill="#3E4347"/>
      <path d="M13.4943 11.9603L11.1897 10.7299C11.0733 10.6678 11 10.5427 11 10.4059C11 10.2692 11.0733 10.1441 11.1897 10.082L13.1413 9.03978C13.3129 8.94861 13.5221 9.01941 13.6093 9.19837C13.6965 9.37732 13.6288 9.59652 13.4576 9.6877L12.1126 10.4059L13.8101 11.3123C13.9812 11.4035 14.0494 11.6227 13.9618 11.8017C13.8741 11.9816 13.6645 12.0509 13.4943 11.9603Z" fill="#3E4347"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7868">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
