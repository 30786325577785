<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2643 7.33653C16.1778 7.30843 16.0196 7.29724 15.9129 7.3116L15.7187 7.33772L13.8651 9.17414L12.0116 11.0106L10.2059 9.20487C8.19591 7.19493 8.27454 7.25907 7.88514 7.3115C7.60627 7.34901 7.3464 7.60888 7.30889 7.88775C7.25646 8.27715 7.19226 8.19846 9.20298 10.2092L11.0094 12.0156L9.20298 13.822C7.19226 15.8328 7.25646 15.7541 7.3089 16.1435C7.3464 16.4223 7.60627 16.6822 7.88514 16.7197C8.27454 16.7722 8.19585 16.8364 10.2066 14.8256L12.013 13.0192L13.8194 14.8256C15.8302 16.8364 15.7515 16.7722 16.1409 16.7197C16.4197 16.6822 16.6796 16.4223 16.7171 16.1435C16.7695 15.7541 16.8337 15.8328 14.823 13.822L13.0166 12.0156L14.823 10.2092C16.8295 8.20266 16.7694 8.27611 16.7181 7.89494C16.6857 7.65423 16.486 7.40851 16.2643 7.33653Z"
      fill="#B0B0B0"
    />
  </svg>
</template>
