<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7776)">
      <path
        d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z"
        fill="#4D2908"/>
      <path
        d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z"
        fill="#4D2908"/>
      <path
        d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z"
        fill="url(#paint0_linear_3220_7776)"/>
      <path
        d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z"
        fill="#4D2908"/>
      <path
        d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z"
        fill="#009D94"/>
      <path
        d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z"
        fill="#00819D"/>
      <path
        d="M8.27299 8.33787C8.13893 8.67671 7.75554 8.84275 7.41676 8.70875L4.98363 7.74634C4.64453 7.61216 4.47875 7.2289 4.61275 6.89011C4.7468 6.55127 5.13019 6.38524 5.46897 6.51923L7.9021 7.48164C7.98863 7.51592 8.06397 7.56637 8.12586 7.62827C8.3062 7.80861 8.37277 8.08568 8.27299 8.33787Z"
        fill="#4C2908"/>
      <path
        d="M15.3199 6.88869C15.1858 6.54985 14.8024 6.38382 14.4636 6.51781L12.0305 7.48022C11.6914 7.61441 11.5256 7.99767 11.6596 8.33645C11.7937 8.67529 12.1771 8.84132 12.5158 8.70733L14.949 7.74492C15.0355 7.71064 15.1108 7.66019 15.1727 7.59829C15.3531 7.41795 15.4196 7.14088 15.3199 6.88869Z"
        fill="#4C2908"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_3220_7776" x1="9.97432" y1="7.54492" x2="9.97432" y2="16.946"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.229167" stop-color="#E24B4B"/>
        <stop offset="0.536458" stop-color="#FA5F5F"/>
        <stop offset="1" stop-color="#FACCB4"/>
      </linearGradient>
      <clipPath id="clip0_3220_7776">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
