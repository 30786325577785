export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru"])},
  "meta_title": {
    "preview_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр статьи"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "login": {
      "by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход по Email"])},
      "by_phone_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход по телефону - Шаг 1"])},
      "confirm_phone_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход по телефону - Шаг 2"])}
    },
    "register": {
      "add_email_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация - Добавление Email"])},
      "confirm_email_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация - Подтверждение Email"])},
      "create_password_step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация - Создание пароля"])},
      "add_info_step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация - Добавление информации"])},
      "add_phone_step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация - Добавление телефона"])},
      "category_interests_step6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация - Выбор категорий интересов"])}
    },
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыл пароль"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])},
    "marriage_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брачное агенство"])},
    "my_albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои альбомы"])},
    "messenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мессенджер"])},
    "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои альбомы"])},
    "my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои группы"])},
    "my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя страница"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненное"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
    "search": {
      "all-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все рекомендации"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["группы"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хештеги"])}
    },
    "show_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "placeholders": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш email..."])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш пароль..."])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите номер телефона..."])}
    },
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "with_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти по номеру телефона"])},
    "with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти по email"])},
    "code_from_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код из SMS"])},
    "interests_category": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний шаг!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категории интересов, чтобы мы могли показывать контент, который вас заинтересует"])}
    },
    "messages": {
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы отправим вам сообщение для изменения пароля"])},
      "verify_with_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение с кодом выслали вам на указанный номер телефона"])},
      "didnt_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не получили код?"])}
    },
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "empty_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный email"])},
      "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный пароль"])},
      "incorrect_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код"])}
    }
  },
  "links": {
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ссылку"])},
    "link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка успешно скопирована"])},
    "link_copied_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так при копировании ссылки "])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить повторно"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустить"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
    "my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя страница"])},
    "messenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мессенджер"])},
    "my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои группы"])},
    "my_albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои альбомы"])},
    "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои аудио"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненное"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])},
    "marriage_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брачное агенство"])},
    "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все группы"])},
    "all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все люди"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать далее"])},
    "search": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всё"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хештеги"])}
    },
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "placeholders": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваше имя..."])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу фамилию..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш email..."])},
      "code_from_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код из почтового сообщения..."])},
      "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш пароль для регистрации..."])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пол"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужчина"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Женщина"])}
      }
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть аккаунт?"])},
    "messages": {
      "agreement_to_creating_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавая аккаунт, вы соглашаетесь с нашими "])},
      "verify_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение с кодом выслали вам на указанный почтовый адрес"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте пароль для входа в систему"])},
      "fill_your_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните информацию о себе для входа в UmmaLife"])},
      "required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Обязательные поля для заполнения)"])},
      "enter_your_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите свой номер телефона"])},
      "can_login_with_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы сможете заходить в сеть при помощи вашего номера телефона"])}
    },
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный email"])},
      "incorrect_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код"])},
      "empty_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "empty_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "empty_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "empty_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "incorrect_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный номер телефона"])}
    },
    "category_interests": {
      "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Религия"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Семья"])},
      "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здоровье"])},
      "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фитнес"])},
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Книги"])},
      "traveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествия"])},
      "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто"])},
      "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наука"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Природа"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еда"])},
      "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мода"])},
      "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архитектура"])},
      "beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Красота"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
      "studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учеба"])},
      "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искусство"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спорт"])},
      "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позитив"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
      "psychology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Психология"])},
      "upbringing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воспитание"])}
    }
  },
  "forgot_password": {
    "validation": {
      "empty_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не должно быть пустым"])},
      "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный email"])}
    }
  },
  "reset_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
    "succeed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронное письмо было успешно отправлено."])}
  },
  "buttons": {
    "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
    "show_original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать оригинал"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
    "get_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить код на почту"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
    "create_umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать Umma Video"])},
    "toggle_theme": {
      "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темный"])},
      "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Светлый"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вступить"])},
    "in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В группе"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слежу"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравится"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментировать"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ссылку"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "open_in_new_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть в новой вкладке"])},
    "complain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожаловаться"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
    "open_like_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить как сообщение"])},
    "share_on_my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться на моей странице"])},
    "share_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться в группе"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить"])},
    "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравится"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать"])},
    "only_sisters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только сёстрам"])},
    "available_everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно всем"])},
    "who_am_i_following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На кого я подписан"])},
    "who_is_following_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто подписан на меня"])},
    "anonymously": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимно"])},
    "create_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать опрос"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
    "add_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Добавить ответ"])},
    "mood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроение"])},
    "traveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествую"])},
    "watching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотрю"])},
    "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играю"])},
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слушаю"])},
    "emotions": {
      "happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счастливый"])},
      "in_love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влюблен"])},
      "outraged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возмущен"])},
      "celebrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праздную"])},
      "giggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хихикаю"])},
      "crazy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С ума сошел"])},
      "falling_asleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Засыпаю"])},
      "shocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В шоке"])},
      "excited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В восторге"])},
      "angry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Злой"])},
      "masked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В маске"])},
      "think": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Думаю"])},
      "laughing_out_loud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ржунимагу"])},
      "wonder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удивляюсь"])},
      "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болею"])},
      "embarrassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смущаюсь"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорирую"])},
      "frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замерз"])}
    },
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать"])},
    "pinned_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепленное сообщение"])},
    "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
    "image_upload": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите или перетащите изображение"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые расширения jpeg, jpg, png"])}
    },
    "back_to_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к списку статей"])},
    "article_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр статьи"])},
    "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как черновик"])},
    "read_more_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать далее..."])},
    "read_less_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить..."])},
    "only_subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только подписчикам"])}
  },
  "languages": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "name": {
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
      "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اَلْعَرَبِيَّةُ"])},
      "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türk"])},
      "uzbek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'zbek"])},
      "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa Indonesia"])},
      "malay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayu"])},
      "tajik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тоҷик"])},
      "tabasaran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табасаран"])},
      "chuvash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чӑвашла"])},
      "urdu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اردو"])},
      "ingush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["гӏалгӏай"])},
      "tatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Татар"])},
      "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лакский"])},
      "qumuq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Къумукъ"])},
      "balkarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tаулу"])},
      "amharic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["አማርኛ"])},
      "lezgin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лезги"])}
    }
  },
  "placeholders": {
    "search_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "search_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск статьи"])},
    "publications_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напиши благое или молчи..."])},
    "comment_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш комментарий..."])},
    "question_for_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос или тема для опроса..."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение..."])},
    "article_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок статьи"])}
  },
  "tabs": {
    "profile_tabs": {
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
      "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaVideo"])}
    },
    "my_page": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикации"])},
      "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaShorts"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaVideo"])},
      "alboms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альбомы"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненное"])}
    },
    "news_page": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикации"])},
      "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaShorts"])},
      "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UmmaVideo"])}
    },
    "publications_inside": {
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикации"])},
      "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])}
    },
    "stickers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стикеры"])}
    },
    "search": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди"])},
      "communities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщества"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео"])},
      "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хештеги"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "age": {
        "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["год"])},
        "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лет"])}
      }
    },
    "articles_page": {
      "all_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все статьи"])},
      "my_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои статьи"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненные"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновики"])},
      "create_new_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую статью"])}
    }
  },
  "video": {
    "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["реакций"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["просмотра"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["читать далее"])},
    "search_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск видео"])},
    "related_movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похожие фильмы"])},
    "video_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница видео"])},
    "tabs": {
      "all_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все фильмы"])},
      "new_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новинки"])},
      "recommended_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендации"])},
      "saved_films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненное"])},
      "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотренные"])}
    },
    "parameters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры поиска"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
      "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жанр"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создатель"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность:"])},
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минут"])},
      "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество просмотров"])}
    }
  },
  "audio": {
    "tabs": {
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
      "my_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои аудио"])},
      "playlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плейлисты"])},
      "liked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравится"])},
      "download_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить новые аудио"])}
    },
    "search_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск аудио"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])},
    "my_playlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои плейлисты"])},
    "all_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все аудио"])},
    "add_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить аудио"])},
    "all_audios_for_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все аудио для загрузки"])},
    "create_album": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать альбом"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файла"])},
    "drop_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите или перетащите аудио"])},
    "drop_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые расширения mp3"])},
    "status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка завершена"])},
    "status_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание"])},
    "status_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки"])},
    "album_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для создания альбома необходимо заполнить поля ниже"])},
    "album_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить название альбома"])},
    "album_textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить описание альбома"])},
    "download_to_playlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить в плейлист"])},
    "create_as_album": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать как альбом"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
    "upload_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить аудио"])}
  },
  "sections_title": {
    "last_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние действия"])},
    "recommended_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемые группы"])},
    "recommended_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемые люди"])},
    "create_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте опрос"])}
  },
  "labels": {
    "subscribers": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подписчиков"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подписчик"])}
    },
    "members": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["участников"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["участник"])}
    },
    "comments": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментария"])}
    },
    "reposts": {
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["репостов"])},
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["репост"])}
    },
    "time": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["год"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неделя"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["день"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["час"])},
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минут"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["секунд"])}
    },
    "poll": {
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос"])},
      "answer_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты ответов"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ №"])},
      "only_eight_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить до 8 вариантов ответа"])},
      "quiz_options_valid_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты в Викторине могут иметь только один верный ответ."])},
      "anonymous_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимный опрос"])},
      "multiple_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор несколько"])},
      "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Викторина"])}
    },
    "feeling": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вы чувствуете или чем вы заняты?"])},
      "mood": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроение"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что чувствуйте?"])}
      },
      "traveling": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествие"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куда едете?"])}
      },
      "watching": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотрю"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что смотрите?"])}
      },
      "playing": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играю"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во что играете?"])}
      },
      "listening": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слушаю"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что слушаете?"])}
      }
    },
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои аудио"])},
    "stickers": {
      "popular_stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные стикерпаки"])},
      "featured_stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранные стикеры"])}
    },
    "search": {
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавнее"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди"])},
      "communities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщества"])},
      "muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUVI"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
      "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть все"])},
      "show_all_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все рекомендации"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширенный поиск"])}
    },
    "choose_article_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию статьи"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
    "article_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обложка статьи"])},
    "articles": {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "popular_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные статьи"])},
      "create_article": {
        "press_to_select_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите “+” для выбора инструмента"])}
      },
      "editor": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
        "photo_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото или видео"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сылка"])},
        "inset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Врезка"])},
        "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цитата"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список"])},
        "divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделитель"])},
        "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудио"])},
        "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите сообщение..."])},
        "poll": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок опроса"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вариант ответа"])}
        }
      }
    }
  },
  "dropdown": {
    "reply_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть ответы"])},
    "download_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить комментарии"])},
    "delete_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить диалог"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
    "copy_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать текст"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
    "first_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначало новые"])},
    "first_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначало старые"])}
  },
  "profile": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать профиль"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День рождения"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна проживания"])},
    "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебное заведение"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочий сайт"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обо мне"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписки"])},
    "subscribes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписчики"])},
    "umma_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umma Video"])},
    "umma_shorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umma Shorts"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписчики"])},
    "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посты"])},
    "wallet": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой кошелёк"])}
    }
  },
  "chat": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите ваше сообщение"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "empty_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите, кому хотелы бы написать"])},
    "typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["печатает..."])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["онлайн"])}
  },
  "modal": {
    "delete_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить чат"])},
    "sure_delete_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверенны, что хотите удалить чат?"])},
    "delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить сообщение"])},
    "sure_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверенны, что хотите удалить сообщение?"])},
    "also_delete_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так же удалить и у"])},
    "cancel_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить действие"])},
    "clear_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить историю"])},
    "link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка скопирована"])}
  },
  "reaction_tooltip": {
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ма шаа Аллах!"])},
    "dislike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возмущен"])},
    "love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Супер!"])},
    "fire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Огонь!"])},
    "angry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сердитый"])},
    "scared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удивлён"])},
    "laugh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ХаХа"])},
    "think": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задумался"])},
    "sad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грущу"])}
  },
  "settings": {
    "nav": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные настройки"])},
      "privet_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные данные"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образование"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
      "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "control_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление сеансами"])},
      "restore_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты для восстановления"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений"])},
      "black_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черный список"])},
      "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аккаунт"])}
    },
    "main": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные настройки"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе \"Основные настройки\" вы можете настроить основные параметры вашего профиля. Обновите свой логин, телефон, адрес электронной почты, дату рождения и страну проживания, чтобы ваш профиль на Umma Life был актуальным и полноценным."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
      "show_in_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отобразить в профиле"])},
      "show_in_profile_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображать логин в профиле вместо имени и фамилии"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
      "phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронный адрес"])},
      "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронный адрес"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес"])},
      "categories_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории интересов"])},
      "categories": {
        "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Религия"])},
        "psychology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Психология"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Семья"])},
        "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здоровье"])},
        "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
        "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учеба"])},
        "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествия"])},
        "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Книги"])},
        "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наука"])},
        "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спорт"])},
        "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искусство"])},
        "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто"])},
        "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еда"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позитив"])},
        "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фитнес"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
        "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мода"])},
        "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архитектура"])},
        "beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Красота"])},
        "parenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воспитание"])},
        "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Природа"])}
      }
    },
    "privet_data": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные данные"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе \"Личная информация\" вы можете указать дополнительные данные о себе, чтобы другие пользователи могли лучше узнать вас. Заполните поля ФИО, расскажите немного о себе и укажите ваше место проживания. Это поможет создать более личное и индивидуальное представление о вас в сообществе Umma Life."])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
      "about_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обо мне"])},
      "about_me_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расскажите о себе..."])},
      "marital_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Семейное положение"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Женат"])},
      "not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выбрано"])}
    },
    "education": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образование"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе \"Образование и работа\" вы можете указать информацию о вашем образовании и занимаемой должности. Это поможет другим пользователям лучше понять вашу академическую и профессиональную сферу интересов."])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закончил"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Школа"])},
      "school_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название вашей школы"])},
      "school_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить школу"])},
      "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колледж"])},
      "college_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название вашего колледжа"])},
      "college_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить колледж"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Университет"])},
      "university_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название вашего университета"])},
      "university_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить университет"])}
    },
    "work": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе \"Работа\" вы можете указать информацию об организации, в которой вы работаете и занимаемой должности. Это поможет другим пользователям лучше понять вашу профессиональную сферу интересов."])},
      "work_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место работы"])},
      "work_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите ваше место работы"])},
      "work_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-сайт компании"])},
      "self_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личная страница"])}
    },
    "privacy": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе \"Конфиденциальность\" вы можете установить настройки приватности для вашего профиля. Здесь вы можете ограничить действия других пользователей с вашим профилем, настроить уведомления, ограничить доступ к определенной информации и так далее. Настройте эти параметры в соответствии с вашими предпочтениями и желаемым уровнем конфиденциальности."])},
      "top_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете выбрать информацию, которую хотите показывать у себя на странице, а так же запрещать или ограничивать доступ к своей странице"])},
      "available_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно всем"])},
      "only_friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только друзья"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может подписаться на меня?"])},
      "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может отправлять мне сообщения?"])},
      "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может видеть моих друзей?"])},
      "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может оставлять записи на моей стене?"])},
      "question5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может видеть мой день рождения?"])},
      "question6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимать запрос на подписку?"])},
      "question7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может добавлять меня в группу?"])},
      "question8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлять пользователям уведомления, когда я посещаю их профили?"])},
      "question8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если отключить это уведомление, вы не будете получать уведомления о том кто посещал ваш профиль."])},
      "question9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать ваши действия?"])},
      "question10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать ваше местоположение другим?"])},
      "question10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если отключить это уведомление, вы не будете получать уведомления о том кто посещал ваш профиль."])},
      "question11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранение и копирование материалов"])},
      "question11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запретить копирование"])},
      "question11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете выбрать информацию, которую хотите показывать у себя на странице, а так же запрещать или ограничивать доступ к своей странице"])}
    },
    "password": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль - важный элемент безопасности вашей учетной записи. Он обеспечивает защиту от несанкционированного доступа и сохраняет конфиденциальность ваших личных данных. Правильно выбранный пароль помогает предотвратить мошенничество и защищает вашу репутацию в онлайн-среде. Поэтому не забывайте создавать и использовать надежные пароли для своих аккаунтов и следить за их безопасностью."])},
      "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])},
      "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш пароль"])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
      "repeat_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите новый пароль"])},
      "auth2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двухфакторная аутентификация"])},
      "auth2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для двухфакторной аутентификации необходимо ввести свой номер телефона в ваших настройках, что бы получать код проверки для вашего аккаунта UmmaLife"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включена"])},
      "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключена"])}
    },
    "session": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление сеансами"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление сеансами - важная функция, позволяющая вам контролировать активные сеансы вашей учетной записи. Это дает вам дополнительную возможность обеспечить безопасность и защитить свою учетную запись от несанкционированного доступа. Вы можете просмотреть активные сеансы и завершить их при необходимости. Таким образом, вы получаете больший контроль над своим аккаунтом и можете быть уверены в его безопасности."])},
      "current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая сессия"])},
      "recent_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавние сессии"])},
      "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["онлайн"])},
      "hour_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 час назад"])},
      "ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP адрес"])},
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
      "auto_quit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматический выход из сессии"])},
      "if_not_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если неактивен "])},
      "six_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 месяцев"])},
      "complete_all_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершить остальные сеансы"])}
    },
    "restore": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты для восстановления"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы забудете пароль, мы поможем восстановить доступ к вашей почте в течении нескольких минут: на указанный здесь мобильный телефон или резервный email будет отправлен код восстановления. Проверьте свои контакты и при необходимости замените их на новые."])},
      "mobile_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобильный телефон"])},
      "mobile_phone_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить ещё один мобильный телефон"])},
      "reserve_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервный e-mail"])},
      "reserve_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите e-mail адрес"])},
      "reserve_email_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить ещё один резервный e-mail"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменить"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
    },
    "notifications": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений позволяют вам настроить информацию и оповещения, которые вы получаете от нашего сайта."])},
      "system_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системные уведомления"])},
      "email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail уведомления"])},
      "like_my_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравятся мои заметки"])},
      "comment_my_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прокомментировали мои заметки"])},
      "share_my_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделились моими заметками"])},
      "subscribed_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаны на меня"])},
      "like_my_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравится моя страница"])},
      "mentioned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упомянули меня"])},
      "join_my_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вступили в мою группу"])},
      "accept_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приняли запрос на подписку"])},
      "post_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикация на стене профиля"])}
    },
    "black_list": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черный список"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черный список - это функция, которая позволяет вам контролировать и ограничивать взаимодействие с определенными пользователями на нашем сайте. Вы можете добавлять пользователей в черный список, чтобы предотвратить получение сообщений, комментариев или упоминаний от них."])}
    },
    "delete_account": {
      "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить акаунт"])},
      "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы всегда имеете возможность удалить свою учетную запись на нашем сайте. Мы ценим ваше право на контроль над вашими личными данными и уважаем ваш выбор."])},
      "delete_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление аккаунта"])},
      "delete_account_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы не заходите в свой аккаунт за это время, то ваш аккаунт будет автоматически удален из системы навсегда"])},
      "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль для удаления аккаунта"])},
      "enter_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш текущий пароль"])}
    },
    "account": {
      "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки аккаунта"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
      "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность"])},
      "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дисплей"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "popup_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всплывающее уведомление"])},
      "show_popup_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать уведомления из приложения"])},
      "help_and_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка и помощь"])},
      "to_system_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К настройкам системы"])},
      "report_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить о проблеме"])},
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
      "profile_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки профиля"])},
      "profile_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личная информация"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
      "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP адрес"])},
      "finish_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершить сессию"])}
    }
  },
  "themes": {
    "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системная"])},
    "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Светлая"])},
    "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темная"])}
  },
  "library": {
    "all_books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все книги"])},
    "read_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читаю сейчас"])},
    "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки"])},
    "liked_books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравится"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор:"])},
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по названию книги"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
    "back_to_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к списку книг"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество просмотров"])},
    "share_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться в"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["читать далее"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировка"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать"])},
    "popular_books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные книги"])},
    "book_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница книги"])}
  },
  "notification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметить всё прочитанным"])},
    "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На этой неделе"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом месяце"])}
  },
  "muvi": {
    "tabs": {
      "feed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed"])},
      "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
      "add_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Добавить MUVI"])}
    },
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подписчики"])},
    "my_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой Muvi"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненный"])},
    "liked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понравившиеся MUVI"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотренные"])},
    "other_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие MUVI"])},
    "view_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть ответы"])},
    "hide_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть ответы"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нравится"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить"])},
    "no_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите, пожалуйста, видео"])},
    "hide_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть MUVI"])},
    "block_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокировать пользователя"])},
    "comments_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии отключены"])}
  },
  "time": {
    "day_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["день назад"])},
    "days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней назад"])},
    "hour_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["час назад"])},
    "hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["часов назад"])},
    "minute_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минуту назад"])},
    "minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минуты назад"])},
    "just_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только сейчас"])}
  },
  "report_comment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо, что дали нам знать!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все сообщения анализируются и рецензируются модераторами нашего сервиса и удаляются в случае нарушения правил Umma Life!"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
  },
  "add_muvi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить MUVI"])},
    "file_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите видео для загрузки или перетащите файл"])},
    "file_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые расширения: mp4"])},
    "file_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение 1280*720"])},
    "file_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не более 5 минут"])},
    "file_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менее 50 мб"])},
    "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
    "errors": {
      "size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер файла превышает максимальное ограничение в 50 мб"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность видео превышает максимальное ограничение в 5 минут"])},
      "invalid_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный тип файла. Пожалуйста, выберите правильный видеофайл"])},
      "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при загрузке метаданных видео"])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео успешно добавлено"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
    "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5000"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обложка"])},
    "change_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить обложку"])},
    "add_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить обложку"])},
    "allow_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить комментарии"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать"])},
    "tag_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметить людей"])},
    "add_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить местоположение"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "current_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущее местоположение"])},
    "can_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто может просматривать этот MUVI"])},
    "change_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить видимость MUVI"])},
    "delete_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить MUVI"])},
    "edit_muvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить описание"])},
    "disable_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить комментарии"])},
    "playback_speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорость воспроизведения"])},
    "views_options": {
      "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно для всех"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В Umma Life и за ее пределами"])},
      "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только для подписчиков"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши подписчики в Umma Life"])},
      "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только подписки"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши подписки в Umma Life"])}
    },
    "choose_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию"])}
  },
  "muvi_mobile": {
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписки"])},
    "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендации"])},
    "no_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментариев нет"])},
    "no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи не найдены"])}
  },
  "date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])}
  }
}