<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_253_18834)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.27641 0.0302695C1.96023 0.240747 0.522007 1.49493 0.134241 3.64238C-0.0449473 4.63477 -0.04468 9.28027 0.134642 10.3359C0.542179 12.7352 1.78318 13.656 4.7344 13.7488C5.18148 13.7628 5.49319 13.7871 5.52146 13.81C5.54725 13.8308 5.85272 14.2278 6.20028 14.6921C7.06264 15.8444 7.2783 16 8.01229 16C8.74627 16 8.96193 15.8444 9.8243 14.6921C10.5955 13.6619 10.4323 13.7763 11.1685 13.7491C14.0546 13.6425 15.5984 12.3494 15.9495 9.74479C16.0168 9.24497 16.0168 4.5073 15.9495 4.00748C15.6427 1.73135 14.4241 0.469383 12.1504 0.0732195C11.7731 0.00743703 4.93759 -0.0298159 4.27641 0.0302695ZM12.0478 1.22187C13.663 1.53045 14.4849 2.35669 14.784 3.97272C14.882 4.50263 14.882 9.24964 14.784 9.77955C14.423 11.7301 13.3658 12.5163 10.976 12.6112C9.90864 12.6536 9.9195 12.6469 9.04859 13.8055C7.98499 15.2204 8.03905 15.2188 7.02194 13.8659C6.08998 12.6261 6.12302 12.6457 4.89867 12.608C2.26014 12.5267 1.50109 11.968 1.22104 9.90128C1.14191 9.31734 1.15807 4.41864 1.24059 3.97272C1.56792 2.20398 2.57292 1.33212 4.4812 1.16143C5.0576 1.10989 11.741 1.16326 12.0478 1.22187ZM4.72474 6.55955C4.20405 6.80052 4.13917 7.56449 4.61279 7.87778C4.89742 8.06601 5.38017 8.01567 5.59908 7.77488C6.14889 7.17015 5.46362 6.21764 4.72474 6.55955ZM7.735 6.53053C6.96218 6.85517 7.17744 7.98883 8.01193 7.98883C8.98883 7.98883 9.03002 6.54291 8.05428 6.50379C7.92594 6.49862 7.78229 6.51064 7.735 6.53053ZM10.6322 6.58172C10.1859 6.80403 10.0879 7.4708 10.4528 7.80221C10.8191 8.13486 11.4289 8.03222 11.6435 7.60183C11.9809 6.92496 11.3074 6.24546 10.6322 6.58172Z"
        fill="#9D9D9D"
      />
    </g>
    <defs>
      <clipPath id="clip0_253_18834">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
