<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.3749 13.6875H11.2424C10.9349 13.6875 10.6799 13.4325 10.6799 13.125C10.6799 12.8175 10.9349 12.5625 11.2424 12.5625H12.3749C14.3399 12.5625 15.9374 10.965 15.9374 9C15.9374 7.035 14.3399 5.4375 12.3749 5.4375H11.2499C10.9424 5.4375 10.6874 5.1825 10.6874 4.875C10.6874 4.5675 10.9349 4.3125 11.2499 4.3125H12.3749C14.9624 4.3125 17.0624 6.4125 17.0624 9C17.0624 11.5875 14.9624 13.6875 12.3749 13.6875Z"
        fill="#49A399"/>
    <path
        d="M6.75 13.6875H5.625C3.0375 13.6875 0.9375 11.5875 0.9375 9C0.9375 6.4125 3.0375 4.3125 5.625 4.3125H6.75C7.0575 4.3125 7.3125 4.5675 7.3125 4.875C7.3125 5.1825 7.0575 5.4375 6.75 5.4375H5.625C3.66 5.4375 2.0625 7.035 2.0625 9C2.0625 10.965 3.66 12.5625 5.625 12.5625H6.75C7.0575 12.5625 7.3125 12.8175 7.3125 13.125C7.3125 13.4325 7.0575 13.6875 6.75 13.6875Z"
        fill="#49A399"/>
    <path
        d="M12 9.5625H6C5.6925 9.5625 5.4375 9.3075 5.4375 9C5.4375 8.6925 5.6925 8.4375 6 8.4375H12C12.3075 8.4375 12.5625 8.6925 12.5625 9C12.5625 9.3075 12.3075 9.5625 12 9.5625Z"
        fill="#49A399"/>
  </svg>
</template>
