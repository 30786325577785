<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_927_21807)">
      <path
        d="M10 19.375C8.1458 19.375 6.33324 18.8252 4.79153 17.795C3.24982 16.7649 2.04821 15.3007 1.33863 13.5877C0.629062 11.8746 0.443406 9.9896 0.805142 8.17103C1.16688 6.35246 2.05976 4.68199 3.37088 3.37088C4.68199 2.05976 6.35246 1.16688 8.17103 0.805142C9.9896 0.443406 11.8746 0.629062 13.5877 1.33863C15.3007 2.04821 16.7649 3.24982 17.795 4.79153C18.8252 6.33324 19.375 8.1458 19.375 10C19.375 12.4864 18.3873 14.871 16.6291 16.6291C14.871 18.3873 12.4864 19.375 10 19.375ZM10 1.875C8.39303 1.875 6.82214 2.35153 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73103 12.1549 1.875 10 1.875Z"
        fill="#9D9D9D"/>
      <path
        d="M12.65 13.5375L9.55626 10.4437C9.49833 10.3853 9.45251 10.3161 9.4214 10.2399C9.3903 10.1638 9.37453 10.0823 9.37501 10V5H10.625V9.74375L13.5375 12.65L12.65 13.5375Z"
        fill="#9D9D9D"/>
    </g>
    <defs>
      <clipPath id="clip0_927_21807">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
