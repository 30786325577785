<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2471_23610)">
      <mask id="mask0_2471_23610" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="16"
            height="16">
        <path d="M16 0H0V16H16V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_2471_23610)">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.8379 0.270194C8.96435 -0.0900688 7.03565 -0.0900606 5.16202 0.270194C3.03829 0.678523 1.5 2.48381 1.5 4.59074V13.963C1.5 15.5458 3.26645 16.5059 4.65341 15.7206L7.32635 14.2072C7.7421 13.9718 8.2579 13.9718 8.67365 14.2072L11.3466 15.7206C12.7335 16.5059 14.5 15.5458 14.5 13.963V4.59074C14.5 2.48381 12.9617 0.678523 10.8379 0.270194Z"
              fill="#F4D115"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2471_23610">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
