<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M4.57812 4.375C4.9233 4.375 5.20312 4.09518 5.20312 3.75C5.20312 3.40482 4.9233 3.125 4.57812 3.125C4.23295 3.125 3.95312 3.40482 3.95312 3.75C3.95312 4.09518 4.23295 4.375 4.57812 4.375Z"
      fill="currentColor"
    />
    <path
      d="M6.66406 4.375C7.00924 4.375 7.28906 4.09518 7.28906 3.75C7.28906 3.40482 7.00924 3.125 6.66406 3.125C6.31888 3.125 6.03906 3.40482 6.03906 3.75C6.03906 4.09518 6.31888 4.375 6.66406 4.375Z"
      fill="currentColor"
    />
    <path
      d="M8.75 4.375C9.09518 4.375 9.375 4.09518 9.375 3.75C9.375 3.40482 9.09518 3.125 8.75 3.125C8.40482 3.125 8.125 3.40482 8.125 3.75C8.125 4.09518 8.40482 4.375 8.75 4.375Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9557 7.4974C18.9557 7.8424 18.6757 8.1224 18.3307 8.1224C17.9857 8.1224 17.7057 7.8424 17.7057 7.4974V3.7474C17.7057 2.9424 17.0532 2.28906 16.2474 2.28906H3.7474C2.94156 2.28906 2.28906 2.94156 2.28906 3.7474V16.2474C2.28906 17.0532 2.94156 17.7057 3.7474 17.7057H10.8307C11.1757 17.7057 11.4557 17.9857 11.4557 18.3307C11.4557 18.6757 11.1757 18.9557 10.8307 18.9557H3.7474C2.25156 18.9557 1.03906 17.7432 1.03906 16.2474V3.7474C1.03906 2.25156 2.25156 1.03906 3.7474 1.03906H16.2474C17.7432 1.03906 18.9557 2.25156 18.9557 3.7474V7.4974Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6648 9.05319C15.0056 8.32319 15.8731 8.00736 16.6031 8.34819L18.1131 9.05236C18.8431 9.39319 19.159 10.2607 18.819 10.9907L16.0015 17.0324C15.949 17.144 15.8648 17.2382 15.7598 17.3024L13.1923 18.864C13.014 18.9724 12.7931 18.9849 12.604 18.8965C12.414 18.8082 12.2815 18.6307 12.2498 18.424L11.7965 15.454C11.7773 15.3324 11.7956 15.2074 11.8473 15.0957L14.6648 9.05319ZM15.7981 9.58153L13.0606 15.4524L13.344 17.309L14.9481 16.3332L17.6856 10.4624C17.7348 10.3582 17.6898 10.234 17.5848 10.1857L16.0748 9.48069C15.9706 9.43236 15.8465 9.47736 15.7981 9.58153Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3307 5.20312C18.6757 5.20312 18.9557 5.48313 18.9557 5.82812C18.9557 6.17312 18.6757 6.45312 18.3307 6.45312H1.66406C1.31906 6.45312 1.03906 6.17312 1.03906 5.82812C1.03906 5.48313 1.31906 5.20312 1.66406 5.20312H18.3307Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2675 11.3951C13.9541 11.2492 13.8191 10.8767 13.965 10.5642C14.1108 10.2517 14.4825 10.1159 14.7958 10.2617L17.8166 11.6709C18.1291 11.8167 18.265 12.1884 18.1191 12.5009C17.9733 12.8142 17.6008 12.9492 17.2883 12.8034L14.2675 11.3951Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 9.375C4.655 9.375 4.375 9.095 4.375 8.75C4.375 8.405 4.655 8.125 5 8.125H10.8333C11.1783 8.125 11.4583 8.405 11.4583 8.75C11.4583 9.095 11.1783 9.375 10.8333 9.375H5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 12.7031C4.655 12.7031 4.375 12.4231 4.375 12.0781C4.375 11.7331 4.655 11.4531 5 11.4531H10.8333C11.1783 11.4531 11.4583 11.7331 11.4583 12.0781C11.4583 12.4231 11.1783 12.7031 10.8333 12.7031H5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 16.0391C4.655 16.0391 4.375 15.7591 4.375 15.4141C4.375 15.0691 4.655 14.7891 5 14.7891H9.16667C9.51167 14.7891 9.79167 15.0691 9.79167 15.4141C9.79167 15.7591 9.51167 16.0391 9.16667 16.0391H5Z"
      fill="currentColor"
    />
  </svg>
</template>
