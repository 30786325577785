<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/search-normal">
      <g id="search-normal">
        <path
          id="Vector"
          d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M22.0014 22.7504C21.8114 22.7504 21.6214 22.6804 21.4714 22.5304L19.4714 20.5304C19.1814 20.2404 19.1814 19.7604 19.4714 19.4704C19.7614 19.1804 20.2414 19.1804 20.5314 19.4704L22.5314 21.4704C22.8214 21.7604 22.8214 22.2404 22.5314 22.5304C22.3814 22.6804 22.1914 22.7504 22.0014 22.7504Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
