<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.41461 0.0454042C2.94035 0.36112 0.783011 2.24239 0.201361 5.46358C-0.0674209 6.95216 -0.06702 13.9204 0.201963 15.5039C0.813269 19.1028 2.67477 20.4839 7.1016 20.6232C7.77222 20.6442 8.23978 20.6806 8.2822 20.7149C8.32087 20.7462 8.77909 21.3417 9.30042 22.0382C10.594 23.7665 10.9175 24 12.0184 24C13.1194 24 13.4429 23.7665 14.7364 22.0382C15.8932 20.4928 15.6485 20.6645 16.7527 20.6237C21.082 20.4637 23.3977 18.5241 23.9242 14.6172C24.0253 13.8675 24.0253 6.76095 23.9242 6.01122C23.4641 2.59703 21.6362 0.704074 18.2256 0.109829C17.6597 0.0111555 7.40638 -0.0447239 6.41461 0.0454042ZM18.0717 1.83281C20.4946 2.29567 21.7274 3.53503 22.176 5.95908C22.3231 6.75394 22.3231 13.8745 22.176 14.6693C21.6345 17.5952 20.0488 18.7744 16.464 18.9168C14.863 18.9805 14.8793 18.9703 13.5729 20.7083C11.9775 22.8306 12.0586 22.8282 10.5329 20.7988C9.13497 18.9391 9.18453 18.9685 7.34801 18.912C3.39021 18.7901 2.25163 17.9521 1.83156 14.8519C1.71286 13.976 1.73711 6.62796 1.86088 5.95908C2.35189 3.30597 3.85938 1.99818 6.7218 1.74215C7.5864 1.66484 17.6115 1.74489 18.0717 1.83281ZM7.0871 9.83933C6.30607 10.2008 6.20875 11.3467 6.91918 11.8167C7.34614 12.099 8.07026 12.0235 8.39862 11.6623C9.22334 10.7552 8.19543 9.32647 7.0871 9.83933ZM11.6025 9.7958C10.4433 10.2828 10.7662 11.9832 12.0179 11.9832C13.4832 11.9832 13.545 9.81436 12.0814 9.75568C11.8889 9.74793 11.6734 9.76596 11.6025 9.7958ZM15.9483 9.87258C15.2789 10.2061 15.1319 11.2062 15.6793 11.7033C16.2286 12.2023 17.1433 12.0483 17.4652 11.4028C17.9714 10.3874 16.961 9.36819 15.9483 9.87258Z"
      fill="currentColor"
    />
  </svg>
</template>
