<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_253_18837)">
      <path
        d="M8.33203 14.9993C8.33203 15.916 9.08203 16.666 9.9987 16.666C10.9154 16.666 11.6654 15.916 11.6654 14.9993C11.6654 14.0827 10.9154 13.3327 9.9987 13.3327C9.08203 13.3327 8.33203 14.0827 8.33203 14.9993ZM8.33203 4.99935C8.33203 5.91602 9.08203 6.66602 9.9987 6.66602C10.9154 6.66602 11.6654 5.91602 11.6654 4.99935C11.6654 4.08268 10.9154 3.33268 9.9987 3.33268C9.08203 3.33268 8.33203 4.08268 8.33203 4.99935ZM8.33203 9.99935C8.33203 10.916 9.08203 11.666 9.9987 11.666C10.9154 11.666 11.6654 10.916 11.6654 9.99935C11.6654 9.08268 10.9154 8.33268 9.9987 8.33268C9.08203 8.33268 8.33203 9.08268 8.33203 9.99935Z"
        fill="#9D9D9D"
      />
    </g>
    <defs>
      <clipPath id="clip0_253_18837">
        <rect width="20" height="20" fill="white" transform="matrix(0 -1 1 0 0 20)" />
      </clipPath>
    </defs>
  </svg>
</template>
