<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_13199_62086)">
      <path
        d="M9.96232 15.6279C9.3427 15.6279 8.82886 15.1219 8.82886 14.5116V7.06976C8.82886 3.17023 12.0479 0 16.0075 0C19.967 0 23.1861 3.17023 23.1861 7.06976V7.51628C23.1861 8.12651 22.6722 8.63256 22.0526 8.63256C21.433 8.63256 20.9191 8.12651 20.9191 7.51628V7.06976C20.9191 4.40559 18.7127 2.23256 16.0075 2.23256C13.3023 2.23256 11.0958 4.40559 11.0958 7.06976V14.5116C11.0958 15.1219 10.582 15.6279 9.96232 15.6279Z"
        fill="currentColor"
      />
      <path
        d="M16.0077 22.3258C13.9825 22.3258 12.0481 21.4774 10.688 20.0039C10.2648 19.5424 10.3102 18.843 10.7636 18.4262C11.2321 18.0095 11.9424 18.0542 12.3655 18.5006C13.2874 19.5127 14.6173 20.0932 16.0077 20.0932C18.7129 20.0932 20.9195 17.9202 20.9195 15.256V14.5118C20.9195 13.9015 21.4332 13.3955 22.0529 13.3955C22.6725 13.3955 23.1864 13.9015 23.1864 14.5118V15.256C23.1864 19.1555 19.9673 22.3258 16.0077 22.3258Z"
        fill="currentColor"
      />
      <path
        d="M16.0075 27.5346C12.7885 27.5346 9.70545 26.3438 7.34784 24.1857C6.89445 23.769 6.86424 23.0546 7.2874 22.6081C7.72567 22.1466 8.45108 22.1318 8.90447 22.5485C10.8389 24.3197 13.3627 25.302 16.0075 25.302C21.7505 25.302 26.4354 20.6881 26.4354 15.0322V12.502C26.4354 11.8918 26.9493 11.3857 27.5689 11.3857C28.1885 11.3857 28.7023 11.8918 28.7023 12.502V15.0322C28.7023 21.9234 23.0047 27.5346 16.0075 27.5346Z"
        fill="currentColor"
      />
      <path
        d="M5.35298 20.5838C4.9147 20.5838 4.49154 20.3309 4.3102 19.8992C3.64522 18.3513 3.31274 16.729 3.31274 15.0322V12.502C3.31274 11.8918 3.82658 11.3857 4.44621 11.3857C5.06584 11.3857 5.57968 11.8918 5.57968 12.502V15.0322C5.57968 16.4165 5.8517 17.756 6.39577 19.021C6.63757 19.5866 6.36554 20.2416 5.79125 20.4797C5.65524 20.5541 5.5041 20.5838 5.35298 20.5838Z"
        fill="currentColor"
      />
      <path
        d="M3.79644 27.52C3.5093 27.52 3.22215 27.4158 2.99546 27.1925C2.55718 26.7609 2.55718 26.0465 2.99546 25.6149L27.4028 1.57763C27.841 1.146 28.5665 1.146 29.0048 1.57763C29.443 2.00925 29.443 2.72368 29.0048 3.15531L4.61254 27.1925C4.38584 27.4158 4.0987 27.52 3.79644 27.52Z"
        fill="currentColor"
      />
      <path
        d="M14.496 8.18595C13.8764 8.18595 13.3625 7.67991 13.3625 7.06967V2.60455C13.3625 1.99432 13.8764 1.48828 14.496 1.48828C15.1156 1.48828 15.6295 1.99432 15.6295 2.60455V7.06967C15.6295 7.67991 15.1156 8.18595 14.496 8.18595Z"
        fill="currentColor"
      />
      <path
        d="M16.0075 32.0005C15.3879 32.0005 14.874 31.4945 14.874 30.8842V26.4191C14.874 25.8089 15.3879 25.3027 16.0075 25.3027C16.6271 25.3027 17.141 25.8089 17.141 26.4191V30.8842C17.141 31.4945 16.6271 32.0005 16.0075 32.0005Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13199_62086">
        <rect width="32" height="32" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
