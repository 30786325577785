<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill="url(#a)"
          d="M8 14.934c3.725 0 6.745-3.105 6.745-6.934S11.726 1.067 8 1.067C4.274 1.067 1.254 4.17 1.254 8c0 3.83 3.02 6.934 6.746 6.934Z"/>
    <path fill="url(#b)"
          d="M8 14.934c3.725 0 6.745-3.105 6.745-6.934S11.726 1.067 8 1.067C4.274 1.067 1.254 4.17 1.254 8c0 3.83 3.02 6.934 6.746 6.934Z"/>
    <path fill="#303030"
          d="M5.527 8.852c.36 0 .65-.551.65-1.23 0-.68-.29-1.23-.65-1.23-.359 0-.65.55-.65 1.23 0 .679.291 1.23.65 1.23Z"/>
    <path stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width=".7"
          d="m4.72 5.867 1.655 1.312"/>
    <path fill="#303030"
          d="M11.281 7.622c0 .672-.283 1.23-.65 1.23-.354 0-.65-.558-.65-1.23 0-.673.282-1.23.65-1.23.367 0 .65.557.65 1.23Z"/>
    <path stroke="#303030" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width=".7"
          d="M11.267 5.867 9.613 7.179"/>
    <path stroke="#5B0600" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
          d="M5.867 10.667s1.788-1.2 4.267 0"/>
    <defs>
      <linearGradient id="a" x1="2.149" x2="13.999" y1="11.47" y2="4.814" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset=".033" stop-color="#ECB64D"/>
        <stop offset=".178" stop-color="#EEC04F"/>
        <stop offset=".551" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
      <linearGradient id="b" x1="7.999" x2="7.999" y1="1.069" y2="14.942" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DD5F70"/>
        <stop offset=".162" stop-color="#DD636F" stop-opacity=".968"/>
        <stop offset=".686" stop-color="#EBAA5D" stop-opacity=".362"/>
        <stop offset="1" stop-color="#F3D652" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>
