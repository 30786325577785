<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.874 6.69565C13.2323 6.80871 13.5137 7.12185 13.666 7.57698C13.7468 7.81869 13.7608 7.98809 13.7776 8.92938C13.7994 10.1489 13.7652 10.0551 14.1879 10.0564C14.5007 10.0573 15.437 10.1798 16.047 10.2997C17.3315 10.552 18.4554 10.9333 19.7013 11.5394C21.1679 12.2527 22.2989 13.0405 23.4609 14.1579C24.7658 15.4128 25.6858 16.6515 26.4559 18.1906C27.4262 20.1297 27.9118 22.0117 27.9884 24.1299C28.0109 24.7521 28.0048 24.8447 27.9326 24.98C27.7674 25.2895 27.347 25.4249 27.0389 25.2679C26.9719 25.2338 26.7309 24.9764 26.5032 24.6958C24.1044 21.7395 20.8779 19.6995 17.2208 18.8268C16.1863 18.5799 15.2586 18.4373 14.2244 18.3661L13.7971 18.3367L13.7771 19.4224C13.7528 20.7435 13.7056 20.9453 13.326 21.3519C13.0941 21.6003 12.8819 21.6852 12.5034 21.6814C12.1123 21.6774 11.9003 21.5968 11.5068 21.3025C11.3606 21.1932 10.0241 19.8995 8.53673 18.4277C6.24301 16.1579 5.81061 15.7104 5.68875 15.48C5.23819 14.6279 5.21599 13.7938 5.62226 12.9807C5.77818 12.6686 5.9209 12.5203 8.69056 9.79231C11.9323 6.59934 11.8156 6.69661 12.437 6.66924C12.6075 6.66174 12.8042 6.67361 12.874 6.69565ZM9.48694 10.8283C7.4488 12.8439 6.86841 13.4421 6.78496 13.6134C6.62811 13.9353 6.63409 14.4442 6.79829 14.748C6.88418 14.9069 7.6557 15.6938 9.64157 17.6479C11.1405 19.1229 12.3827 20.3143 12.402 20.2953C12.4212 20.2764 12.4469 19.6283 12.4591 18.8551C12.4836 17.3002 12.4828 17.3043 12.7826 17.1243C13.1258 16.9181 15.4038 17.086 17.0436 17.4383C20.422 18.164 23.4467 19.7726 25.9952 22.1989L26.5337 22.7116L26.4555 22.2825C25.6392 17.8055 22.4279 13.9539 18.1067 12.2692C16.6303 11.6937 15.2616 11.4109 13.562 11.3302C12.8027 11.2941 12.7064 11.2632 12.5458 11.0037C12.4652 10.8736 12.4594 10.775 12.4587 9.53332C12.4583 8.80131 12.4459 8.15836 12.4313 8.10451L12.4046 8.00663L12.2436 8.13397C12.1551 8.20401 10.9146 9.41642 9.48694 10.8283Z"
      fill="#B0B0B0"
    />
  </svg>
</template>
