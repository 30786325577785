<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5953_33220)">
      <path
          d="M5.49765 12.0008H3.15349C0.971165 12.0008 0 11.0296 0 8.84728V6.50308C0 4.32077 0.971165 3.34961 3.15349 3.34961H5.49765C7.68 3.34961 8.65115 4.32077 8.65115 6.50308V8.84728C8.65115 11.0296 7.68 12.0008 5.49765 12.0008ZM3.15349 4.18682C1.42326 4.18682 0.83721 4.77287 0.83721 6.50308V8.84728C0.83721 10.5775 1.42326 11.1636 3.15349 11.1636H5.49765C7.2279 11.1636 7.81395 10.5775 7.81395 8.84728V6.50308C7.81395 4.77287 7.2279 4.18682 5.49765 4.18682H3.15349Z"
          fill="#F1F1F1"/>
      <path
          d="M8.84631 8.65115H8.23236C8.00351 8.65115 7.81376 8.4614 7.81376 8.23255V6.50235C7.81376 4.77209 7.22771 4.18605 5.49746 4.18605H3.76724C3.5384 4.18605 3.34863 3.99628 3.34863 3.76744V3.15349C3.34863 0.971165 4.3198 0 6.50211 0H8.84631C11.0287 0 11.9998 0.971165 11.9998 3.15349V5.49765C11.9998 7.68 11.0287 8.65115 8.84631 8.65115ZM8.65096 7.81395H8.84631C10.5766 7.81395 11.1626 7.2279 11.1626 5.49765V3.15349C11.1626 1.42326 10.5766 0.83721 8.84631 0.83721H6.50211C4.77189 0.83721 4.18584 1.42326 4.18584 3.15349V3.34883H5.49746C7.67981 3.34883 8.65096 4.32 8.65096 6.50235V7.81395Z"
          fill="#F1F1F1"/>
    </g>
    <defs>
      <clipPath id="clip0_5953_33220">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
