<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49998 14C10.9927 14 13.8242 11.0899 13.8242 7.5C13.8242 3.91015 10.9927 1 7.49998 1C4.00722 1 1.17578 3.91015 1.17578 7.5C1.17578 11.0899 4.00722 14 7.49998 14Z" fill="url(#paint0_linear_862_5055)"/>
    <path d="M7.49991 10.7783C9.42466 10.7783 11.0195 9.30873 11.2944 7.38699C11.3494 7.10439 11.1294 6.82178 10.7995 6.82178H4.14533C3.87036 6.82178 3.65039 7.10439 3.65039 7.38699C3.98035 9.30873 5.57515 10.7783 7.49991 10.7783Z" fill="white"/>
    <path d="M6.48427 4.61654C6.24078 3.93985 5.65235 3.5 4.99291 3.5C4.34361 3.5 3.76533 3.92857 3.52184 4.58271C3.47111 4.70677 3.5117 4.85338 3.61315 4.93233C3.7146 5.01128 3.84649 5 3.9378 4.8985C4.41463 4.3797 5.17553 3.96241 6.06831 4.92105C6.11904 4.97745 6.17991 5 6.25093 5C6.30165 5 6.35238 4.98872 6.39296 4.95489C6.48427 4.88722 6.52485 4.7406 6.48427 4.61654Z" fill="#303030"/>
    <path d="M11.4782 4.61654C11.2352 3.93985 10.648 3.5 9.98987 3.5C9.34189 3.5 8.76479 3.92857 8.52179 4.58271C8.47117 4.70677 8.51167 4.85338 8.61292 4.93233C8.71417 5.01128 8.84579 5 8.93691 4.8985C9.41277 4.3797 10.1721 3.96241 11.0631 4.92105C11.1137 4.97745 11.1745 5 11.2453 5C11.296 5 11.3466 4.98872 11.3871 4.95489C11.4883 4.88722 11.5288 4.7406 11.4782 4.61654Z" fill="#303030"/>
    <path d="M11.25 4.75L12 4.25" stroke="#303030" stroke-linecap="round"/>
    <path d="M3 4.25L3.75 4.75" stroke="#303030" stroke-linecap="round"/>
    <path d="M11 4.5L11.5 4" stroke="#303030" stroke-width="0.8" stroke-linecap="round"/>
    <path d="M3.5 4L4 4.5" stroke="#303030" stroke-width="0.8" stroke-linecap="round"/>
    <defs>
      <linearGradient id="paint0_linear_862_5055" x1="2.01512" y1="10.7532" x2="13.1243" y2="4.51272" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EB3E7D"/>
        <stop offset="0.0001" stop-color="#EB3E7D"/>
        <stop offset="0.033108" stop-color="#F84E8C"/>
        <stop offset="0.1781" stop-color="#F86399"/>
        <stop offset="0.5514" stop-color="#E76493"/>
        <stop offset="1" stop-color="#FD7FB4"/>
      </linearGradient>
    </defs>
  </svg>
</template>
