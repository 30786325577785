<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/gallery">
      <path
        id="Vector"
        d="M10.2331 16.0009H5.76775C1.7266 16.0009 0 14.2743 0 10.2331V5.76775C0 1.7266 1.7266 0 5.76775 0H10.2331C14.2743 0 16.0009 1.7266 16.0009 5.76775V10.2331C16.0009 14.2743 14.2743 16.0009 10.2331 16.0009ZM5.76775 1.11634C2.33687 1.11634 1.11634 2.33687 1.11634 5.76775V10.2331C1.11634 13.664 2.33687 14.8845 5.76775 14.8845H10.2331C13.664 14.8845 14.8845 13.664 14.8845 10.2331V5.76775C14.8845 2.33687 13.664 1.11634 10.2331 1.11634H5.76775Z"
        fill="#1F1F1F"
      />
      <path
        id="Vector_2"
        d="M5.76537 7.0698C4.63415 7.0698 3.71875 6.15441 3.71875 5.02318C3.71875 3.89196 4.63415 2.97656 5.76537 2.97656C6.89659 2.97656 7.81199 3.89196 7.81199 5.02318C7.81199 6.15441 6.89659 7.0698 5.76537 7.0698ZM5.76537 4.0929C5.25186 4.0929 4.83509 4.50967 4.83509 5.02318C4.83509 5.5367 5.25186 5.95347 5.76537 5.95347C6.27889 5.95347 6.69565 5.5367 6.69565 5.02318C6.69565 4.50967 6.27889 4.0929 5.76537 4.0929Z"
        fill="#1F1F1F"
      />
      <path
        id="Vector_3"
        d="M1.05392 13.732C0.87531 13.732 0.696696 13.6427 0.592504 13.4864C0.421332 13.2333 0.488313 12.8836 0.748792 12.7124L4.41782 10.249C5.22159 9.70571 6.33049 9.77269 7.05983 10.3904L7.30542 10.6062C7.67753 10.9262 8.31013 10.9262 8.6748 10.6062L11.7708 7.94934C12.5597 7.27209 13.8025 7.27209 14.5988 7.94934L15.8119 8.99126C16.0426 9.1922 16.0724 9.54198 15.8715 9.78014C15.6705 10.0108 15.3207 10.0406 15.0826 9.83967L13.8695 8.79776C13.4974 8.47774 12.8648 8.47774 12.4927 8.79776L9.3967 11.4546C8.60782 12.1319 7.36496 12.1319 6.56864 11.4546L6.32304 11.2388C5.9807 10.9486 5.41509 10.9188 5.03553 11.1793L1.3665 13.6427C1.26975 13.7022 1.15812 13.732 1.05392 13.732Z"
        fill="#1F1F1F"
      />
    </g>
  </svg>
</template>
