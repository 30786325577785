<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9.73414 2.34321L11.4066 5.69649C11.5273 5.94043 11.7626 6.11018 12.0355 6.15017L15.7819 6.6959C16.0022 6.72679 16.2008 6.84267 16.3339 7.01789C16.467 7.19311 16.5235 7.41324 16.491 7.62955C16.4651 7.80885 16.3806 7.975 16.2502 8.10296L13.5341 10.733C13.3351 10.9189 13.2446 11.1907 13.2932 11.4562L13.9622 15.158C14.0361 15.6008 13.7407 16.0219 13.2932 16.1114C13.1101 16.1401 12.9224 16.1101 12.758 16.0259L9.41302 14.2835C9.1653 14.1583 8.87132 14.1583 8.6236 14.2835L5.27859 16.0259C4.86656 16.245 4.35181 16.095 4.12791 15.6906C4.04308 15.5287 4.01263 15.3445 4.04094 15.1646L4.70994 11.4628C4.75853 11.1973 4.66804 10.9255 4.4691 10.7395L1.75296 8.10953C1.59361 7.95508 1.50391 7.74431 1.50391 7.52435C1.50391 7.3044 1.59361 7.09362 1.75296 6.93917C1.88133 6.80856 2.0513 6.72503 2.23464 6.70247L5.98104 6.15674C6.25399 6.11676 6.48929 5.947 6.6099 5.70306L8.2222 2.34321C8.36319 2.05662 8.66101 1.87689 8.98486 1.88296H9.08521C9.36702 1.9181 9.61141 2.09143 9.73414 2.34321Z"
      fill="#F4D115"
    />
    <path
      d="M9.00882 14.1856C8.8623 14.1847 8.72451 14.2329 8.59415 14.2987L5.25971 16.0368C4.85978 16.2383 4.35196 16.0764 4.1318 15.6913C4.04659 15.5319 4.01608 15.3497 4.04483 15.1719L4.71383 11.4767C4.75876 11.2094 4.66897 10.9374 4.47299 10.7469L1.75016 8.11687C1.42182 7.79348 1.42182 7.2699 1.75016 6.94651C1.87859 6.81962 2.0451 6.73664 2.22515 6.70981L5.98494 6.15751C6.27467 6.11545 6.48175 5.95526 6.6138 5.70383L8.24712 2.30547C8.37159 2.02902 8.79007 1.83797 9.01551 1.88373C9.00882 2.10728 9.00882 14.0344 9.00882 14.1856Z"
      fill="#F4D115"
    />
  </svg>
</template>
