<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11228_46557)">
      <path
        d="M4.44196 3.32819C4.52971 3.32819 4.61845 3.30219 4.69595 3.24819C5.3847 2.76844 6.15195 2.45069 6.97645 2.30369C7.21846 2.26069 7.37996 2.02919 7.3367 1.78694C7.2937 1.54469 7.06245 1.38369 6.8202 1.42694C5.86795 1.59669 4.98221 1.96369 4.18696 2.51769C3.98521 2.65819 3.93546 2.93569 4.07596 3.13769C4.16271 3.26169 4.30121 3.32819 4.44196 3.32819Z"
        fill="#1F1F1F"/>
      <path
        d="M1.78719 7.33701C1.81369 7.34176 1.83994 7.34401 1.86594 7.34401C2.07769 7.34401 2.26544 7.19251 2.30369 6.97701C2.45094 6.15201 2.76869 5.38501 3.24844 4.69651C3.38919 4.49426 3.33969 4.21676 3.13769 4.07626C2.93569 3.93526 2.65844 3.98526 2.51769 4.18701C1.96394 4.98176 1.59694 5.86776 1.42694 6.82026C1.38369 7.06251 1.54494 7.29376 1.78719 7.33701Z"
        fill="#1F1F1F"/>
      <path
        d="M6.97555 13.6965C6.15105 13.5495 5.3838 13.2315 4.69505 12.752C4.4933 12.611 4.2158 12.661 4.07505 12.8628C3.93455 13.0648 3.98405 13.3423 4.18605 13.4828C4.98105 14.0368 5.86705 14.4035 6.8193 14.5735C6.8458 14.5783 6.87205 14.5805 6.89805 14.5805C7.1098 14.5805 7.29755 14.429 7.3358 14.2135C7.37905 13.971 7.2178 13.7398 6.97555 13.6965Z"
        fill="#1F1F1F"/>
      <path
        d="M2.30368 9.02334C2.26043 8.78109 2.02893 8.61984 1.78718 8.66334C1.54518 8.70634 1.38368 8.93784 1.42693 9.18009C1.59668 10.1326 1.96368 11.0186 2.51768 11.8133C2.60443 11.9376 2.74268 12.0041 2.88343 12.0041C2.97118 12.0041 3.05993 11.9781 3.13768 11.9241C3.33943 11.7836 3.38893 11.5058 3.24843 11.3041C2.76868 10.6153 2.45068 9.84834 2.30368 9.02334Z"
        fill="#1F1F1F"/>
      <path
        d="M9.18001 1.42714C8.93726 1.38314 8.70651 1.54489 8.66326 1.78714C8.62001 2.02939 8.78126 2.26039 9.02326 2.30389C11.7848 2.79639 13.7888 5.19239 13.7888 8.00064C13.7888 10.8089 11.7848 13.2049 9.02351 13.6974C8.78151 13.7406 8.62026 13.9719 8.66351 14.2141C8.70201 14.4299 8.88976 14.5811 9.10126 14.5811C9.12726 14.5811 9.15351 14.5789 9.18001 14.5741C12.3668 14.0056 14.6795 11.2411 14.6795 8.00064C14.6795 4.76014 12.3668 1.99564 9.18001 1.42714Z"
        fill="#1F1F1F"/>
      <path
        d="M10.1763 8.00041C10.1763 7.71866 10.0306 7.46641 9.78655 7.32541L7.4373 5.96891C7.19355 5.82791 6.90205 5.82816 6.65805 5.96916C6.4143 6.11016 6.26855 6.36241 6.26855 6.64391V9.35666C6.26855 9.63816 6.4143 9.89041 6.65805 10.0314C6.78005 10.1019 6.91405 10.1372 7.0478 10.1372C7.18155 10.1372 7.31555 10.1019 7.43755 10.0317L9.7868 8.67541C10.0306 8.53441 10.1763 8.28216 10.1763 8.00041ZM7.1593 9.16391V6.83691L9.1743 8.00041L7.1593 9.16391Z"
        fill="#1F1F1F"/>
    </g>
    <defs>
      <clipPath id="clip0_11228_46557">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
