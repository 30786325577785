<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#D94B4B" />
    <path
      d="M19.4604 22.4794C17.5004 22.4794 15.9004 20.8794 15.9004 18.9194C15.9004 16.9594 17.5004 15.3594 19.4604 15.3594C21.4204 15.3594 23.0204 16.9594 23.0204 18.9194C23.0204 20.8794 21.4204 22.4794 19.4604 22.4794ZM19.4604 16.8694C18.3304 16.8694 17.4004 17.7894 17.4004 18.9294C17.4004 20.0694 18.3204 20.9894 19.4604 20.9894C20.6004 20.9894 21.5204 20.0694 21.5204 18.9294C21.5204 17.7894 20.6004 16.8694 19.4604 16.8694Z"
      fill="white"
    />
    <path
      d="M24.6495 28.9507C24.2395 28.9507 23.8995 28.6107 23.8995 28.2007C23.8995 26.2807 21.9095 24.7207 19.4595 24.7207C17.0095 24.7207 15.0195 26.2807 15.0195 28.2007C15.0195 28.6107 14.6795 28.9507 14.2695 28.9507C13.8595 28.9507 13.5195 28.6107 13.5195 28.2007C13.5195 25.4607 16.1795 23.2207 19.4595 23.2207C22.7395 23.2207 25.3995 25.4507 25.3995 28.2007C25.3995 28.6107 25.0595 28.9507 24.6495 28.9507Z"
      fill="white"
    />
    <path
      d="M19.5 30.75C13.85 30.75 9.25 26.15 9.25 20.5C9.25 14.85 13.85 10.25 19.5 10.25C20.89 10.25 22.23 10.52 23.49 11.05C23.85 11.2 24.03 11.6 23.91 11.97C23.8 12.3 23.75 12.65 23.75 13C23.75 13.59 23.91 14.17 24.22 14.67C24.38 14.95 24.59 15.2 24.83 15.41C25.7 16.2 26.99 16.45 28 16.09C28.37 15.95 28.79 16.14 28.94 16.51C29.48 17.78 29.75 19.13 29.75 20.51C29.75 26.15 25.15 30.75 19.5 30.75ZM19.5 11.75C14.68 11.75 10.75 15.67 10.75 20.5C10.75 25.33 14.68 29.25 19.5 29.25C24.32 29.25 28.25 25.33 28.25 20.5C28.25 19.54 28.09 18.59 27.79 17.68C26.41 17.92 24.9 17.49 23.84 16.52C23.49 16.22 23.18 15.85 22.94 15.44C22.5 14.72 22.26 13.87 22.26 13C22.26 12.73 22.28 12.47 22.33 12.21C21.42 11.9 20.47 11.75 19.5 11.75Z"
      fill="white"
    />
    <path
      d="M27 17.75C25.82 17.75 24.7 17.31 23.83 16.52C23.48 16.22 23.17 15.85 22.93 15.44C22.49 14.72 22.25 13.87 22.25 13C22.25 12.49 22.33 11.99 22.49 11.51C22.71 10.83 23.09 10.2 23.6 9.69C24.5 8.77 25.71 8.25 27.01 8.25C28.37 8.25 29.66 8.83002 30.54 9.83002C31.32 10.7 31.76 11.82 31.76 13C31.76 13.38 31.71 13.76 31.61 14.12C31.51 14.57 31.32 15.04 31.06 15.45C30.48 16.43 29.56 17.16 28.48 17.5C28.03 17.67 27.53 17.75 27 17.75ZM27 9.75C26.11 9.75 25.28 10.1 24.67 10.73C24.32 11.09 24.07 11.5 23.92 11.97C23.81 12.3 23.76 12.65 23.76 13C23.76 13.59 23.92 14.17 24.23 14.67C24.39 14.95 24.6 15.2 24.84 15.41C25.71 16.2 27 16.45 28.01 16.09C28.77 15.85 29.39 15.35 29.79 14.68C29.97 14.39 30.09 14.08 30.16 13.77C30.23 13.51 30.26 13.26 30.26 13C30.26 12.2 29.96 11.43 29.42 10.83C28.81 10.14 27.93 9.75 27 9.75Z"
      fill="white"
    />
    <path
      d="M27.5449 14.5783L25.4306 12.464C25.1407 12.1741 25.1407 11.6933 25.4306 11.4034C25.7205 11.1135 26.2014 11.1135 26.4913 11.4034L28.6055 13.5176C28.8954 13.8075 28.8954 14.2884 28.6055 14.5783C28.3156 14.8682 27.8418 14.8753 27.5449 14.5783Z"
      fill="white"
    />
    <path
      d="M25.4121 14.6056C25.1222 14.3157 25.1222 13.8349 25.4121 13.545L27.5263 11.4307C27.8162 11.1408 28.2971 11.1408 28.587 11.4307C28.8769 11.7206 28.8769 12.2015 28.587 12.4914L26.4727 14.6056C26.1757 14.9026 25.702 14.8955 25.4121 14.6056Z"
      fill="white"
    />
  </svg>
</template>
