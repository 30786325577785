<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_738_23846)">
      <path
        d="M10 20C4.48372 20 0 15.5163 0 10C0 4.48372 4.48372 0 10 0C15.5163 0 20 4.48372 20 10C20 15.5163 15.5163 20 10 20ZM10 1.39535C5.25581 1.39535 1.39535 5.25581 1.39535 10C1.39535 14.7442 5.25581 18.6047 10 18.6047C14.7442 18.6047 18.6047 14.7442 18.6047 10C18.6047 5.25581 14.7442 1.39535 10 1.39535Z"
        fill="currentColor"
      />
      <path
        d="M13.7186 10.6981H6.27678C5.89538 10.6981 5.5791 10.3818 5.5791 10.0004C5.5791 9.61901 5.89538 9.30273 6.27678 9.30273H13.7186C14.1 9.30273 14.4163 9.61901 14.4163 10.0004C14.4163 10.3818 14.1 10.6981 13.7186 10.6981Z"
        fill="currentColor"
      />
      <path
        d="M9.99846 14.4192C9.61706 14.4192 9.30078 14.103 9.30078 13.7216V6.27971C9.30078 5.89831 9.61706 5.58203 9.99846 5.58203C10.3799 5.58203 10.6961 5.89831 10.6961 6.27971V13.7216C10.6961 14.103 10.3799 14.4192 9.99846 14.4192Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_738_23846">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
