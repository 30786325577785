<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#61CA5E" />
    <path
      d="M20.0002 20.6243C17.3585 20.6243 15.2085 18.4743 15.2085 15.8327C15.2085 13.191 17.3585 11.041 20.0002 11.041C22.6418 11.041 24.7918 13.191 24.7918 15.8327C24.7918 18.4743 22.6418 20.6243 20.0002 20.6243ZM20.0002 12.291C18.0502 12.291 16.4585 13.8827 16.4585 15.8327C16.4585 17.7827 18.0502 19.3743 20.0002 19.3743C21.9502 19.3743 23.5418 17.7827 23.5418 15.8327C23.5418 13.8827 21.9502 12.291 20.0002 12.291Z"
      fill="white"
    />
    <path
      d="M23.1828 28.9588C22.8661 28.9588 22.5661 28.8421 22.3495 28.6254C22.0911 28.3671 21.9745 27.9921 22.0328 27.6005L22.1911 26.4755C22.2328 26.1838 22.4078 25.8421 22.6162 25.6255L25.5661 22.6755C26.7495 21.4922 27.7912 22.1672 28.2995 22.6755C28.7328 23.1088 28.9578 23.5754 28.9578 24.0421C28.9578 24.5171 28.7412 24.9588 28.2995 25.4005L25.3495 28.3505C25.1411 28.5588 24.7912 28.7338 24.4995 28.7755L23.3745 28.9338C23.3078 28.9504 23.2495 28.9588 23.1828 28.9588ZM26.9245 23.2671C26.7745 23.2671 26.6411 23.3672 26.4495 23.5588L23.4995 26.5088C23.4745 26.5338 23.4328 26.6171 23.4328 26.6505L23.2828 27.6921L24.3245 27.5421C24.3578 27.5338 24.4411 27.4922 24.4661 27.4672L27.4162 24.5171C27.5495 24.3838 27.7078 24.1921 27.7078 24.0421C27.7078 23.9171 27.6078 23.7422 27.4162 23.5588C27.2162 23.3588 27.0661 23.2671 26.9245 23.2671Z"
      fill="white"
    />
    <path
      d="M27.4338 26.0178C27.3755 26.0178 27.3171 26.0094 27.2671 25.9928C26.1671 25.6844 25.2922 24.8095 24.9838 23.7095C24.8922 23.3761 25.0838 23.0345 25.4172 22.9428C25.7505 22.8511 26.0922 23.0428 26.1838 23.3761C26.3755 24.0594 26.9172 24.6011 27.6005 24.7928C27.9338 24.8844 28.1255 25.2344 28.0338 25.5594C27.9588 25.8344 27.7088 26.0178 27.4338 26.0178Z"
      fill="white"
    />
    <path
      d="M12.8418 28.9583C12.5001 28.9583 12.2168 28.675 12.2168 28.3333C12.2168 24.775 15.7085 21.875 20.0002 21.875C20.9085 21.875 21.8085 22.0084 22.6501 22.2584C22.9835 22.3584 23.1668 22.7083 23.0668 23.0333C22.9668 23.3667 22.6168 23.55 22.2918 23.45C21.5585 23.2333 20.7918 23.1167 20.0002 23.1167C16.4002 23.1167 13.4668 25.45 13.4668 28.325C13.4668 28.675 13.1835 28.9583 12.8418 28.9583Z"
      fill="white"
    />
  </svg>
</template>
