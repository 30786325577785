<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_862_5006)">
      <path d="M9.26256 11.4798C9.45054 10.8524 9.82649 10.2877 10.3278 9.84844C11.2676 9.06414 11.8629 7.87201 11.8629 6.55439C11.8629 4.10738 9.82652 2.09957 7.32016 2.22506C5.09577 2.31918 3.27867 4.2015 3.21602 6.42889C3.18468 7.80928 3.77995 9.03278 4.75116 9.84844C5.28375 10.2876 5.6597 10.8523 5.84767 11.4798H9.26256Z" fill="#FFD15C"/>
      <path d="M7.14742 11.5563H7.42702L6.18434 6.88512C6.21542 6.88512 6.24647 6.88512 6.27754 6.88512C6.43287 6.88512 6.58822 6.82243 6.71247 6.69702C6.77459 6.63433 6.83674 6.60297 6.92994 6.60297C7.02314 6.60297 7.08527 6.63433 7.14742 6.69702C7.36489 6.94781 7.73769 6.94781 7.95514 6.69702C8.01727 6.63433 8.07942 6.60297 8.17262 6.60297C8.23474 6.60297 8.32794 6.63433 8.39009 6.69702C8.51437 6.82243 8.63862 6.88512 8.82502 6.88512C8.8561 6.88512 8.88714 6.88512 8.91822 6.88512L7.70662 11.5563H7.98622L9.25994 6.66566C9.25994 6.60297 9.25994 6.54025 9.19782 6.50892C9.13569 6.47755 9.07354 6.50892 9.0425 6.54028C8.98037 6.60297 8.91822 6.63433 8.85609 6.63433C8.76289 6.63433 8.70077 6.60297 8.60757 6.54028C8.48329 6.41486 8.35905 6.35217 8.20369 6.35217C8.04834 6.35217 7.92409 6.41486 7.79982 6.54028C7.67554 6.66569 7.48914 6.66569 7.36489 6.54028C7.27169 6.41486 7.11637 6.35217 6.96102 6.35217C6.80569 6.35217 6.65034 6.41486 6.55714 6.54028C6.49502 6.60297 6.40182 6.63433 6.30862 6.63433C6.24649 6.63433 6.15329 6.60297 6.12222 6.54028C6.09114 6.50892 6.02902 6.47758 5.96689 6.50892C5.90477 6.54028 5.87369 6.60297 5.90477 6.66566L7.14742 11.5563Z" fill="white"/>
      <path d="M6.5625 14.3439C6.71877 14.7189 7.09378 15.0001 7.53127 15.0001C7.96877 15.0001 8.34377 14.7189 8.50004 14.3439H6.5625Z" fill="#EB3E7D"/>
      <path d="M8.68753 14.3753H6.40625C6.09374 14.3753 5.84375 14.1253 5.84375 13.8128V11.4377H9.25003V13.8128C9.25003 14.1253 9.00001 14.3753 8.68753 14.3753Z" fill="#EB3E7D"/>
      <path d="M7.53127 0C7.37502 0 7.21875 0.125011 7.21875 0.312513V1.37504C7.21875 1.53128 7.34376 1.68755 7.53127 1.68755C7.71877 1.68755 7.84378 1.56254 7.84378 1.37504V0.312513C7.84378 0.125011 7.68751 0 7.53127 0Z" fill="#FFD15C"/>
      <path d="M3.34375 1.90601C3.21873 1.781 3.03123 1.781 2.90625 1.90601C2.78127 2.03103 2.78124 2.21853 2.90625 2.34351L3.65626 3.09352C3.78128 3.21853 3.96878 3.21853 4.09376 3.09352C4.21875 2.96851 4.21877 2.781 4.09376 2.65602L3.34375 1.90601Z" fill="#FFD15C"/>
      <path d="M2.37502 6.21851H1.31251C1.15627 6.21851 1 6.34352 1 6.53102C1 6.68726 1.12501 6.84353 1.31251 6.84353H2.37502C2.53127 6.84353 2.68754 6.71852 2.68754 6.53102C2.68754 6.37475 2.53127 6.21851 2.37502 6.21851Z" fill="#FFD15C"/>
      <path d="M3.65627 9.96863L2.90626 10.7186C2.78125 10.8437 2.78125 11.0312 2.90626 11.1561C3.03127 11.2811 3.21877 11.2811 3.34375 11.1561L4.09377 10.4061C4.21878 10.2811 4.21878 10.0936 4.09377 9.96863C3.96875 9.84364 3.78128 9.84361 3.65627 9.96863Z" fill="#FFD15C"/>
      <path d="M11.4063 9.96864C11.2813 9.84362 11.0938 9.84362 10.9688 9.96864C10.8437 10.0936 10.8437 10.2812 10.9688 10.4061L11.7187 11.1561C11.8438 11.2812 12.0313 11.2812 12.1562 11.1561C12.2812 11.0311 12.2812 10.8436 12.1562 10.7187L11.4063 9.96864Z" fill="#FFD15C"/>
      <path d="M13.752 6.21851H12.6895C12.5332 6.21851 12.377 6.34352 12.377 6.53102C12.377 6.68726 12.502 6.84353 12.6895 6.84353H13.752C13.9083 6.84353 14.0645 6.71852 14.0645 6.53102C14.0645 6.37475 13.9395 6.21851 13.752 6.21851Z" fill="#FFD15C"/>
      <path d="M11.7188 1.906L10.9688 2.65601C10.8437 2.78102 10.8437 2.96852 10.9688 3.0935C11.0938 3.21852 11.2813 3.21852 11.4062 3.0935L12.1562 2.3435C12.2813 2.21849 12.2813 2.03098 12.1562 1.906C12.0312 1.78102 11.8438 1.78099 11.7188 1.906Z" fill="#FFD15C"/>
    </g>
    <defs>
      <clipPath id="clip0_862_5006">
        <rect width="15" height="15" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
