<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
    <rect width="60" height="60" rx="8" fill="#F1F1F1" />
    <path
      d="M34.5 46.125H25.5C18.885 46.125 16.875 44.115 16.875 37.5V22.5C16.875 15.885 18.885 13.875 25.5 13.875H34.5C41.115 13.875 43.125 15.885 43.125 22.5V37.5C43.125 44.115 41.115 46.125 34.5 46.125ZM25.5 16.125C20.13 16.125 19.125 17.145 19.125 22.5V37.5C19.125 42.855 20.13 43.875 25.5 43.875H34.5C39.87 43.875 40.875 42.855 40.875 37.5V22.5C40.875 17.145 39.87 16.125 34.5 16.125H25.5Z"
      fill="#9D9D9D"
    />
    <path
      d="M33 21.375H27C26.385 21.375 25.875 20.865 25.875 20.25C25.875 19.635 26.385 19.125 27 19.125H33C33.615 19.125 34.125 19.635 34.125 20.25C34.125 20.865 33.615 21.375 33 21.375Z"
      fill="#9D9D9D"
    />
    <path
      d="M29.9988 41.7906C28.0938 41.7906 26.5488 40.2456 26.5488 38.3406C26.5488 36.4356 28.0938 34.8906 29.9988 34.8906C31.9038 34.8906 33.4488 36.4356 33.4488 38.3406C33.4488 40.2456 31.9038 41.7906 29.9988 41.7906ZM29.9988 37.1256C29.3388 37.1256 28.7988 37.6656 28.7988 38.3256C28.7988 38.9856 29.3388 39.5256 29.9988 39.5256C30.6588 39.5256 31.1988 38.9856 31.1988 38.3256C31.1988 37.6656 30.6588 37.1256 29.9988 37.1256Z"
      fill="#9D9D9D"
    />
  </svg>
</template>
