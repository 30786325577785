<template>
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/previous">
      <g id="previous">
        <path
          id="Vector"
          d="M19.5952 22C18.8344 22 18.0869 21.7961 17.3928 21.3882L6.3274 14.8887C4.95256 14.0729 4.125 12.6181 4.125 11C4.125 9.38197 4.95256 7.9271 6.3274 7.11128L17.3928 0.611866C18.7677 -0.203955 20.4094 -0.203955 21.7976 0.611866C23.1858 1.42769 24 2.86899 24 4.50064V17.513C24 19.131 23.1725 20.5859 21.7976 21.4017C21.1035 21.796 20.3561 22 19.5952 22ZM19.5952 2.03958C19.1814 2.03958 18.7677 2.14834 18.3939 2.36589L7.32849 8.86527C6.58101 9.31397 6.12719 10.1026 6.12719 10.9864C6.12719 11.8702 6.58101 12.6588 7.32849 13.1075L18.3939 19.6069C19.1414 20.0556 20.049 20.0556 20.7965 19.6069C21.544 19.1582 21.9978 18.3696 21.9978 17.4858V4.47346C21.9978 3.58965 21.544 2.80103 20.7965 2.35232C20.4228 2.16197 20.009 2.03958 19.5952 2.03958Z"
          fill="#49A399"
        />
        <path
          id="Vector_2"
          d="M1.00109 20.4226C0.453829 20.4226 0 19.9603 0 19.4028V2.59693C0 2.03945 0.453829 1.57715 1.00109 1.57715C1.54836 1.57715 2.00219 2.03945 2.00219 2.59693V19.4028C2.00219 19.9603 1.54836 20.4226 1.00109 20.4226Z"
          fill="#49A399"
        />
      </g>
    </g>
  </svg>
</template>
