<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M16.7788 30.0915H7.41785C1.94313 30.0915 0 26.2378 0 22.6473V10.1112C0 4.75633 2.08193 2.66699 7.41785 2.66699H16.7788C22.1148 2.66699 24.1967 4.75633 24.1967 10.1112V22.6473C24.1967 28.0022 22.1148 30.0915 16.7788 30.0915ZM7.41785 5.01943C3.39279 5.01943 2.34411 6.07183 2.34411 10.1112V22.6473C2.34411 24.5509 3.00724 27.739 7.41785 27.739H16.7788C20.8039 27.739 21.8525 26.6866 21.8525 22.6473V10.1112C21.8525 6.07183 20.8039 5.01943 16.7788 5.01943H7.41785Z"
      fill="currentColor "
    />
    <path
      d="M28.9619 25.8341C28.2989 25.8341 27.4506 25.6174 26.4791 24.9365L22.3615 22.0423C22.053 21.8257 21.8679 21.4697 21.8679 21.0827V11.6731C21.8679 11.2861 22.053 10.9302 22.3615 10.7135L26.4791 7.81939C28.3143 6.53483 29.6559 6.90627 30.2882 7.23127C30.9206 7.57177 32.0001 8.45393 32.0001 10.698V22.0423C32.0001 24.2865 30.9206 25.1841 30.2882 25.5091C29.9953 25.6794 29.5326 25.8341 28.9619 25.8341ZM24.1967 20.4638L27.8207 23.0019C28.5147 23.4817 29.0082 23.5281 29.2087 23.4198C29.4246 23.3114 29.6559 22.8781 29.6559 22.0423V10.7135C29.6559 9.8623 29.4093 9.44443 29.2087 9.33609C29.0082 9.22775 28.5147 9.27418 27.8207 9.75395L24.1967 12.2921V20.4638Z"
      fill="currentColor "
    />
    <path
      d="M14.6507 15.9927C12.7384 15.9927 11.1808 14.4297 11.1808 12.5106C11.1808 10.5915 12.7384 9.02832 14.6507 9.02832C16.563 9.02832 18.1206 10.5915 18.1206 12.5106C18.1206 14.4297 16.563 15.9927 14.6507 15.9927ZM14.6507 11.3498C14.0184 11.3498 13.494 11.876 13.494 12.5106C13.494 13.1451 14.0184 13.6713 14.6507 13.6713C15.283 13.6713 15.8072 13.1451 15.8072 12.5106C15.8072 11.876 15.283 11.3498 14.6507 11.3498Z"
      fill="currentColor "
    />
  </svg>
</template>
