<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4105_12065)">
      <path
        d="M8.00065 13.333C6.53398 13.333 5.33398 14.533 5.33398 15.9997C5.33398 17.4663 6.53398 18.6663 8.00065 18.6663C9.46732 18.6663 10.6673 17.4663 10.6673 15.9997C10.6673 14.533 9.46732 13.333 8.00065 13.333ZM24.0007 13.333C22.534 13.333 21.334 14.533 21.334 15.9997C21.334 17.4663 22.534 18.6663 24.0007 18.6663C25.4673 18.6663 26.6673 17.4663 26.6673 15.9997C26.6673 14.533 25.4673 13.333 24.0007 13.333ZM16.0007 13.333C14.534 13.333 13.334 14.533 13.334 15.9997C13.334 17.4663 14.534 18.6663 16.0007 18.6663C17.4673 18.6663 18.6673 17.4663 18.6673 15.9997C18.6673 14.533 17.4673 13.333 16.0007 13.333Z"
        fill="#B0B0B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_4105_12065">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
