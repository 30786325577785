<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49998 14C10.9927 14 13.8242 11.0899 13.8242 7.5C13.8242 3.91015 10.9927 1 7.49998 1C4.00722 1 1.17578 3.91015 1.17578 7.5C1.17578 11.0899 4.00722 14 7.49998 14Z" fill="url(#paint0_linear_862_5069)"/>
    <path d="M7.50054 12.0992C8.32927 12.0992 9.00108 10.9577 9.00108 9.54959C9.00108 8.14149 8.32927 7 7.50054 7C6.67182 7 6 8.14149 6 9.54959C6 10.9577 6.67182 12.0992 7.50054 12.0992Z" fill="#303030"/>
    <path d="M8.76874 10.9173C7.67321 10.3131 6.78351 10.6583 6.26562 10.997C6.53785 11.6609 6.98934 12.0991 7.50059 12.0991C8.03175 12.0991 8.50316 11.6277 8.76874 10.9173Z" fill="#D94B4B"/>
    <path d="M3 7.7887L3.47869 7.49998" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M11.5 7L11.9787 7.28872" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M3 7.2887L3.47869 6.99998" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M11.5 7.5L11.9787 7.78872" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M6.00301 6.95726C6.49975 5.99276 6.32267 4.93982 5.60749 4.60546C4.8923 4.27109 3.90984 4.78192 3.4131 5.74642C2.91636 6.71092 3.09344 7.76385 3.80863 8.09822C4.52381 8.43258 5.50627 7.92175 6.00301 6.95726Z" fill="white"/>
    <path d="M11.2051 8.09938C11.9203 7.76501 12.0974 6.71208 11.6007 5.74758C11.1039 4.78308 10.1215 4.27225 9.40629 4.60662C8.6911 4.94098 8.51402 5.99392 9.01076 6.95842C9.5075 7.92292 10.49 8.43374 11.2051 8.09938Z" fill="white"/>
    <path d="M10.5482 3.9787C10.3943 3.9787 10.2734 3.8578 10.2734 3.70393V0.274772C10.2734 0.1209 10.3943 0 10.5482 0C10.7021 0 10.823 0.1209 10.823 0.274772V3.70393C10.823 3.84681 10.6911 3.9787 10.5482 3.9787Z" fill="#414042"/>
    <path d="M11.8119 3.41812C11.658 3.41812 11.5371 3.29722 11.5371 3.14335V0.670402C11.5371 0.51653 11.658 0.39563 11.8119 0.39563C11.9658 0.39563 12.0867 0.51653 12.0867 0.670402V3.14335C12.0867 3.29722 11.9658 3.41812 11.8119 3.41812Z" fill="#414042"/>
    <path d="M13.0873 5.24261C12.9334 5.24261 12.8125 5.12171 12.8125 4.96783V1.27489C12.8125 1.12102 12.9334 1.00012 13.0873 1.00012C13.2411 1.00012 13.362 1.12102 13.362 1.27489V4.96783C13.362 5.12171 13.2411 5.24261 13.0873 5.24261Z" fill="#414042"/>
    <defs>
      <linearGradient id="paint0_linear_862_5069" x1="2.01512" y1="10.7532" x2="13.1243" y2="4.51272" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EB3E7D"/>
        <stop offset="0.0001" stop-color="#EB3E7D"/>
        <stop offset="0.033108" stop-color="#F84E8C"/>
        <stop offset="0.1781" stop-color="#F86399"/>
        <stop offset="0.5514" stop-color="#E76493"/>
        <stop offset="1" stop-color="#FD7FB4"/>
      </linearGradient>
    </defs>
  </svg>
</template>
