<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3.30023 22.7491C2.94023 22.7491 2.59022 22.6592 2.29022 22.4792C1.62022 22.0892 1.24023 21.3091 1.24023 20.3391V8.97916C1.24023 6.84916 2.97022 5.11914 5.10022 5.11914H12.8802C15.0102 5.11914 16.7402 6.84916 16.7402 8.97916V20.3391C16.7402 21.3091 16.3602 22.0792 15.6902 22.4792C15.0202 22.8692 14.1602 22.8291 13.3102 22.3591L9.38025 20.1692C9.19025 20.0592 8.79022 20.0592 8.60022 20.1692L4.67023 22.3591C4.21023 22.6191 3.74023 22.7491 3.30023 22.7491ZM5.11023 6.62915C3.81023 6.62915 2.75024 7.68914 2.75024 8.98914V20.3492C2.75024 20.7592 2.87024 21.0791 3.06024 21.1891C3.25024 21.2991 3.59026 21.2491 3.95026 21.0491L7.88025 18.8591C8.52025 18.5091 9.48024 18.5091 10.1202 18.8591L14.0502 21.0491C14.4102 21.2491 14.7402 21.2991 14.9402 21.1891C15.1302 21.0791 15.2502 20.7592 15.2502 20.3492V8.98914C15.2502 7.68914 14.1903 6.62915 12.8903 6.62915H5.11023Z"
      fill="#49A399"
    />
    <path
      d="M14.69 22.7506C14.25 22.7506 13.79 22.6206 13.32 22.3706L9.39001 20.1806C9.20001 20.0806 8.78998 20.0806 8.59998 20.1806L4.67999 22.3706C3.82999 22.8406 2.95999 22.8806 2.29999 22.4906C1.62999 22.1006 1.25 21.3206 1.25 20.3606V9.00061C1.25 6.87061 2.97999 5.14062 5.10999 5.14062H12.89C15.02 5.14062 16.75 6.87061 16.75 9.00061V20.3606C16.75 21.3206 16.37 22.1006 15.7 22.4906C15.4 22.6606 15.06 22.7506 14.69 22.7506ZM9 18.6006C9.4 18.6006 9.79 18.6906 10.12 18.8706L14.05 21.0606C14.41 21.2606 14.75 21.3106 14.94 21.1906C15.13 21.0806 15.25 20.7606 15.25 20.3506V8.9906C15.25 7.6906 14.19 6.63062 12.89 6.63062H5.10999C3.80999 6.63062 2.75 7.6906 2.75 8.9906V20.3506C2.75 20.7606 2.87 21.0806 3.06 21.1906C3.25 21.3006 3.59001 21.2506 3.95001 21.0506L7.88 18.8606C8.20001 18.6906 8.6 18.6006 9 18.6006Z"
      fill="#49A399"
    />
    <path
      d="M20.69 18.8698C20.25 18.8698 19.79 18.7397 19.32 18.4897L15.63 16.4297C15.39 16.2997 15.25 16.0498 15.25 15.7798V8.99976C15.25 7.69976 14.19 6.63977 12.89 6.63977H8C7.59 6.63977 7.25 6.29977 7.25 5.88977V5.11975C7.25 2.98975 8.97999 1.25977 11.11 1.25977H18.89C21.02 1.25977 22.75 2.98975 22.75 5.11975V16.4798C22.75 17.4398 22.37 18.2197 21.7 18.6097C21.4 18.7797 21.06 18.8698 20.69 18.8698ZM16.75 15.3297L20.05 17.1797C20.41 17.3797 20.74 17.4298 20.94 17.3098C21.14 17.1898 21.25 16.8798 21.25 16.4698V5.10974C21.25 3.80974 20.19 2.74976 18.89 2.74976H11.11C9.80999 2.74976 8.75 3.80974 8.75 5.10974V5.12976H12.89C15.02 5.12976 16.75 6.85975 16.75 8.98975V15.3297Z"
      fill="#49A399"
    />
  </svg>
</template>
