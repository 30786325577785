<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
    <path
      d="M37.5 56.875H22.5C8.925 56.875 3.125 51.075 3.125 37.5V22.5C3.125 8.925 8.925 3.125 22.5 3.125H37.5C51.075 3.125 56.875 8.925 56.875 22.5V37.5C56.875 51.075 51.075 56.875 37.5 56.875ZM22.5 6.875C10.975 6.875 6.875 10.975 6.875 22.5V37.5C6.875 49.025 10.975 53.125 22.5 53.125H37.5C49.025 53.125 53.125 49.025 53.125 37.5V22.5C53.125 10.975 49.025 6.875 37.5 6.875H22.5Z"
      fill="#9D9D9D"
    />
    <path
      d="M29.9996 38.15C29.5246 38.15 29.0496 37.975 28.6746 37.6L21.1746 30.1C20.4496 29.375 20.4496 28.175 21.1746 27.45C21.8996 26.725 23.0996 26.725 23.8246 27.45L29.9996 33.625L36.1746 27.45C36.8996 26.725 38.0996 26.725 38.8246 27.45C39.5496 28.175 39.5496 29.375 38.8246 30.1L31.3246 37.6C30.9496 37.975 30.4746 38.15 29.9996 38.15Z"
      fill="#9D9D9D"
    />
    <path
      d="M30 38.1499C28.975 38.1499 28.125 37.2999 28.125 36.2749V16.2749C28.125 15.2499 28.975 14.3999 30 14.3999C31.025 14.3999 31.875 15.2499 31.875 16.2749V36.2749C31.875 37.3249 31.025 38.1499 30 38.1499Z"
      fill="#9D9D9D"
    />
    <path
      d="M30.0001 45.5749C24.7251 45.5749 19.4251 44.7249 14.4001 43.0499C13.4251 42.7249 12.9001 41.6499 13.2251 40.6749C13.5501 39.6999 14.6001 39.1499 15.6001 39.4999C24.9001 42.5999 35.1251 42.5999 44.4251 39.4999C45.4001 39.1749 46.4751 39.6999 46.8001 40.6749C47.1251 41.6499 46.6001 42.7249 45.6251 43.0499C40.5751 44.7499 35.2751 45.5749 30.0001 45.5749Z"
      fill="#9D9D9D"
    />
  </svg>
</template>
