<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8339 9.7917C10.6755 9.7917 10.5172 9.73337 10.3922 9.60837C10.1505 9.3667 10.1505 8.9667 10.3922 8.72503L17.2255 1.8917C17.4672 1.65003 17.8672 1.65003 18.1089 1.8917C18.3505 2.13337 18.3505 2.53337 18.1089 2.77503L11.2755 9.60837C11.1505 9.73337 10.9922 9.7917 10.8339 9.7917Z"
      fill="currentColor"
    />
    <path
      d="M18.332 6.2915C17.9904 6.2915 17.707 6.00817 17.707 5.6665V2.2915H14.332C13.9904 2.2915 13.707 2.00817 13.707 1.6665C13.707 1.32484 13.9904 1.0415 14.332 1.0415H18.332C18.6737 1.0415 18.957 1.32484 18.957 1.6665V5.6665C18.957 6.00817 18.6737 6.2915 18.332 6.2915Z"
      fill="currentColor"
    />
    <path
      d="M12.5013 18.9582H7.5013C2.9763 18.9582 1.04297 17.0248 1.04297 12.4998V7.49984C1.04297 2.97484 2.9763 1.0415 7.5013 1.0415H9.16797C9.50963 1.0415 9.79297 1.32484 9.79297 1.6665C9.79297 2.00817 9.50963 2.2915 9.16797 2.2915H7.5013C3.65964 2.2915 2.29297 3.65817 2.29297 7.49984V12.4998C2.29297 16.3415 3.65964 17.7082 7.5013 17.7082H12.5013C16.343 17.7082 17.7096 16.3415 17.7096 12.4998V10.8332C17.7096 10.4915 17.993 10.2082 18.3346 10.2082C18.6763 10.2082 18.9596 10.4915 18.9596 10.8332V12.4998C18.9596 17.0248 17.0263 18.9582 12.5013 18.9582Z"
      fill="currentColor"
    />
  </svg>
</template>
