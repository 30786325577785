<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#B161CD" />
    <path
      d="M23.3334 28.9578H16.6668C12.8168 28.9578 12.1001 27.1661 11.9168 25.4245L11.2918 18.7495C11.2001 17.8745 11.1751 16.5828 12.0418 15.6161C12.7918 14.7828 14.0334 14.3828 15.8334 14.3828H24.1668C25.9751 14.3828 27.2168 14.7911 27.9584 15.6161C28.8251 16.5828 28.8001 17.8745 28.7084 18.7578L28.0834 25.4161C27.9001 27.1661 27.1834 28.9578 23.3334 28.9578ZM15.8334 15.6245C14.4251 15.6245 13.4584 15.8995 12.9668 16.4495C12.5584 16.8995 12.4251 17.5911 12.5334 18.6245L13.1584 25.2995C13.3001 26.6161 13.6584 27.7078 16.6668 27.7078H23.3334C26.3334 27.7078 26.7001 26.6161 26.8418 25.2911L27.4668 18.6328C27.5751 17.5911 27.4418 16.8995 27.0334 16.4495C26.5418 15.8995 25.5751 15.6245 24.1668 15.6245H15.8334Z"
      fill="white"
    />
    <path
      d="M23.3334 15.6243C22.9917 15.6243 22.7084 15.341 22.7084 14.9993V14.3327C22.7084 12.8493 22.7084 12.291 20.6667 12.291H19.3334C17.2917 12.291 17.2917 12.8493 17.2917 14.3327V14.9993C17.2917 15.341 17.0084 15.6243 16.6667 15.6243C16.3251 15.6243 16.0417 15.341 16.0417 14.9993V14.3327C16.0417 12.866 16.0417 11.041 19.3334 11.041H20.6667C23.9584 11.041 23.9584 12.866 23.9584 14.3327V14.9993C23.9584 15.341 23.6751 15.6243 23.3334 15.6243Z"
      fill="white"
    />
    <path
      d="M19.9999 23.9583C17.7083 23.9583 17.7083 22.5417 17.7083 21.6917V20.8333C17.7083 19.6583 17.9916 19.375 19.1666 19.375H20.8333C22.0083 19.375 22.2916 19.6583 22.2916 20.8333V21.6667C22.2916 22.5333 22.2916 23.9583 19.9999 23.9583ZM18.9583 20.625C18.9583 20.6917 18.9583 20.7667 18.9583 20.8333V21.6917C18.9583 22.55 18.9583 22.7083 19.9999 22.7083C21.0416 22.7083 21.0416 22.575 21.0416 21.6833V20.8333C21.0416 20.7667 21.0416 20.6917 21.0416 20.625C20.9749 20.625 20.8999 20.625 20.8333 20.625H19.1666C19.0999 20.625 19.0249 20.625 18.9583 20.625Z"
      fill="white"
    />
    <path
      d="M21.6667 22.3089C21.3584 22.3089 21.0834 22.0755 21.0501 21.7589C21.0084 21.4172 21.2501 21.1005 21.5917 21.0589C23.7917 20.7839 25.9001 19.9505 27.6751 18.6589C27.9501 18.4505 28.3417 18.5172 28.5501 18.8005C28.7501 19.0755 28.6917 19.4672 28.4084 19.6755C26.4584 21.0922 24.1584 22.0005 21.7417 22.3089C21.7167 22.3089 21.6917 22.3089 21.6667 22.3089Z"
      fill="white"
    />
    <path
      d="M18.3334 22.3174C18.3084 22.3174 18.2834 22.3174 18.2584 22.3174C15.9751 22.0591 13.7501 21.2257 11.8251 19.9091C11.5418 19.7174 11.4668 19.3257 11.6584 19.0424C11.8501 18.7591 12.2418 18.6841 12.5251 18.8757C14.2834 20.0757 16.3084 20.8341 18.3918 21.0757C18.7334 21.1174 18.9834 21.4257 18.9418 21.7674C18.9168 22.0841 18.6501 22.3174 18.3334 22.3174Z"
      fill="white"
    />
  </svg>
</template>
