<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/camera">
      <g id="camera">
        <path
          id="Vector"
          d="M17.9832 24.002H6.01681C2.81969 24.002 0.787235 22.1377 0.604542 19.023L0.0107911 9.81291C-0.0805552 8.41745 0.410431 7.0778 1.3924 6.0619C2.36296 5.046 3.73316 4.46549 5.14902 4.46549C5.51441 4.46549 5.86838 4.25338 6.03965 3.91847L6.86177 2.32205C7.53545 1.0159 9.22535 0 10.6983 0H13.3131C14.7861 0 16.4646 1.0159 17.1382 2.31089L17.9603 3.94079C18.1316 4.25338 18.4742 4.46549 18.851 4.46549C20.2668 4.46549 21.637 5.046 22.6076 6.0619C23.5896 7.08896 24.0806 8.41745 23.9892 9.81291L23.3955 19.0341C23.1899 22.1935 21.2146 24.002 17.9832 24.002ZM10.6983 1.67456C9.85336 1.67456 8.78004 2.32205 8.39182 3.07002L7.5697 4.6776C7.09013 5.58186 6.16525 6.14005 5.14902 6.14005C4.18989 6.14005 3.29926 6.51961 2.637 7.2006C1.98616 7.88159 1.65503 8.77468 1.72354 9.70127L2.31729 18.9225C2.45431 21.1776 3.6989 22.3274 6.01681 22.3274H17.9832C20.2897 22.3274 21.5343 21.1776 21.6827 18.9225L22.2765 9.70127C22.3336 8.77468 22.0138 7.88159 21.363 7.2006C20.7007 6.51961 19.8101 6.14005 18.851 6.14005C17.8347 6.14005 16.9099 5.58186 16.4303 4.69993L15.5968 3.07002C15.22 2.33322 14.1466 1.68572 13.3017 1.68572H10.6983V1.67456Z"
          :fill="white ? '#ffffff' : '#B0B0B0'"
        />
        <path
          id="Vector_2"
          d="M13.7125 8.3728H10.287C9.81889 8.3728 9.43066 7.99323 9.43066 7.53552C9.43066 7.07781 9.81889 6.69824 10.287 6.69824H13.7125C14.1807 6.69824 14.5689 7.07781 14.5689 7.53552C14.5689 7.99323 14.1807 8.3728 13.7125 8.3728Z"
          :fill="white ? '#ffffff' : '#B0B0B0'"
        />
        <path
          id="Vector_3"
          d="M11.9999 19.5364C9.47649 19.5364 7.43262 17.5381 7.43262 15.071C7.43262 12.6038 9.47649 10.6055 11.9999 10.6055C14.5234 10.6055 16.5673 12.6038 16.5673 15.071C16.5673 17.5381 14.5234 19.5364 11.9999 19.5364ZM11.9999 12.28C10.4242 12.28 9.14536 13.5304 9.14536 15.071C9.14536 16.6115 10.4242 17.8619 11.9999 17.8619C13.5757 17.8619 14.8545 16.6115 14.8545 15.071C14.8545 13.5304 13.5757 12.28 11.9999 12.28Z"
          :fill="white ? '#ffffff' : '#B0B0B0'"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    white: Boolean
  }
}
</script>
