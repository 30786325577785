<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 30C6.72558 30 0 23.2744 0 15C0 6.72558 6.72558 0 15 0C23.2744 0 30 6.72558 30 15C30 23.2744 23.2744 30 15 30ZM15 2.09302C7.88372 2.09302 2.09302 7.88372 2.09302 15C2.09302 22.1163 7.88372 27.907 15 27.907C22.1163 27.907 27.907 22.1163 27.907 15C27.907 7.88372 22.1163 2.09302 15 2.09302Z"
      fill="currentColor"
    />
    <path
      d="M14.9996 17.4409C14.4275 17.4409 13.9531 16.9664 13.9531 16.3943V9.41761C13.9531 8.84551 14.4275 8.37109 14.9996 8.37109C15.5717 8.37109 16.0461 8.84551 16.0461 9.41761V16.3943C16.0461 16.9664 15.5717 17.4409 14.9996 17.4409Z"
      fill="currentColor"
    />
    <path
      d="M14.9998 21.9771C14.8184 21.9771 14.6371 21.9352 14.4696 21.8655C14.3022 21.7957 14.1487 21.698 14.0091 21.5724C13.8836 21.4329 13.7859 21.2934 13.7161 21.112C13.6464 20.9445 13.6045 20.7631 13.6045 20.5817C13.6045 20.4003 13.6464 20.2189 13.7161 20.0515C13.7859 19.8841 13.8836 19.7306 14.0091 19.591C14.1487 19.4655 14.3022 19.3678 14.4696 19.298C14.8045 19.1585 15.1952 19.1585 15.5301 19.298C15.6975 19.3678 15.851 19.4655 15.9905 19.591C16.1161 19.7306 16.2138 19.8841 16.2836 20.0515C16.3533 20.2189 16.3952 20.4003 16.3952 20.5817C16.3952 20.7631 16.3533 20.9445 16.2836 21.112C16.2138 21.2934 16.1161 21.4329 15.9905 21.5724C15.851 21.698 15.6975 21.7957 15.5301 21.8655C15.3626 21.9352 15.1812 21.9771 14.9998 21.9771Z"
      fill="currentColor"
    />
  </svg>
</template>
