<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_167_18347)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8931 2.21679C13.3228 1.72261 10.6772 1.72261 8.10698 2.21679C5.79173 2.66193 4.1371 4.62254 4.1371 6.88611V20.9445C4.1371 21.86 5.17991 22.4671 6.03486 21.983L10.0442 19.713C11.2527 19.0289 12.7473 19.0289 13.9558 19.713L17.9651 21.983C18.8201 22.4671 19.8629 21.86 19.8629 20.9445V6.88611C19.8629 4.62254 18.2083 2.66193 15.8931 2.21679ZM7.743 0.405291C10.5535 -0.135091 13.4465 -0.135103 16.2569 0.405291C19.4425 1.01779 21.75 3.72572 21.75 6.88611V20.9445C21.75 23.3187 19.1003 24.7588 17.0199 23.581L13.0105 21.3108C12.3868 20.9577 11.6132 20.9577 10.9895 21.3108L6.98012 23.581C4.89968 24.7588 2.25 23.3187 2.25 20.9445V6.88611C2.25 3.72572 4.55744 1.01779 7.743 0.405291Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_167_18347">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
