<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#a)">
      <path fill="#D94B4B" fill-rule="evenodd" d="M3.142.807c-.606.076-1.098.246-1.543.533A3.436 3.436 0 0 0 .166 3.186c-.443 1.373-.004 2.79 1.377 4.438.267.318 1.149 1.199 1.528 1.525a21.28 21.28 0 0 0 2.147 1.618c.685.452.761.483.962.392.154-.07 1.065-.683 1.534-1.03.846-.63 1.45-1.15 2.104-1.81 1.247-1.262 1.925-2.37 2.138-3.495.059-.31.059-.91 0-1.208-.28-1.409-1.32-2.454-2.746-2.758a4.304 4.304 0 0 0-1.292-.01 3.601 3.601 0 0 0-1.708.836l-.205.179-.218-.188a4.775 4.775 0 0 0-.378-.294A3.724 3.724 0 0 0 4.088.849a5.5 5.5 0 0 0-.946-.042Z" clip-rule="evenodd"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>
