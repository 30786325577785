<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M1.27734 3.66901C1.27734 3.86792 1.35636 4.05869 1.49701 4.19934C1.63767 4.33999 1.82843 4.41901 2.02734 4.41901H2.42734V13.2815C2.42801 13.9359 2.68822 14.5633 3.1509 15.0261C3.61359 15.4889 4.24095 15.7492 4.89534 15.75H11.1033C11.7577 15.7492 12.3851 15.4889 12.8478 15.0261C13.3105 14.5633 13.5707 13.9359 13.5713 13.2815V4.41901H13.9713C14.1703 4.41901 14.361 4.33999 14.5017 4.19934C14.6423 4.05869 14.7213 3.86792 14.7213 3.66901C14.7213 3.4701 14.6423 3.27933 14.5017 3.13868C14.361 2.99803 14.1703 2.91901 13.9713 2.91901H11.3463V2.14501C11.3463 1.10001 10.4963 0.249512 9.45134 0.249512H6.54734C5.50234 0.249512 4.65234 1.09951 4.65234 2.14501V2.91851H2.02734C1.82843 2.91851 1.63767 2.99753 1.49701 3.13818C1.35636 3.27883 1.27734 3.4701 1.27734 3.66901ZM6.15234 2.14551C6.15234 1.92701 6.32934 1.75001 6.54734 1.75001H9.45134C9.66934 1.75001 9.84634 1.92701 9.84634 2.14551V2.91901H6.15184V2.14501L6.15234 2.14551ZM3.92734 4.41901H12.0713V13.2815C12.0711 13.5382 11.969 13.7843 11.7876 13.9659C11.6061 14.1474 11.36 14.2496 11.1033 14.25H4.89534C4.63865 14.2496 4.39259 14.1474 4.21113 13.9659C4.02966 13.7843 3.92761 13.5382 3.92734 13.2815V4.41901Z"
      fill="currentColor"
    />
    <path
      d="M6.13672 12.8683C6.33563 12.8683 6.5264 12.7893 6.66705 12.6486C6.8077 12.508 6.88672 12.3172 6.88672 12.1183V6.55029C6.88672 6.35138 6.8077 6.16062 6.66705 6.01996C6.5264 5.87931 6.33563 5.80029 6.13672 5.80029C5.93781 5.80029 5.74704 5.87931 5.60639 6.01996C5.46574 6.16062 5.38672 6.35138 5.38672 6.55029V12.1183C5.38672 12.3172 5.46574 12.508 5.60639 12.6486C5.74704 12.7893 5.93781 12.8683 6.13672 12.8683ZM9.86472 12.8683C10.0636 12.8683 10.2544 12.7893 10.395 12.6486C10.5357 12.508 10.6147 12.3172 10.6147 12.1183V6.55029C10.6147 6.35138 10.5357 6.16062 10.395 6.01996C10.2544 5.87931 10.0636 5.80029 9.86472 5.80029C9.66581 5.80029 9.47504 5.87931 9.33439 6.01996C9.19374 6.16062 9.11472 6.35138 9.11472 6.55029V12.1183C9.11472 12.3172 9.19374 12.508 9.33439 12.6486C9.47504 12.7893 9.66581 12.8683 9.86472 12.8683Z"
      fill="currentColor"
    />
  </svg>
</template>
