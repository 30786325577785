<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_2670_24704)">
      <mask
        id="mask0_2670_24704"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <path d="M18 0H0V18H18V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2670_24704)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.1926 0.303968C10.0849 -0.101327 7.91511 -0.101318 5.80727 0.303968C3.41808 0.763338 1.6875 2.79429 1.6875 5.16458V15.7084C1.6875 17.489 3.67476 18.5691 5.23509 17.6857L8.24214 15.9831C8.70986 15.7183 9.29014 15.7183 9.75786 15.9831L12.7649 17.6857C14.3252 18.5691 16.3125 17.489 16.3125 15.7084V5.16458C16.3125 2.79429 14.5819 0.763338 12.1926 0.303968Z"
          fill="#F4D115"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2670_24704">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
