<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9996 22.4C17.588 22.4 22.1183 17.7437 22.1183 12C22.1183 6.25621 17.588 1.59998 11.9996 1.59998C6.41117 1.59998 1.88086 6.25621 1.88086 12C1.88086 17.7437 6.41117 22.4 11.9996 22.4Z" fill="url(#paint0_linear_860_5056)"/>
    <path d="M11.9991 17.2452C15.0787 17.2452 17.6304 14.8939 18.0703 11.8191C18.1583 11.367 17.8063 10.9148 17.2784 10.9148H6.63175C6.1918 10.9148 5.83984 11.367 5.83984 11.8191C6.36778 14.8939 8.91946 17.2452 11.9991 17.2452Z" fill="white"/>
    <path d="M10.3744 7.38644C9.98486 6.30373 9.04337 5.59998 7.98826 5.59998C6.94938 5.59998 6.02413 6.28569 5.63455 7.33231C5.55339 7.53081 5.61832 7.76539 5.78065 7.8917C5.94297 8.01802 6.154 7.99998 6.30009 7.83757C7.06302 7.00749 8.28045 6.33983 9.70891 7.87366C9.79007 7.96389 9.88747 7.99998 10.0011 7.99998C10.0823 7.99998 10.1634 7.98193 10.2284 7.9278C10.3744 7.81953 10.4394 7.58494 10.3744 7.38644Z" fill="#303030"/>
    <path d="M18.3647 7.38644C17.976 6.30373 17.0364 5.59998 15.9834 5.59998C14.9466 5.59998 14.0233 6.28569 13.6345 7.33231C13.5535 7.53081 13.6183 7.76539 13.7803 7.8917C13.9423 8.01802 14.1529 7.99998 14.2987 7.83757C15.06 7.00749 16.275 6.33983 17.7006 7.87366C17.7816 7.96389 17.8788 7.99998 17.9922 7.99998C18.0732 7.99998 18.1541 7.98193 18.2189 7.9278C18.3809 7.81953 18.4457 7.58494 18.3647 7.38644Z" fill="#303030"/>
    <defs>
      <linearGradient id="paint0_linear_860_5056" x1="3.22379" y1="17.205" x2="20.9986" y2="7.22032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
    </defs>
  </svg>
</template>
