<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4156 5.72508C18.9528 5.9894 18.6355 6.22652 15.2906 9.55452C11.2079 13.6166 10.3298 14.5671 9.26124 16.0802C7.02812 19.2424 5.16276 24.437 5.84332 25.5982C6.31652 26.4057 6.81436 26.5019 8.42476 26.0968C11.803 25.2469 14.4731 23.9431 17.062 21.8792C18.436 20.7838 25.0049 14.2468 25.518 13.4644C27.9556 9.74636 24.7842 4.93588 20.4156 5.72508ZM21.8879 7.75676C23.4368 8.0798 24.5231 9.62364 24.2553 11.1215C24.1232 11.8614 23.8885 12.2552 23.1096 13.0447L22.4136 13.7502L22.0663 13.7495C20.3119 13.7457 18.2548 11.6887 18.2511 9.93428L18.2504 9.587L18.9535 8.89516C20.0218 7.84396 20.8204 7.53412 21.8879 7.75676ZM16.6112 11.9208C17.2051 13.3448 18.7415 14.8679 20.1356 15.4148L20.5706 15.5855L18.6136 17.5464C15.3624 20.8041 13.61 22.0623 10.7504 23.192C9.53316 23.6728 7.86444 24.1716 7.90764 24.0416C7.921 24.0016 8.03316 23.61 8.157 23.1714C8.851 20.7136 10.0569 18.3699 11.6859 16.3127C12.102 15.7872 16.3672 11.4023 16.411 11.4549C16.4175 11.4626 16.5076 11.6723 16.6112 11.9208Z"
      fill="#B0B0B0"
    />
  </svg>
</template>
