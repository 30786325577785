<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <path fill="currentColor"
          d="M4.346 10.84a.494.494 0 0 1-.353-.147.503.503 0 0 1 0-.706l2.033-2.034a.503.503 0 0 1 .707 0 .503.503 0 0 1 0 .707l-2.034 2.033a.524.524 0 0 1-.353.147Z"/>
    <path fill="currentColor"
          d="M6.4 10.86a.494.494 0 0 1-.353-.147L4.014 8.68a.503.503 0 0 1 0-.707.503.503 0 0 1 .707 0l2.033 2.034a.503.503 0 0 1 0 .706c-.1.1-.227.147-.353.147ZM9.027 10a.674.674 0 0 1-.673-.667c0-.366.293-.666.66-.666h.013c.367 0 .667.3.667.666a.664.664 0 0 1-.667.667ZM11.654 10a.674.674 0 0 1-.674-.667c0-.366.294-.666.66-.666h.014c.367 0 .666.3.666.666 0 .367-.3.667-.666.667ZM10.333 11.313a.663.663 0 0 1-.667-.66v-.013c0-.367.3-.667.667-.667a.67.67 0 0 1 0 1.34ZM10.333 8.687a.663.663 0 0 1-.667-.66v-.014c0-.366.3-.666.667-.666a.67.67 0 0 1 0 1.34Z"/>
    <path fill="currentColor"
          d="M10 15.167H6C2.38 15.167.835 13.62.835 10V8.667c0-3.62 1.547-5.167 5.167-5.167h4c3.62 0 5.166 1.547 5.166 5.167V10c0 3.62-1.546 5.167-5.166 5.167ZM6 4.5c-3.073 0-4.166 1.093-4.166 4.167V10c0 3.073 1.093 4.167 4.167 4.167h4c3.073 0 4.166-1.094 4.166-4.167V8.667c0-3.074-1.093-4.167-4.166-4.167H6Z"/>
    <path fill="currentColor"
          d="M8.66 4.5h-.667a1.167 1.167 0 0 1-.007-2.333c.107 0 .18-.074.187-.167l.007-.673a.502.502 0 0 1 .5-.494h.006c.274.007.5.234.494.507l-.007.673a1.174 1.174 0 0 1-1.167 1.154c-.106 0-.173.073-.173.166 0 .094.073.167.167.167h.666c.274 0 .5.227.5.5a.51.51 0 0 1-.506.5Z"/>
  </svg>
</template>
