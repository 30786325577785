<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.1162 10.4418C5.97477 10.4418 5.83334 10.3897 5.72169 10.2781C5.11132 9.66772 4.77637 8.85638 4.77637 8.00038C4.77637 6.22138 6.22041 4.77734 7.9994 4.77734C8.8554 4.77734 9.66674 5.1123 10.2771 5.72267C10.3813 5.82688 10.4409 5.9683 10.4409 6.11717C10.4409 6.26604 10.3813 6.40747 10.2771 6.51168L6.5107 10.2781C6.39905 10.3897 6.25762 10.4418 6.1162 10.4418ZM7.9994 5.89387C6.83822 5.89387 5.89289 6.83919 5.89289 8.00038C5.89289 8.37255 5.98966 8.72984 6.1683 9.04247L9.04149 6.16928C8.72886 5.99063 8.37158 5.89387 7.9994 5.89387Z"
      fill="#1F1F1F"/>
    <path
      d="M3.23609 12.8469C3.10955 12.8469 2.97557 12.8022 2.87136 12.7129C2.0749 12.0355 1.36033 11.2018 0.749961 10.2342C-0.0390495 9.00602 -0.0390495 7.00372 0.749961 5.76809C2.56617 2.92468 5.20862 1.28711 7.99993 1.28711C9.6375 1.28711 11.2527 1.85282 12.667 2.91724C12.9126 3.10332 12.9647 3.45317 12.7787 3.6988C12.5926 3.94444 12.2427 3.99654 11.9971 3.81045C10.7764 2.88746 9.39186 2.40363 7.99993 2.40363C5.59568 2.40363 3.29564 3.84767 1.68784 6.37102C1.12958 7.24191 1.12958 8.76038 1.68784 9.63127C2.2461 10.5022 2.88624 11.254 3.59338 11.8643C3.82413 12.0653 3.8539 12.4151 3.65292 12.6533C3.54872 12.7799 3.3924 12.8469 3.23609 12.8469Z"
      fill="#1F1F1F"/>
    <path
      d="M7.99983 14.7157C7.00984 14.7157 6.04219 14.5148 5.11175 14.1202C4.8289 14.0012 4.69491 13.6736 4.81401 13.3908C4.9331 13.1079 5.26062 12.974 5.54347 13.093C6.33248 13.428 7.15871 13.5992 7.99238 13.5992C10.3966 13.5992 12.6967 12.1552 14.3045 9.63182C14.8627 8.76093 14.8627 7.24246 14.3045 6.37157C14.0737 6.00684 13.8206 5.65699 13.5527 5.32948C13.3591 5.09129 13.3964 4.74144 13.6346 4.54047C13.8727 4.34694 14.2226 4.37671 14.4236 4.62235C14.7139 4.97964 14.9967 5.3667 15.2498 5.76865C16.0388 6.99682 16.0388 8.99912 15.2498 10.2347C13.4336 13.0782 10.7911 14.7157 7.99983 14.7157Z"
      fill="#1F1F1F"/>
    <path
      d="M8.51342 11.1799C8.2529 11.1799 8.01471 10.9938 7.9626 10.7258C7.90305 10.4206 8.10403 10.1303 8.40921 10.0782C9.228 9.92936 9.9128 9.24455 10.0617 8.42577C10.1212 8.12059 10.4115 7.92706 10.7167 7.97916C11.0219 8.03871 11.2229 8.329 11.1633 8.63419C10.9251 9.92191 9.89791 10.9417 8.61763 11.1799C8.58041 11.1724 8.55064 11.1799 8.51342 11.1799Z"
      fill="#1F1F1F"/>
    <path
      d="M0.556401 16.0031C0.414975 16.0031 0.273548 15.951 0.161896 15.8393C-0.0539653 15.6235 -0.0539653 15.2662 0.161896 15.0503L5.72219 9.49002C5.93805 9.27416 6.29534 9.27416 6.5112 9.49002C6.72706 9.70588 6.72706 10.0632 6.5112 10.279L0.950907 15.8393C0.839254 15.951 0.697828 16.0031 0.556401 16.0031Z"
      fill="#1F1F1F"/>
    <path
      d="M9.88355 6.67496C9.74212 6.67496 9.6007 6.62285 9.48904 6.5112C9.27318 6.29534 9.27318 5.93805 9.48904 5.72219L15.0493 0.161896C15.2652 -0.0539653 15.6225 -0.0539653 15.8383 0.161896C16.0542 0.377757 16.0542 0.735045 15.8383 0.950907L10.2781 6.5112C10.1664 6.62285 10.025 6.67496 9.88355 6.67496Z"
      fill="#1F1F1F"/>
  </svg>
</template>
