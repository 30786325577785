<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_1122_5269)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.49968 17.1443C6.06181 18.2287 8.18206 18.2211 9.73939 17.1255C10.1214 16.8567 15.6626 11.3171 15.9812 10.8856C18.3274 7.70659 17.2444 3.19848 13.7095 1.4294C11.6817 0.414538 9.36671 0.556708 7.3656 1.81897L7.06073 2.01126L4.0234 5.03261L0.986045 8.054L0.94118 8.22601C0.810115 8.72816 1.30631 9.22837 1.80582 9.09765L1.97227 9.05409L4.86478 6.17927C8.27564 2.78928 8.22559 2.83081 9.34517 2.46173C13.8297 0.98338 17.5832 6.10443 14.8564 9.98088C14.5345 10.4385 9.23256 15.7449 8.86202 15.9804C5.97717 17.8133 2.69607 14.5707 4.47474 11.6446C4.63231 11.3854 9.60018 6.35558 10.0009 6.0496C11.3174 5.04419 12.9559 6.63887 11.9665 7.96264C11.8744 8.08585 10.8029 9.17663 9.13618 10.844L6.45403 13.5271L6.40967 13.6971C6.27863 14.1992 6.77636 14.6969 7.27841 14.5659L7.44839 14.5215L10.3073 11.6626C13.5604 8.40952 13.3471 8.65097 13.5796 7.95841C14.3927 5.53679 11.6386 3.35787 9.43455 4.6789C9.09621 4.88169 3.66893 10.2896 3.35287 10.739C1.90115 12.8024 2.42007 15.7007 4.49968 17.1443Z" fill="#B0B0B0"/>
    </g>
    <defs>
      <clipPath id="clip0_1122_5269">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
