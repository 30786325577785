<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M10.473.593c-.684.113-.769.185-3.72 3.144-3.062 3.07-2.718 2.579-2.951 4.204-.199 1.384-.165 1.667.246 2.05.405.38.643.404 2.012.208 1.628-.233 1.136.112 4.203-2.95 2.845-2.838 2.88-2.878 3.081-3.477.483-1.443-1.317-3.437-2.87-3.179ZM4.718.729C2.228.879 1.042 1.945.76 4.28c-.051.43-.051 5.01 0 5.44.261 2.175 1.258 3.196 3.423 3.506.451.065 4.989.075 5.524.013 2.174-.254 3.228-1.276 3.517-3.409.027-.198.046-.647.055-1.312l.014-1.012-.063-.122c-.17-.322-.574-.338-.781-.03-.061.09-.063.106-.063.86 0 2.919-.622 3.857-2.734 4.123-.41.051-4.928.052-5.305 0-1.847-.252-2.559-1.044-2.708-3.013-.041-.543-.02-4.712.026-5.017.315-2.105 1.212-2.694 4.104-2.694.771 0 .787 0 .877-.062.307-.206.291-.615-.03-.779-.115-.059-1.22-.084-1.9-.043Zm6.524.862c.638.305 1.239 1.037 1.276 1.553.028.39-.144.7-.698 1.263l-.369.375-.133-.053c-.909-.358-1.757-1.213-2.063-2.08l-.036-.102.403-.392c.706-.685 1.089-.818 1.62-.564ZM9.003 4.05a4.685 4.685 0 0 0 1.547 1.324l.2.105-1.758 1.76c-2.126 2.127-1.754 1.876-3.06 2.064-1.505.216-1.449.271-1.236-1.221.187-1.31-.07-.926 2.04-3.042l1.777-1.782.167.292c.092.16.237.385.323.5Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
