<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_5528_36462)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5731 15.5083C3.13849 15.8361 3.78098 16 4.45774 16C5.38293 16 6.39377 15.6981 7.43035 15.1029L12.7159 12.0323C14.5148 10.9887 15.5 9.55687 15.5 8.00433C15.5 6.45176 14.5063 5.01995 12.7159 3.97629L7.43035 0.905667C5.63135 -0.137998 3.90948 -0.284629 2.5731 0.491651C1.23672 1.26793 0.5 2.83774 0.5 4.92507V11.0749C0.5 13.1537 1.23672 14.7321 2.5731 15.5083Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5528_36462">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
