<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M12.66.752c-.058.026-.51.456-1.135 1.08l-1.039 1.037-.273-.178C7.263.772 3.55 1.795 1.38 5.123c-.533.82-.68 1.9-.395 2.914.164.587.759 1.465 1.498 2.213l.31.314-1.012 1.014c-.855.857-1.016 1.031-1.044 1.127-.111.38.26.699.616.531.176-.082 11.865-11.795 11.908-11.932.114-.36-.26-.703-.601-.552Zm-5.182 1.9a4.98 4.98 0 0 1 1.6.455c.31.147.738.39.738.419 0 .008-.307.323-.683.698l-.684.683-.075-.05c-.708-.462-1.739-.517-2.51-.133-1.333.664-1.815 2.412-1.006 3.65l.05.075-.725.725c-.398.398-.73.724-.74.724-.129 0-1.164-1.252-1.432-1.732-.384-.689-.348-1.78.082-2.467C3.386 3.63 5.505 2.432 7.478 2.653Zm3.99 1.583c-.3.183-.302.457-.007.839.631.82.775 1.178.775 1.926 0 .645-.098.957-.474 1.509-1.674 2.451-4.148 3.415-6.506 2.533-.468-.175-.752-.07-.792.291-.033.287.094.422.55.584 2.724.967 5.478-.068 7.434-2.793.878-1.222.956-2.78.198-3.96-.564-.88-.867-1.119-1.178-.929ZM7.313 5.401c.205.042.452.136.452.171 0 .025-2.167 2.193-2.193 2.193-.035 0-.129-.247-.171-.451C5.17 6.196 6.196 5.17 7.313 5.4Zm1.52 1.62c-.122.06-.206.186-.247.368-.125.544-.63 1.063-1.162 1.195-.373.093-.522.272-.467.564.176.938 2.004-.042 2.45-1.314.213-.608-.1-1.052-.575-.814Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
