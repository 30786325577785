<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#534BAF" />
    <path
      d="M25 18.9577C24.6583 18.9577 24.375 18.6743 24.375 18.3327V16.666C24.375 14.041 23.6333 12.291 20 12.291C16.3667 12.291 15.625 14.041 15.625 16.666V18.3327C15.625 18.6743 15.3417 18.9577 15 18.9577C14.6583 18.9577 14.375 18.6743 14.375 18.3327V16.666C14.375 14.2493 14.9583 11.041 20 11.041C25.0417 11.041 25.625 14.2493 25.625 16.666V18.3327C25.625 18.6743 25.3417 18.9577 25 18.9577Z"
      fill="white"
    />
    <path
      d="M20 26.0417C18.5083 26.0417 17.2916 24.825 17.2916 23.3333C17.2916 21.8417 18.5083 20.625 20 20.625C21.4916 20.625 22.7083 21.8417 22.7083 23.3333C22.7083 24.825 21.4916 26.0417 20 26.0417ZM20 21.875C19.2 21.875 18.5416 22.5333 18.5416 23.3333C18.5416 24.1333 19.2 24.7917 20 24.7917C20.8 24.7917 21.4583 24.1333 21.4583 23.3333C21.4583 22.5333 20.8 21.875 20 21.875Z"
      fill="white"
    />
    <path
      d="M24.1666 28.959H15.8333C12.1583 28.959 11.0416 27.8423 11.0416 24.1673V22.5007C11.0416 18.8257 12.1583 17.709 15.8333 17.709H24.1666C27.8416 17.709 28.9583 18.8257 28.9583 22.5007V24.1673C28.9583 27.8423 27.8416 28.959 24.1666 28.959ZM15.8333 18.959C12.85 18.959 12.2916 19.5257 12.2916 22.5007V24.1673C12.2916 27.1423 12.85 27.709 15.8333 27.709H24.1666C27.15 27.709 27.7083 27.1423 27.7083 24.1673V22.5007C27.7083 19.5257 27.15 18.959 24.1666 18.959H15.8333Z"
      fill="white"
    />
  </svg>
</template>
