<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9996 22.4C17.588 22.4 22.1183 17.7437 22.1183 12C22.1183 6.25621 17.588 1.59998 11.9996 1.59998C6.41117 1.59998 1.88086 6.25621 1.88086 12C1.88086 17.7437 6.41117 22.4 11.9996 22.4Z" fill="url(#paint0_linear_862_4879)"/>
    <path d="M14.4004 12C14.4004 12 14.6708 12.8 16.0004 12.8C17.2624 12.8 17.6004 12 17.6004 12" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.40039 12C6.40039 12 6.67081 12.8 8.00039 12.8C9.26236 12.8 9.60039 12 9.60039 12" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4004 18.4C10.4004 18.4 11.7413 16.6 13.6004 18.4" stroke="#5B0600" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.9737 13.6451L18.9662 16.3744C19.1692 16.9609 18.876 17.5925 18.2895 17.7955C17.703 17.9985 17.0715 17.7052 16.8685 17.1188C16.7782 16.8707 16.7782 16.6 16.8685 16.3744L17.8609 13.6451C17.8835 13.6225 17.906 13.6 17.9512 13.6C17.9512 13.6225 17.9737 13.6225 17.9737 13.6451Z" fill="#91B5E6"/>
    <defs>
      <linearGradient id="paint0_linear_862_4879" x1="3.22379" y1="17.205" x2="20.9986" y2="7.22032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
    </defs>
  </svg>
</template>
