<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1863 15.6903H12.7817C12.4003 15.6903 12.084 15.374 12.084 14.9926C12.084 14.6112 12.4003 14.2949 12.7817 14.2949H14.1863C16.6235 14.2949 18.6048 12.3136 18.6048 9.87639C18.6048 7.43921 16.6235 5.45783 14.1863 5.45783H12.791C12.4096 5.45783 12.0933 5.14156 12.0933 4.76017C12.0933 4.37878 12.4003 4.0625 12.791 4.0625H14.1863C17.3956 4.0625 20.0002 6.66712 20.0002 9.87639C20.0002 13.0857 17.3956 15.6903 14.1863 15.6903Z"
      fill="currentColor"
    />
    <path
      d="M7.20922 15.6903H5.81389C2.60462 15.6903 0 13.0857 0 9.87639C0 6.66712 2.60462 4.0625 5.81389 4.0625H7.20922C7.59061 4.0625 7.90689 4.37878 7.90689 4.76017C7.90689 5.14156 7.59061 5.45783 7.20922 5.45783H5.81389C3.37671 5.45783 1.39533 7.43921 1.39533 9.87639C1.39533 12.3136 3.37671 14.2949 5.81389 14.2949H7.20922C7.59061 14.2949 7.90689 14.6112 7.90689 14.9926C7.90689 15.374 7.59061 15.6903 7.20922 15.6903Z"
      fill="currentColor"
    />
    <path
      d="M13.7215 10.5739H6.2797C5.89831 10.5739 5.58203 10.2576 5.58203 9.87626C5.58203 9.49486 5.89831 9.17859 6.2797 9.17859H13.7215C14.1029 9.17859 14.4191 9.49486 14.4191 9.87626C14.4191 10.2576 14.1029 10.5739 13.7215 10.5739Z"
      fill="currentColor"
    />
  </svg>
</template>
