<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7792)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.67736 7.43289C4.53255 7.09849 4.68625 6.71 5.02055 6.56519L7.42155 5.52524C7.75621 5.38035 8.14444 5.53413 8.28925 5.86843C8.43405 6.20282 8.28036 6.59132 7.94606 6.73613L5.54506 7.77608C5.45963 7.81302 5.37069 7.83062 5.28316 7.83062C5.02812 7.83062 4.78513 7.68177 4.67736 7.43289Z" fill="#4C2908"/>
      <path d="M10 16.3692C11.1046 16.3692 12 15.4311 12 14.2739C12 13.1168 11.1046 12.1787 10 12.1787C8.89543 12.1787 8 13.1168 8 14.2739C8 15.4311 8.89543 16.3692 10 16.3692Z" fill="#1B1C1D"/>
      <path d="M10.0018 16.3695C10.5197 16.3695 10.9902 16.1615 11.3454 15.823L11.3351 15.798C11.3351 15.1184 10.7382 14.5674 10.0018 14.5674C9.2654 14.5674 8.66844 15.1184 8.66844 15.798L8.66406 15.8285C9.01854 16.1638 9.48673 16.3695 10.0018 16.3695Z" fill="#F64F4F"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7792">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
