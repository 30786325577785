<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.9974 5.94094C15.6899 5.94094 15.4349 5.68594 15.4349 5.37844V4.01344C15.4349 3.46594 14.9924 3.02344 14.4449 3.02344H3.55494C3.00744 3.02344 2.56494 3.46594 2.56494 4.01344V5.38594C2.56494 5.69344 2.30994 5.94844 2.00244 5.94844C1.69494 5.94844 1.43994 5.69344 1.43994 5.37844V4.01344C1.43994 2.84344 2.39244 1.89844 3.55494 1.89844H14.4449C15.6149 1.89844 16.5599 2.85094 16.5599 4.01344V5.38594C16.5599 5.69344 16.3124 5.94094 15.9974 5.94094Z"
        fill="#49A399"/>
    <path
        d="M9 16.102C8.6925 16.102 8.4375 15.847 8.4375 15.5395V3.08203C8.4375 2.77453 8.6925 2.51953 9 2.51953C9.3075 2.51953 9.5625 2.77453 9.5625 3.08203V15.5395C9.5625 15.8545 9.3075 16.102 9 16.102Z"
        fill="#49A399"/>
    <path
        d="M11.9549 16.1016H6.04492C5.73742 16.1016 5.48242 15.8466 5.48242 15.5391C5.48242 15.2316 5.73742 14.9766 6.04492 14.9766H11.9549C12.2624 14.9766 12.5174 15.2316 12.5174 15.5391C12.5174 15.8466 12.2624 16.1016 11.9549 16.1016Z"
        fill="#49A399"/>
  </svg>
</template>
