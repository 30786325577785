<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9996 22.4C17.588 22.4 22.1183 17.7437 22.1183 12C22.1183 6.25621 17.588 1.59998 11.9996 1.59998C6.41117 1.59998 1.88086 6.25621 1.88086 12C1.88086 17.7437 6.41117 22.4 11.9996 22.4Z" fill="url(#paint0_linear_862_4887)"/>
    <path d="M12.0005 19.3586C13.3264 19.3586 14.4013 17.5323 14.4013 15.2793C14.4013 13.0263 13.3264 11.2 12.0005 11.2C10.6745 11.2 9.59961 13.0263 9.59961 15.2793C9.59961 17.5323 10.6745 19.3586 12.0005 19.3586Z" fill="#303030"/>
    <path d="M14.0304 17.4676C12.2775 16.5009 10.854 17.0533 10.0254 17.5951C10.4609 18.6575 11.1833 19.3586 12.0013 19.3586C12.8512 19.3586 13.6054 18.6043 14.0304 17.4676Z" fill="#F06669"/>
    <path d="M9.60638 11.1315C10.4012 9.58834 10.1178 7.90364 8.97355 7.36866C7.82925 6.83368 6.25731 7.651 5.46252 9.1942C4.66774 10.7374 4.95107 12.4221 6.09537 12.9571C7.23966 13.4921 8.8116 12.6747 9.60638 11.1315Z" fill="white"/>
    <path d="M17.9286 12.959C19.0729 12.424 19.3562 10.7393 18.5615 9.19611C17.7667 7.65291 16.1947 6.83558 15.0504 7.37056C13.9062 7.90554 13.6228 9.59025 14.4176 11.1334C15.2124 12.6766 16.7843 13.494 17.9286 12.959Z" fill="white"/>
    <path d="M16.8771 6.36592C16.6309 6.36592 16.4375 6.17248 16.4375 5.92629V0.439635C16.4375 0.19344 16.6309 0 16.8771 0C17.1233 0 17.3168 0.19344 17.3168 0.439635V5.92629C17.3168 6.1549 17.1057 6.36592 16.8771 6.36592Z" fill="#414042"/>
    <path d="M18.8986 5.46904C18.6524 5.46904 18.459 5.27561 18.459 5.02941V1.07269C18.459 0.826496 18.6524 0.633057 18.8986 0.633057C19.1448 0.633057 19.3383 0.826496 19.3383 1.07269V5.02941C19.3383 5.27561 19.1448 5.46904 18.8986 5.46904Z" fill="#414042"/>
    <path d="M20.9396 8.3882C20.6934 8.3882 20.5 8.19476 20.5 7.94856V2.03986C20.5 1.79366 20.6934 1.60022 20.9396 1.60022C21.1858 1.60022 21.3793 1.79366 21.3793 2.03986V7.94856C21.3793 8.19476 21.1858 8.3882 20.9396 8.3882Z" fill="#414042"/>
    <defs>
      <linearGradient id="paint0_linear_862_4887" x1="3.22379" y1="17.205" x2="20.9986" y2="7.22032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
    </defs>
  </svg>
</template>
