<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7682)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M11.6997 5.9258C11.8337 5.58696 12.2171 5.42093 12.5559 5.55492L14.989 6.51733C15.3281 6.65152 15.4939 7.03478 15.3599 7.37356C15.2259 7.7124 14.8425 7.87843 14.5037 7.74444L12.0706 6.78203C11.984 6.74775 11.9087 6.6973 11.8468 6.6354C11.6665 6.45506 11.5999 6.17799 11.6997 5.9258Z" fill="#4C2908"/>
      <path d="M4.65281 7.37498C4.78687 7.71382 5.17025 7.87986 5.50904 7.74586L7.94216 6.78345C8.28125 6.64927 8.44703 6.26601 8.31304 5.92722C8.17898 5.58838 7.79559 5.42235 7.45681 5.55634L5.02369 6.51875C4.93716 6.55303 4.86183 6.60348 4.79993 6.66538C4.61959 6.84572 4.55303 7.12279 4.65281 7.37498Z" fill="#4C2908"/>
      <path d="M15.9967 13.8516C16.0292 14.9285 15.8169 15.8485 15.6 16.5492C15.5864 16.5934 15.5701 16.6367 15.5513 16.679C15.4793 16.8401 15.372 16.9839 15.2369 17.1C15.1018 17.2162 14.9423 17.3019 14.7695 17.3512C13.2102 17.8094 11.5897 18.0376 9.96149 18.0284C8.18256 18.027 6.41273 17.7804 4.70431 17.2959C4.53192 17.2471 4.38428 17.1374 4.29011 16.9881C4.27013 16.9478 4.25015 16.9079 4.22975 16.8685C4.22929 16.8677 4.22901 16.8669 4.22892 16.866C4.21067 16.8179 4.19769 16.768 4.19021 16.7172C4.03169 15.6406 3.97206 14.5522 4.01204 13.4652C4.03161 12.9459 4.07282 12.4529 4.1286 11.9905C4.16265 11.7129 4.27675 11.4504 4.45761 11.2337C4.63847 11.0169 4.87864 10.8549 5.15015 10.7664C6.38769 10.3622 7.67329 10.1152 8.97533 10.0315C11.4971 9.8704 13.3725 10.3572 14.695 10.8245C14.9167 10.9027 15.1166 11.0304 15.2789 11.1974C15.4413 11.3643 15.5614 11.566 15.63 11.7864C15.8069 12.3582 15.973 13.0553 15.9967 13.8516Z" fill="#72BCEF"/>
      <path d="M13.9707 12.0467C11.3064 11.5521 8.57058 11.5521 5.90625 12.0467C5.87403 12.0527 5.84333 12.0648 5.8159 12.0824C5.78847 12.0999 5.76486 12.1226 5.74641 12.1491C5.72795 12.1756 5.71502 12.2054 5.70835 12.2368C5.70168 12.2682 5.70139 12.3005 5.70752 12.332C5.71364 12.3635 5.72606 12.3935 5.74405 12.4203C5.76204 12.4471 5.78525 12.4701 5.81237 12.4882C5.83949 12.5062 5.86997 12.5188 5.90209 12.5253C5.9342 12.5319 5.96732 12.5321 5.99954 12.5261C8.60224 12.0431 11.2748 12.0431 13.8775 12.5261C13.9425 12.538 14.0096 12.5242 14.0641 12.4877C14.1187 12.4513 14.1563 12.3952 14.1686 12.3318C14.1809 12.2684 14.167 12.2027 14.1299 12.1493C14.0929 12.0959 14.0356 12.059 13.9707 12.0467Z" fill="#344D6B"/>
      <path d="M13.9239 13.9395H5.95289C5.88665 13.9395 5.82312 13.9652 5.77628 14.0109C5.72944 14.0567 5.70312 14.1188 5.70312 14.1835C5.70312 14.2482 5.72944 14.3103 5.77628 14.356C5.82312 14.4018 5.88665 14.4275 5.95289 14.4275H13.9239C13.9902 14.4275 14.0537 14.4018 14.1005 14.356C14.1474 14.3103 14.1737 14.2482 14.1737 14.1835C14.1737 14.1188 14.1474 14.0567 14.1005 14.0109C14.0537 13.9652 13.9902 13.9395 13.9239 13.9395Z" fill="#344D6B"/>
      <path d="M13.8628 15.7614C12.6078 16.0612 11.3211 16.2164 10.0291 16.2237C8.67518 16.2311 7.32545 16.0758 6.01029 15.7614C5.97833 15.7535 5.94508 15.7518 5.91247 15.7565C5.87985 15.7612 5.84851 15.7722 5.82026 15.7888C5.792 15.8054 5.76739 15.8273 5.74785 15.8533C5.7283 15.8792 5.7142 15.9087 5.70637 15.9399C5.69854 15.9712 5.69712 16.0037 5.70221 16.0356C5.70729 16.0674 5.71878 16.0979 5.736 16.1254C5.75322 16.1528 5.77583 16.1767 5.80254 16.1956C5.82925 16.2145 5.85952 16.228 5.89161 16.2354C7.21568 16.5518 8.57383 16.7118 9.93692 16.7119C9.96856 16.7119 10.0003 16.7119 10.0319 16.7117C11.363 16.7042 12.6885 16.5443 13.9815 16.2354C14.0456 16.2198 14.1007 16.18 14.1348 16.1248C14.1689 16.0695 14.1792 16.0033 14.1635 15.9407C14.1478 15.878 14.1074 15.824 14.051 15.7904C13.9947 15.7568 13.927 15.7464 13.8628 15.7614Z" fill="#344D6B"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7682">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
