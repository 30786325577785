<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M7 .903c-.372.083-.767.233-1.019.39-.253.156-3.864 2.96-4.084 3.172-.503.481-.955 1.374-1.05 2.076-.045.329-.046 5.346-.001 5.71a3.32 3.32 0 0 0 2.97 2.904c.43.044 7.939.044 8.37 0a3.31 3.31 0 0 0 2.972-2.936c.072-.633.025-5.599-.057-5.891-.194-.696-.525-1.286-.973-1.734-.303-.304-4.394-3.183-4.85-3.415C8.67.871 7.685.752 7 .903ZM8.422 1.95c.332.093.534.223 2.689 1.73 2.663 1.863 2.79 1.991 3.004 3.018.074.353.046 5.463-.03 5.719-.28.93-1.016 1.577-1.94 1.705-.162.023-1.521.034-4.143.034s-3.982-.011-4.145-.034c-.92-.128-1.659-.777-1.938-1.705-.08-.263-.105-5.448-.028-5.824.086-.428.288-.854.57-1.202.22-.27 3.924-3.155 4.24-3.3.474-.22 1.223-.28 1.721-.14Z"
      clip-rule="evenodd"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M7.78 9.54c-.285.143-.28.116-.28 1.457v1.176l.076.112a.525.525 0 0 0 .848 0l.076-.112v-1.176c0-1.168 0-1.176-.07-1.281-.078-.117-.304-.247-.43-.247a.65.65 0 0 0-.22.07Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
