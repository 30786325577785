<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9983 0.0607141C12.6358 0.201344 12.4336 0.371141 12.2747 0.668304L12.1175 0.962187L12.102 3.42756L12.0864 5.89287H10.9726C3.98208 5.89287 0.189563 10.1144 0.0113913 18.0942C-0.0455904 20.6448 0.0932855 21.2371 0.839485 21.6267C1.54343 21.9941 2.14387 21.7033 2.64281 20.7529C3.94781 18.2671 5.97177 17.057 9.32178 16.7597C10.0431 16.6957 11.3772 16.6837 11.8084 16.7374L12.0906 16.7725V18.7736C12.0906 19.9973 12.1128 20.8655 12.1475 21.0084C12.3514 21.845 13.2219 22.2358 14.0262 21.8518C14.3754 21.685 23.6671 12.1264 23.88 11.715C24.0322 11.4208 24.0409 10.8267 23.8989 10.4335C23.7844 10.117 14.5489 0.453865 14.0913 0.171892C13.8108 -0.000900483 13.293 -0.0536012 12.9983 0.0607141ZM17.9913 15.2945L13.8649 19.5705V17.8364C13.8649 14.9185 13.7913 14.8584 10.3164 14.9354C6.44895 15.0212 3.9463 15.9888 2.29128 18.0385C2.08097 18.2989 1.87417 18.5496 1.83178 18.5956C1.72022 18.7166 1.78402 17.0301 1.92936 16.0182C2.72862 10.4521 5.76793 7.70729 11.0422 7.7883C12.9097 7.81697 12.9008 7.8174 13.1458 7.68711C13.8221 7.32733 13.8623 7.15504 13.8637 4.61151L13.8649 2.46654L17.9913 6.74256L22.1176 11.0185L17.9913 15.2945Z"
      fill="currentColor"
    />
  </svg>
</template>
