<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.99967 15.1668H5.99967C2.37967 15.1668 0.833008 13.6202 0.833008 10.0002V6.00016C0.833008 2.38016 2.37967 0.833496 5.99967 0.833496H9.99967C13.6197 0.833496 15.1663 2.38016 15.1663 6.00016V10.0002C15.1663 13.6202 13.6197 15.1668 9.99967 15.1668ZM5.99967 1.8335C2.92634 1.8335 1.83301 2.92683 1.83301 6.00016V10.0002C1.83301 13.0735 2.92634 14.1668 5.99967 14.1668H9.99967C13.073 14.1668 14.1663 13.0735 14.1663 10.0002V6.00016C14.1663 2.92683 13.073 1.8335 9.99967 1.8335H5.99967Z"
      fill="currentColor"
    />
    <path
      d="M14.3197 5.24023H1.67969C1.40635 5.24023 1.17969 5.01357 1.17969 4.74023C1.17969 4.4669 1.39969 4.24023 1.67969 4.24023H14.3197C14.593 4.24023 14.8197 4.4669 14.8197 4.74023C14.8197 5.01357 14.5997 5.24023 14.3197 5.24023Z"
      fill="currentColor"
    />
    <path
      d="M5.67969 5.14674C5.40635 5.14674 5.17969 4.92007 5.17969 4.64674V1.40674C5.17969 1.1334 5.40635 0.906738 5.67969 0.906738C5.95302 0.906738 6.17969 1.1334 6.17969 1.40674V4.64674C6.17969 4.92007 5.95302 5.14674 5.67969 5.14674Z"
      fill="currentColor"
    />
    <path
      d="M10.3203 4.84674C10.047 4.84674 9.82031 4.62007 9.82031 4.34674V1.40674C9.82031 1.1334 10.047 0.906738 10.3203 0.906738C10.5936 0.906738 10.8203 1.1334 10.8203 1.40674V4.34674C10.8203 4.62674 10.6003 4.84674 10.3203 4.84674Z"
      fill="currentColor"
    />
    <path
      d="M7.39333 12.0798C7.15333 12.0798 6.92667 12.0198 6.72 11.9065C6.26667 11.6398 6 11.1065 6 10.4332V8.83318C6 8.15984 6.26667 7.61984 6.72667 7.35318C7.18667 7.08651 7.78667 7.12651 8.36667 7.46651L9.75333 8.26651C10.3333 8.59984 10.6733 9.09984 10.6733 9.63318C10.6733 10.1665 10.3333 10.6665 9.74667 10.9998L8.36 11.7998C8.04 11.9865 7.70667 12.0798 7.39333 12.0798ZM7.4 8.17984C7.33333 8.17984 7.27333 8.19318 7.22667 8.21984C7.08667 8.29984 7 8.52651 7 8.83318V10.4332C7 10.7332 7.08667 10.9598 7.22667 11.0465C7.36667 11.1265 7.60667 11.0865 7.86667 10.9332L9.25333 10.1332C9.51333 9.97984 9.67333 9.79318 9.67333 9.63318C9.67333 9.47318 9.52 9.28651 9.25333 9.13318L7.86667 8.33318C7.69333 8.23318 7.52667 8.17984 7.4 8.17984Z"
      fill="currentColor"
    />
  </svg>
</template>
