<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49998 14C10.9927 14 13.8242 11.0899 13.8242 7.5C13.8242 3.91015 10.9927 1 7.49998 1C4.00722 1 1.17578 3.91015 1.17578 7.5C1.17578 11.0899 4.00722 14 7.49998 14Z" fill="url(#paint0_linear_862_5090)"/>
    <path d="M6.5 11.5C6.5 11.5 7.3381 10.375 8.5 11.5" stroke="#5B0600" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.2331 8.52819L11.8534 10.234C11.9803 10.6006 11.797 10.9953 11.4305 11.1222C11.0639 11.2491 10.6692 11.0658 10.5423 10.6992C10.4859 10.5442 10.4859 10.375 10.5423 10.234L11.1626 8.52819C11.1767 8.5141 11.1908 8.5 11.219 8.5C11.219 8.5141 11.2331 8.5141 11.2331 8.52819Z" fill="#91B5E6"/>
    <path d="M4 7.5C4 7.5 4.16901 8 5 8C5.78873 8 6 7.5 6 7.5" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.0625 7.5L3.4375 7.8125" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M4.0625 7.8125L3.75 8.125" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M10.75 7.5C10.75 7.5 10.581 8 9.75 8C8.96127 8 8.75 7.5 8.75 7.5" stroke="#303030" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6875 7.5L11.3125 7.8125" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <path d="M10.6875 7.8125L11 8.125" stroke="#303030" stroke-width="0.5" stroke-linecap="round"/>
    <defs>
      <linearGradient id="paint0_linear_862_5090" x1="2.01512" y1="10.7532" x2="13.1243" y2="4.51272" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EB3E7D"/>
        <stop offset="0.0001" stop-color="#EB3E7D"/>
        <stop offset="0.033108" stop-color="#F84E8C"/>
        <stop offset="0.1781" stop-color="#F86399"/>
        <stop offset="0.5514" stop-color="#E76493"/>
        <stop offset="1" stop-color="#FD7FB4"/>
      </linearGradient>
    </defs>
  </svg>
</template>
