<template>
  <svg
    v-if="!isActive"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0007 26.5416C7.08232 26.5416 1.45898 20.9183 1.45898 13.9999C1.45898 7.08159 7.08232 1.45825 14.0007 1.45825C20.919 1.45825 26.5423 7.08159 26.5423 13.9999C26.5423 20.9183 20.919 26.5416 14.0007 26.5416ZM14.0007 3.20825C8.05065 3.20825 3.20898 8.04992 3.20898 13.9999C3.20898 19.9499 8.05065 24.7916 14.0007 24.7916C19.9507 24.7916 24.7923 19.9499 24.7923 13.9999C24.7923 8.04992 19.9507 3.20825 14.0007 3.20825Z"
      fill="currentColor"
    />
    <path
      d="M18.6673 14.875H9.33398C8.85565 14.875 8.45898 14.4783 8.45898 14C8.45898 13.5217 8.85565 13.125 9.33398 13.125H18.6673C19.1457 13.125 19.5423 13.5217 19.5423 14C19.5423 14.4783 19.1457 14.875 18.6673 14.875Z"
      fill="currentColor"
    />
    <path
      d="M14 19.5416C13.5217 19.5416 13.125 19.1449 13.125 18.6666V9.33325C13.125 8.85492 13.5217 8.45825 14 8.45825C14.4783 8.45825 14.875 8.85492 14.875 9.33325V18.6666C14.875 19.1449 14.4783 19.5416 14 19.5416Z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9993 26.8334C6.9117 26.8334 1.16602 21.0877 1.16602 14.0001C1.16602 6.91243 6.9117 1.16675 13.9993 1.16675C21.087 1.16675 26.8327 6.91243 26.8327 14.0001C26.8327 21.0877 21.087 26.8334 13.9993 26.8334ZM8.45768 14.0001C8.45768 14.4784 8.85435 14.8751 9.33268 14.8751H13.1243V18.6667C13.1243 19.1451 13.521 19.5417 13.9993 19.5417C14.4777 19.5417 14.8743 19.1451 14.8743 18.6667V14.8751H18.666C19.1443 14.8751 19.541 14.4784 19.541 14.0001C19.541 13.5217 19.1443 13.1251 18.666 13.1251H14.8743V9.33341C14.8743 8.85508 14.4777 8.45841 13.9993 8.45841C13.521 8.45841 13.1243 8.85508 13.1243 9.33341V13.1251H9.33268C8.85435 13.1251 8.45768 13.5217 8.45768 14.0001Z"
      fill="#49A399"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
