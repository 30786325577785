<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.84999 16.1033H3.43501C2.06251 16.1033 0.9375 14.9859 0.9375 13.6059V9.25586C0.9375 8.94836 1.1925 8.69336 1.5 8.69336H5.84999C7.27499 8.69336 8.3475 9.76583 8.3475 11.1908V13.6059C8.34 15.0309 7.26749 16.1033 5.84999 16.1033ZM2.0625 9.82582V13.6134C2.0625 14.3709 2.67751 14.9858 3.43501 14.9858H5.84999C6.64499 14.9858 7.2225 14.4084 7.2225 13.6134V11.1983C7.2225 10.4033 6.64499 9.82582 5.84999 9.82582H2.0625Z"
        fill="#49A399"/>
    <path
        d="M1.5 9.82509C1.1925 9.82509 0.9375 9.57009 0.9375 9.26259C0.9375 4.57509 1.89002 3.59262 4.61252 1.98012C4.88252 1.82262 5.22749 1.91263 5.38499 2.17513C5.54249 2.44513 5.45249 2.79011 5.18999 2.94761C2.75999 4.38761 2.0625 4.98759 2.0625 9.26259C2.0625 9.57009 1.8075 9.82509 1.5 9.82509Z"
        fill="#49A399"/>
    <path
        d="M14.5648 16.1014H12.1499C10.7774 16.1014 9.65234 14.9839 9.65234 13.6039V9.25391C9.65234 8.94641 9.90734 8.69141 10.2148 8.69141H14.5648C15.9898 8.69141 17.0623 9.76387 17.0623 11.1889V13.6039C17.0623 15.0289 15.9898 16.1014 14.5648 16.1014ZM10.7849 9.82387V13.6114C10.7849 14.3689 11.3999 14.9839 12.1574 14.9839H14.5723C15.3673 14.9839 15.9449 14.4064 15.9449 13.6114V11.1964C15.9449 10.4014 15.3673 9.82387 14.5723 9.82387H10.7849Z"
        fill="#49A399"/>
    <path
        d="M10.2227 9.82509C9.91516 9.82509 9.66016 9.57009 9.66016 9.26259C9.66016 4.57509 10.6127 3.59262 13.3352 1.98012C13.6052 1.82262 13.9502 1.91263 14.1077 2.17513C14.2652 2.44513 14.1751 2.79011 13.9126 2.94761C11.4826 4.38761 10.7852 4.98759 10.7852 9.26259C10.7852 9.57009 10.5302 9.82509 10.2227 9.82509Z"
        fill="#49A399"/>
  </svg>
</template>
