export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "placeholders": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email..."])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password..."])}
    },
    "forget-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])}
  }
}