<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.99998 15.1673H5.99998C2.37998 15.1673 0.833313 13.6207 0.833313 10.0007V6.00065C0.833313 2.38065 2.37998 0.833984 5.99998 0.833984H9.99998C13.62 0.833984 15.1666 2.38065 15.1666 6.00065V10.0007C15.1666 13.6207 13.62 15.1673 9.99998 15.1673ZM5.99998 1.83398C2.92665 1.83398 1.83331 2.92732 1.83331 6.00065V10.0007C1.83331 13.074 2.92665 14.1673 5.99998 14.1673H9.99998C13.0733 14.1673 14.1666 13.074 14.1666 10.0007V6.00065C14.1666 2.92732 13.0733 1.83398 9.99998 1.83398H5.99998Z"
        fill="#1F1F1F"/>
    <path
        d="M8.0001 12.6327C7.8401 12.6327 7.68011 12.5927 7.54677 12.5194C6.77344 12.0994 5.40677 11.6461 4.54677 11.5327L4.4201 11.5127C3.76677 11.4327 3.24677 10.8461 3.24677 10.1727V5.03941C3.24677 4.65941 3.40678 4.29274 3.68011 4.03941C3.96011 3.78607 4.3201 3.65941 4.70677 3.69275C5.6801 3.77275 7.09344 4.23942 7.92678 4.75942L7.99343 4.79942L8.02012 4.77942C8.86012 4.25275 10.2734 3.77941 11.2534 3.69275C11.2668 3.69275 11.3068 3.69275 11.3201 3.69275C11.6668 3.65941 12.0401 3.79274 12.3134 4.03941C12.5934 4.29274 12.7468 4.64607 12.7468 5.03273V10.1594C12.7468 10.8261 12.2268 11.4127 11.5668 11.4994L11.4268 11.5194C10.5734 11.6327 9.20008 12.0861 8.44008 12.5061C8.31342 12.5994 8.1601 12.6327 8.0001 12.6327ZM4.59344 4.6994C4.50011 4.6994 4.42009 4.73274 4.36009 4.78607C4.28675 4.85274 4.25344 4.94608 4.25344 5.04608V10.1794C4.25344 10.3394 4.40011 10.5061 4.55345 10.5261L4.68011 10.5461C5.65345 10.6794 7.14678 11.1661 8.01345 11.6394C8.82012 11.1727 10.3201 10.6727 11.3001 10.5461L11.4468 10.5261C11.6001 10.5061 11.7468 10.3394 11.7468 10.1794V5.05273C11.7468 4.94607 11.7068 4.85941 11.6401 4.79274C11.5668 4.72608 11.4734 4.69941 11.3668 4.70607C11.3534 4.70607 11.3134 4.70607 11.3001 4.70607C10.5268 4.77274 9.25345 5.1994 8.56012 5.6394L8.49343 5.6794C8.2001 5.8594 7.78678 5.85941 7.50677 5.68607L7.40676 5.62608C6.70676 5.18608 5.43344 4.76607 4.62677 4.6994C4.61344 4.6994 4.60678 4.6994 4.59344 4.6994Z"
        fill="#1F1F1F"/>
    <path
        d="M8 12.2737C7.72667 12.2737 7.5 12.0471 7.5 11.7737V5.40039C7.5 5.12706 7.72667 4.90039 8 4.90039C8.27333 4.90039 8.5 5.12706 8.5 5.40039V11.7737C8.5 12.0537 8.27333 12.2737 8 12.2737Z"
        fill="#1F1F1F"/>
  </svg>
</template>
