<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.7559 13.333C12.1759 13.3545 10.6512 12.7519 9.51285 11.656C8.3745 10.5601 7.71442 9.05936 7.67594 7.47967C7.67624 6.70457 7.83351 5.93755 8.13829 5.22488C8.44307 4.51221 8.88902 3.86865 9.44927 3.33301C7.7884 3.497 6.24636 4.26781 5.11838 5.49788C3.99041 6.72795 3.35576 8.33084 3.33594 9.99967C3.37619 11.8039 4.1299 13.5186 5.43196 14.7682C6.73402 16.0177 8.47826 16.7003 10.2826 16.6663C11.6263 16.6781 12.9445 16.3002 14.078 15.5785C15.2114 14.8568 16.1115 13.8221 16.6693 12.5997C15.773 13.0793 14.7725 13.3311 13.7559 13.333Z"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke="isChecked ? '#49A399' : '#9D9D9D'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isChecked: {
      type: Boolean,
      default: false
    }
  }
}
</script>
