<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.515 1.98674C7.46344 1.99398 7.29096 2.01502 7.13176 2.0335C5.1298 2.2657 3.07888 3.64266 1.68928 5.68746C0.719759 7.11414 0.696358 8.83974 1.62772 10.2271C4.97552 15.214 10.7238 15.3056 14.2261 10.4279C15.2451 9.00858 15.3203 7.23446 14.4199 5.85322C13.055 3.7593 11.0694 2.37058 8.9738 2.04442C8.68012 1.99874 7.70432 1.96014 7.515 1.98674ZM8.96084 3.09234C11.0834 3.48306 13.5796 5.63294 13.952 7.39098C14.197 8.54758 13.7644 9.5827 12.4857 10.9C9.5196 13.9555 5.46912 13.6229 2.82464 10.1069C1.8566 8.8199 1.71004 7.60298 2.39184 6.51298C3.96916 3.99134 6.5484 2.64822 8.96084 3.09234ZM7.68688 5.11174C7.64388 5.11942 7.52436 5.1409 7.42124 5.1595C4.94512 5.60614 4.25988 8.98342 6.35872 10.3963C8.64032 11.9323 11.5876 9.74026 10.7622 7.12134C10.356 5.83258 8.91728 4.89246 7.68688 5.11174ZM8.35872 6.17178C9.81836 6.47718 10.3614 8.26506 9.31292 9.3135C8.26448 10.362 6.4766 9.81894 6.1712 8.3593C5.905 7.08702 7.08644 5.90558 8.35872 6.17178Z"
      fill="currentColor"
    />
  </svg>
</template>
