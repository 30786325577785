<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.5" cy="14.5" r="13.5" fill="currentColor" />
    <path
      d="M14.5 29C6.501 29 0 22.499 0 14.5S6.501 0 14.5 0 29 6.501 29 14.5 22.499 29 14.5 29Zm0-26.977C7.62 2.023 2.023 7.621 2.023 14.5c0 6.88 5.598 12.477 12.477 12.477 6.88 0 12.477-5.598 12.477-12.477 0-6.88-5.598-12.477-12.477-12.477Z"
      fill="currentColor"
    />
    <path
      d="M8.392 15.216a1 1 0 0 1-.295-.715c0-.553.457-1.011 1.01-1.011h10.797c.553 0 1.011.458 1.011 1.011 0 .553-.458 1.011-1.01 1.011H9.107a.979.979 0 0 1-.716-.296Z"
      fill="currentColor"
    />
    <path
      d="M13.79 20.615a1 1 0 0 1-.295-.716V9.103c0-.554.458-1.011 1.01-1.011.554 0 1.012.457 1.012 1.01V19.9c0 .553-.458 1.011-1.011 1.011a1 1 0 0 1-.716-.295Z"
      fill="currentColor"
    />
  </svg>
</template>
