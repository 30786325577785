<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3220_7811)">
      <path d="M3.67799 14.7453L3.78768 12.5037C3.93858 12.5037 3.65029 10.4715 3.78768 10.5133V7.60195L3.34444 7.54492L2.16406 7.60195L2.67526 10.0949L3.67799 14.7453Z" fill="#4D2908"/>
      <path d="M16.279 14.7453L16.1693 12.5037C16.0184 12.5037 16.3067 10.4715 16.1693 10.5133V7.60195L16.6126 7.54492L17.793 7.60195L17.2818 10.0949L16.279 14.7453Z" fill="#4D2908"/>
      <path d="M4.16406 12.3531C4.16406 14.8856 6.22443 16.946 8.75696 16.946H11.1917C13.7242 16.946 15.7846 14.8856 15.7846 12.3531V7.54492H4.16406V12.3531Z" fill="#FACCB4"/>
      <path d="M15.7291 7.60352V12.3547C15.7291 14.8558 13.6943 16.8905 11.1932 16.8905H8.75852C6.25742 16.8905 4.22266 14.8558 4.22266 12.3547V7.60352H3.65234V14.4212C3.65234 17.5038 6.15128 20.0027 9.23382 20.0027H10.718C13.8005 20.0027 16.2995 17.5037 16.2995 14.4212V7.60352H15.7291Z" fill="#4D2908"/>
      <path d="M17.3536 5.85385L17.7042 5.79682C17.1941 2.53481 14.3722 0.0390625 10.9671 0.0390625H8.98319C5.57808 0.0390625 2.75607 2.53481 2.24609 5.79682L2.59666 5.85385H17.3536Z" fill="#009D94"/>
      <path d="M2.24647 5.79688C2.19235 6.14288 2.16406 6.49745 2.16406 6.85868V7.6026H17.787V6.85868C17.787 6.49745 17.7586 6.14288 17.7046 5.79688H2.24647Z" fill="#00819D"/>
      <path d="M8.4486 6.64961C8.44893 7.01401 8.15378 7.30971 7.78946 7.31008L5.17291 7.31269C4.80823 7.313 4.51281 7.01788 4.51244 6.65356C4.5121 6.28916 4.80725 5.99346 5.17157 5.99309L7.78812 5.99048C7.88119 5.99043 7.96982 6.00955 8.05018 6.04426C8.28431 6.14539 8.44835 6.3784 8.4486 6.64961Z" fill="#4C2908"/>
      <path d="M15.1439 6.64961C15.1442 7.01401 14.8491 7.30971 14.4848 7.31008L11.8682 7.31269C11.5035 7.313 11.2081 7.01788 11.2077 6.65356C11.2074 6.28916 11.5026 5.99346 11.8669 5.99309L14.4834 5.99048C14.5765 5.99043 14.6651 6.00955 14.7455 6.04426C14.9796 6.14539 15.1437 6.3784 15.1439 6.64961Z" fill="#4C2908"/>
      <path d="M9 9.75C9 10.2141 8.81563 10.6592 8.48744 10.9874C8.15925 11.3156 7.71413 11.5 7.25 11.5C6.78587 11.5 6.34075 11.3156 6.01256 10.9874C5.68437 10.6592 5.5 10.2141 5.5 9.75L7.25 9.75H9Z" fill="#3E4347"/>
      <path d="M14.5 9.75C14.5 10.2141 14.3156 10.6592 13.9874 10.9874C13.6592 11.3156 13.2141 11.5 12.75 11.5C12.2859 11.5 11.8408 11.3156 11.5126 10.9874C11.1844 10.6592 11 10.2141 11 9.75L12.75 9.75H14.5Z" fill="#3E4347"/>
    </g>
    <defs>
      <clipPath id="clip0_3220_7811">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
