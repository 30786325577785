<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_13199_62102)">
      <path
        d="M4.5066 27.5045C4.25354 27.5045 4.00049 27.4151 3.79209 27.2514C2.28866 26.0159 1.45508 24.0362 1.45508 21.6694V9.76103C1.45508 4.6702 3.43484 2.69043 8.52566 2.69043H17.4569C20.4936 2.69043 23.6791 3.25608 24.3637 7.49843C24.468 8.10874 24.0512 8.67439 23.4409 8.77859C22.8305 8.88278 22.2649 8.46599 22.1608 7.85568C21.8481 5.92058 21.0295 4.92326 17.4569 4.92326H8.52566C4.68522 4.92326 3.68789 5.92058 3.68789 9.76103V21.6694C3.68789 22.637 3.88141 24.4381 5.20621 25.5247C5.68254 25.9118 5.75697 26.6262 5.35506 27.1026C5.14666 27.3705 4.81918 27.5045 4.5066 27.5045Z"
        fill="currentColor"
      />
      <path
        d="M17.457 28.7401H10.0142C9.40393 28.7401 8.89783 28.234 8.89783 27.6236C8.89783 27.0133 9.40393 26.5072 10.0142 26.5072H17.457C21.2974 26.5072 22.2947 25.5099 22.2947 21.6695V14.2268C22.2947 13.6164 22.8008 13.1104 23.4111 13.1104C24.0215 13.1104 24.5275 13.6164 24.5275 14.2268V21.6695C24.5275 26.7603 22.5478 28.7401 17.457 28.7401Z"
        fill="currentColor"
      />
      <path
        d="M29.0675 24.7062C28.4275 24.7062 27.6386 24.4978 26.7007 23.8428L22.771 21.089C22.2649 20.7318 22.1458 20.0322 22.503 19.541C22.8603 19.0348 23.545 18.9158 24.0511 19.273L27.9809 22.0268C28.6359 22.4883 29.1122 22.533 29.3057 22.4287C29.4993 22.3246 29.7374 21.9226 29.7374 21.1188V8.27265C29.7374 7.66236 30.2435 7.15625 30.8538 7.15625C31.4641 7.15625 31.9702 7.66236 31.9702 8.27265V21.1039C31.9702 23.2326 30.9431 24.081 30.3329 24.3936C30.0499 24.5424 29.6183 24.7062 29.0675 24.7062Z"
        fill="currentColor"
      />
      <path
        d="M1.11269 32C0.829864 32 0.54704 31.8959 0.323759 31.6725C-0.10792 31.2408 -0.10792 30.5264 0.323759 30.0947L30.0947 0.323759C30.5264 -0.10792 31.2408 -0.10792 31.6725 0.323759C32.1041 0.755436 32.1041 1.46993 31.6725 1.90161L1.90161 31.6725C1.69321 31.8959 1.39551 32 1.11269 32Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13199_62102">
        <rect width="32" height="32" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
