<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/arrow-up">
      <g id="arrow-up">
        <path
          id="Vector"
          d="M16.5998 13.1669C16.4414 13.1669 16.2831 13.1086 16.1581 12.9836L10.7248 7.55023C10.3248 7.15023 9.67477 7.15023 9.27477 7.55023L3.84144 12.9836C3.59977 13.2252 3.19977 13.2252 2.95811 12.9836C2.71644 12.7419 2.71644 12.3419 2.95811 12.1002L8.39144 6.66689C9.27477 5.78356 10.7164 5.78356 11.6081 6.66689L17.0414 12.1002C17.2831 12.3419 17.2831 12.7419 17.0414 12.9836C16.9164 13.1002 16.7581 13.1669 16.5998 13.1669Z"
          fill="#49A399"
        />
      </g>
    </g>
  </svg>
</template>
