<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.54493 0.846145C3.25133 1.11626 1.85697 3.66178 2.85197 5.76226C4.09813 8.3929 7.81945 8.43194 9.10885 5.8279C10.3377 3.34626 8.31565 0.519865 5.54493 0.846145ZM6.40429 1.88831C8.31161 2.23503 9.09357 4.52919 7.78589 5.94163C6.24673 7.60407 3.46005 6.43434 3.56921 4.17166C3.63949 2.71458 4.98565 1.63043 6.40429 1.88831ZM10.6732 2.22442C10.1905 2.51078 10.4127 3.12842 11.0196 3.18722C13.3238 3.41046 13.304 6.6509 10.9974 6.81086C10.5383 6.8427 10.3723 6.96895 10.3489 7.30406C10.3133 7.81174 10.873 7.98742 11.7261 7.73638C14.2167 7.00338 14.5169 3.61262 12.1948 2.44186C11.6949 2.18978 10.9186 2.07887 10.6732 2.22442ZM5.48241 8.29911C5.43085 8.30607 5.26213 8.32702 5.10741 8.34562C2.19061 8.69678 0.387733 10.7416 1.27321 12.6945C2.16533 14.662 5.60453 15.6259 8.39905 14.6915C12.2723 13.3966 12.0177 9.54842 7.99057 8.51702C7.27693 8.33423 6.02681 8.22563 5.48241 8.29911ZM12.0449 8.85658C11.5204 9.0907 11.655 9.71218 12.2651 9.87335C14.3402 10.4215 14.27 12.2443 12.1514 12.8257C11.6543 12.9621 11.5495 13.4929 11.9644 13.7725C12.1436 13.8933 12.4588 13.849 13.0213 13.6241C15.4009 12.6726 15.4236 10.027 13.0606 9.05206C12.5657 8.84791 12.2154 8.78047 12.0449 8.85658ZM6.80397 9.34546C9.53313 9.64382 10.9673 11.4255 9.59557 12.8135C7.80209 14.6283 3.24189 14.3095 2.24601 12.2998C1.43545 10.664 3.95093 9.03354 6.80397 9.34546Z"
      fill="currentColor"
    />
  </svg>
</template>
