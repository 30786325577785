<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
    <rect width="60" height="60" rx="8" fill="#F1F1F1" />
    <path
      d="M38.34 38.955H21.66C15.69 38.955 13.875 37.14 13.875 31.17V21.66C13.875 15.69 15.69 13.875 21.66 13.875H38.325C44.295 13.875 46.11 15.69 46.11 21.66V31.155C46.125 37.14 44.31 38.955 38.34 38.955ZM21.66 16.125C16.95 16.125 16.125 16.95 16.125 21.66V31.155C16.125 35.865 16.95 36.69 21.66 36.69H38.325C43.035 36.69 43.86 35.865 43.86 31.155V21.66C43.86 16.95 43.035 16.125 38.325 16.125H21.66Z"
      fill="#9D9D9D"
    />
    <path
      d="M30 46.1251C29.385 46.1251 28.875 45.6151 28.875 45.0001V37.8301C28.875 37.2151 29.385 36.7051 30 36.7051C30.615 36.7051 31.125 37.2151 31.125 37.8301V45.0001C31.125 45.6151 30.615 46.1251 30 46.1251Z"
      fill="#9D9D9D"
    />
    <path
      d="M45 32.625H15C14.385 32.625 13.875 32.115 13.875 31.5C13.875 30.885 14.385 30.375 15 30.375H45C45.615 30.375 46.125 30.885 46.125 31.5C46.125 32.115 45.615 32.625 45 32.625Z"
      fill="#9D9D9D"
    />
    <path
      d="M36.75 46.125H23.25C22.635 46.125 22.125 45.615 22.125 45C22.125 44.385 22.635 43.875 23.25 43.875H36.75C37.365 43.875 37.875 44.385 37.875 45C37.875 45.615 37.365 46.125 36.75 46.125Z"
      fill="#9D9D9D"
    />
  </svg>
</template>
