<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt"
    height="1267.000000pt"
    viewBox="0 0 1280.000000 1267.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1267.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M9600 11542 c-8 -5 -60 -15 -115 -21 -55 -7 -112 -19 -127 -27 -15
-8 -38 -14 -51 -14 -50 0 -255 -114 -348 -194 -20 -17 -52 -44 -73 -60 -34
-28 -206 -271 -206 -293 0 -6 -9 -22 -21 -37 -11 -14 -78 -139 -148 -278 -71
-139 -138 -269 -150 -288 -11 -19 -26 -46 -32 -60 -29 -66 -54 -116 -65 -130
-7 -8 -46 -82 -88 -164 -42 -82 -83 -158 -91 -170 -8 -11 -15 -27 -15 -34 0
-8 -9 -25 -20 -39 -11 -14 -20 -32 -20 -39 0 -7 -13 -32 -29 -56 -15 -24 -34
-61 -41 -83 -7 -22 -23 -53 -35 -70 -13 -16 -67 -118 -121 -225 -53 -107 -109
-213 -124 -235 -15 -22 -71 -128 -125 -235 -177 -352 -165 -337 -295 -351 -41
-5 -111 -13 -155 -20 -44 -6 -111 -14 -150 -18 -38 -4 -87 -15 -107 -24 -21
-9 -48 -17 -60 -17 -39 0 -248 -69 -323 -108 -16 -8 -37 -18 -45 -22 -15 -7
-43 -21 -82 -42 -64 -33 -166 -93 -191 -112 -15 -12 -46 -35 -70 -53 -107 -81
-145 -114 -226 -193 -47 -47 -140 -146 -206 -220 -286 -321 -646 -698 -763
-799 -39 -34 -72 -66 -72 -70 0 -4 -36 -39 -81 -77 -45 -38 -116 -100 -158
-139 -76 -69 -259 -213 -299 -234 -11 -6 -26 -17 -34 -24 -7 -7 -65 -46 -128
-86 -63 -40 -133 -85 -155 -101 -55 -37 -367 -198 -495 -255 -184 -81 -150
-68 -380 -145 -228 -76 -358 -111 -555 -150 -251 -49 -340 -83 -437 -168 -31
-28 -60 -52 -65 -53 -9 -4 -20 -20 -88 -121 -51 -77 -65 -107 -65 -148 0 -15
-7 -41 -15 -56 -32 -62 -8 -251 47 -359 16 -33 51 -85 77 -115 55 -65 239
-251 336 -341 204 -186 264 -240 300 -268 52 -40 77 -60 144 -123 162 -152
628 -515 852 -667 31 -20 74 -51 95 -68 22 -16 111 -79 199 -138 88 -60 185
-125 215 -146 347 -235 670 -435 915 -564 130 -69 290 -156 333 -182 20 -13
48 -27 62 -33 14 -5 32 -14 40 -20 30 -19 347 -170 357 -170 3 0 56 -24 118
-54 61 -29 194 -86 296 -126 101 -40 193 -76 204 -81 84 -36 259 -99 291 -105
21 -3 47 -11 57 -16 24 -13 231 -36 282 -32 103 7 161 17 185 29 14 7 43 17
65 21 46 8 85 30 181 100 89 66 137 120 229 259 40 61 80 116 89 124 9 7 16
19 16 25 0 6 17 36 39 67 21 31 42 66 45 77 4 12 11 22 15 22 4 0 13 12 20 27
7 16 31 60 53 98 39 67 54 91 110 180 62 100 93 153 111 190 11 22 26 47 34
56 7 8 54 96 104 195 85 166 141 273 174 334 29 52 185 380 185 388 0 5 16 39
35 76 19 37 35 73 35 79 0 7 21 53 45 102 25 50 45 97 45 106 0 8 5 20 10 25
13 13 88 204 100 254 5 20 25 71 45 113 19 43 35 84 35 90 0 7 9 29 20 50 11
20 20 43 20 51 0 8 11 36 25 62 14 26 25 54 25 63 0 9 7 25 15 35 8 11 15 27
15 35 0 9 6 27 13 41 7 14 28 68 46 120 32 94 38 109 80 203 11 26 21 57 21
68 0 12 9 38 19 58 21 39 61 164 61 190 0 9 6 25 14 36 21 31 135 379 145 443
5 31 17 77 27 102 25 65 27 606 2 690 -9 30 -19 80 -23 110 -3 30 -12 64 -20
76 -7 12 -17 39 -20 60 -4 21 -13 49 -21 61 -8 12 -14 28 -14 34 0 11 -66 147
-134 275 -17 32 -17 42 -6 93 6 31 29 82 51 117 21 33 39 65 39 70 0 6 11 25
25 44 14 19 25 41 25 49 0 8 7 22 15 31 12 14 71 124 185 350 19 36 39 72 46
80 7 8 92 173 189 365 97 193 192 377 211 410 39 69 83 155 288 559 81 160
152 296 157 303 15 17 49 83 59 113 5 14 23 59 40 100 71 168 80 219 80 448 1
191 -1 213 -24 287 -68 223 -145 351 -308 512 -126 125 -169 158 -278 216
-172 91 -200 99 -360 108 -80 4 -161 10 -180 14 -19 3 -42 1 -50 -3z m258
-694 c130 -54 233 -174 273 -319 16 -61 14 -172 -4 -246 -16 -60 -195 -426
-377 -768 -50 -93 -125 -237 -166 -320 -112 -221 -156 -306 -180 -345 -25 -41
-101 -185 -244 -465 -249 -484 -261 -505 -285 -505 -29 0 -154 46 -174 64 -9
8 -105 59 -213 114 -109 55 -210 109 -225 120 -16 10 -56 31 -90 47 -34 15
-71 37 -83 50 l-21 23 26 66 c27 67 118 250 134 270 10 12 158 302 448 876 96
190 179 350 184 356 10 12 66 121 171 329 34 66 67 127 74 135 7 8 43 76 81
150 38 74 77 149 88 165 63 97 178 193 264 219 25 8 90 15 144 15 88 1 105 -2
175 -31z m-2258 -3126 c63 -17 145 -42 182 -56 122 -46 562 -273 669 -344 280
-187 473 -500 519 -840 16 -115 7 -361 -15 -447 -39 -146 -170 -507 -187 -516
-19 -10 -169 63 -703 344 -104 54 -282 148 -395 207 -362 190 -786 413 -1145
603 -192 101 -386 203 -431 225 -115 58 -115 58 -52 135 74 90 205 236 302
335 164 170 410 301 673 361 126 29 132 30 308 26 141 -2 174 -6 275 -33z
m-1822 -1421 c136 -71 324 -168 417 -216 94 -48 242 -127 330 -175 88 -48 293
-156 455 -240 162 -85 367 -191 455 -238 88 -46 270 -141 405 -212 135 -70
286 -150 335 -177 50 -28 145 -78 213 -112 120 -61 122 -63 122 -96 0 -65
-280 -746 -473 -1153 -235 -494 -449 -880 -766 -1382 -156 -246 -166 -256
-255 -247 -113 12 -811 294 -840 340 -9 14 -6 21 15 40 15 12 57 58 94 102 37
44 77 90 89 102 43 45 72 86 278 392 128 190 150 242 150 356 1 92 -24 164
-73 211 -16 15 -29 33 -29 38 0 6 -24 24 -53 40 -44 25 -65 30 -145 34 -91 4
-95 4 -167 -32 -92 -45 -126 -84 -229 -255 -44 -73 -86 -135 -93 -139 -7 -5
-23 -27 -36 -50 -35 -66 -184 -248 -287 -352 -52 -52 -106 -110 -119 -129 -38
-52 -74 -43 -276 74 -94 54 -188 110 -210 125 -22 15 -78 49 -125 76 -47 26
-196 122 -333 212 -229 151 -246 165 -236 184 6 11 20 23 30 25 10 3 19 9 19
13 0 7 157 132 177 140 25 10 94 117 110 169 21 72 14 179 -17 248 -41 96
-157 173 -273 181 -76 6 -173 -15 -201 -43 -11 -12 -46 -39 -76 -60 -30 -21
-91 -68 -134 -103 -89 -72 -204 -132 -252 -132 -40 0 -91 34 -275 184 -369
302 -509 421 -509 433 0 7 -11 18 -24 25 -35 19 -356 321 -356 335 0 7 10 15
23 17 599 121 1186 355 1762 704 219 133 362 236 570 409 114 95 120 101 348
316 148 139 171 158 193 153 14 -4 137 -64 272 -135z"
      />
      <path
        d="M2760 8609 c-60 -34 -179 -145 -212 -197 -26 -43 -68 -212 -68 -277
1 -165 103 -309 284 -399 50 -26 76 -31 184 -39 108 -7 131 -6 166 8 132 55
160 76 270 205 30 35 57 160 57 264 0 94 -2 108 -29 164 -36 73 -95 157 -136
192 -16 14 -47 40 -68 59 -48 42 -88 50 -263 50 -127 1 -131 0 -185 -30z"
      />
      <path
        d="M1488 7099 c-144 -34 -279 -172 -337 -344 -19 -58 -23 -86 -20 -160
4 -80 8 -99 44 -172 22 -46 43 -83 47 -83 4 0 28 -18 53 -40 25 -22 53 -40 60
-40 8 0 19 -8 24 -17 5 -10 40 -29 77 -42 58 -21 86 -24 203 -24 126 -1 140 1
192 25 66 30 154 116 185 179 91 185 85 403 -13 540 -19 27 -38 49 -42 49 -4
0 -29 20 -54 44 -65 61 -174 96 -295 95 -48 -1 -104 -5 -124 -10z"
      />
    </g>
  </svg>
</template>
