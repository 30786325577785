<template>
  <svg viewBox="0 0 20 20" width="20" height="20" fill="none">
    <path d="M10 18.958c-4.941 0-8.958-4.016-8.958-8.958S5.059 1.042 10 1.042c4.942 0 8.959 4.016 8.959 8.958 0 4.941-4.017 8.958-8.959 8.958Zm0-16.666C5.75 2.291 2.292 5.75 2.292 10S5.75 17.708 10 17.708 17.71 14.25 17.71 10 14.25 2.292 10 2.292Z" fill="currentColor"/>
    <path d="M7.5 18.125h-.833a.63.63 0 0 1-.625-.625.62.62 0 0 1 .608-.625 24.575 24.575 0 0 1 0-13.75.62.62 0 0 1-.608-.625.63.63 0 0 1 .625-.625H7.5c.2 0 .392.1.509.258.116.167.15.375.083.567a23.29 23.29 0 0 0 0 14.608c.067.192.033.4-.083.567a.656.656 0 0 1-.509.25ZM12.5 18.125a.62.62 0 0 1-.592-.825 23.29 23.29 0 0 0 0-14.608.624.624 0 1 1 1.183-.4 24.432 24.432 0 0 1 0 15.392.62.62 0 0 1-.591.441Z" fill="currentColor"/>
    <path d="M10 14.334c-2.325 0-4.642-.325-6.875-.984a.626.626 0 0 1-.625.609.63.63 0 0 1-.625-.625V12.5c0-.2.1-.392.258-.508a.626.626 0 0 1 .567-.084 23.29 23.29 0 0 0 14.608 0 .626.626 0 0 1 .567.084.617.617 0 0 1 .258.508v.834a.63.63 0 0 1-.625.625.62.62 0 0 1-.625-.609 24.414 24.414 0 0 1-6.883.984ZM17.5 8.125a.56.56 0 0 1-.2-.033 23.29 23.29 0 0 0-14.608 0A.624.624 0 0 1 1.9 7.7a.636.636 0 0 1 .4-.792 24.431 24.431 0 0 1 15.392 0 .62.62 0 0 1 .392.792.608.608 0 0 1-.584.425Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
