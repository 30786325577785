<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99972 14.9331C11.7253 14.9331 14.7455 11.8289 14.7455 7.99974C14.7455 4.17057 11.7253 1.06641 7.99972 1.06641C4.27411 1.06641 1.25391 4.17057 1.25391 7.99974C1.25391 11.8289 4.27411 14.9331 7.99972 14.9331Z" fill="url(#paint0_linear_4_4624)"/>
    <path d="M8.00068 11.4962C10.0538 11.4962 11.7549 9.92863 12.0482 7.87878C12.1068 7.57733 11.8722 7.27588 11.5202 7.27588H4.42247C4.12917 7.27588 3.89453 7.57733 3.89453 7.87878C4.24649 9.92863 5.94761 11.4962 8.00068 11.4962Z" fill="white"/>
    <path d="M6.91564 4.92389C6.65592 4.20208 6.02826 3.73291 5.32486 3.73291C4.63227 3.73291 4.01544 4.19005 3.75572 4.8878C3.70161 5.02013 3.7449 5.17652 3.85311 5.26073C3.96133 5.34494 4.10201 5.33291 4.19941 5.22464C4.70803 4.67126 5.51965 4.22614 6.47196 5.2487C6.52606 5.30885 6.59099 5.33291 6.66675 5.33291C6.72085 5.33291 6.77496 5.32088 6.81825 5.28479C6.91565 5.21261 6.95893 5.05622 6.91564 4.92389Z" fill="#303030"/>
    <path d="M12.2432 4.92389C11.984 4.20208 11.3576 3.73291 10.6556 3.73291C9.96443 3.73291 9.34884 4.19005 9.08965 4.8878C9.03565 5.02013 9.07886 5.17652 9.18685 5.26073C9.29485 5.34494 9.43525 5.33291 9.53244 5.22464C10.04 4.67126 10.85 4.22614 11.8004 5.2487C11.8544 5.30885 11.9192 5.33291 11.9948 5.33291C12.0488 5.33291 12.1028 5.32088 12.146 5.28479C12.254 5.21261 12.2972 5.05622 12.2432 4.92389Z" fill="#303030"/>
    <defs>
      <linearGradient id="paint0_linear_4_4624" x1="2.1492" y1="11.4698" x2="13.999" y2="4.8133" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBB34D"/>
        <stop offset="0.033108" stop-color="#ECB64D"/>
        <stop offset="0.1781" stop-color="#EEC04F"/>
        <stop offset="0.5514" stop-color="#F1CC51"/>
        <stop offset="1" stop-color="#F3D652"/>
      </linearGradient>
    </defs>
  </svg>
</template>
