<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M16.5 2.8125H1.5C1.1925 2.8125 0.9375 2.5575 0.9375 2.25C0.9375 1.9425 1.1925 1.6875 1.5 1.6875H16.5C16.8075 1.6875 17.0625 1.9425 17.0625 2.25C17.0625 2.5575 16.8075 2.8125 16.5 2.8125Z"
        fill="#49A399"/>
    <path
        d="M8.25 7.3125H1.5C1.1925 7.3125 0.9375 7.0575 0.9375 6.75C0.9375 6.4425 1.1925 6.1875 1.5 6.1875H8.25C8.5575 6.1875 8.8125 6.4425 8.8125 6.75C8.8125 7.0575 8.5575 7.3125 8.25 7.3125Z"
        fill="#49A399"/>
    <path
        d="M6 11.8125H1.5C1.1925 11.8125 0.9375 11.5575 0.9375 11.25C0.9375 10.9425 1.1925 10.6875 1.5 10.6875H6C6.3075 10.6875 6.5625 10.9425 6.5625 11.25C6.5625 11.5575 6.3075 11.8125 6 11.8125Z"
        fill="#49A399"/>
    <path
        d="M4.5 16.3125H1.5C1.1925 16.3125 0.9375 16.0575 0.9375 15.75C0.9375 15.4425 1.1925 15.1875 1.5 15.1875H4.5C4.8075 15.1875 5.0625 15.4425 5.0625 15.75C5.0625 16.0575 4.8075 16.3125 4.5 16.3125Z"
        fill="#49A399"/>
    <path
        d="M8.88012 17.063C7.67262 17.063 6.68262 16.0805 6.68262 14.8655C6.68262 13.658 7.66512 12.668 8.88012 12.668C10.0951 12.668 11.0776 13.6505 11.0776 14.8655C11.0776 16.0805 10.0951 17.063 8.88012 17.063ZM8.88012 13.8005C8.28762 13.8005 7.80762 14.2805 7.80762 14.873C7.80762 15.4655 8.28762 15.9455 8.88012 15.9455C9.47262 15.9455 9.95262 15.4655 9.95262 14.873C9.95262 14.2805 9.47262 13.8005 8.88012 13.8005Z"
        fill="#49A399"/>
    <path
        d="M10.5151 15.4285C10.2076 15.4285 9.95264 15.1735 9.95264 14.866V8.28847C9.95264 7.38097 10.4926 6.66849 11.3701 6.43599L14.6326 5.54349C15.3451 5.34849 15.9601 5.42347 16.3951 5.76097C16.8301 6.09847 17.0551 6.64597 17.0551 7.40347V13.786C17.0551 14.0935 16.8001 14.3485 16.4926 14.3485C16.1851 14.3485 15.9301 14.0935 15.9301 13.786V7.40347C15.9301 7.02847 15.8551 6.76598 15.7126 6.66098C15.5701 6.54848 15.2776 6.54096 14.9251 6.63846L11.6626 7.53096C11.4451 7.59096 11.0776 7.77098 11.0776 8.30348V14.881C11.0776 15.181 10.8226 15.4285 10.5151 15.4285Z"
        fill="#49A399"/>
    <path
        d="M14.865 15.9751C13.6575 15.9751 12.6675 14.9926 12.6675 13.7776C12.6675 12.5701 13.65 11.5801 14.865 11.5801C16.08 11.5801 17.0625 12.5626 17.0625 13.7776C17.0625 14.9926 16.08 15.9751 14.865 15.9751ZM14.865 12.7126C14.2725 12.7126 13.7925 13.1926 13.7925 13.7851C13.7925 14.3776 14.2725 14.8576 14.865 14.8576C15.4575 14.8576 15.9375 14.3776 15.9375 13.7851C15.9375 13.1926 15.4575 12.7126 14.865 12.7126Z"
        fill="#49A399"/>
    <path
        d="M10.5151 10.7634C10.2676 10.7634 10.0426 10.5984 9.97512 10.3509C9.89262 10.0509 10.0726 9.74339 10.3726 9.66089L16.3576 8.0259C16.6576 7.9434 16.9651 8.12338 17.0476 8.42338C17.1301 8.72338 16.9501 9.03091 16.6501 9.11341L10.6651 10.7484C10.6126 10.7559 10.5601 10.7634 10.5151 10.7634Z"
        fill="#49A399"/>
  </svg>
</template>
