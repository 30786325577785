<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#E66987" />
    <path
      d="M20.0002 28.9654C19.0918 28.9654 18.1918 28.6987 17.4835 28.1737L13.9002 25.4987C12.9502 24.7904 12.2085 23.3154 12.2085 22.132V15.9404C12.2085 14.657 13.1502 13.2904 14.3585 12.8404L18.5168 11.282C19.3418 10.9737 20.6418 10.9737 21.4668 11.282L25.6335 12.8404C26.8418 13.2904 27.7835 14.657 27.7835 15.9404V22.132C27.7835 23.3154 27.0418 24.7904 26.0918 25.4987L22.5085 28.1737C21.8085 28.6987 20.9085 28.9654 20.0002 28.9654ZM18.9585 12.4487L14.8002 14.007C14.0835 14.2737 13.4585 15.1737 13.4585 15.9404V22.132C13.4585 22.9237 14.0168 24.032 14.6418 24.4987L18.2252 27.1737C19.1835 27.8903 20.8085 27.8903 21.7668 27.1737L25.3502 24.4987C25.9835 24.0237 26.5335 22.9154 26.5335 22.132V15.9404C26.5335 15.182 25.9085 14.282 25.1918 14.007L21.0335 12.4487C20.4835 12.2404 19.5168 12.2404 18.9585 12.4487Z"
      fill="white"
    />
    <path
      d="M20.0001 19.7258C19.9834 19.7258 19.9667 19.7258 19.9417 19.7258C18.7334 19.6925 17.8501 18.7675 17.8501 17.6425C17.8501 16.4925 18.7917 15.5508 19.9417 15.5508C21.0917 15.5508 22.0334 16.4925 22.0334 17.6425C22.0251 18.7758 21.1417 19.6924 20.0167 19.7341C20.0084 19.7258 20.0084 19.7258 20.0001 19.7258ZM19.9417 16.8008C19.4751 16.8008 19.1001 17.1758 19.1001 17.6425C19.1001 18.1008 19.4584 18.4674 19.9084 18.4841C19.9084 18.4841 19.9501 18.4841 20.0001 18.4841C20.4418 18.4591 20.7834 18.0925 20.7834 17.6425C20.7918 17.1758 20.4084 16.8008 19.9417 16.8008Z"
      fill="white"
    />
    <path
      d="M20.0005 24.4585C19.2838 24.4585 18.5588 24.2668 18.0005 23.8918C17.4422 23.5251 17.1255 22.9835 17.1255 22.4085C17.1255 21.8335 17.4422 21.2918 18.0005 20.9168C19.1255 20.1668 20.8838 20.1751 22.0005 20.9168C22.5588 21.2835 22.8755 21.8251 22.8755 22.4001C22.8755 22.9751 22.5588 23.5168 22.0005 23.8918C21.4422 24.2668 20.7172 24.4585 20.0005 24.4585ZM18.6922 21.9501C18.4838 22.0835 18.3672 22.2501 18.3755 22.4001C18.3755 22.5501 18.4922 22.7168 18.6922 22.8501C19.3922 23.3168 20.6088 23.3168 21.3088 22.8501C21.5172 22.7168 21.6338 22.5501 21.6338 22.4001C21.6338 22.2501 21.5172 22.0835 21.3172 21.9501C20.6172 21.4918 19.3922 21.4918 18.6922 21.9501Z"
      fill="white"
    />
  </svg>
</template>
