<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <g fill="#B0B0B0" clip-path="url(#a)">
      <path d="M7.046 0A7.054 7.054 0 0 0 0 7.046a7.054 7.054 0 0 0 7.046 7.046 7.054 7.054 0 0 0 7.046-7.046A7.054 7.054 0 0 0 7.046 0Zm0 12.791a5.752 5.752 0 0 1-5.745-5.745 5.752 5.752 0 0 1 5.745-5.745 5.752 5.752 0 0 1 5.745 5.745 5.752 5.752 0 0 1-5.745 5.745Z"/>
      <path d="m15.81 14.89-3.729-3.729a.65.65 0 1 0-.92.92l3.73 3.729a.648.648 0 0 0 .919 0 .65.65 0 0 0 0-.92Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>
