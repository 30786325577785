<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_121070)">
      <rect x="4.57031" y="4.57422" width="6.85714" height="6.85714" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.57095 0.360465C5.03643 0.511025 4.74719 0.880705 4.38675 1.87394C4.09159 2.68738 4.16091 2.64146 3.15623 2.68918C1.42811 2.77126 0.983025 3.4159 1.47199 5.12858C1.53851 5.36159 1.60479 5.63866 1.61923 5.74434L1.64551 5.93647L1.48679 6.08887C1.39951 6.17267 1.17554 6.35915 0.989065 6.50319C0.236625 7.08451 0.015625 7.42466 0.015625 8.00122C0.015625 8.58867 0.278505 8.97539 1.09374 9.58715C1.69951 10.0417 1.70939 10.0725 1.48879 10.8159C0.967585 12.5721 1.41163 13.2317 3.17187 13.3157C4.14267 13.362 4.10747 13.3407 4.34851 14.0276C4.98339 15.8369 5.7463 16.1286 7.22934 15.1291C7.46222 14.9721 7.73091 14.808 7.82639 14.7643L7.99999 14.6848L8.16883 14.7621C8.26171 14.8046 8.53067 14.968 8.76659 15.1253C9.45434 15.5836 9.66987 15.6715 10.1093 15.6727C10.8145 15.6745 11.1939 15.2853 11.6132 14.1298C11.9078 13.318 11.841 13.3628 12.8281 13.3157C14.5733 13.2324 15.0185 12.5933 14.528 10.8751C14.4615 10.6421 14.3952 10.3651 14.3807 10.2594L14.3545 10.0673L14.5132 9.91487C14.6005 9.83107 14.8244 9.64458 15.0109 9.50055C15.7636 8.91899 15.9843 8.57915 15.9843 8.00187C15.9843 7.41559 15.7664 7.08739 14.9613 6.46127C14.2847 5.9351 14.2873 5.94246 14.5112 5.18786C15.0311 3.43602 14.588 2.77202 12.8437 2.68918C11.8391 2.64146 11.9084 2.68738 11.6132 1.87394C10.9776 0.122305 10.1413 -0.134455 8.64147 0.961385C8.53787 1.03706 8.35115 1.14958 8.22655 1.21142L7.99999 1.32386L7.77343 1.21118C7.64883 1.14922 7.44843 1.02706 7.32811 0.939665C6.60423 0.413985 6.04055 0.228185 5.57095 0.360465ZM10.7747 5.74898C11.1006 5.91662 11.2393 6.27506 11.0962 6.57999C11.0135 6.75631 7.56002 10.2001 7.39943 10.2664C7.02814 10.4197 6.96027 10.3771 5.91303 9.33487C4.73703 8.16439 4.64627 7.98763 5.00923 7.57423C5.38755 7.14335 5.67343 7.24551 6.54667 8.12371L7.17147 8.75203L8.65602 7.26874C9.59355 6.33203 10.1809 5.76819 10.25 5.73863C10.4036 5.67287 10.6356 5.67746 10.7747 5.74898Z"
            fill="#49ADF4"/>
    </g>
    <defs>
      <clipPath id="clip0_4_121070">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
